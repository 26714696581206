import {Inject, Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {
  ChangeStateOnboardingAction, ConfirmEmailAction,
  GetUserAction, PasswordRecoveryConfirmAction, SignInAction, SignInSuccessAction,
  SignOutAction,
  SignUpAction, SignUpStepCompanyAction,
  SignUpStepPersonalContactAction,
  SignUpStepPersonalDataAction
} from '@core/redux/auth/auth.actions';
import {IdentityModel, UserOnboarding} from '@core/models/identity.model';
import {SignUpModel} from '@core/models/sign-up.model';
import {SignUpPersonalDataModel} from '@core/models/sign-up-personal-data.model';
import {SignUpPersonalContactModel} from '@core/models/sign-up-personal-contact.model';
import * as fromRoot from '@core/redux/index';
import * as AuthActions from '@core/redux/auth/auth.actions';
import {SignUpCompanyModel} from '@core/models/sign-up-company.model';
import {UserModel} from '@core/models/user.model';
import {SignInModel} from '@core/models/sign-in.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private store: Store<fromRoot.State>,) {
  }

  signOut() {
    this.store.dispatch(new SignOutAction());
  }

  signIn(model: SignInModel) {
    this.store.dispatch(new SignInAction(model));
  }

  signInSuccess(model: IdentityModel) {
    this.store.dispatch(new SignInSuccessAction(model));
  }

  signUp(model: SignUpModel) {
    this.store.dispatch(new SignUpAction(model));
  }

  saveStepPersonalData(model: SignUpPersonalDataModel) {
    this.store.dispatch(new SignUpStepPersonalDataAction(model));
  }

  saveStepPersonalContact(model: SignUpPersonalContactModel) {
    this.store.dispatch(new SignUpStepPersonalContactAction(model));
  }

  saveStepCompanyInfo(model: SignUpCompanyModel) {
    this.store.dispatch(new SignUpStepCompanyAction(model));
  }

  profileUpdate(model: IdentityModel | UserModel) {
    this.store.dispatch(new AuthActions.ProfileUpdateAction(model));
  }

  changeStateOnboarding(model: UserOnboarding) {
    this.store.dispatch(new ChangeStateOnboardingAction(model));
  }

  fetchUser() {
    this.store.dispatch(new GetUserAction());
  }

  confirmationEmail(token: any) {
    this.store.dispatch(new ConfirmEmailAction(token));
  }

  passwordRecoveryConfirm(token: string) {
    this.store.dispatch(new PasswordRecoveryConfirmAction(token));
  }

  getUser() {
    return this.store.pipe(select(fromRoot.getAuthUser));
  }

  getIsTest() {
    return this.store.pipe(select(fromRoot.getUserIsTest));
  }

  getIsActive() {
    return this.store.pipe(select(fromRoot.getUserIsActive));
  }

  getIsAuth() {
    return this.store.pipe(select(fromRoot.getAuthStep));
  }

  getAuthErrors() {
    return this.store.pipe(select(fromRoot.getAuthErrors));
  }

  getLogo(): Observable<string> {
    return this.getUser().pipe(
      map(user => (user && user.logo) || 'Mybase'),
    );
  }

  getToken(): Observable<string> {
    return this.store.pipe(select(fromRoot.getToken));
  }
}
