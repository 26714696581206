import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UiHeaderFilterMenuComponent } from '../ui-header-filter-menu/ui-header-filter-menu.component';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'ui-header-app',
  templateUrl: './ui-header-app.component.html',
  styleUrls: ['./ui-header-app.component.less']
})
export class UiHeaderAppComponent implements OnInit, OnDestroy {
  isOpen: Boolean = false;
  onWikiPage = false;

  private readonly subscription = new Subscription();

  constructor(
    private _activeRoute: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.isOpen = !!this._activeRoute.snapshot.queryParamMap.get('match');
    this.onRouteChanges();
    this.subscribeToRoute();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onOpenSearch() {
    this.isOpen = true;
  }

  onChanged(increased: any) {
    this.isOpen = false;
  }

  onCloseSearch() {
    this.isOpen = false;
  }

  onOpenFilters() {
    this.dialog.open(UiHeaderFilterMenuComponent, {
      height: '100%',
      width: '288px',
      closeOnNavigation: true,
      position: {
        right: '0'
      },
    });
  }

  private onRouteChanges() {
    this.onWikiPage = this.router.url.includes('/wiki/')
  }

  private subscribeToRoute() {
    this.subscription.add(this
      .router
      .events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => this.onRouteChanges()));
  }
}
