import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FileModel } from '@core/models/file.model';
import { ApiFileService } from '@core/services/entities/file/api-file.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'ui-files',
  templateUrl: './ui-files.component.html',
  styleUrls: ['./ui-files.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiFilesComponent implements OnDestroy {
  private readonly _subscriptions$: Subscription = new Subscription();
  @Output() onDeleted: EventEmitter<string> = new EventEmitter<string>();
  @Input() inComments = false;
  @Input() focusFirstImage = false;

  private _files: FileModel[];
  @Input()
  set files(value: FileModel[]) {
    this._files = value;
    this._prepareData(value);
  }

  get files() {
    return this._files;
  }

  @Input() isReadOnly = true;

  imagesForView: FileModel[] = [];
  filesForView: FileModel[] = [];

  constructor(private _apiFileService: ApiFileService,
    private _cdr: ChangeDetectorRef,
  ) {
  }

  ngOnDestroy(): void {
    this._subscriptions$.unsubscribe();
  }

  onDelete(item: FileModel) {
    this._subscriptions$.add(
      this._apiFileService.delete(item.id).subscribe(() => this.onDeleted.emit(item.id))
    );
  }

  trackByFn(index: number, item: FileModel) {
    return item.id;
  }

  private _prepareData(files: FileModel[]) {
    if (files) {
      this.imagesForView = files.filter((item) => item.isImage);
      this.filesForView = files.filter((item) => !item.isImage);
    } else {
      this.imagesForView = [];
      this.filesForView = [];
    }

    this._cdr.detectChanges();
  }
}
