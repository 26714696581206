import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiPopupComponent} from '@ui/modules/ui-popup/ui-popup.component';

@NgModule({
  declarations: [
    UiPopupComponent
  ],
  exports: [
    UiPopupComponent
  ],
  imports: [
    CommonModule
  ]
})
export class UiPopupModule {
}
