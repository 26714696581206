import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiContainerComponent} from '@ui/modules/ui-container/ui-container.component';

@NgModule({
  declarations: [
    UiContainerComponent
  ],
  exports: [
    UiContainerComponent
  ],
  imports: [
    CommonModule
  ]
})
export class UiContainerModule {
}
