import {Action} from '@ngrx/store';
import {EventModel, PostEventModel, PutEventModel} from '@core/models';

export enum EventActionTypes {
  DeleteAction = '[Event] Delete',
  DeleteActionSuccess = '[Event] Delete Success',
  DeleteActionFailure = '[Event] Delete Failure',

  GetEventsAction = '[Event] Get Events',
  GetEventsSuccessAction = '[Event] Get Events Success',
  GetEventsFailureAction = '[Event] Get Events Failure',

  SelectAction = '[Event] Select',

  UpdateAction = '[Event] Update',
  UpdateActionSuccess = '[Event] Update Success',
  UpdateActionFailure = '[Event] Update Failure',

  CreateAction = '[Event] Create',
  CreateActionSuccess = '[Event] Create Success',
  CreateActionFailure = '[Event] Create Failure',
}

export class DeleteAction implements Action {
  readonly type = EventActionTypes.DeleteAction;

  constructor(public payload: string) {
  }
}

export class DeleteActionSuccess implements Action {
  readonly type = EventActionTypes.DeleteActionSuccess;

  constructor(public payload: string) {
  }
}

export class DeleteActionFailure implements Action {
  readonly type = EventActionTypes.DeleteActionFailure;

  constructor(public payload: any) {
  }
}

export class SelectAction implements Action {
  readonly type = EventActionTypes.SelectAction;

  constructor(public payload: string) {
  }
}


export class UpdateAction implements Action {
  readonly type = EventActionTypes.UpdateAction;

  constructor(public payload: PutEventModel) {
  }
}

export class UpdateActionSuccess implements Action {
  readonly type = EventActionTypes.UpdateActionSuccess;

  constructor(public payload: EventModel) {
  }
}

export class UpdateActionFailure implements Action {
  readonly type = EventActionTypes.UpdateActionFailure;

  constructor(public payload: any) {
  }
}

export class GetEventsAction implements Action {
  readonly type = EventActionTypes.GetEventsAction;

  constructor() {
  }
}

export class GetEventsSuccessAction implements Action {
  readonly type = EventActionTypes.GetEventsSuccessAction;

  constructor(public payload: EventModel[]) {
  }
}

export class GetEventsFailureAction implements Action {
  readonly type = EventActionTypes.GetEventsFailureAction;

  constructor(public payload: any) {
  }
}

export class CreateAction implements Action {
  readonly type = EventActionTypes.CreateAction;

  constructor(public payload: PostEventModel) {
  }
}


export class CreateActionSuccess implements Action {
  readonly type = EventActionTypes.CreateActionSuccess;

  constructor(public payload: EventModel) {
  }
}

export class CreateActionFailure implements Action {
  readonly type = EventActionTypes.CreateActionFailure;

  constructor(public payload: any) {
  }
}


export type EventActions = SelectAction
  | GetEventsAction
  | GetEventsSuccessAction
  | GetEventsFailureAction
  | UpdateAction
  | UpdateActionSuccess
  | UpdateActionFailure
  | CreateAction
  | CreateActionSuccess
  | CreateActionFailure
  | DeleteAction
  | DeleteActionSuccess
  | DeleteActionFailure
  ;
