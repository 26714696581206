<ui-tooltip arrow="left"
            left="-3"
            [textNowrap]="true"
            [hideTip]="!tooltip"
            position="top">
  <a class="ui-category-block-header-control__item"
     ngProjectAs="content"
     [class.ui-category-block-header-control__item_active]="isActive">
    <span [ngClass]="cssClass"></span>
  </a>
  <span ngProjectAs="tip">{{tooltip}}</span>
</ui-tooltip>
