import {Injectable} from '@angular/core';
import {UserPositionsModel} from '@core/models/user-positions.model';
import 'rxjs/add/operator/map';
import {map} from 'rxjs/operators';
import {ApiService} from '@core/services/entities/api.service';

@Injectable({
  providedIn: 'root',
})
export class ApiUserPositionsService extends ApiService {
  search() {
    return this
      .http
      .get<UserPositionsModel[]>(this.getApiUrl(`user-positions`))
      .pipe(
        map((response) => response.map(model => (new UserPositionsModel()).setAttributes(model)))
      );
  }

  update(model: UserPositionsModel) {
    return this
      .http
      .put<UserPositionsModel>(this.getApiUrl(`user-positions/${model.id}`), model)
      .pipe(
        map((response) => (new UserPositionsModel()).setAttributes(response))
      );
  }

  create(model: UserPositionsModel) {
    return this
      .http
      .post<UserPositionsModel>(this.getApiUrl(`user-positions`), model)
      .pipe(
        map((response) => (new UserPositionsModel()).setAttributes(response))
      );
  }

  delete(id: string) {
    return this.http.delete(this.getApiUrl(`user-positions/${id}`));
  }
}
