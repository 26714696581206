import {Pipe, PipeTransform} from '@angular/core';
import {environment} from '../../../environments/environment';

const API_URL = environment.apiUrl;

@Pipe({name: 'imagePreview'})
export class ImagePreviewPipe implements PipeTransform {

  transform(value: any, width?: number, height?: number, action = '', companyId?: string, accessToken?: string): string {
    let url = `${API_URL}files/${action}${value.id}?`;

    if (companyId && accessToken) {
      url += `companyId=${companyId}&accessToken=${accessToken}`;
    }

    if (width) {
      url += `&width=${width}`;
    }

    if (height) {
      url += `&height=${height}`;
    }

    return url;
  }
}
