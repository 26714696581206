import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UiPopupModule } from '@ui/modules/ui-popup/ui-popup.module';
import { UiRatingModule } from '@ui/modules/ui-rating/ui-rating.module';
import { UiAvatarComponent } from '@ui/modules/ui-avatar/ui-avatar.component';
import { UiUserDetailsComponent } from './ui-user-details/ui-user-detaits.component';

@NgModule({
  declarations: [
    UiAvatarComponent,
    UiUserDetailsComponent,
  ],
  exports: [
    UiAvatarComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    UiPopupModule,
    UiRatingModule,
  ]
})
export class UiAvatarModule {
}
