import { Injectable, OnDestroy } from '@angular/core';
import { IdentityModel } from '@core/models/identity.model';
import { AuthService } from '@core/services/entities/auth/auth.service';
import { Subscription } from 'rxjs/Subscription';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ModuleService implements OnDestroy {
  private readonly _subscriptions$: Subscription = new Subscription();

  isVisibleServiceAndInvoiceBlock = false;
  isVisiblePartnerCabinet = true;

  constructor(private _authService: AuthService) {
    this._subscriptionUser();
  }

  ngOnDestroy() {
    this._subscriptions$.unsubscribe();
  }

  private _subscriptionUser() {
    this._subscriptions$.add(this
      ._authService
      .getUser()
      .pipe(
        filter(user => !!user)
      )
      .subscribe((user: IdentityModel) => {
        if (user.sourceDomain === 'burobase.ru') {
          this.isVisibleServiceAndInvoiceBlock = true;
          this.isVisiblePartnerCabinet = false;
        } else {
          this.isVisibleServiceAndInvoiceBlock = false;
          this.isVisiblePartnerCabinet = true;
        }
      })
    );
  }
}
