import {Component, Input, OnDestroy, OnInit, ViewChild, AfterViewInit, ElementRef} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '@core/redux/index';
import {Subscription} from 'rxjs/Subscription';
import {filter} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { OnboardingStateService } from '@core/redux/onboarding/onboarding-state.service';
import { StudyAddingUserStep, StudySettingUpUserStep } from '@core/redux/onboarding/onboarding.model';
import { TooltipDirective } from '@ui/modules/tooltip/tooltip.directive';
import { OnboardingActionsService } from '@core/redux/onboarding/onboarding-actions.service';

@Component({
  selector: 'ui-header-menu',
  templateUrl: './ui-header-menu.component.html',
  styleUrls: ['./ui-header-menu.component.less']
})
export class UiHeaderMenuComponent implements AfterViewInit, OnInit, OnDestroy {
  private _subscriptions$: Subscription = new Subscription();
  /**
   * headerActiveLineLeft - изменяет положение по левому краю ползунка
   * @type {number}
   */
  headerActiveLineLeft: Number;
  /**
   * headerActiveLineWidth - изменяет длину ползунка относительно длины выбранного элемента
   * @type {number}
   */
  headerActiveLineWidth: Number;
  /**
   * ID компании
   */
  companyId: string;

  @Input()
  view: 'main' | 'affiliate' = 'main';
  
  @ViewChild('userTipContainer', {static: false, read: ElementRef})
  userLink: ElementRef<HTMLElement>;

  @ViewChild('userTipContainer', {static: false, read: TooltipDirective})
  userTipContainer: TooltipDirective;

  @ViewChild('rightsTipContainer', {static: false, read: TooltipDirective})
  rightsTipContainer: TooltipDirective;

  readonly tooltipPosition: ConnectedPosition[] = [
    {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
      offsetY: 20,
    }
  ];

  private studyingAddingUser = false;
  private studySettingUpUser = false;

  constructor(
    private onboardingActionsService: OnboardingActionsService,
    private onboardingStateService: OnboardingStateService,
    private store: Store<fromRoot.State>,
    private router: Router,
  ) {
  }

  ngAfterViewInit() {
    this.showAddingUserTipIfNeeded();
    setTimeout(() => this.showSettingUpUserTipIfNeeded(), 3000);
  }

  ngOnInit() {
    if (this.view === 'main') {
      this.subscribeToCompany();
    } else {
      this.setActive();

      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.setActive();
        }
      });
    }
  }

  ngOnDestroy() {
    this._subscriptions$.unsubscribe();
  }

  /**
   * Изменение расположения и длины бегунка
   * @param event
   */
  onMoveSolid(event) {
    const currentElement: HTMLElement = event.target;
    if (currentElement.tagName !== 'A') {
      return;
    }
    this.headerActiveLineWidth = currentElement.clientWidth;
    this.headerActiveLineLeft = currentElement.offsetLeft;
  }

  setActive() {
    setTimeout(_ => {
      const element: HTMLElement = document.getElementsByClassName('header-menu__link_active')[0] as HTMLElement;
      if (element) {
        this.headerActiveLineWidth = element.clientWidth;
        this.headerActiveLineLeft = element.offsetLeft;
      }
    }, 100);
  }

  onNavigateToUsers() {
    if (!this.studyingAddingUser) {
      return;
    }
    this.userTipContainer.hide();
    this.onboardingActionsService.changeStudyAddingUserStep(StudyAddingUserStep.ClickToSelect);
  }

  onNavigateToArticles() {
    if (!this.studySettingUpUser) {
      return;
    }
    this.rightsTipContainer.hide();
    this.onboardingActionsService.changeStudySettingUpUserStep(StudySettingUpUserStep.Edit);
  }

  private subscribeToCompany() {
    this._subscriptions$.add(this
      .store
      .pipe(
        select(fromRoot.getCompanyStart),
        filter((company) => !!company)
      )
      .subscribe((company) => {
        this.companyId = company.id;
        this.setActive();
      }));
  }

  private showAddingUserTipIfNeeded() {
    this._subscriptions$.add(
      this.onboardingStateService.shouldStudyAddingUser().subscribe(should => {
        this.studyingAddingUser = should;
        if (should && this.userLink) {
          this.onboardingActionsService.changeStudyAddingUserStep(StudyAddingUserStep.NavigateToUsers);
          this.showAddingUserTip();
        } else if (!this.userLink) {
          console.warn('link not rendered');
        } else if (!should && this.userTipContainer) {
          this.userTipContainer.hide();
        }
      })
    );
  }

  /**
   * Скрол, после доскрола показ
   */
  private showAddingUserTip() {
    this.userLink.nativeElement.scrollIntoView({behavior: 'smooth'});
    try {
      const observer = new IntersectionObserver(entries => {
        if (entries[0] && entries[0].isIntersecting) {
          this.userTipContainer.show();
          observer.disconnect();
        }
      }, {threshold: [.5]});
      observer.observe(this.userLink.nativeElement); 
    } catch (e) {
      setTimeout(() => this.userTipContainer.show(), 600);
      console.warn(e);         
    }
  }

  private showSettingUpUserTipIfNeeded() {
    this._subscriptions$.add(
      this.onboardingStateService.getStudySettingUpUserStep().pipe(
      ).subscribe(step => {
        if (step === StudySettingUpUserStep.SetUpRights) {
          this.studySettingUpUser = true;
          this.rightsTipContainer.show();  
        } else if (this.rightsTipContainer) {
          this.rightsTipContainer.hide();
        }
      })
    );
  }
}
