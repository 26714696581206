import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild} from '@angular/router';
import {Observable, of} from 'rxjs';
import * as fromRoot from '@core/redux/index';
import {Store, select, createSelector} from '@ngrx/store';
import {OnboardingKey} from '@core/redux/onboarding/onboarding.reducer';
import {take, filter, switchMap, map, tap} from 'rxjs/operators';
import {OnboardingStateService} from './onboarding-state.service';
import {RemoteDataState} from '@helpers/types/web-data';
import {LessonStatus} from './onboarding.model';

interface OnboardingPage {
  lesson: OnboardingKey;
  link: string;
}

const onboardingPages: OnboardingPage[] = [
  {
    lesson: OnboardingKey.CreateKnowledgeBase,
    link: '/onboarding/greeting',
  },
  {
    lesson: OnboardingKey.TellAboutGoals,
    link: '/onboarding/goals',
  },
  {
    lesson: OnboardingKey.TellAboutYourself,
    link: '/onboarding/classification',
  },
];

const lessonToLearn = createSelector(
  fromRoot.getOnboardingOnboarding,
  lessons => onboardingPages.find(p => (lessons[p.lesson] as { status?: LessonStatus }).status === LessonStatus.InProgress)
);

@Injectable({
  providedIn: 'root',
})
export class OnboardingGuard implements CanActivateChild, CanActivate {
  private lessonToLearn$: Observable<OnboardingPage | undefined> = this.store.pipe(select(lessonToLearn));

  constructor(
    private onboardingService: OnboardingStateService,
    private router: Router,
    private store: Store<fromRoot.State>,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivateChild(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.onboardingService.getState().pipe(
      filter(state => state === RemoteDataState.Success || state === RemoteDataState.Failure),
      switchMap(state =>
        state === RemoteDataState.Success
          ? this.lessonToLearn$.pipe(
          map(lesson => lesson ? this.router.parseUrl(lesson.link) : true),
          )
          : of(true),
      ),
      take(1),
    );
  }

}
