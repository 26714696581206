<ng-container [ngSwitch]="view">

  <ng-container *ngSwitchCase="'main'">
    <div
      *ngIf="companyId"
      (click)="onMoveSolid($event)"
      class="header-menu__inner"
    >
      <a
        #rightsTipContainer
        (click)="onNavigateToArticles()"
        [rtTooltip]="rightsTip"
        [position]="tooltipPosition"
        [routerLink]="['/', companyId, 'wiki']"
        [routerLinkActiveOptions]="{ exact: false, __change_detection_hack__: companyId }"
        routerLinkActive="header-menu__link_active"
        class="header-menu__link"
      >
        <span class="icon-base header-menu__icon"></span>
        База знаний
      </a>
      <a
        #userTipContainer
        (click)="onNavigateToUsers()"
        [rtTooltip]="userTip"
        [position]="tooltipPosition"
        [routerLink]="['/', companyId, 'users']"
        routerLinkActive="header-menu__link_active"
        class="header-menu__link"
      >
        <span class="icon-team header-menu__icon"></span>
        команда
      </a>
      <a [routerLink]="['/', companyId, 'learning']"
         class="header-menu__link"
         routerLinkActive="header-menu__link_active">
        <span class="icon-hat header-menu__icon"></span>
        Обучение
      </a>
      <!--<a class="header-menu__link header-menu__link_disabled">-->
      <!--<span class="icon-scripts header-menu__icon"></span>-->
      <!--Скрипты-->
      <!--</a>-->
      <a [routerLink]="['/', companyId, 'events']"
         class="header-menu__link"
         routerLinkActive="header-menu__link_active">
        <span class="icon-period header-menu__icon"></span>
        События
      </a>
      <div class="header-menu__active"
           [ngStyle]="{'left.px': headerActiveLineLeft,'width.px': headerActiveLineWidth}">
      </div>
    </div>
  </ng-container>

  <div class="header-menu__inner" *ngSwitchCase="'affiliate'" (click)="onMoveSolid($event)">
    <a [routerLink]="['/affiliate/balance']"
       class="header-menu__link"
       #rla="routerLinkActive"
       routerLinkActive="header-menu__link_active">
      <span class="header-menu__icon icon-wallet"></span>
      Баланс
    </a>
    <a [routerLink]="['/affiliate/users']"
       class="header-menu__link"
       routerLinkActive="header-menu__link_active">
      <span class="icon-team header-menu__icon"></span>
      Пользователи
    </a>
    <a [routerLink]="['/affiliate/link']"
       class="header-menu__link"
       routerLinkActive="header-menu__link_active">
      <span class="icon-link header-menu__icon"></span>
      Ссылка и реклама
    </a>
    <div class="header-menu__active"
         [ngStyle]="{'left.px': headerActiveLineLeft,'width.px': headerActiveLineWidth}">
    </div>
  </div>
</ng-container>
<ng-template #userTip>
  <rt-onboarding-tooltip
    [lines]="[.5, .2, .2, .2]"
    arrow="top"
  >
    <p>
      Давайте добавим людей в вашу базу знаний
    </p>
    <p>
      Перейдите в модуль<br>
      <span class="icon-team rt-color-white"></span>
      команда
    </p>
  </rt-onboarding-tooltip>
</ng-template>
<ng-template #rightsTip>
  <rt-onboarding-tooltip
    [lines]="[1, 1, 1, .5]"
    arrow="top"
  >
    <p>
      Теперь давайте назначим права доступа к разделам отделу «Разработчики»
    </p>
    <p>
      Перейдите в модуль
      <br>
      <span class="icon-base rt-color-white"></span>
      База знаний
    </p>
  </rt-onboarding-tooltip>
</ng-template>
