import { Injectable } from '@angular/core';
import * as amplitude from '@amplitude/analytics-browser';
import {IdentityModel} from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class AmplitudeService {
  constructor() {
      amplitude.init('91aa5ed829505d7c2d4496c1b4f0e7ed');
  }

  setUser(user: IdentityModel) {
    amplitude.setUserId(user.id);
    const identify = new amplitude.Identify()
      .set('email', user.email)
      .set('firstName', user.firstName)
      .set('isTest', user.isTest ? 1 : 0)
      .set('isActive', user.isActive ? 1 : 0);
    amplitude.identify(identify);
  }

  logEvent(event: string) {
    amplitude.logEvent(event);
  }
}
