import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TestResultsComponent } from './test-results.component';

import { UiAvatarModule } from '@ui/modules/ui-avatar/ui-avatar.module';


@NgModule({
  imports: [
    CommonModule,
    UiAvatarModule
  ],
  declarations: [
    TestResultsComponent
  ],
})
export class TestResultsModule { }
