<p class="test-question__text">
  {{ question }}
</p>
<content-view [content]="text"></content-view>

<div class="test-question__answers">
  <test-answer
    *ngFor="let answer of answers"
    (valueChange)="onAnswerValueChange(answer, $event)"
    [answer]="answer"
    class="test-question__answer">
  </test-answer>
</div>
