import {Component, EventEmitter, HostListener, Output} from '@angular/core';

@Component({
  selector: 'ui-collapser',
  templateUrl: './ui-collapser.component.html',
  styleUrls: ['./ui-collapser.component.less']
})
export class UiCollapserComponent {
  @Output() changeState: EventEmitter<boolean> = new EventEmitter();
  isOpen = false;

  @HostListener('click', ['$event'])
  onClick(e) {
    this.onChangeState();
  }

  constructor() {
  }

  onChangeState() {
    this.isOpen = !this.isOpen;
    this.changeState.emit(this.isOpen);
  }
}
