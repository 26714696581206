import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  CreateAction,
  CreateActionFailure,
  CreateActionSuccess,
  SearchFailureAction,
  SearchSuccessAction,
  UpdateAction,
  UpdateActionFailure,
  UpdateActionSuccess,
  SearchAction,
  FavoriteAction,
  FavoriteActionSuccess,
  FavoriteActionFailure,
  ViewAction,
  ViewActionFailure,
  ViewActionSuccess,
  ArchiveAction,
  ArchiveActionSuccess,
  ArchiveActionFailure,
  ChangeCategoryAction,
  ChangeCategoryActionSuccess,
  ChangeCategoryActionFailure,
  SortAction,
  SortActionSuccess,
  ReadAction,
  WikiActionTypes,
  DislikeAction, LikeAction, LikeActionSuccess, LikeActionFailure, DislikeActionSuccess, DislikeActionFailure
} from './wiki.actions';
import {SearchAction as TagSearchAction} from '@core/redux/tag/tag.actions';
import {map, exhaustMap, catchError, switchMap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs/index';
import {WikiModel, WikiSortInterface} from '@core/models';
import {ApiWikiService} from '@core/services/entities/wiki/api-wiki.service';
import { OnboardingStateService } from '../onboarding/onboarding-state.service';
import { StudyCreatingFromLinkStep } from '../onboarding/onboarding.model';
import { setFirstLinkArticle } from '../onboarding/onboarding.actions';

@Injectable()
export class WikiEffects {
  constructor(
    private actions$: Actions,
    private apiWikiService: ApiWikiService,
    private onboardingStateService: OnboardingStateService,
  ) {
  }

  @Effect()
  read$ = this.actions$.pipe(
    ofType(WikiActionTypes.ReadAction),
    map((action: ReadAction) => action.payload),
    exhaustMap((model: WikiModel) => {
      return this.apiWikiService
        .markAsRead(model)
        .pipe(
          map(response => new UpdateActionSuccess(response)),
          catchError((errors) => of(new UpdateActionFailure(errors)))
        );
    })
  );

  @Effect()
  search$ = this.actions$.pipe(
    ofType(WikiActionTypes.SearchAction),
    exhaustMap((action: SearchAction) => {
        return this.apiWikiService
          .search(action.payload)
          .pipe(
            map((response) => new SearchSuccessAction(response, action.deletePrevious)),
            catchError((errors) => of(new SearchFailureAction(errors)))
          );
      }
    )
  );

  @Effect()
  view$ = this.actions$.pipe(
    ofType(WikiActionTypes.ViewAction),
    map((action: ViewAction) => action.payload),
    exhaustMap((id: string) => {
        return this.apiWikiService
          .view(id)
          .pipe(
            map((response) => new ViewActionSuccess(response)),
            catchError((errors) => of(new ViewActionFailure(errors)))
          );
      }
    )
  );

  @Effect()
  update$ = this.actions$.pipe(
    ofType(WikiActionTypes.UpdateAction),
    map((action: UpdateAction) => action.payload),
    exhaustMap((model: WikiModel) => {
        return this.apiWikiService
          .update(model)
          .pipe(
            switchMap((response) => [
              new UpdateActionSuccess(response),
              new TagSearchAction(),
            ]),
            catchError((errors) => of(new UpdateActionFailure(errors)))
          );
      }
    )
  );

  @Effect()
  sort$ = this.actions$.pipe(
    ofType(WikiActionTypes.SortAction),
    map((action: SortAction) => action.payload),
    exhaustMap((model: WikiSortInterface) => {
        return this.apiWikiService
          .sort(model)
          .pipe(
            map(() => new SortActionSuccess()),
            catchError((errors) => of(new UpdateActionFailure(errors)))
          );
      }
    )
  );

  @Effect()
  favorite$ = this.actions$.pipe(
    ofType(WikiActionTypes.FavoriteAction),
    map((action: FavoriteAction) => action.payload),
    exhaustMap((payload) => {
        return this.apiWikiService
          .favorite(payload.ids, payload.isDelete)
          .pipe(
            map((response) => new FavoriteActionSuccess(response)),
            catchError((errors) => of(new FavoriteActionFailure(errors)))
          );
      }
    )
  );

  @Effect()
  archive$ = this.actions$.pipe(
    ofType(WikiActionTypes.ArchiveAction),
    map((action: ArchiveAction) => action.payload),
    exhaustMap((ids) => {
        return this.apiWikiService
          .archive(ids)
          .pipe(
            map((response) => new ArchiveActionSuccess(response)),
            catchError((errors) => of(new ArchiveActionFailure(errors)))
          );
      }
    )
  );

  @Effect()
  changeCategory$ = this.actions$.pipe(
    ofType(WikiActionTypes.ChangeCategoryAction),
    map((action: ChangeCategoryAction) => action.payload),
    exhaustMap((payload) => {
        return this.apiWikiService
          .changeCategory(payload.ids, payload.categoryId)
          .pipe(
            map((response) => new ChangeCategoryActionSuccess(response)),
            catchError((errors) => of(new ChangeCategoryActionFailure(errors)))
          );
      }
    )
  );

  @Effect()
  create$ = this.actions$.pipe(
    ofType(WikiActionTypes.CreateAction),
    exhaustMap((action: CreateAction) => {
        return this.apiWikiService
          .create(action.payload)
          .pipe(
            switchMap((response) => [ new CreateActionSuccess(response), new TagSearchAction() ]
            ),
            catchError((errors) => of(new CreateActionFailure(errors)))
          );
      }
    )
  );

  @Effect()
  like$ = this.actions$.pipe(
    ofType(WikiActionTypes.LikeAction),
    exhaustMap((action: LikeAction) =>
      this.apiWikiService
        .like(action.wikiId)
        .pipe(
          map((wiki: WikiModel) => new LikeActionSuccess(wiki)),
          catchError(errors => of(new LikeActionFailure(errors)))
        ))
  );

  @Effect()
  dislike$ = this.actions$.pipe(
    ofType(WikiActionTypes.DislikeAction),
    exhaustMap((action: DislikeAction) =>
      this.apiWikiService
        .dislike(action.wikiId)
        .pipe(
          map((wiki: WikiModel) => new DislikeActionSuccess(wiki)),
          catchError(errors => of(new DislikeActionFailure(errors)))
        ))
  );
}
