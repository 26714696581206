<ui-container
  (click)="onSetPosition($event)"
  class="container_pd-10-24-24 tag-sidebar__container">
    <div class="form__row">
        <input [formControl]="query" class="input input_transparent input_sidebar" placeholder="Поиск по тегам"
               type="search">
        <span (click)="onSort()"
              [class.tag-sidebar__icon-downarrow_upward]="sorting === DES"
              class="form__icon form__icon_right icon-downarrow tag-sidebar__icon-downarrow">
        </span>
        <span class="form__icon form__icon_right icon-search"></span>
    </div>
    <div class="tag-sidebar__list">
        <div class="tag-sidebar__item" *ngFor="let tag of visibleTags">
            <ui-tag [value]="tag.name" (click)="filterService.toggleTag(tag)"></ui-tag>
            <span class="tag-sidebar__numb">{{tag.countData}}</span>
            <span class="tag-sidebar__icon-pencil icon-pencil" *ngIf="allowCrud" (click)="onOpenPopupForm(tag)"></span>
        </div>
    </div>
    <div *ngIf="filteredTags.length >= 10"
         (click)="onToggle()"
         class="tag-sidebar__btn-open">
        <span [class.tag-sidebar__icon-arrow_expanded]="isExpanded"
              class="tag-sidebar__icon-arrow icon-arrow"></span>
    </div>
</ui-container>

<ui-popup #popup
          [arrow]="isShowArrowPopup"
          [arrowTop]="popupArrowPosition"
          [ngStyle]="popupPosition"
          (clickOutside)="onClosePopupForm()"
          [exclude]="'.tag-sidebar__icon-pencil'"
          [excludeBeforeClick]="true"
          [delayClickOutsideInit]="true"
          *ngIf="isOpenPopup"
          class="tag-sidebar__form">
    <ui-tag-sidebar-form (close)="onClosePopupForm()" [tags]="tags"></ui-tag-sidebar-form>
</ui-popup>
