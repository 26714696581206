<div
  *ngIf="popupScreenMode === ScreenMode.Desktop"
  (clickOutside)="onClickOutside()"
  [delayClickOutsideInit]="true">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
