import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from '@core/services/entities/api.service';
import { UserModel, PutUserCompany, PutUserCompanyContacts, IdentityModel } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class ApiUserCompanyService extends ApiService {
  put(company: PutUserCompany) {
    return this
      .http
      .put<IdentityModel>(this.getApiUrl('profiles/company-info'), company);
  }

  putContacts(contacts: PutUserCompanyContacts) {
    return this
      .http
      .put<IdentityModel>(this.getApiUrl('profiles/company-contact'), contacts);
  }
}
