<button
  (click)="onClose(false)"
  class="button button_not-button exit-confirmation__close"
  type="button">
  <i class="icon-close"></i>
</button>
<div class="exit-confirmation__img">
  <img src="/assets/img/exit-green.svg">
</div>
<p class="exit-confirmation__text">
  Вы действительно хотите завершить тест?
</p>
<div class="exit-confirmation__actions">
  <button
    (click)="onClose(false)"
    class="button button_not-button button_mobile button_mobile button_mb_17 exit-confirmation__cancel"
    type="button">
    Отменить
  </button>
  <button
    (click)="onClose(true)"
    class="button button_theme-1 button_mobile exit-confirmation__confirm"
    type="button">
    Завершить
  </button>
</div>

