import { AuthActions, AuthActionTypes } from './auth.actions';
import { IdentityModel, UserOnboarding } from '@core/models/identity.model';
import { createSelector } from '@ngrx/store';
import { UserCompanyActionTypes, SetUserCompanyContactsActionSuccess, SetUserCompanyActionSuccess, UserCompanyActions } from '../user-company/user-company.actions';
import * as Cookies from 'es-cookie';

export const tokenKey = 'accessToken';

export interface State {
  loading: boolean;
  isAuth: boolean;
  token: string;
  step: string;
  errors: any;
  user: IdentityModel;
}

export const initialState: State = {
  loading: false,
  isAuth: false,
  token: Cookies.get(tokenKey),
  step: null,
  errors: {},
  user: null,
};

export function reducer(state = initialState, action: AuthActions | UserCompanyActions): State {
  switch (action.type) {
    case AuthActionTypes.SignOutAction:
      return {...state, isAuth: false, user: null};
    case AuthActionTypes.SignOutSuccessAction:
      Cookies.remove(tokenKey);
      return initialState;
    case AuthActionTypes.SignInAction:
      return Object.assign({}, state, {
        loading: true,
        errors: {}
      });

    case AuthActionTypes.SignInSuccessAction:
    case AuthActionTypes.PasswordRecoveryConfirmSuccessAction:
      Cookies.set(tokenKey, action.payload.token);
      return Object.assign({}, state, {
        loading: false,
        isAuth: true,
        errors: {},
        token: action.payload.token,
        step: action.payload.step,
        user: action.payload
      });

    case AuthActionTypes.SignInFailureAction:
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload.error,
      });

    //
    case AuthActionTypes.SignUpAction:
      return Object.assign({}, state, {
        loading: true,
        errors: {}
      });

    case AuthActionTypes.SignUpSuccessAction:
      return Object.assign({}, state, {
        loading: false,
        isAuth: true,
        errors: {},
        token: action.payload.token,
        step: action.payload.step,
        user: action.payload
      });

    case AuthActionTypes.SignUpFailureAction:
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload.error,
      });

    //
    case AuthActionTypes.SignUpStepPersonalDataAction:
      return Object.assign({}, state, {
        loading: true,
      });

    case AuthActionTypes.SignUpStepPersonalDataSuccessAction:
      return Object.assign({}, state, {
        loading: false,
        isAuth: true,
        errors: {},
        step: action.payload.step,
        user: action.payload
      });

    case AuthActionTypes.SignUpStepPersonalDataFailureAction:
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload.error,
      });

    //
    case AuthActionTypes.SignUpStepPersonalContactAction:
      return Object.assign({}, state, {
        loading: true,
      });

    case AuthActionTypes.SignUpStepPersonalContactSuccessAction:
      return Object.assign({}, state, {
        loading: false,
        isAuth: true,
        errors: {},
        step: action.payload.step,
        user: action.payload
      });

    case AuthActionTypes.SignUpStepPersonalContactFailureAction:
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload.error,
      });

    //

    case AuthActionTypes.SignUpStepCompanyAction:
      return Object.assign({}, state, {
        loading: true,
      });

    case AuthActionTypes.SignUpStepCompanySuccessAction:
      return Object.assign({}, state, {
        loading: false,
        isAuth: true,
        errors: {},
        step: action.payload.step,
        user: action.payload
      });

    case AuthActionTypes.SignUpStepCompanyFailureAction:
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload.error,
      });

    //

    case AuthActionTypes.ProfileUpdateAction:
      return Object.assign({}, state, {
        loading: true,
        errors: {},
      });

    case AuthActionTypes.ProfileUpdateSuccessAction:
      return Object.assign({}, state, {
        loading: false,
        user: action.payload
      });

    case AuthActionTypes.ProfileUpdateFailureAction:
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload.error,
      });

    case AuthActionTypes.GetUserAction:
      return Object.assign({}, state, {
        loading: true,
        errors: {},
      });

    case AuthActionTypes.GetUserSuccessAction:
      return Object.assign({}, state, {
        loading: false,
        user: action.payload,
        step: action.payload.step,
      });

    case AuthActionTypes.GetUserFailureAction:
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload.error,
      });

    case AuthActionTypes.ChangeStateOnboardingAction: {
      const payload = action.payload as UserOnboarding;
      const user = { ...state.user, onboarding: { ...state.user.onboarding, ...payload } } as IdentityModel;
      return { ...state, user: user };
    }

    case AuthActionTypes.ConfirmEmailAction:
      return Object.assign({}, state, {
        loading: false,
      });

    case AuthActionTypes.ConfirmEmailFailureAction:
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload.error,
      });

    case AuthActionTypes.PasswordRecoveryConfirmAction:
      return Object.assign({}, state, {
        loading: false,
      });

    case AuthActionTypes.PasswordRecoveryConfirmFailureAction:
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload.error,
      });

    case UserCompanyActionTypes.SetUserCompanyContactsActionSuccess: {
      const { user } = action as SetUserCompanyContactsActionSuccess;
      if (state.user && state.user.id === user.id) {
        return { ...state, user };
      }
      return state;
    }

    case UserCompanyActionTypes.SetuserCompanyActionSuccess: {
      const { user } = action as SetUserCompanyActionSuccess;
      if (state.user && state.user.id === user.id) {
        return { ...state, user };
      }
      return state;
    }

    default:
      return state;
  }
}

export const getIsAuth = (state: State) => state.isAuth;
export const getStep = (state: State) => state.step;
export const getErrors = (state: State) => state.errors;
export const getLoading = (state: State) => state.loading;
export const getUser = (state: State) => state.user;
export const getToken = (state: State) => state.token;
export const getIsActive = createSelector(getUser, (user) => user && user.isActive);
export const getIsTest = createSelector(getUser, (user) => user && user.isTest);
