import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiNotificationComponent } from '@ui/modules/ui-notification/ui-notification.component';
import { UiContainerModule } from '@ui/modules/ui-container/ui-container.module';

@NgModule({
  declarations: [
    UiNotificationComponent
  ],
  exports: [
    UiNotificationComponent
  ],
  imports: [
    CommonModule,
    UiContainerModule
  ]
})
export class UiNotificationModule {
}
