import {Injectable} from '@angular/core';
import {CourseModel, CourseQueryModel, PostCourseModel, PutCourseModel} from '@core/models';
import 'rxjs/add/operator/map';
import {map} from 'rxjs/operators';
import {PageResult} from '@core/models/filters/page-result';
import {ApiService} from '@core/services/entities/api.service';


@Injectable({
  providedIn: 'root',
})
export class ApiCourseService extends ApiService {
  delete(id: string) {
    return this
      .http
      .delete(this.getApiUrl('courses/' + id));
  }

  checkResult(courseId: string, lessonId: string) {
    const url = `courses/check-result/${courseId}/${lessonId}`;
    return this
      .http
      .put<CourseModel>(this.getApiUrl(url), undefined);
  }

  search(query: CourseQueryModel) {
    return this
      .http
      .get<CourseModel[]>(this.getApiUrl(`courses?${query.getQuery()}`), {observe: 'response'})
      .pipe(
        map((response) => new PageResult<CourseModel[]>(
          response,
          response.body
        ))
      );
  }

  update(model: PutCourseModel) {
    return this
      .http
      .put<CourseModel>(this.getApiUrl(`courses/${model.id}`), model);
  }

  view(id: string) {
    return this
      .http
      .get<CourseModel>(this.getApiUrl(`courses/${id}`));
  }

  create(model: PostCourseModel) {
    return this
      .http
      .post<CourseModel>(this.getApiUrl(`courses`), model);
  }

  changeCategory(ids: string[], categoryId: string) {
    return this
      .http
      .put<CourseModel[]>(this.getApiUrl(`courses/change-category/${categoryId}`), {
        ids: ids
      });
  }

  recovery(id: string) {
    return this
      .http
      .put(this.getApiUrl(`courses/recovery/${id}`), {});
  }
}
