export function cutUrl(url) {
  let hostname;

  // Убрать протокол
  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2];
  }

  // Убрать порт из ссылки
  hostname = hostname.split(':')[0];

  // Убрать параметры
  hostname = hostname.split('?')[0];

  return hostname;
}
