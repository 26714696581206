import {
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';

import { TakeTestService } from '@ui/modules/take-test/services/take-test.service';
import { TestAnswerState, TmpAnswerModel } from '@ui/modules/take-test/helpers/types';


@Component({
  selector: 'test-answer',
  templateUrl: './test-answer.component.html',
  styleUrls: ['./test-answer.component.less']
})
export class TestAnswerComponent implements OnInit {
  @Input()
  answer: TmpAnswerModel;

  disabled$: Observable<boolean>;

  @HostBinding('class.test-answer_checked')
  get checked(): boolean {
    return this.answer.isChecked;
  }

  @HostBinding('class.test-answer_right')
  get right(): boolean {
    return this.answer.state === TestAnswerState.Right;
  }

  @HostBinding('class.test-answer_wrong')
  get wrong(): boolean {
    return this.answer.state === TestAnswerState.Wrong;
  }


  constructor(
    private service: TakeTestService
  ) { }

  ngOnInit() {
    this.disabled$ = this.service.state.map(s => s !== 'question');
  }

  onChange() {
    this.answer.isChecked = !this.answer.isChecked;
  }

}
