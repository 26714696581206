/**
 * Возвращает разницу двух дат в формате 04:01 или 01:03:03
 * @param time0 более поздняя дата 
 * @param time1 более ранняя дата
 */
export function stringTimeDiff(time0: Date, time1: Date): string {
  const diff = new Date((+time0) - (+time1));
  const timeString = diff.toISOString();
  // если нет часов, то оставить только минуты и секунды
  const start = timeString[12] === '0'
    ? 14
    : 11;
  return timeString.slice(start, 19);
}
