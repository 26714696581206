import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { TooltipArrowPosition } from '../tooltip.component';

@Component({
  selector: 'rt-onboarding-tooltip',
  templateUrl: './onboarding-tooltip.component.html',
  styleUrls: ['./onboarding-tooltip.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingTooltipComponent implements OnInit {
  /**
   * Непрозрачность плашек
   */
  @Input()
  lines: [number, number, number, number] = [
    1,
    .5,
    .5,
    .5,
  ];

  @Input()
  arrow: TooltipArrowPosition;

  constructor() { }

  ngOnInit() {
  }

}
