<button
  (click)="onClose()"
  class="button button_not-button header-filter-menu__close">
  <i class="icon-close"></i>
</button>
<div>
  <div>
    <strong class="header-filter-menu__header">
      Фильтр по контенту
    </strong>
  </div>
  <div class="header-filter-menu__radios">
    <a
      *ngFor="let option of filterOptions"
      (click)="onClose()"
      [routerLink]="[]"
      [queryParams]="{isRead: option.value}"
      queryParamsHandling="merge"
      [class.header-filter-menu__radio_active]="isRead === option.value"
      class="header-filter-menu__radio">
      <label class="radio__label">
        <input
          [checked]="isRead === option.value"
          name="filter"
          class="radio__input"
          type="radio">
        <i class="radio__indicator radio__indicator_blue"></i>
        <span class="radio__text">{{ option.name }}</span>
      </label>
    </a>
  </div>
</div>
<div *ngIf="tags.length">
  <div>
    <strong class="header-filter-menu__header">
      Теги
    </strong>
  </div>
  <ui-tag-sidebar
    [allowCrud]="user?.permissions.tagCrud"
    [tags]="tags"
    [maxTags]="6"
    class="header-filter-menu__tags">
  </ui-tag-sidebar>
</div>
