import { BaseModel } from './base.model';
import { ContactModel } from './contact.model';
import { FileModel } from '@core/models/file.model';

export const IdentityRoles = [
  {
    name: 'Уволенный',
    id: 'dismissed',
    icon: 'icon-fired icon-red',
    description: 'Доступ ко всем разделам портала закрыт'
  },
  {
    name: 'Пользователь',
    id: 'user',
    icon: 'icon-user',
    description: 'Просмотр и комментирование доступных материалов'
  },
  {
    name: 'Автор',
    id: 'author',
    icon: 'icon-user icon-green',
    description: 'Создание, редактирование и удаление собственных публикаций'
  },
  {
    name: 'Модератор',
    id: 'moderator',
    icon: 'icon-moderator icon-blue',
    description: 'Создание, редактирование и удаление всех материалов'
  },
  {
    name: 'Администратор',
    id: 'administrator',
    icon: 'icon-user-admin icon-blue',
    description: 'Полный доступ ко всем материалам,управление правами доступа'
  },
];

export const IdentityRolesForInviteForm = [
  {
    name: 'Пользователь',
    id: 'user',
    icon: 'icon-user',
    description: 'Просмотр и комментирование доступных материалов'
  },
  {
    name: 'Автор',
    id: 'author',
    icon: 'icon-user icon-green',
    description: 'Создание, редактирование и удаление собственных публикаций'
  },
  {
    name: 'Модератор',
    id: 'moderator',
    icon: 'icon-moderator icon-blue',
    description: 'Создание, редактирование и удаление всех материалов'
  },
  {
    name: 'Администратор',
    id: 'administrator',
    icon: 'icon-user-admin icon-blue',
    description: 'Полный доступ ко всем материалам,управление правами доступа'
  },
];

export interface UserOnboarding {
  affiliateHello?: boolean;
}

export class IdentityModel extends BaseModel {
  id: string;
  email: string;
  expiredAt: string;
  firstName: string;
  lastName: string;
  middleName: string;
  token: string;
  phone: string;
  step: string;
  avatarId: string;
  avatar: FileModel;
  birthday: string;
  colorCssClass: string;
  floor: 'man' | 'woman';
  inviteId: string;
  isTest: boolean;
  isActive: boolean;
  affiliateCode: string;
  affiliateDomain: string;
  sourceDomain: string;
  affiliateReward: number;
  affiliateBalance: number;
  totalAffiliateSum: number;
  onboarding: UserOnboarding;
  permissions: {
    categoryCrud: boolean;
    inviteCrud: boolean;
    tagCrud: boolean;
    wikiCreate: boolean;
    wikiCrud: boolean;
    testCrud: boolean;
    testCreate: boolean;
    courseCrud: boolean;
    courseCreate: boolean;
    companyOwner: boolean;
    createKarma: boolean;
  };
  logo: string;
  karma: number;
  additionalContacts?: ContactModel[];
  // copy-pasted from PutUserCompany
  mcComapnyName: string;
  mcFieldOfActivityId: string;
  mcAbout: string;
  mcAnnualTurnover: string;
  mcPosition: string;
  // copy-pasted from PutUserCompanyContacts
  mcSite: string;
  mcAdditionalContacts: ContactModel[];
  mcEmail: string;
}

