import {Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'ui-select-option',
  templateUrl: './ui-select-option.component.html',
  styleUrls: ['./ui-select-option.component.less']
})
export class UiSelectOptionComponent implements OnInit {
  @Input() allowEdit = true;
  @Input() icon: string;
  @Input() description: string;
  @Input() name: string;
  @Input() type: 'thin';

  @ViewChild('contentWrapper', { static: true })
  content: ElementRef;

  @HostBinding('class.selected')
  selected = false;
  @HostBinding('class.focused')
  focused = false;

  constructor(public elementRef: ElementRef) {
  }

  ngOnInit() {
  }

  @Input() value: any;
  @Input() model: any;

  @Output()
  select = new EventEmitter<any>();

  @Output()
  edit = new EventEmitter<any>();

  onClick() {
    this.select.emit({value: this.value, content: this.content});
    this.selected = true;
  }

  onEdit() {
    this.edit.emit(this.model);
  }
}
