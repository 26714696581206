import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { CategoryModel } from '@core/models/category.model';
import { InviteModel, InviteQueryModel } from '@core/models/invite.model';
import { UserStatusModel } from '@core/models/user-status.model';
import { UserPositionsModel } from '@core/models/user-positions.model';
import * as fromRoot from '@core/redux/index';
import * as _ from 'lodash';
import 'rxjs/add/observable/combineLatest';
import 'rxjs/add/observable/combineLatest';
import 'rxjs/add/operator/map';
import {
  ConfirmInviteAdminAction,
  CreateAction,
  DeleteAction,
  ResendEmailAction, ResetAction,
  SearchAction,
  SelectAction,
  UpdateAction,
  ViewAction
} from '@core/redux/invite/invite.actions';
import * as InviteActions from '@core/redux/invite/invite.actions';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InviteService {
  constructor(private store: Store<fromRoot.State>) {
  }

  getActiveItems() {
    const invites$ = this.store.pipe(
      select(fromRoot.getInviteActiveEntities),
    );
    return this.getItems(invites$);
  }

  getPageItems() {
    const invites$ = this.store.pipe(select(fromRoot.getInviteEntities));
    return this.getItems(invites$);
  }

  getSelected() {
    const invite$ = this.store.pipe(select(fromRoot.getInviteSelect));
    const statuses$ = this.store.pipe(select(fromRoot.getUserStatusEntities));
    const positions$ = this.store.pipe(select(fromRoot.getUserPositionsEntities));
    const departments$ = this.store.pipe(select(fromRoot.getCategoryEntitiesByType('user')));

    return Observable.combineLatest(
      invite$,
      statuses$,
      positions$,
      departments$,
      (invite: InviteModel,
        statuses: UserStatusModel[],
        positions: UserPositionsModel[],
        departments: CategoryModel[]) => {
        if (invite) {
          invite.userStatus = _.find(statuses, { id: invite.userStatusId });
          invite.userPosition = _.find(positions, { id: invite.userPositionId });
          invite.department = _.find(departments, { id: invite.departmentId });
        }
        return invite;
      });
  }

  select(id: string) {
    this.store.dispatch(new SelectAction(id));
  }

  fetchOne(id: string) {
    this.store.dispatch(new ViewAction(id));
  }

  fetchAll(query: InviteQueryModel, forInvitesPage: boolean) {
    this.store.dispatch(new SearchAction(query, forInvitesPage));
  }

  deleteOne(id: string) {
    this.store.dispatch(new DeleteAction(id));
  }

  resendEmail(id: string) {
    this.store.dispatch(new ResendEmailAction(id));
  }

  create(model: InviteModel) {
    this.store.dispatch(new CreateAction(model));
  }

  confirmInviteAdmin(model: InviteModel) {
    this.store.dispatch(new ConfirmInviteAdminAction(model));
  }


  update(model: InviteModel) {
    this.store.dispatch(new UpdateAction(model));
  }

  resetStore() {
    this.store.dispatch(new ResetAction());
  }

  private getItems(invites$: Observable<InviteModel[]>) {
    const statuses$ = this.store.pipe(select(fromRoot.getUserStatusEntities));
    const positions$ = this.store.pipe(select(fromRoot.getUserPositionsEntities));
    const departments$ = this.store.pipe(select(fromRoot.getCategoryEntitiesByType('user')));

    return Observable.combineLatest(
      invites$,
      statuses$,
      positions$,
      departments$,
      (invites: InviteModel[],
        statuses: UserStatusModel[],
        positions: UserPositionsModel[],
        departments: CategoryModel[]) => {

        invites.forEach(item => {
          item.userStatus = _.find(statuses, { id: item.userStatusId });
          item.userPosition = _.find(positions, { id: item.userPositionId });
          item.department = _.find(departments, { id: item.departmentId });
        });

        return invites;
      });
  }
}
