import {Injectable} from '@angular/core';
import {ApiService} from '../../services/entities/api.service';
import {retry} from 'rxjs/operators';
import {OnboardingRequest, OnboardingResponse} from './onboarding.model';

@Injectable({
  providedIn: 'root',
})
export class ApiOnboardingService extends ApiService {
  get() {
    return this.http.get<OnboardingResponse>(this.getApiUrl('onboarding'));
  }

  update(request: OnboardingRequest) {
    return this.http.put<OnboardingResponse>(this.getApiUrl('onboarding'), request).pipe(retry(2));
  }
}
