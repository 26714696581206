import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  FieldsOfActivityActionTypes,
  GetFieldsOfActivityAction,
  GetFieldsOfActivityActionSuccess,
  GetFieldsOfActivityActionFailure
} from './field-of-activity.actions';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs/index';
import { ApiFieldOfActivityService } from '@core/services/entities/field-of-activity/api-field-of-activity.service';

@Injectable()
export class FieldsOfActivityEffects {
  constructor(private actions$: Actions,
    private apiService: ApiFieldOfActivityService
  ) {
  }

  @Effect()
  get$ = this.actions$.pipe(
    ofType(FieldsOfActivityActionTypes.GetFieldsOfActivityAction),
    exhaustMap((action: GetFieldsOfActivityAction) =>
      this.apiService
        .getFieldsOfActivity()
        .pipe(
          map((response) => new GetFieldsOfActivityActionSuccess(response)),
          catchError((errors) => of(new GetFieldsOfActivityActionFailure(errors)))
        )),
  );
}
