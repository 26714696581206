import {Injectable} from '@angular/core';
import {WikiModel, WikiQueryModel, WikiSortInterface} from '@core/models/wiki.model';
import 'rxjs/add/operator/map';
import {map} from 'rxjs/operators';
import {PageResult} from '@core/models/filters/page-result';
import {ApiService} from '@core/services/entities/api.service';
import {ParseUrlModel} from '@core/models/parse-url.model';

@Injectable({
  providedIn: 'root',
})
export class ApiWikiService extends ApiService {

  search(query: WikiQueryModel) {
    return this
      .http
      .get<any>(this.getApiUrl(`wiki?${query.getQuery()}`), {observe: 'response'})
      .pipe(
        map((response) => new PageResult<WikiModel[]>(
          response,
          response.body.map(model => (new WikiModel()).setAttributes(model))
        ))
      );
  }

  update(model: WikiModel) {
    return this
      .http
      .put<WikiModel>(this.getApiUrl(`wiki/${model.id}`), model)
      .pipe(
        map((response) => (new WikiModel()).setAttributes(response))
      );
  }

  markAsRead(model: WikiModel) {
    const readModel = { ...model, isRead: true };
    return this
      .http
      .put<WikiModel>(this.getApiUrl('wiki/read/' + model.id), readModel)
      .pipe(
        map(response => (new WikiModel()).setAttributes(response))
      );
  }

  sort(model: WikiSortInterface) {
    return this
      .http
      .put(this.getApiUrl(`wiki/sort/${model.id}`), model);
  }

  view(id: string) {
    return this
      .http
      .get<WikiModel>(this.getApiUrl(`wiki/${id}`))
      .pipe(
        map((response) => (new WikiModel()).setAttributes(response))
      );
  }

  viewShared(id: string) {
    return this
      .http
      .get<WikiModel>(this.getApiUrl(`wiki/shared/${id}?expand=tags,category`))
      .pipe(
        map((response) => (new WikiModel()).setAttributes(response))
      );
  }

  favorite(ids, isDelete = null) {
    return this
      .http
      .put<WikiModel[]>(this.getApiUrl(`wiki/favorite`), {
        ids: ids,
        isDelete: isDelete
      })
      .pipe(
        map((response) => response.map(model => (new WikiModel()).setAttributes(model)))
      );
  }

  create(model: WikiModel) {
    return this
      .http
      .post<WikiModel>(this.getApiUrl(`wiki`), model)
      .pipe(
        map((response) => (new WikiModel()).setAttributes(response))
      );
  }

  archive(ids) {
    return this
      .http
      .put<WikiModel[]>(this.getApiUrl(`wiki/archive`), {
        ids: ids
      })
      .pipe(
        map((response) => response.map(model => (new WikiModel()).setAttributes(model)))
      );
  }

  changeCategory(ids: string[], categoryId: string) {
    return this
      .http
      .put<WikiModel[]>(this.getApiUrl(`wiki/change-category/${categoryId}`), {
        ids: ids
      })
      .pipe(
        map((response) => response.map(model => (new WikiModel()).setAttributes(model)))
      );
  }

  requestFromUrl(url: string) {
    return this
      .http
      .get<any>(this.getApiUrl(`wiki/parse?url=${url}`), {})
      .pipe(
        map((response: ParseUrlModel) => ({ ...response, name: response.header }))
      );
  }

  like(wikiId: string) {
    return this
      .http
      .post<WikiModel>(this.getApiUrl(`wiki/like/${wikiId}`), {});
  }

  dislike(wikiId: string) {
    return this
      .http
      .post(this.getApiUrl(`wiki/dislike/${wikiId}`), {});
  }

  saveSharedContent(id: string) {
    return this
      .http
      .post<WikiModel>(this.getApiUrl(`wiki/save-share-content/${id}`), {});
  }
}
