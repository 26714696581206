import {Injectable} from '@angular/core';
import {FileModel} from '@core/models/file.model';
import {map} from 'rxjs/operators';
import StringHelper from '@core/string.helper';
import FileHelper from '@core/file.helper';
import {ApiService} from '@core/services/entities/api.service';

@Injectable({
  providedIn: 'root',
})
export class ApiFileService extends ApiService {
  avatar(dataURI) {
    const file: Blob = FileHelper.dataURItoBlob(dataURI);
    const body: FormData = new FormData();
    const name: string = StringHelper.randomString() + '.png';
    body.append('file', file, name);
    return this
      .http
      .post<FileModel>(this.getApiUrl('files/upload-avatar'), body)
      .pipe(map((response) => (new FileModel()).setAttributes(response)));
  }

  upload(file, forObject: 'wikiFile' | 'testFile' | 'courseFile' | 'commentFile') {
    const body: FormData = new FormData();
    body.append('file', file, file.name);
    body.append('forObject', forObject);

    return this
      .http
      .post<FileModel>(this.getApiUrl('files'), body)
      .pipe(map((response) => (new FileModel()).setAttributes(response)));
  }

  uploadImage(dataURI, forObject: 'articleImage' | 'testImage' | 'courseImage' | 'commentFile') {
    const file: Blob = FileHelper.dataURItoBlob(dataURI);
    const body: FormData = new FormData();
    const name: string = StringHelper.randomString() + '.png';
    body.append('file', file, name);
    body.append('forObject', forObject);
    return this
      .http
      .post<FileModel>(this.getApiUrl('files/upload-image'), body)
      .pipe(map((response) => (new FileModel()).setAttributes(response)));
  }

  uploadCompanyImage(dataURI) {
    const file: Blob = FileHelper.dataURItoBlob(dataURI);
    const body: FormData = new FormData();
    const name: string = StringHelper.randomString() + '.png';
    body.append('file', file, name);
    return this
      .http
      .post<FileModel>(this.getApiUrl('files/upload-company-image'), body)
      .pipe(map((response) => (new FileModel()).setAttributes(response)));
  }

  delete(id: string) {
    return this
      .http
      .delete<FileModel>(this.getApiUrl(`files/${id}`));
  }
}
