import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  TestActionTypes,
  CreateAction,
  CreateActionFailure,
  CreateActionSuccess,
  SearchFailureAction,
  SearchSuccessAction,
  UpdateAction,
  UpdateActionFailure,
  UpdateActionSuccess,
  SearchAction,
  ViewAction,
  ViewActionFailure,
  ViewActionSuccess,
  ChangeCategoryAction,
  ChangeCategoryActionSuccess,
  ChangeCategoryActionFailure,
  DeleteAction,
  DeleteActionFailure,
  DeleteActionSuccess, RecoveryAction, RecoveryActionSuccess, RecoveryActionFailure,
  StartTestAction,
  StartTestActionSuccess,
  ShowResultsAction,
  ShowResultsActionSuccess,
} from './test.actions';
import { SearchAction as TagSearchAction } from '@core/redux/tag/tag.actions';
import { map, exhaustMap, catchError, switchMap, tap, filter } from 'rxjs/operators';
import { of } from 'rxjs/index';
import { TestQueryModel, PostTestModel, PutTestModel, TestModel } from '@core/models';
import { ApiTestService } from '@core/services/entities/test/api-test.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TestResultsComponent, MatDialogData as ResultsDialogData } from '@ui/modules/test-results/test-results.component';
import { TakeTestComponent, MatDialogData as TakeTestDialogData } from '@ui/modules/take-test/components/take-test/take-test.component';
import { Store } from '@ngrx/store';
import { State } from './test.reducer';


@Injectable()
export class TestEffects {
  constructor(private actions$: Actions,
    private store: Store<State>,
    private dialog: MatDialog,
    private apiTestService: ApiTestService) {
  }

  @Effect()
  archive$ = this.actions$.pipe(
    ofType(TestActionTypes.DeleteAction),
    map((action: DeleteAction) => action.payload),
    exhaustMap((id) => {
      return this.apiTestService
        .delete(id)
        .pipe(
          map(() => new DeleteActionSuccess(id)),
          catchError((errors) => of(new DeleteActionFailure(errors)))
        );
    }
    )
  );

  @Effect()
  recovery$ = this.actions$.pipe(
    ofType(TestActionTypes.RecoveryAction),
    map((action: RecoveryAction) => action.payload),
    exhaustMap((id) => {
      return this.apiTestService
        .recovery(id)
        .pipe(
          map(() => new RecoveryActionSuccess(id)),
          catchError((errors) => of(new RecoveryActionFailure(errors)))
        );
    }
    )
  );

  @Effect()
  search$ = this.actions$.pipe(
    ofType(TestActionTypes.SearchAction),
    exhaustMap((action: SearchAction) => {
      return this.apiTestService
        .search(action.payload)
        .pipe(
          map((response) => new SearchSuccessAction(response, action.deletePrevious)),
          catchError((errors) => of(new SearchFailureAction(errors)))
        );
    }
    )
  );

  @Effect()
  view$ = this.actions$.pipe(
    ofType(TestActionTypes.ViewAction),
    map((action: ViewAction) => action.payload),
    exhaustMap((id: string) => {
      return this.apiTestService
        .view(id)
        .pipe(
          map((response) => new ViewActionSuccess(response)),
          catchError((errors) => of(new ViewActionFailure(errors)))
        );
    }
    )
  );

  @Effect()
  update$ = this.actions$.pipe(
    ofType(TestActionTypes.UpdateAction),
    map((action: UpdateAction) => action.payload),
    exhaustMap((model: PutTestModel) => {
      return this.apiTestService
        .update(model)
        .pipe(
          switchMap((response) => [
            new UpdateActionSuccess(response),
            new TagSearchAction(),
          ]),
          catchError((errors) => of(new UpdateActionFailure(errors)))
        );
    }
    )
  );

  @Effect()
  changeCategory$ = this.actions$.pipe(
    ofType(TestActionTypes.ChangeCategoryAction),
    map((action: ChangeCategoryAction) => action.payload),
    exhaustMap((payload) => {
      return this.apiTestService
        .changeCategory(payload.ids, payload.categoryId)
        .pipe(
          map((response) => new ChangeCategoryActionSuccess(response)),
          catchError((errors) => of(new ChangeCategoryActionFailure(errors)))
        );
    }
    )
  );

  @Effect()
  create$ = this.actions$.pipe(
    ofType(TestActionTypes.CreateAction),
    map((action: CreateAction) => action.payload),
    exhaustMap((model: PostTestModel) => {
      return this.apiTestService
        .create(model)
        .pipe(
          switchMap((response) => [
            new CreateActionSuccess(response),
            new TagSearchAction(),
          ]),
          catchError((errors) => of(new CreateActionFailure(errors)))
        );
    }
    )
  );

  @Effect()
  startTest$ = this.actions$.pipe(
    ofType(TestActionTypes.StartTestAction),
    exhaustMap((action: StartTestAction) => {
      const data: TakeTestDialogData = { test: action.test, lessonId: action.lessonId };
      const settings = { width: '100%', height: '100%', data };
      return of(new StartTestActionSuccess(
        this.dialog.open(TakeTestComponent, settings),
        action.test,
        action.lessonId
      ));
    })
  );

  @Effect()
  showResults$ = this.actions$.pipe(
    ofType(TestActionTypes.ShowResultsAction),
    exhaustMap((action: ShowResultsAction) => {
      if (typeof action.test === 'string') {
        return this.apiTestService
          .view(action.test as string)
          .pipe(
            map(test => ({ test, lessonId: action.lessonId }))
          );
      } else {
        return of({ test: action.test, lessonId: action.lessonId });
      }
    }),
    exhaustMap(({ test, lessonId }: { test: TestModel, lessonId: string | undefined }) => {
      const data: ResultsDialogData = { test, lessonId };
      const settings = { width: '100%', height: '100%', data };
      return of(new ShowResultsActionSuccess(
        this.dialog.open(TestResultsComponent, settings),
        test,
        lessonId
      ));
    })
  );
}
