import { Injectable } from "@angular/core";
import { Store, select, MemoizedSelector, DefaultProjectorFn } from '@ngrx/store';
import * as fromRoot from '@core/redux/index';
import { Observable, of, combineLatest } from 'rxjs';
import { RemoteDataState } from '@helpers/types/web-data';
import { CompanyService } from '@core/services/entities/company/company.service';
import { switchMap } from 'rxjs/operators';
import { StudySettingUpUserStep, StudyAddingUserStep, StudyWritingArticleStep, StudyCreatingFromLinkStep } from './onboarding.model';


@Injectable({
  providedIn: 'root',
})
export class OnboardingStateService {
  constructor(
    private companyService: CompanyService,
    private store: Store<fromRoot.State>,
  ) {
  }

  getCompanyType() {
    return this.store.pipe(select(fromRoot.getCompanyType));
  }

  getFirstLinkArticle() {
    return this.store.pipe(select(fromRoot.getFirstLinkArticle));
  }

  getFirstWrittenArticle() {
    return this.store.pipe(select(fromRoot.getFirstWrittenArticle));
  }

  getOnboarding() {
    return this.store.pipe(select(fromRoot.getOnboardingOnboarding));
  }

  getUserId(): Observable<string | undefined> {
    return this.store.pipe(select(fromRoot.getOnboardingUserId));
  }

  getCompanyId(): Observable<string | undefined> {
    return this.store.pipe(select(fromRoot.getOnboardingCompanyId));
  }

  getState(): Observable<RemoteDataState> {
    return this.store.pipe(select(fromRoot.getOnboardingState));
  }

  getStudyCreatingFromLinkStep(): Observable<StudyCreatingFromLinkStep> {
    return this.defaultWhenNotInOnboardingCompany(fromRoot.getStudyCreatingFromLinkStep, StudyCreatingFromLinkStep.NotStarted);
  }

  getStudyWritingArticleStep(): Observable<StudyWritingArticleStep> {
    return this.defaultWhenNotInOnboardingCompany(fromRoot.getStudyWritingArticleStep, StudyWritingArticleStep.NotStarted);
  }

  getStudyAddingUserStep(): Observable<StudyAddingUserStep> {
    return this.defaultWhenNotInOnboardingCompany(fromRoot.getStudyAddingUserStep, StudyAddingUserStep.NotStarted);
  }
  
  getStudySettingUpUserStep(): Observable<StudySettingUpUserStep> {
    return this.defaultWhenNotInOnboardingCompany(fromRoot.getStudySettingUpUserStep, StudySettingUpUserStep.NotStarted);
  }

  shouldCreateKnowledgeBase(): Observable<boolean> {
    return this.store.pipe(select(fromRoot.shouldCreateKnowledgeBase));
  }

  shouldTellAboutGoals(): Observable<boolean> {
    return this.store.pipe(select(fromRoot.shouldTellAboutGoals));
  }

  shouldTellAboutYourself(): Observable<boolean> {
    return this.store.pipe(select(fromRoot.shouldTellAboutYourself));
  }

  shouldStudyService(): Observable<boolean> {
    return this.defaultWhenNotInOnboardingCompany(fromRoot.shouldStudyService, false);
  }

  shouldOpenStudyService(): Observable<boolean> {
    return this.defaultWhenNotInOnboardingCompany(fromRoot.shouldOpenStudyService, false);
  }

  shouldStudyCreatingFromLink(): Observable<boolean> {
    return this.defaultWhenNotInOnboardingCompany(fromRoot.shouldStudyCreatingFromLink, false);
  }

  shouldStudyWritingArticle(): Observable<boolean> {
    return this.defaultWhenNotInOnboardingCompany(fromRoot.shouldStudyWritingArticle, false);
  }

  shouldStudyAddingUser(): Observable<boolean> {
    return this.defaultWhenNotInOnboardingCompany(fromRoot.shouldStudyAddingUser, false);
  }

  shouldStudySettingUpUser(): Observable<boolean> {
    return this.defaultWhenNotInOnboardingCompany(fromRoot.shouldStudySettingUpUser, false);
  }

  private defaultWhenNotInOnboardingCompany<T>(
    selector: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
    fallback: T): Observable<T> {
    return combineLatest([this.companyService.getCompanyId(), this.getCompanyId()]).pipe(
      switchMap(([appCompanyId, onboardingCompanyId]) =>
        appCompanyId && appCompanyId === onboardingCompanyId
        ? this.store.pipe(select(selector))
        : of(fallback)
    ));
  }
}
