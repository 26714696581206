import { TestActions, TestActionTypes } from './test.actions';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { TestModel } from '@core/models';
import { createSelector } from '@ngrx/store';
import { UpdateStr } from '@ngrx/entity/src/models';
import { PaginationResponse } from '@core/models/filters/pagination-response';
import * as _ from 'lodash';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TestResultsComponent } from '@ui/modules/test-results/test-results.component';

export interface State {
  ids: string[];
  loading: boolean;
  updateId: string | null;
  selectedId: string | null;
  entities: any;
  errors: any;
  pagination: PaginationResponse;
  filter: string;
  showingResults: undefined | { dialog: MatDialogRef<TestResultsComponent>, test: TestModel };
}

export const initialState: State = {
  ids: [],
  loading: false,
  updateId: null,
  selectedId: null,
  entities: {},
  errors: {},
  pagination: null,
  filter: 'active',
  showingResults: undefined
};

export const adapter: EntityAdapter<TestModel> = createEntityAdapter<TestModel>({
  selectId: (test: TestModel) => test.id,
  sortComparer: false,
});

export function reducer(state = initialState, action: TestActions): State {
  switch (action.type) {

    case TestActionTypes.ResetAction: {
      return {...initialState};
    }

    case TestActionTypes.CombineTagAction: {
      const id = action.id;
      const combineId = action.combineId;
      const entities = { ...state.entities };

      const updates: UpdateStr<TestModel>[] = [];

      for (const field in entities) {
        if (entities.hasOwnProperty(field)) {
          const item: TestModel = entities[field];
          let isUpdate = false;
          combineId.forEach((tagId: string) => {
            if (item.tagIds.indexOf(tagId) > -1) {
              item.tagIds.splice(item.tagIds.indexOf(tagId), 1);
              isUpdate = true;
            }
          });

          if (isUpdate) {
            item.tagIds.push(id);
            item.tagIds = item.tagIds.filter((el, i, a) => i === a.indexOf(el));
            updates.push({
              id: item.id,
              changes: {
                tagIds: item.tagIds
              }
            });
          }
        }
      }

      return adapter.updateMany(updates, state);
    }

    case TestActionTypes.DeleteAction: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case TestActionTypes.DeleteActionSuccess: {
      return adapter.removeOne(action.payload, Object.assign({}, state, {
        loading: false
      }));
    }

    case TestActionTypes.DeleteActionFailure: {
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload
      });
    }

    case TestActionTypes.RecoveryAction: {
      return { ...state, loading: true };
    }

    case TestActionTypes.RecoveryActionSuccess: {
      return adapter.removeOne(action.payload, { ...state, loading: false });
    }

    case TestActionTypes.RecoveryActionFailure: {
      return { ...state, loading: false, errors: action.payload };
    }

    case TestActionTypes.ViewCloseAction: {
      return Object.assign({}, state, {
        loading: false,
        selectedId: null,
        errors: {}
      });
    }

    case TestActionTypes.SearchAction: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case TestActionTypes.SearchSuccessAction: {
      const func = action.deletePrevious
        ? adapter.setAll
        : adapter.addMany;
      return func(action.payload.data, Object.assign({}, state, {
        loading: false,
        pagination: action.payload.pagination
      }));
    }

    case TestActionTypes.SearchFailureAction: {
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload
      });
    }

    case TestActionTypes.UpdateActionSuccess: {
      return adapter.updateOne({
        id: action.payload.id,
        changes: action.payload
      }, state);
    }

    case TestActionTypes.UpdateActionFailure: {
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload
      });
    }

    case TestActionTypes.ViewAction: {
      return Object.assign({}, state, {
        loading: true,
        errors: {}
      });
    }

    case TestActionTypes.ViewActionSuccess: {
      return adapter.upsertOne(action.payload, Object.assign({}, state, {
        selectedId: action.payload.id,
        loading: false
      }));
    }

    case TestActionTypes.ViewActionFailure: {
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload
      });
    }

    case TestActionTypes.ChangeCategoryActionSuccess: {
      const updates: UpdateStr<TestModel>[] = [];

      action.payload.forEach((model: TestModel) => {
        updates.push({
          id: model.id,
          changes: model
        });
      });

      return adapter.updateMany(updates, state);
    }

    case TestActionTypes.CreateActionSuccess: {
      return adapter.addOne(action.payload, state);
    }

    case TestActionTypes.CreateActionFailure: {
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload
      });
    }

    case TestActionTypes.ShowResultsActionSuccess: {
      if (state.showingResults && state.showingResults.dialog) {
        state.showingResults.dialog.close();
      }
      const showingResults = {
        dialog: action.dialog,
        test: action.test
      };
      const nextState = { ...state, showingResults };
      return adapter.upsertOne(action.test, nextState);
    }

    case TestActionTypes.CloseResultsAction: {
      if (state.showingResults && state.showingResults.dialog) {
        state.showingResults.dialog.close();
      }
      return { ...state, showingResults: undefined };
    }

    case TestActionTypes.StartTestActionSuccess: {
      if (state.showingResults && state.showingResults.dialog) {
        state.showingResults.dialog.close();
      }
      return { ...state, showingResults: undefined };
    }

    default:
      return state;
  }
}

// Фильтр
export const getFilter = (state: State) => state.filter;

// Ошибки
export const getErrors = (state: State) => state.errors;

// Пагинация
export const getPagination = (state: State) => state.pagination;

// Записи в виде объектов {id: item}
export const getEntitiesObject = (state: State) => state.entities;

// Все ID объектов в сторе
export const getIds = (state: State) => state.ids;

// Происходит ли обращение в апи
export const getLoading = (state: State) => state.loading;

// Обновляемая запись
export const getUpdateId = (state: State) => state.updateId;

// Выделенный объект
export const getSelectedId = (state: State) => state.selectedId;

// Записи в виде массива
export const getEntitiesArray = createSelector(getEntitiesObject, getIds, (entities, ids) => ids.map(id => entities[id]));

// Список объектов для вывода. Отсортированные по id
export const getEntities = createSelector(getEntitiesArray, (entities) => _.orderBy(entities, ['id'], ['desc']));

// Список тестов для уроков
export const getEntitiesByIds = (ids: string[]) => createSelector(getEntitiesObject, (entities) => {
  return ids.map(id => entities[id]).filter(wiki => wiki);
});

// Получить выделенный объект
export const getSelectedEntity = createSelector<State, TestModel, string, any>(getEntitiesObject, getSelectedId, (entities, selectedId) => entities[selectedId]);

// Получить обновляемый объект
export const getUpdateEntity = createSelector(getEntitiesObject, getUpdateId, (entities, id) => entities[id]);

