<ui-container class="container_pd-24-16 container_mobile">
  <ui-category-block
    (create)="tree.onOpenCreateForm()"
    [isShowCreateButton]="allowCrud">
    <span ngProjectAs="header">
      Разделы
    </span>

    <ui-category-block-header-control
      [routerLink]="[mobileMode ? 'wiki' : '../', 'active']"
      [isActive]="activeFilter.folder === 'active'"
      tooltip="Все разделы"
      icon="home"
      color="blue">
    </ui-category-block-header-control>
    <ui-category-block-header-control
      [routerLink]="[mobileMode ? 'wiki' : '../', 'unsorted']"
      [isActive]="activeFilter.folder === 'unsorted'"
      tooltip="Без раздела"
      icon="documents"
      color="blue">
    </ui-category-block-header-control>
    <ui-category-block-header-control
      [routerLink]="[mobileMode ? 'wiki' : '../', 'favorite']"
      [isActive]="activeFilter.folder === 'favorite'"
      tooltip="Избранное"
      icon="star-full"
      color="yellow">
    </ui-category-block-header-control>
    <ui-category-block-header-control
      [routerLink]="[mobileMode ? 'wiki' : '../', 'archive']"
      [isActive]="activeFilter.folder === 'archive'"
      tooltip="Корзина"
      icon="trash"
      color="red">
    </ui-category-block-header-control>
    <ui-tree
      #tree
      (changeValue)="onFilter($event)"
      [rtTooltip]="editTip"
      [position]="editTipPosition"
      [selectedId]="selectedId"
      [categories]="categories"
      [isAllowEdit]="allowCrud"
      [isEditAccess]="true">
    </ui-tree>
  </ui-category-block>
</ui-container>
<ng-template #editTip>
  <rt-onboarding-tooltip
    [lines]="[1, 1, 1, .5]"
    arrow="left"
  >
    <p>
      Наведите курсор на раздел к которому хотите дать доступ отделу Разработки
    </p>
    <p>
      И нажмите на иконку <span class="icon-edit rt-color-white"></span>
    </p>
  </rt-onboarding-tooltip>
</ng-template>
