import { Action } from '@ngrx/store';
import { FieldOfActivity } from '@core/models/company.model';


export enum FieldsOfActivityActionTypes {
  GetFieldsOfActivityAction = '[Company] Get Feilds of Activity',
  GetFieldsOfActivityActionSuccess = '[Company] Get Fields of Activity Success',
  GetFieldsOfActivityActionFailure = '[Company] Get Fields of Activity Failure',
}

export class GetFieldsOfActivityAction implements Action {
  readonly type = FieldsOfActivityActionTypes.GetFieldsOfActivityAction;
}

export class GetFieldsOfActivityActionSuccess implements Action {
  readonly type = FieldsOfActivityActionTypes.GetFieldsOfActivityActionSuccess;

  constructor(public readonly activities: FieldOfActivity[]) { }
}

export class GetFieldsOfActivityActionFailure implements Action {
  readonly type = FieldsOfActivityActionTypes.GetFieldsOfActivityActionFailure;

  constructor(public readonly errors: any) { }
}

export type FieldOfActivityActions
  = GetFieldsOfActivityAction
  | GetFieldsOfActivityActionSuccess
  | GetFieldsOfActivityActionFailure
  ;
