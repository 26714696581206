import { Injectable } from '@angular/core';
import { CompanyType } from '@core/models/company.model';
import { State } from '@core/redux';
import { Store } from '@ngrx/store';
import { GoalsRequest, AboutYourselfRequest, LessonStatus, StudyCreatingFromLinkStep, StudyWritingArticleStep, StudyAddingUserStep, StudySettingUpUserStep } from './onboarding.model';
import { getOnboarding, updateOnboarding, changeStudyCreatingFromLinkStep, setFirstLinkArticle, changeStudyWritingArticleStep, setFirstWrittenArticle, changeStudyAddingUserStep, changeStudySettingUpUserStep } from '@core/redux/onboarding/onboarding.actions';
import { OnboardingStateService } from './onboarding-state.service';
import { first } from 'rxjs/operators';
import { OnboardingKey } from './onboarding.reducer';

@Injectable({
  providedIn: 'root',
})
export class OnboardingActionsService {
  constructor(
    private onboardingStateService: OnboardingStateService,
    private store: Store<State>,
  ) {}

  changeStudyCreatingFromLinkStep(nextStep: StudyCreatingFromLinkStep) {
    this.store.dispatch(changeStudyCreatingFromLinkStep({nextStep}));
  }

  changeStudyWritingArticleStep(nextStep: StudyWritingArticleStep) {
    this.store.dispatch(changeStudyWritingArticleStep({nextStep}));
  }

  changeStudyAddingUserStep(nextStep: StudyAddingUserStep) {
    this.store.dispatch(changeStudyAddingUserStep({nextStep}));
  }

  changeStudySettingUpUserStep(nextStep: StudySettingUpUserStep) {
    this.store.dispatch(changeStudySettingUpUserStep({nextStep}));
  }

  get() {
    this.store.dispatch(getOnboarding());
  }

  markStudyServiceAsSeen() {
    this.store.dispatch(updateOnboarding({request: {openStudyService: LessonStatus.Finished}}));
  }

  finishStudySection() {
    this.onboardingStateService.getOnboarding().pipe(first()).subscribe(onboarding => {
      if (onboarding.studyCreatingFromLink === LessonStatus.InProgress) {
        this.store.dispatch(setFirstLinkArticle({id: undefined}));
        this.store.dispatch(updateOnboarding({request: {
          studyCreatingFromLink: LessonStatus.Finished,
          openStudyService: LessonStatus.InProgress
        }}));
      } else if (onboarding.studyWritingArticle === LessonStatus.InProgress) {
        this.store.dispatch(setFirstWrittenArticle({id: undefined}));
        this.store.dispatch(updateOnboarding({request: {
          studyWritingArticle: LessonStatus.Finished,
          openStudyService: LessonStatus.InProgress,
        }}));
      } else if (onboarding.studyAddingUser === LessonStatus.InProgress) {
        this.store.dispatch(updateOnboarding({request: {
          studyAddingUser: LessonStatus.Finished,
        }}));
      } else if (onboarding.studySettingUpUser === LessonStatus.InProgress) {
        this.changeStudySettingUpUserStep(StudySettingUpUserStep.NotStarted)
        this.store.dispatch(updateOnboarding({request: {
          studySettingUpUser: LessonStatus.Finished,
          openStudyService: LessonStatus.InProgress,
        }}));
      } else {
        this.store.dispatch(updateOnboarding({request: {
          openStudyService: LessonStatus.Finished,
          studyService: LessonStatus.Finished,
        }}));
      }
    });
  }

  sendAboutYourself(request: AboutYourselfRequest) {
    this.store.dispatch(updateOnboarding({request: {tellAboutYourself: {...request, status: LessonStatus.Finished}}}));
  }

  sendGoals(request: GoalsRequest) {
    this.store.dispatch(updateOnboarding({request: {tellAboutGoals: {...request, status: LessonStatus.Finished}}}));
  }

  startLearning() {
    this.onboardingStateService.getOnboarding().pipe(first()).subscribe(onboarding => {
      const nextLessonKey = onboarding.studyCreatingFromLink === LessonStatus.Finished
        ? onboarding.studyWritingArticle === LessonStatus.Finished
          ? OnboardingKey.StudyAddingUser
          : OnboardingKey.StudyWritingArticle
        : OnboardingKey.StudyCreatingFromLink;
      const request = {
        openStudyService: LessonStatus.Finished,
        [nextLessonKey]: LessonStatus.InProgress,
      };
      this.store.dispatch(updateOnboarding({request}));
    });
  }

  updateCompanyType(companyType: CompanyType) {
    this.store.dispatch(updateOnboarding({request: {createKnowledgeBase: {selected: companyType, status: LessonStatus.Finished}}}));
  }
}
