import { BaseModel } from './base.model';
import { CategoryModel } from './category.model';
import { NewQuestionModel, QuestionModel } from './question.model';
import { TagModel } from './tag.model';
import { TestResultsModel } from './test-results.model';
import { UserModel, getEmptyUser } from './user.model';
import { FileModel } from '@core/models/file.model';


/**
 * Тест, приходящий с сервера
 */
export interface TestModel {
  colorCssClass: string;
  /**
   * Курс в архиве или активный
   */
  isArchive: boolean;
  /**
   * ID раздела
   */
  categoryId: string;
  /**
   * Описание
   */
  description: any;
  /**
   * Описание без HTML
   */
  shortDescription: string;
  /**
   * Название теста
   */
  name: string;
  /**
   * Основная картинка
   */
  image?: FileModel;
  /**
   * Категория
   * Присваивается на клиенте
   */
  category: CategoryModel;
  /**
   * ID компании
   */
  companyId: string;
  /**
   * Время создания
   */
  createdAt: string;
  /**
   * Идентификатор
   */
  id: string;
  /**
   * Закреплен?
   */
  isPinned: boolean;
  /**
   * Разрешено ли редактировать (права доступа)
   */
  isAllowEdit: boolean;
  /**
   * Идентификаторы тегов
   */
  tagIds: string[];
  /**
   * Теги
   */
  tags: TagModel[];
  /**
   * Время последнего обновления
   */
  updatedAt: string;
  /**
   * Пользователь, создавший этот тест или курс
   */
  user: UserModel;
  userId: string;
  /**
   * Статистика
   */
  results: TestResultsModel;
  /**
   * Вопросы теста
   */
  questions: QuestionModel[];
  /**
   * Файлы
   */
  files: FileModel[];
}

/**
 * Вернуть тест со всеми заполненными полями
 */
export function getEmptyTest(): TestModel {
  return {
    isAllowEdit: false,
    colorCssClass: 'test_style-1',
    isArchive: false,
    category: undefined,
    categoryId: '',
    companyId: '',
    createdAt: '1984-04-01T00:00:00',
    description: '',
    id: '',
    isPinned: false,
    name: '',
    tagIds: [],
    tags: [],
    updatedAt: '1984-04-01T00:00:00',
    questions: [],
    results: {
      userResult: {
        userId: '',
        countCorrectAnswer: 0,
        countIncorrectAnswer: 0,
        numberOfAttempts: 0,
        score: 0,
        totalCountQuestions: 0,
        time: 0,
      },
      allResults: []
    },
    user: getEmptyUser(),
    userId: '',
    files: [],
    shortDescription: '',
  };
}


/**
 * Тест, только что созданный пользователем в форме
 * Отличается от `PostTestModel` тем,
 * что вопросы и ответы ещё не имеют случайных uuid
 */
export interface NewTestModel {
  /**
   * ID раздела
   */
  categoryId: string;
  /**
   * Описание
   */
  description: any;
  /**
   * Название теста
   */
  name: string;
  /**
   * Основная картинка
   */
  image?: File;
  /**
   * Выбранные теги
   */
  tagIds: string[];
  /**
   * Новые теги
   */
  tagNames: string[];
  questions: NewQuestionModel[];
}


/**
 * Используется для POST запроса на /tests
 * Отличается от `NewTestModel` тем,
 * что вопросы и ответы имеют id,
 * сгенерированные на стороне клиента
 */
export interface PostTestModel {
  /**
   * ID раздела
   */
  categoryId: string;
  /**
   * Описание
   */
  description: any;
  /**
   * Название теста
   */
  name: string;
  /**
   * Основная картинка
   */
  image?: File;
  /**
   * Выбранные теги
   */
  tagIds: string[];
  /**
   * Новые теги
   */
  tagNames: string[];
  questions: QuestionModel[];
}

/**
 * Тест, обновленный пользователем в форме
 * Отличается от `PostTestModel` тем,
 * что новые вопросы и ответы ещё не имеют случайных uuid
 */
export interface UpdatedTestModel extends NewTestModel {
  id: string;
}

/**
 * Используется для PUT запроса на /tests
 * Отличается от `UpdatedTestModel` тем,
 * что все вопросы и ответы имеют id,
 * сгенерированные на стороне клиента
 */
export interface PutTestModel extends PostTestModel {
  id: string;
}

/**
 * Класс, формирующий GET запрос
 */
export class TestQueryModel extends BaseModel {
  id: string[];
  page: number;
  categoryId: string;
  match: string;
  tagIds: string[] = [];
  isArchive: 0 | 1;
  userId: string;
}
