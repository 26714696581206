import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-popup',
  templateUrl: './ui-popup.component.html',
  styleUrls: ['./ui-popup.component.less']
})
export class UiPopupComponent implements OnInit {
  @Input() arrow = false;
  @Input() arrowTop: number;
  @Input() isEditAccess = false;
  @Input() overlay = true;

  @Input() title = '';


  constructor() {
  }


  ngOnInit() {
  }

}
