import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import * as fromRoot from '@core/redux/index';
import * as _ from 'lodash';
import 'rxjs/add/observable/combineLatest';
import 'rxjs/add/operator/map';

import {
  addUUID,
  CategoryModel,
  NewTestModel,
  PostTestModel,
  PutTestModel,
  QuestionModel,
  TagModel,
  TestModel,
  TestQueryModel,
  UpdatedTestModel,
} from '@core/models';
import * as TestActions from '@core/redux/test/test.actions';
import {
  ChangeCategoryAction,
  CreateAction,
  DeleteAction,
  RecoveryAction,
  SearchAction,
  StartTestAction,
  ShowResultsAction,
  TestActionTypes,
  UpdateAction,
  ViewCloseAction,
  ViewActionSuccess,
  ResetAction,
} from '@core/redux/test/test.actions';
import { CombineTagAction } from '@core/redux/wiki/wiki.actions';
import { Actions, ofType } from '@ngrx/effects';


@Injectable({
  providedIn: 'root',
})
export class TestService {
  private companyId: string;
  constructor(
    private actions: Actions,
    private store: Store<fromRoot.State>,
  ) {
    this.subscribeToCompany();
  }

  /**
   * События закрытия страницы результатов теста
   */
  closing(): Observable<TestActions.CloseResultsAction> {
    return this.actions.pipe(
      ofType(TestActionTypes.CloseResultsAction),
    );
  }

  /**
   * События окончания теста
   */
  finishedTest(): Observable<TestActions.FinishTestAction> {
    return this.actions.pipe(
      ofType(TestActionTypes.FinishTestAction)
    );
  }

  resetStore() {
    this.store.dispatch(new ResetAction());
  }

  delete(id: string) {
    this.store.dispatch(new DeleteAction(id));
  }

  recovery(id: string) {
    this.store.dispatch(new RecoveryAction(id));
  }

  closeView() {
    this.store.dispatch(new ViewCloseAction());
  }

  combineTag(tagId, combineIds) {
    this.store.dispatch(new CombineTagAction(tagId, combineIds));
  }

  startTest(test: TestModel, lessonId?: string) {
    this.store.dispatch(new StartTestAction(test, lessonId));
  }

  showResults(test: TestModel | string, lessonId?: string) {
    this.store.dispatch(new ShowResultsAction(test, lessonId));
  }

  closeResults() {
    this.store.dispatch(new TestActions.CloseResultsAction());
  }

  getLoading() {
    return this.store.pipe(select(fromRoot.getTestLoading));
  }

  searchAll(query: TestQueryModel, deletePrevious = true) {
    this.store.dispatch(new SearchAction(query, deletePrevious));
  }

  fetchOne(id: string) {
    if (!id) {
      return;
    }
    this.store.dispatch(new TestActions.ViewAction(id));
  }

  create(model: NewTestModel) {
    const questions = model.questions
      .map<QuestionModel>(addUUID);
    const postModel: PostTestModel = {
      ...model,
      questions
    };
    this.store.dispatch(new CreateAction(postModel));
  }

  update(model: UpdatedTestModel) {
    const questions = model.questions
      .map<QuestionModel>(addUUID);
    const putModel: PutTestModel = {
      ...model,
      questions
    };
    this.store.dispatch(new UpdateAction(putModel));
  }

  changeCategory(model) {
    this.store.dispatch(new ChangeCategoryAction(model));
  }

  getItems(): Observable<TestModel[]> {
    const test$ = this.store.pipe(select(fromRoot.getTestEntities));
    const category$ = this.store.pipe(select(fromRoot.getCategoryEntitiesByType('test')));
    const tag$ = this.store.pipe(select(fromRoot.getTagEntitiesByType('test')));
    return Observable.combineLatest(
      test$,
      category$,
      tag$,
      (test: TestModel[],
        category: CategoryModel[],
        tag: TagModel[]) => {
        test.forEach(item => {
          const tags = [];
          item.category = _.find(category, { id: item.categoryId });

          (item.tagIds || []).forEach((id) => {
            const tagModel = _.find(tag, { id: id });
            if (tagModel) {
              tags.push(tagModel);
            }
          });

          item.tags = tags;
        });

        return test;
      });
  }

  getSelected() {
    const test$ = this.store.pipe(select(fromRoot.getTestSelected));
    const category$ = this.store.pipe(select(fromRoot.getCategoryEntitiesByType('test')));
    const tag$ = this.store.pipe(select(fromRoot.getTagEntitiesByType('test')));
    return Observable.combineLatest(
      test$,
      category$,
      tag$,
      (test: TestModel,
        category: CategoryModel[],
        tag: TagModel[]) => {

        if (test && category && tag) {
          const tags = [];
          test.category = _.find(category, { id: test.categoryId });

          (test.tagIds || []).forEach((id) => {
            const tagModel = _.find(tag, { id: id });
            if (tagModel) {
              tags.push(tagModel);
            }
          });

          test.tags = tags;
        }

        return test;
      });
  }

  getLink(testId: string): string {
    return `/${this.companyId}/learning/tests/active?id=${testId}`;
  }

  private subscribeToCompany() {
    this
      .store
      .pipe(
        select(fromRoot.getCompanyCurrentId),
      )
      .subscribe((companyId) => {
        this.companyId = companyId;
      });
  }
}
