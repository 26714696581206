import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiTagSidebarComponent } from './ui-tag-sidebar.component';
import { UiTagSidebarFormComponent } from './components/ui-tag-sidebar-form/ui-tag-sidebar-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiTagModule } from '@ui/modules/ui-tag/ui-tag.module';
import { UiContainerModule } from '@ui/modules/ui-container/ui-container.module';
import { UiPopupModule } from '@ui/modules/ui-popup/ui-popup.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { UiSelectMultiTagModule } from '@ui/modules/ui-select-multi-tag/ui-select-multi-tag.module';
import { UiTooltipModule } from '@ui/modules/ui-tooltip/ui-tooltip.module';

@NgModule({
  declarations: [
    UiTagSidebarComponent,
    UiTagSidebarFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UiTagModule,
    UiContainerModule,
    UiPopupModule,
    ClickOutsideModule,
    UiSelectMultiTagModule,
    UiTooltipModule
  ],
  exports: [
    UiTagSidebarComponent
  ],
})
export class UiTagSidebarModule {
}
