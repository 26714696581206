<input #inputSearch
       type="text"
       class="input ui-tree__search"
       *ngIf="turnSearch"
       [ngModel]="search"
       (ngModelChange)="onSearchChange($event)"
       [placeholder]="searchPlaceholder">
<span *ngIf="turnSearch" class="ui-tree__icon-search icon-search"></span>
<div class="ui-tree__tree"
     [id]="treeHtmlId"
     [ngClass]="{'ui-tree__tree_search': turnSearch}"
     (click)="onSetPosition($event)">
</div>

<div class="ui-tree__new" (click)="onCreateCategory($event)" *ngIf="search && turnSearch && isAllowEdit">
  <div class="ui-tree__new-text">Нажмите ниже, чтобы добавить</div>
  <span class="ui-tree__new-val">{{search}}</span>
</div>
<ui-popup [arrow]="isShowArrowPopup"
          [arrowTop]="popupArrowPosition"
          [ngStyle]="popupPosition"
          (clickOutside)="onClosePopupForm()"
          [exclude]="'.jqtree__edit,ui-btn-entity-create'"
          [excludeBeforeClick]="true"
          [delayClickOutsideInit]="true"
          *ngIf="isOpenPopup"
          [ngClass]="{'ui-tree__form_header' : turnSearch}"
          class="ui-tree__form">
  <ui-popup-category #form
                     [isAccess]="isEditAccess"
                     [type]="type"
                     (close)="onClosePopupForm()">
  </ui-popup-category>
</ui-popup>
