import {TagActions, TagActionTypes} from './tag.actions';
import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';
import {TagModel, TagTypes} from '@core/models/tag.model';
import {createSelector} from '@ngrx/store';

export interface State {
  ids: string[];
  loading: boolean;
  updateId: string | null;
  selectedId: string | null;
  entities: any;
  errors: any;
}

export const initialState: State = {
  ids: [],
  loading: false,
  updateId: null,
  selectedId: null,
  entities: {},
  errors: {}
};

export const adapter: EntityAdapter<TagModel> = createEntityAdapter<TagModel>({
  selectId: (tag: TagModel) => tag.id,
  sortComparer: false,
});

export function reducer(state = initialState, action: TagActions): State {
  switch (action.type) {

    case TagActionTypes.ResetAction: {
      return Object.assign({}, state, initialState);
    }

    case TagActionTypes.SearchSuccessAction: {
      return adapter.setAll(action.payload, state);
    }

    case TagActionTypes.SelectUpdateAction: {
      return Object.assign({}, state, {updateId: action.payload});
    }

    case TagActionTypes.UpdateActionSuccess: {
      return adapter.updateOne({
        id: action.payload.id,
        changes: action.payload
      }, state);
    }

    case TagActionTypes.CreateActionSuccess: {
      return adapter.addOne(action.payload, state);
    }

    case TagActionTypes.DeleteAction: {
      return {...state, loading: true, errors: {}};
    }

    case TagActionTypes.DeleteActionSuccess: {
      return adapter.removeOne(action.payload, {...state, loading: false, updateId: null});
    }

    case TagActionTypes.DeleteActionFailure: {
      return {...state, loading: true, errors: action.payload};
    }
    default:
      return state;
  }
}

export const getErrors = (state: State) => state.errors;
export const getEntitiesObject = (state: State) => state.entities;
export const getIds = (state: State) => state.ids;
export const getLoading = (state: State) => state.loading;
export const getUpdateId = (state: State) => state.updateId;
export const getSelectedId = (state: State) => state.selectedId;
export const getSelectedEntity = createSelector(getEntitiesObject, getSelectedId, (entities, selectedId) => entities[selectedId]);
export const getEntities = createSelector(getEntitiesObject, getIds, (entities, ids) => ids.map(id => entities[id]));
export const getUpdateEntity = createSelector(getEntitiesObject, getUpdateId, (entities, id) => entities[id]);
export const getEntitiesByType = (type: TagTypes) => createSelector(getEntities, (entities) => {
  return entities.filter(item => item.type === type);
});
