import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiSelectMultiTagComponent} from '@ui/modules/ui-select-multi-tag/ui-select-multi-tag.component';
import {ClickOutsideModule} from 'ng-click-outside';
import {UiPopupMenuItemModule} from '@ui/modules/ui-popup-menu-item/ui-popup-menu-item.module';
import {UiPopupModule} from '@ui/modules/ui-popup/ui-popup.module';

@NgModule({
  declarations: [
    UiSelectMultiTagComponent
  ],
  exports: [
    UiSelectMultiTagComponent
  ],
  imports: [
    CommonModule,
    ClickOutsideModule,
    UiPopupMenuItemModule,
    UiPopupModule
  ]
})
export class UiSelectMultiTagModule {
}
