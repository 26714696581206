import { BaseModel } from './base.model';
import { CategoryModel } from './category.model';
import { NewLessonModel, LessonModel, PostPutLessonModel } from './lesson.model';
import { TagModel } from './tag.model';
import { UserModel, getEmptyUser } from './user.model';
import { FileModel } from '@core/models/file.model';

/**
 * Курс, приходящий с сервера
 */
export interface CourseModel {
  colorCssClass: string;
  /**
   * Курс в архиве или активный
   */
  isArchive: boolean;
  /**
   * ID раздела
   */
  categoryId: string;
  /**
   * Описание
   */
  description: any;
  /**
   * Описание без HTML
   */
  shortDescription: string;
  /**
   * Название теста
   */
  name: string;
  /**
   * Основная картинка
   */
  image?: FileModel;
  /**
   * Категория
   * Присваивается на клиенте
   */
  category: CategoryModel;
  /**
   * ID компании
   */
  companyId: string;
  /**
   * Время создания
   */
  createdAt: string;
  /**
   * Идентификатор
   */
  id: string;
  /**
   * Закреплен?
   */
  isPinned: boolean;
  /**
   * Разрешено ли редактировать (права досутпа)
   */
  isAllowEdit: boolean;
  /**
   * Идентификаторы тегов
   */
  tagIds: string[];
  /**
   * Теги
   */
  tags: TagModel[];
  /**
   * Время последнего обновления
   */
  updatedAt: string;
  /**
   * Пользователь, создавший этот тест или курс
   */
  user: UserModel;
  userId: string;
  lessons: LessonModel[];
  files: FileModel[];
}

export function getEmptyCourse(): CourseModel {
  return {
    isAllowEdit: false,
    colorCssClass: 'course_style-1',
    isArchive: false,
    category: undefined,
    categoryId: '',
    companyId: '',
    createdAt: '1984-04-01T00:00:00',
    description: '',
    id: '',
    isPinned: false,
    name: '',
    tagIds: [],
    tags: [],
    updatedAt: '1984-04-01T00:00:00',
    lessons: [],
    user: getEmptyUser(),
    userId: '',
    files: [],
    shortDescription: '',
  };
}


/**
 * Курс, созданный пользователем в форме создания курса
 */
export interface NewCourseModel {
  /**
   * Выбранные теги
   */
  tagIds: string[];
  /**
   * Новые теги
   */
  tagNames: string[];
  /**
   * ID раздела
   */
  categoryId: string;
  /**
   * Описание
   */
  description: any;
  /**
   * Название теста
   */
  name: string;
  /**
   * Основная картинка
   */
  image?: File;
  lessons: PostPutLessonModel[];
  files: FileModel[];
}

/**
 * Курс, отправляемый POST запросом на создание
 * Уроки должны иметь ID, сгенерированные клиентом
 */
export interface PostCourseModel {
  /**
   * ID раздела
   */
  categoryId: string;
  /**
   * Описание
   */
  description: any;
  /**
   * Название теста
   */
  name: string;
  /**
   * Основная картинка
   */
  image?: File;
  lessons: PostPutLessonModel[];
  files: FileModel[];
}

/**
 * Курс, обновленный пользователем в форме
 * Отличается от `PostCourseModel` тем,
 * что новые уроки ещё не имеют случайных uuid
 */
export interface UpdatedCourseModel extends NewCourseModel {
  id: string;
}

/**
 * Используется для PUT запроса на /courses
 * Отличается от `UpdatedCourseModel` тем,
 * что все уроки имеют id,
 * сгенерированные на стороне клиента
 */
export interface PutCourseModel extends PostCourseModel {
  id: string;
}


/**
 * Класс, формирующий GET запрос
 */
export class CourseQueryModel extends BaseModel {
  page: number;
  categoryId: string;
  tagIds: string[] = [];
  isArchive: 0 | 1;
  userId: string;
}

