import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  CreateAction,
  CreateActionFailure,
  CreateActionSuccess,
  DeleteAction,
  DeleteActionFailure,
  DeleteActionSuccess,
  SearchAction,
  SearchFailureAction,
  SearchSuccessAction,
  TagActionTypes,
  UpdateAction,
  UpdateActionFailure,
  UpdateActionSuccess
} from './tag.actions';
import {catchError, exhaustMap, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/index';
import {TagModel} from '@core/models/tag.model';
import {Store} from '@ngrx/store';
import * as fromRoot from '@core/redux/index';
import {ApiTagService} from '@core/services/entities/tag/api-tag.service';

@Injectable()
export class TagEffects {
  constructor(private actions$: Actions,
              private store: Store<fromRoot.State>,
              private apiTagService: ApiTagService) {
  }

  @Effect()
  search$ = this.actions$.pipe(
    ofType(TagActionTypes.SearchAction),
    exhaustMap(() => {
        return this.apiTagService
          .search()
          .pipe(
            map((response) => new SearchSuccessAction(response)),
            catchError((errors) => of(new SearchFailureAction(errors)))
          );
      }
    )
  );

  @Effect()
  update$ = this.actions$.pipe(
    ofType(TagActionTypes.UpdateAction),
    map((action: UpdateAction) => action.payload),
    exhaustMap((model: TagModel) => {
        return this.apiTagService
          .update(model)
          .pipe(
            switchMap((response) => [
              new UpdateActionSuccess(response),
              new SearchAction()
            ]),
            catchError((errors) => of(new UpdateActionFailure(errors)))
          );
      }
    )
  );

  @Effect()
  create$ = this.actions$.pipe(
    ofType(TagActionTypes.CreateAction),
    map((action: CreateAction) => action.payload),
    exhaustMap((model: TagModel) => {
        return this.apiTagService
          .create(model)
          .pipe(
            map((response) => new CreateActionSuccess(response)),
            catchError((errors) => of(new CreateActionFailure(errors)))
          );
      }
    )
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(TagActionTypes.DeleteAction),
    map((action: DeleteAction) => action.payload),
    exhaustMap((id: string) => {
        return this.apiTagService
          .delete(id)
          .pipe(
            map(() => new DeleteActionSuccess(id)),
            catchError((errors) => of(new DeleteActionFailure(errors)))
          );
      }
    )
  );
}
