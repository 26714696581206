import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipComponent } from './tooltip.component';
import { TooltipDirective } from './tooltip.directive';
import { OnboardingTooltipComponent } from './onboarding-tooltip/onboarding-tooltip.component';

@NgModule({
  declarations: [TooltipComponent, TooltipDirective, OnboardingTooltipComponent],
  imports: [
    CommonModule
  ],
  exports: [TooltipComponent, TooltipDirective, OnboardingTooltipComponent,]
})
export class TooltipModule { }
