import {BaseModel} from './base.model';

export type TagTypes = 'wiki' | 'test' | 'course';

export class TagModel extends BaseModel {
  id: string;
  name: string;
  countData: number;
  type: TagTypes;
}
