import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ui-category-block-header-control',
  templateUrl: './ui-category-block-header-control.component.html',
  styleUrls: ['./ui-category-block-header-control.component.less']
})
export class UiCategoryBlockHeaderControlComponent implements OnInit {
  @Input() icon: 'home' | 'done' | 'failed' | 'documents' | 'star-full' | 'trash' | 'team' | 'invited-users' | 'user-indicator' | 'fired';
  @Input() color: 'green' | 'yellow' | 'blue' | 'red';
  @Input() isActive;
  cssClass: string[] = [];
  @Input() tooltip: string;

  constructor() {
  }

  ngOnInit() {
    this.cssClass.push('ui-category-block-header-control__icon_' + this.color);
    this.cssClass.push('icon-' + this.icon);
  }
}
