import {CourseActions, CourseActionTypes} from './course.actions';
import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';
import {CourseModel} from '@core/models';
import {createSelector} from '@ngrx/store';
import {UpdateStr} from '@ngrx/entity/src/models';
import {PaginationResponse} from '@core/models/filters/pagination-response';
import * as _ from 'lodash';

export interface State {
  ids: string[];
  loading: boolean;
  updateId: string | null;
  selectedId: string | null;
  entities: any;
  errors: any;
  pagination: PaginationResponse;
  filter: string;
}

export const initialState: State = {
  ids: [],
  loading: false,
  updateId: null,
  selectedId: null,
  entities: {},
  errors: {},
  pagination: null,
  filter: 'active'
};

export const adapter: EntityAdapter<CourseModel> = createEntityAdapter<CourseModel>({
  selectId: (course: CourseModel) => course.id,
  sortComparer: false,
});

export function reducer(state = initialState, action: CourseActions): State {
  switch (action.type) {

    case CourseActionTypes.ResetAction: {
      return {...initialState};
    }

    case CourseActionTypes.DeleteAction: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case CourseActionTypes.DeleteActionSuccess: {
      return adapter.removeOne(action.payload, Object.assign({}, state, {
        loading: false
      }));
    }

    case CourseActionTypes.DeleteActionFailure: {
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload
      });
    }

    case CourseActionTypes.ViewCloseAction: {
      return Object.assign({}, state, {
        loading: false,
        selectedId: null,
        errors: {}
      });
    }

    case CourseActionTypes.SearchAction: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case CourseActionTypes.SearchSuccessAction: {
      return adapter.setAll(action.payload.data, Object.assign({}, state, {
        loading: false,
        pagination: action.payload.pagination
      }));
    }

    case CourseActionTypes.SearchFailureAction: {
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload
      });
    }

    case CourseActionTypes.UpdateActionSuccess: {
      return adapter.updateOne({
        id: action.payload.id,
        changes: action.payload
      }, state);
    }

    case CourseActionTypes.ViewAction: {
      return Object.assign({}, state, {
        loading: true,
        errors: {}
      });
    }

    case CourseActionTypes.ViewActionSuccess: {
      return adapter.upsertOne(action.payload, Object.assign({}, state, {
        selectedId: action.payload.id,
        loading: false
      }));
    }

    case CourseActionTypes.ViewActionFailure: {
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload
      });
    }

    case CourseActionTypes.ChangeCategoryActionSuccess: {
      const updates: UpdateStr<CourseModel>[] = [];

      action.payload.forEach((model: CourseModel) => {
        updates.push({
          id: model.id,
          changes: model
        });
      });

      return adapter.updateMany(updates, state);
    }

    case CourseActionTypes.CreateActionSuccess: {
      return adapter.addOne(action.payload, state);
    }

    case CourseActionTypes.RecoveryAction: {
      return {...state, loading: true};
    }

    case CourseActionTypes.RecoveryActionSuccess: {
      return adapter.removeOne(action.payload, {...state, loading: false});
    }

    case CourseActionTypes.RecoveryActionFailure: {
      return {...state, loading: false, errors: action.payload};
    }

    default:
      return state;
  }
}

// Фильтр
export const getFilter = (state: State) => state.filter;

// Ошибки
export const getErrors = (state: State) => state.errors;

// Пагинация
export const getPagination = (state: State) => state.pagination;

// Записи в виде объектов {id: item}
export const getEntitiesObject = (state: State) => state.entities;

// Все ID объектов в сторе
export const getIds = (state: State) => state.ids;

// Происходит ли обращение в апи
export const getLoading = (state: State) => state.loading;

// Обновляемая запись
export const getUpdateId = (state: State) => state.updateId;

// Выделенный объект
export const getSelectedId = (state: State) => state.selectedId;

// Записи в виде массива
export const getEntitiesArray = createSelector(getEntitiesObject, getIds, (entities, ids) => ids.map(id => entities[id]));

// Список объектов для вывода. Отсортированные по фиксации и сортировке.
export const getEntities = createSelector(getEntitiesArray, (entities) => _.orderBy(entities, ['id'], ['desc']));

// Получить выделенный объект
export const getSelectedEntity = createSelector<State, CourseModel, string, any>(getEntitiesObject, getSelectedId, (entities, selectedId) => entities[selectedId]);

// Получить обновляемый объект
export const getUpdateEntity = createSelector(getEntitiesObject, getUpdateId, (entities, id) => entities[id]);

