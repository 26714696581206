<ui-popup class="ui-user-details__popup">
  <ui-rating
    [value]="user.karma"
    class="ui-user-details__rating">
  </ui-rating>
  <div class="ui-user-details__photo">
    <ui-avatar
      [user]="user"
      class="avatar_xxl">
    </ui-avatar>
  </div>
  <span class="ui-user-details__name">
    {{ user.firstName }} {{ user.lastName }}
  </span>
  <div class="ui-user-details__info">
      <span class="ui-user-details__position">
        {{invite.userPosition?.name}}
      </span>
      <span class="ui-user-details__dot user-status_master">
          &bull;
      </span>
      <span class="ui-user-details__status user-status_master">
        {{invite.userStatus?.name}}
      </span>
  </div>
  <a [routerLink]="[companyId, 'user', invite.id, 'karma']" class="link ui-user-details__link">
    Перейти в профиль
  </a>
</ui-popup>
