import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ui-popup-menu-item',
  templateUrl: './ui-popup-menu-item.component.html',
  styleUrls: ['./ui-popup-menu-item.component.less']
})
export class UiPopupMenuItemComponent implements OnInit {
  @Input() iconName: string;



  constructor() { }

  ngOnInit() {
  }

}
