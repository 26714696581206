import { Injectable } from '@angular/core';
import { FieldOfActivity } from '@core/models/company.model';
import { map } from 'rxjs/operators';
import { ApiService } from '@core/services/entities/api.service';

@Injectable({
  providedIn: 'root',
})
export class ApiFieldOfActivityService extends ApiService {
  getFieldsOfActivity() {
    return this
      .http
      .get<FieldOfActivity[]>(this.getApiUrl('field-of-activity'));
  }
}
