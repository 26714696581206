import { Component, Input, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs/Subscription';
import { CategoryModel } from '@core/models/category.model';
import * as fromRoot from '@core/redux';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/entities/auth/auth.service';
import { IdentityModel } from '@core/models/identity.model';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { OnboardingStateService } from '@core/redux/onboarding/onboarding-state.service';
import { OnboardingActionsService } from '@core/redux/onboarding/onboarding-actions.service';
import { TooltipDirective } from '@ui/modules/tooltip/tooltip.directive';
import { StudySettingUpUserStep } from '@core/redux/onboarding/onboarding.model';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'user-list-filter-sidebar',
  templateUrl: './user-list-filter-sidebar.component.html',
  styleUrls: ['./user-list-filter-sidebar.component.less']
})
export class UserListFilterSidebarComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input()
  folder;
  
  @Input()
  mobileMode = false;

  @ViewChild('requestsTipContainer', {static: false, read: TooltipDirective})
  requestsTipContainer: TooltipDirective;

  categories: CategoryModel[] = [];
  user: IdentityModel;

  studyingSettingUpUser = false;

  readonly requestsTipPosition: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'center',
      overlayX: 'start',
      overlayY: 'center',
      offsetX: 20,
    }
  ];

  private _subscriptions$: Subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private onboardingActionsService: OnboardingActionsService,
    private onboardingStateService: OnboardingStateService,
    private router: Router,
    private store: Store<fromRoot.State>,
  ) {
  }

  ngAfterViewInit() {
    this.showTipIfNeeded();
  }

  ngOnInit() {
    this.subscribeToUser();
    this
      ._subscriptions$
      .add(this
        .store
        .pipe(select(fromRoot.getCategoryEntitiesByType('user')))
        .subscribe((categories) => this.categories = categories)
      );
  }

  ngOnDestroy() {
    this._subscriptions$.unsubscribe();
  }

  onFilter(departmentId) {
    this.router.navigate([], {
      queryParams: {
        departmentId: departmentId
      }
    });
  }

  onNavigationToRequests() {
    if (this.studyingSettingUpUser) {
      this.requestsTipContainer.hide();
      this.onboardingActionsService.changeStudySettingUpUserStep(StudySettingUpUserStep.Approve);  
    }
  }

  private showTipIfNeeded() {
    this._subscriptions$.add(
      this.onboardingStateService.shouldStudySettingUpUser().pipe(
        filter(should => should),
      ).subscribe(() => {
        this.studyingSettingUpUser = true;
        this.onboardingActionsService.changeStudySettingUpUserStep(StudySettingUpUserStep.NavigateToRequests);
        this.requestsTipContainer.show();
      })
    );
  }

  private subscribeToUser() {
    this._subscriptions$.add(this
      .authService
      .getUser()
      .subscribe((user) => this.user = user)
    );
  }
}
