import { Action, createReducer, on } from '@ngrx/store';
import { getOnboardingSuccess, clearOnboarding, getOnboarding, getOnboardingFailure, changeStudyCreatingFromLinkStep, setFirstLinkArticle, changeStudyWritingArticleStep, setFirstWrittenArticle, changeStudyAddingUserStep, changeStudySettingUpUserStep, resetOnboardingSteps } from './onboarding.actions';
import { RemoteDataState, RemoteData } from '@helpers/types/web-data';
import { StudyCreatingFromLinkStep, OnboardingResponse, StudyWritingArticleStep, StudyAddingUserStep, StudySettingUpUserStep } from './onboarding.model';

export enum OnboardingKey {
  CreateKnowledgeBase = 'createKnowledgeBase',
  TellAboutGoals = 'tellAboutGoals',
  TellAboutYourself = 'tellAboutYourself',
  StudyService = 'studyService',
  OpenStudyService = 'openStudyService',
  StudyCreatingFromLink = 'studyCreatingFromLink',
  StudyWritingArticle = 'studyWritingArticle',
  StudyAddingUser = 'studyAddingUser',
  StudySettingUpUser = 'studySettingUpUser',
}

export interface State extends RemoteData {
  studyCreatingFromLinkStep: StudyCreatingFromLinkStep;
  studyWritingArticleStep: StudyWritingArticleStep;
  studyAddingUserStep: StudyAddingUserStep;
  studySettingUpUserStep: StudySettingUpUserStep;
  firstLinkArticle: string;
  firstWrittenArticle: string;
  onboarding: OnboardingResponse | undefined;
}

const initialState: State = {
  state: RemoteDataState.NotAsked,
  studyCreatingFromLinkStep: StudyCreatingFromLinkStep.NotStarted,
  studyWritingArticleStep: StudyWritingArticleStep.NotStarted,
  studyAddingUserStep: StudyAddingUserStep.NotStarted,
  studySettingUpUserStep: StudySettingUpUserStep.NotStarted,
  firstLinkArticle: undefined,
  firstWrittenArticle: undefined,
  onboarding: undefined,
};

const onboardingReducer = createReducer(
  initialState,
  on(getOnboarding, (state): State => ({...state, state: RemoteDataState.Loading})),
  on(getOnboardingSuccess, (state, action): State => ({...state, onboarding: action.response, state: RemoteDataState.Success})),
  on(getOnboardingFailure, (state): State => ({...state, state: RemoteDataState.Failure})),
  on(clearOnboarding, (): State => initialState),
  on(resetOnboardingSteps, (state): State => ({
    ...state,
    studyCreatingFromLinkStep: StudyCreatingFromLinkStep.NotStarted,
    studyWritingArticleStep: StudyWritingArticleStep.NotStarted,
    studyAddingUserStep: StudyAddingUserStep.NotStarted,
    studySettingUpUserStep: StudySettingUpUserStep.NotStarted
  })),
  on(changeStudyCreatingFromLinkStep, (state, action): State => ({...state, studyCreatingFromLinkStep: action.nextStep})),
  on(changeStudyWritingArticleStep, (state, action): State => ({...state, studyWritingArticleStep: action.nextStep})),
  on(changeStudyAddingUserStep, (state, action): State => ({...state, studyAddingUserStep: action.nextStep})),
  on(changeStudySettingUpUserStep, (state, action): State => ({...state, studySettingUpUserStep: action.nextStep})),
  on(setFirstLinkArticle, (state, action): State => ({...state, firstLinkArticle: action.id})),
  on(setFirstWrittenArticle, (state, action): State => ({...state, firstWrittenArticle: action.id})),
);

export function reducer(state: State, action: Action) {
  return onboardingReducer(state, action);
}
