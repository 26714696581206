import {Component, Input, OnDestroy, OnInit, ViewChild, AfterViewInit, ElementRef} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Subscription} from 'rxjs/Subscription';
import {CategoryModel} from '@core/models/category.model';
import {ActivatedRoute, Router} from '@angular/router';
import {pluck, filter} from 'rxjs/operators';
import * as fromRoot from '@core/redux';
import {FilterInterface} from '@core/interfaces/filter-interface.model';
import {FilterService} from '@core/services/filter/filter.service';
import { TooltipDirective } from '@ui/modules/tooltip/tooltip.directive';
import { OnboardingStateService } from '@core/redux/onboarding/onboarding-state.service';
import { StudySettingUpUserStep } from '@core/redux/onboarding/onboarding.model';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { fromEvent } from 'rxjs';
import { OnboardingActionsService } from '@core/redux/onboarding/onboarding-actions.service';

@Component({
  selector: 'wiki-categories',
  templateUrl: './wiki-categories.component.html',
  styleUrls: ['./wiki-categories.component.less']
})
export class WikiCategoriesComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input()
  allowCrud = false;

  @Input()
  mobileMode = false;

  @ViewChild('tree', {static: false, read: TooltipDirective})
  editTipContainer: TooltipDirective;

  /**
   * Активные фильтры
   */
  activeFilter: FilterInterface;
  categories: CategoryModel[] = [];
  selectedId: string | null;

  readonly editTipPosition: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top',
      offsetY: 48,
      offsetX: 20,
    }
  ];

  private _subscriptions$: Subscription = new Subscription();

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private filterService: FilterService,
    private onboardingActionsService: OnboardingActionsService,
    private onboardingStateService: OnboardingStateService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<fromRoot.State>,
  ) {
  }

  ngAfterViewInit() {
    this.showTipIfNeeded();
  }

  ngOnInit() {
    this.subscribeToActiveFilter();
    this
      ._subscriptions$
      .add(this
        .store
        .pipe(select(fromRoot.getCategoryEntitiesByType('wiki')))
        .subscribe((categories) => this.categories = categories)
      );

    this._subscriptions$
      .add(this
        .route
        .queryParams
        .pipe(
          pluck('categoryId'),
        )
        .subscribe((categoryId: string) => {
          this.selectedId = categoryId || null;
        })
      );
  }

  ngOnDestroy() {
    this._subscriptions$.unsubscribe();
  }

  onFilter(categoryId) {
    this.router.navigate(['../active'], {
      queryParams: {
        categoryId: categoryId
      },
      relativeTo: this.route,
    });
  }

  private showTipIfNeeded() {
    this._subscriptions$.add(
      this.onboardingStateService.getStudySettingUpUserStep().pipe(
        filter(step => step === StudySettingUpUserStep.Edit),
      ).subscribe(() => {
        this.editTipContainer.show();
        this.hideTipOnEditClick();
      })
    );
  }

  private hideTipOnEditClick() {
    const sub = fromEvent(this.elementRef.nativeElement, 'click').pipe(
      filter(event => (event.target as HTMLElement).classList.contains('jqtree__edit')),
    ).subscribe(() => {
      this.editTipContainer.hide();
      this.onboardingActionsService.changeStudySettingUpUserStep(StudySettingUpUserStep.SetUpAccess);
    });
    this._subscriptions$.add(sub);
  }

  /**
   * Подписка на получение списка фильтров
   * @private
   */
  private subscribeToActiveFilter() {
    this._subscriptions$.add(this
      .filterService
      .filterBehavior
      .subscribe((filters) => this.activeFilter = filters)
    );
  }
}
