import { AnswerModel, QuestionModel, TestModel } from '@core/models';


/**
 * Результат ответа на вопрос
 */
export type CheckAnswerResponse = {id: string, isCorrect: string}[];

/**
 * Состояние теста: вопрос, результат ответа на вопрос, результаты теста
 */
export type TestState = 'question' | 'question-results';

/**
 * Состояние ответа: непроверен, правильно ответил, неправильно ответил
 */
export enum TestAnswerState {
  Unchecked,
  Right,
  Wrong
}

/**
 * Расширенная модель ответа, хранящая дополнительную временную информацию
 */
export interface TmpAnswerModel extends AnswerModel {
  /**
   * Выбран пользователем?
   */
  isChecked: boolean;
  state: TestAnswerState;
}

/**
 * Непроверенный на правильность ответ
 */
export interface UncheckedAnswerModel extends TmpAnswerModel {
  state: TestAnswerState.Unchecked;
}

/**
 * Расширенная модель вопроса, хранящая дополнительную временную информацию
 */
export interface TmpQuestionModel extends QuestionModel {
  answers: TmpAnswerModel[];
  /**
   * Выбранные ответы
   */
  selectedAnswers: TmpAnswerModel[];
}

/**
 * Расширенная модель теста, хранящая дополнительную временную информацию
 */
export interface TmpTestModel extends TestModel {
  questions: TmpQuestionModel[];
}
