<div class="ui-multi-select__header">
  <input type="search"
         class="input input_padding-right-27 input_padding-left-0 input_search-without-border"
         placeholder="Поиск по отделам"
         (input)="onFilter($event.target.value)">
  <span class="icon-search icon_sidebar i-icon"></span>
</div>

<div class="ui-multi-select__scroll">
  <div class="ui-multi-select__item" *ngFor="let item of filtered">
    <div class="check-box check-box_line-height-28">
      <label class="check-box__label">
        <input type="checkbox"
               class="check-box__input"
               [checked]="item.isChecked"
               (change)="onChangeChecker(item, $event)">
        <i class="check-box__indicator check-box__indicator_blue check-box__indicator_absolute"></i>
        <span class="check-box__text">{{item.name}}</span>
      </label>
    </div>
  </div>
</div>
