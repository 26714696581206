import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {CategoryModel} from '@core/models/category.model';
import {Subscription} from 'rxjs/Subscription';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {CategoryDeleteInterface} from '@core/interfaces/category-delete.interface';
import * as fromRoot from '@core/redux/index';
import {Actions, ofType} from '@ngrx/effects';
import {Router} from '@angular/router';
import {CategoryActionTypes} from '@core/redux/category/category.actions';
import {CategoryService} from '@core/services/entities/category/category.service';

@Component({
  selector: 'popup-delete-category',
  templateUrl: './popup-delete-category.component.html',
  styleUrls: ['./popup-delete-category.component.less']
})
export class PopupDeleteCategoryComponent implements OnInit, OnDestroy {
  private _subscriptions$: Subscription = new Subscription();
  categories: CategoryModel[] = [];
  isMove = false;
  model: CategoryDeleteInterface;

  constructor(private store: Store<fromRoot.State>,
              private updates: Actions,
              private router: Router,
              private dialog: MatDialog,
              private categoryService: CategoryService,
              @Inject(MAT_DIALOG_DATA) public category: CategoryModel) {
  }

  ngOnInit() {
    this.model = {
      id: this.category.id,
      moveTo: ''
    };

    this._subscriptions$.add(this
      .updates
      .pipe(ofType(CategoryActionTypes.DeleteActionSuccess))
      .subscribe(_ => {
        if (this.model.moveTo) {
          this.router.navigate([], {
            queryParams: {
              categoryId: this.model.moveTo
            },
            queryParamsHandling: 'merge'
          });
        } else {
          this.router.navigate([], {
            queryParams: {
              folder: 'trash',
              isTrash: 1
            }
          });
        }
        this.dialog.closeAll();
      })
    );

    this._subscriptions$.add(this
      .store
      .pipe(select(fromRoot.getCategoryEntitiesByType(this.category.type)))
      .subscribe((categories: CategoryModel[]) => this.categories = categories));
  }

  ngOnDestroy() {
    this._subscriptions$.unsubscribe();
  }

  onChangeCategory(id: string) {
    this.model.moveTo = id;
  }

  onSubmit() {
    this.categoryService.deleteOne(this.model);
  }

  onClose() {
    this.dialog.closeAll();
  }
}
