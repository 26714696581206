import {Injectable} from '@angular/core';
import * as fromRoot from '@core/redux';
import {Store} from '@ngrx/store';
import {CreateAction, ResetAction, SearchAction, UpdateAction} from '@core/redux/user-status/user-status.actions';
import {UserStatusModel} from '@core/models/user-status.model';
import * as UserStatusActions from '@core/redux/user-status/user-status.actions';

@Injectable({
  providedIn: 'root',
})
export class UserStatusService {
  constructor(private store: Store<fromRoot.State>) {

  }

  searchAll() {
    this.store.dispatch(new SearchAction());
  }

  update(model: UserStatusModel) {
    this.store.dispatch(new UpdateAction(model));
  }

  create(model: UserStatusModel) {
    this.store.dispatch(new CreateAction(model));
  }

  deleteOne(id: string) {
    this.store.dispatch(new UserStatusActions.DeleteAction(id));
  }

  resetStore() {
    this.store.dispatch(new ResetAction());
  }
}
