<ui-header>
  <ui-fast-search class="header-app__fast-search"
                  (onChanged)="onChanged($event)"
                  *ngIf="isOpen">
  </ui-fast-search>
  <ui-header-menu></ui-header-menu>
  <div class="header-app__right">
    <span class="header-app__icon-search icon-search" (click)="onOpenSearch()"></span>
    <button
      *ngIf="onWikiPage"
      (click)="onOpenFilters()"
      class="button button_not-button header-app__icon-filter">
      <i class="icon-filter icon-dark-gray"></i>
    </button>
    <!--<ui-notification class="notification_active"></ui-notification>-->
    <ui-user-menu></ui-user-menu>
  </div>
</ui-header>
