/**
 * Модель нового урока, создаваемого пользователем в форме курса
 */
export interface NewLessonModel {
  /**
   * Название урока
   */
  name: string;
  /**
   * Открывать следующий урок при прохождении этого на данное кол-во процентов
   */
  percentageToNextOpen: number;
  testId?: string;
  wikiIds: string[];
}

/**
 * Модель для отправки POST/PUT запросов на создание курса
 */
export interface PostPutLessonModel extends NewLessonModel {
  /**
   * ID урока
   * Генерируется клиентом
   */
  id: string;
}


/**
 * Урок, приходящий с сервера
 */
export interface LessonModel extends PostPutLessonModel {
  isPassed: boolean;
}

export enum LessonStatus {
  Done,
  InProgress,
  Locked
}

export function getEmptyLesson(): LessonModel {
  return {
    id: '',
    name: '',
    percentageToNextOpen: 100,
    isPassed: false,
    wikiIds: []
  };
}
