import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiRatingComponent } from './ui-rating.component';

@NgModule({
  imports: [CommonModule],
  declarations: [UiRatingComponent],
  exports: [UiRatingComponent]
})
export class UiRatingModule { }
