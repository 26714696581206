import {Injectable} from '@angular/core';
import * as fromRoot from '@core/redux';
import {select, Store} from '@ngrx/store';
import {
  CreateAction,
  DeleteAction,
  ResetAction,
  SearchAction,
  SelectUpdateAction, SortAction,
  UpdateAction
} from '@core/redux/category/category.actions';
import {CategoryModel, CategorySortModel} from '@core/models/category.model';
import {CategoryDeleteInterface} from '@core/interfaces/category-delete.interface';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(private store: Store<fromRoot.State>) {

  }

  searchAll() {
    this.store.dispatch(new SearchAction());
  }

  resetStore() {
    this.store.dispatch(new ResetAction());
  }

  deleteOne(model: CategoryDeleteInterface) {
    this.store.dispatch(new DeleteAction(model));
  }

  create(model: CategoryModel) {
    this.store.dispatch(new CreateAction(model));
  }

  update(model: CategoryModel) {
    this.store.dispatch(new UpdateAction(model));
  }

  sort(model: CategorySortModel) {
    this.store.dispatch(new SortAction(model));
  }

  selectUpdate(id: string | null) {
    this.store.dispatch(new SelectUpdateAction(id));
  }

  getAllDepartments() {
    return this.store.pipe(select(fromRoot.getCategoryEntitiesByType('user')));
  }

  getAllCategoryWiki() {
    return this.store.pipe(select(fromRoot.getCategoryEntitiesByType('wiki')));
  }
}
