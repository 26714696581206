import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShortUrlPipe } from './short-url.pipe';

@NgModule({
  declarations: [ShortUrlPipe],
  exports: [ShortUrlPipe],
  providers: [ShortUrlPipe],
  imports: [
    CommonModule
  ]
})
export class ShortUrlModule {
}
