import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  CategoryActionTypes, CreateAction, CreateActionFailure, CreateActionSuccess, DeleteAction, DeleteActionFailure, DeleteActionSuccess,
  SearchFailureAction,
  SearchSuccessAction, SortAction,
  UpdateAction,
  UpdateActionFailure,
  UpdateActionSuccess
} from './category.actions';
import {map, exhaustMap, catchError, tap} from 'rxjs/operators';
import {of} from 'rxjs/index';
import {CategoryModel, CategorySortModel} from '@core/models/category.model';
import {Store} from '@ngrx/store';
import {CategoryDeleteInterface} from '@core/interfaces/category-delete.interface';
import {ApiCategoryService} from '@core/services/entities/category/api-category.service';
import * as fromRoot from '@core/redux/index';
import {CategoryService} from '@core/services/entities/category/category.service';
import { Router } from '@angular/router';

@Injectable()
export class CategoryEffects {
  constructor(private actions$: Actions,
              private router: Router,
              private store: Store<fromRoot.State>,
              private categoryService: CategoryService,
              private apiCategoryService: ApiCategoryService) {
  }

  @Effect()
  search$ = this.actions$.pipe(
    ofType(CategoryActionTypes.SearchAction),
    exhaustMap(() => {
        return this.apiCategoryService
          .search()
          .pipe(
            map((response) => new SearchSuccessAction(response)),
            catchError((errors) => of(new SearchFailureAction(errors)))
          );
      }
    )
  );

  @Effect()
  update$ = this.actions$.pipe(
    ofType(CategoryActionTypes.UpdateAction),
    map((action: UpdateAction) => action.payload),
    exhaustMap((model: CategoryModel) => {
        return this.apiCategoryService
          .update(model)
          .pipe(
            map((response) => new UpdateActionSuccess(response)),
            catchError((errors) => of(new UpdateActionFailure(errors)))
          );
      }
    )
  );

  @Effect({dispatch: false})
  sort$ = this.actions$.pipe(
    ofType(CategoryActionTypes.SortAction),
    map((action: SortAction) => action.payload),
    exhaustMap((model: CategorySortModel) => {
        return this.apiCategoryService
          .sort(model)
          .pipe(
            map(() => this.categoryService.searchAll())
          );
      }
    )
  );

  @Effect()
  create$ = this.actions$.pipe(
    ofType(CategoryActionTypes.CreateAction),
    map((action: CreateAction) => action.payload),
    exhaustMap((model: CategoryModel) => {
        return this.apiCategoryService
          .create(model)
          .pipe(
            map((response) => new CreateActionSuccess(response)),
            tap(action => this.router.navigate([], {queryParams: {categoryId: action.payload.id}})),
            catchError((errors) => of(new CreateActionFailure(errors)))
          );
      }
    )
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(CategoryActionTypes.DeleteAction),
    map((action: DeleteAction) => action.payload),
    exhaustMap((model: CategoryDeleteInterface) => {
        return this.apiCategoryService
          .delete(model)
          .pipe(
            map(() => new DeleteActionSuccess(model.id)),
            catchError((errors) => of(new DeleteActionFailure(errors)))
          );
      }
    )
  );
}
