import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Validation} from '@shared/validation/validation.abstract.class';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TagModel} from '@core/models';
import {TagService} from '@core/services/entities/tag/tag.service';
import {Subscription} from 'rxjs/Subscription';
import {WikiService} from '@core/services/entities/wiki/wiki.service';
import {Actions, ofType} from '@ngrx/effects';
import {TagActionTypes} from '@core/redux/tag/tag.actions';
import {TestService} from '@core/services/entities/test/test.service';

@Component({
  selector: 'ui-tag-sidebar-form',
  templateUrl: './ui-tag-sidebar-form.component.html',
  styleUrls: ['./ui-tag-sidebar-form.component.less']
})
export class UiTagSidebarFormComponent extends Validation implements OnInit, OnDestroy {
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Input() tags: TagModel[] = [];
  @ViewChild('inputName', { static: true }) inputName: ElementRef;

  filteredTags: TagModel[] = [];

  form = new UntypedFormGroup({
    id: new UntypedFormControl(''),
    type: new UntypedFormControl(''),
    name: new UntypedFormControl('', [Validators.required]),
    combineIds: new UntypedFormControl([]),
  });

  isLoading = true;
  isDeleteConfirm = false;
  private readonly _subscriptions$: Subscription = new Subscription();

  constructor(private _tagService: TagService,
              private _updates: Actions,
              private _testService: TestService,
              private _wikiService: WikiService) {
    super();
  }

  ngOnInit() {
    this.isDeleteConfirm = false;
    this._subscribeUpdateData();
    this._subscribeLoading();
    this._successSubscription();
  }

  ngOnDestroy() {
    this._subscriptions$.unsubscribe();
  }

  onSubmit() {
    this._tagService.update(this.form.getRawValue());
    const combineIds = this.form.get('combineIds').value;
    const tagId = this.form.get('id').value;

    if (combineIds.length) {
      switch (this.form.get('type').value) {
        case 'test':
          this._testService.combineTag(tagId, combineIds);
          break;
        case 'wiki':
          this._wikiService.combineTag(tagId, combineIds);
          break;
        case 'course':
          console.error('Сделать объединение тегов в сторе');
          break;
      }
    }
  }

  onDelete() {
    if (this.isDeleteConfirm) {
      this._tagService.delete(this.form.get('id').value);
      this.isDeleteConfirm = false;
    }
    this.isDeleteConfirm = true;
  }

  private _subscribeUpdateData() {
    this._subscriptions$.add(
      this._tagService
        .getUpdate()
        .subscribe((model: TagModel) => {
          this.form.patchValue(model);
          this.filteredTags = this.tags.filter((item) => item.id !== model.id);
          setTimeout(() => this.inputName.nativeElement.focus());
        })
    );
  }

  private _subscribeLoading() {
    this._subscriptions$.add(
      this._tagService.getLoading().subscribe((value: boolean) => this.isLoading = value)
    );
  }

  private _successSubscription() {
    this._subscriptions$.add(this
      ._updates
      .pipe(ofType(TagActionTypes.UpdateActionSuccess))
      .subscribe(() => this.close.emit())
    );

    this._subscriptions$.add(this
      ._updates
      .pipe(ofType(TagActionTypes.DeleteActionSuccess))
      .subscribe(() => this.close.emit())
    );
  }
}
