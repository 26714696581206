import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'ui-btn-entity-create',
  templateUrl: './ui-btn-entity-create.component.html',
  styleUrls: ['./ui-btn-entity-create.component.less']
})
export class UiBtnEntityCreateComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
