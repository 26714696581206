import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {Router} from '@angular/router';
import {AuthService} from '@core/services/entities/auth/auth.service';
import {flatMap, tap, take} from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this
      .authService
      .getToken()
      .pipe(
        take(1),
        flatMap(accessToken => {
          if (accessToken) {
            const authReq = request.clone({
              setParams: {
                accessToken: accessToken
              }
            });
            return next.handle(authReq).pipe(
              tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse && event.status === 302) {
                  // redirect
                }
              }, (err: any) => {
                if (err instanceof HttpErrorResponse && err.status === 401 && !request.url.includes('save-share-content')) {
                  this.authService.signOut();
                } else if (err instanceof HttpErrorResponse && err.status === 403) {
                  this.router.navigate(['/dashboard']);
                }
              }));
          } else {
            return next.handle(request).pipe(
              tap((event: HttpEvent<any>) => {
              }, (err: any) => {
                if (err instanceof HttpErrorResponse && err.status === 401 && !request.url.includes('save-share-content')) {
                  this.authService.signOut();
                } else if (err instanceof HttpErrorResponse && err.status === 403) {
                  this.router.navigate(['/dashboard']);
                }
              })
            );
          }
        })
      );
  }
}
