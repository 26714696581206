import { CompanyType } from '@core/models/company.model';

export interface KnowledgeBaseRequest {
  selected: CompanyType;
}

export interface GoalsRequest {
  likes: string[];
  dislikes: string[];
  custom: string;
  skipped: boolean;
}

export interface AboutYourselfRequest {
  skipped: boolean;
  workField: string;
  title: string;
  numberOfEmployees: string;
}

export enum LessonStatus {
  NotStarted = 0,
  InProgress = 1,
  Finished = 2,
}

interface OnboardingFields {
  // показать страницу выбора типа базы
  createKnowledgeBase: KnowledgeBaseRequest & { status: LessonStatus },

  // показать страницу выбора целей использования базы
  tellAboutGoals: GoalsRequest & { status: LessonStatus },

  // показать страницу с формой о себе или компании
  tellAboutYourself: AboutYourselfRequest & { status: LessonStatus },

  // показать кнопку с шагами в статьях
  studyService: LessonStatus,

  // открыть тултип с шагами в статьях
  openStudyService: LessonStatus,

  // изучить создание статьи по ссылке
  studyCreatingFromLink: LessonStatus,

  // изучить написание статьи
  studyWritingArticle: LessonStatus,

  // изучить создание пользователя
  studyAddingUser: LessonStatus,

  // изучить настройку добавленного пользователя
  studySettingUpUser: LessonStatus,
}

export interface OnboardingResponse extends OnboardingFields {
  userId: string;
  companyId: string;
}

export type OnboardingRequest = Partial<OnboardingFields>;

export enum StudyCreatingFromLinkStep {
  NotStarted,
  Copy,
  Paste,
  Enter,
  OpenCategorySelect,
  SelectCategory,
  AddTags,
  Save,
  Done,
}

export enum StudyWritingArticleStep {
  NotStarted,
  Paste,
  Look,
  Cover,
  Control,
  Attach,
  Save,
  Done,
}

export enum StudyAddingUserStep {
  NotStarted,
  NavigateToUsers,
  ClickToSelect,
  Invite,
  Link,
  DefaultAccess,
  Copy,
  Done,
}

export enum StudySettingUpUserStep {
  NotStarted,
  NavigateToRequests,
  Approve,
  StartSettingUp,
  SetUpRights,
  Edit,
  SetUpAccess,
  Done,
}
