import TimeAgo from 'javascript-time-ago';
import { TimeagoFormatter } from 'ngx-timeago';

import ru from 'javascript-time-ago/locale/ru';

// if, try фиксят SSR
if (ru) {
  TimeAgo.addLocale(ru);
}

let timeAgo;

try {
  timeAgo = new TimeAgo('ru-RU');
} catch (e) {
  timeAgo = {
    format() {
      return '...';
    }
  };
}

const day = 1000 * 60 * 60 * 24;

export class CustomTimeagoFormatter implements TimeagoFormatter {
  format(date: number): string {
    const now = new Date();
    const then = new Date(date);
    const diff = +now - date;
    if (diff > day * 2) {
      return timeAgo.format(date, 'twitter') + ' в ' + then.toTimeString().slice(0, 5);
    } else if (diff > day) {
      return 'Вчера в ' + then.toTimeString().slice(0, 5);
    } else {
      return timeAgo.format(date);
    }
  }
}
