import {Action} from '@ngrx/store';

export enum UiActionTypes {
  ChangeViewWikiAction = '[Ui] Change View Wiki',
  ChangeViewUserAction = '[Ui] Change View User',
  OpenUserMenu = '[Ui] Open User Menu',
}

export class ChangeViewWikiAction implements Action {
  readonly type = UiActionTypes.ChangeViewWikiAction;

  constructor(public payload: string) {
  }
}

export class ChangeViewUserAction implements Action {
  readonly type = UiActionTypes.ChangeViewUserAction;

  constructor(public payload: string) {
  }
}

export class OpenUserMenu implements Action {
  readonly type = UiActionTypes.OpenUserMenu;
}

export type UiActions =
  ChangeViewWikiAction
  | ChangeViewUserAction
  | OpenUserMenu
  ;
