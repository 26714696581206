import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {
  ConfirmInviteUserAction,
  CreateAction,
  GetCompaniesAction,
  GetStartAction,
  LeftAction,
  UpdateAction
} from '@core/redux/company/company.actions';
import {CompanyModel} from '@core/models/company.model';
import * as fromRoot from '@core/redux';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private store: Store<fromRoot.State>) {

  }

  fetchStart(id?: string) {
    this.store.dispatch(new GetStartAction(id));
  }

  fetchAll() {
    this.store.dispatch(new GetCompaniesAction());
  }

  left(id: string) {
    this.store.dispatch(new LeftAction(id));
  }

  update(model: CompanyModel) {
    this.store.dispatch(new UpdateAction(model));
  }

  create(model: CompanyModel) {
    this.store.dispatch(new CreateAction(model));
  }

  confirmInvite(id: string) {
    this.store.dispatch(new ConfirmInviteUserAction(id));
  }

  getCompany() {
    return this.store.pipe(select(fromRoot.getCompanyStart));
  }

  getCompanyId() {
    return this.store.pipe(select(fromRoot.getCompanyCurrentId));
  }
}
