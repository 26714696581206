<div class="avatar__wrapper">
  <div *ngIf="image"
       [style.background-image]="'url('+image+')'"
       class="avatar__img"></div>
  <span *ngIf="!image" class="avatar__text">{{shortName}}</span>
</div>
<ui-user-details
  *ngIf="userDetails && shouldOpenDetails"
  [invite]="invite"
  [user]="user"
  class="avatar__details">
</ui-user-details>
