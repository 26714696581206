import {Action} from '@ngrx/store';
import {WikiModel, WikiQueryModel, WikiSortInterface} from '@core/models';
import {PageResult} from '@core/models/filters/page-result';
import {WikiFavoriteInterface} from '@core/interfaces/wiki-favorite.interface';

export enum WikiActionTypes {
  CombineTagAction = '[Wiki] Combine Tag',
  SelectMultipleAction = '[Wiki] Select Multiple',
  SelectUpdateAction = '[Wiki] Select Update',
  FilterAction = '[Wiki] Filter',
  ReadAction = '[Wiki] Read',
  ResetAction = '[Wiki] Reset',

  ViewCloseAction = '[Wiki] View Close',

  SearchAction = '[Wiki] Search',
  SearchSuccessAction = '[Wiki] Search Success',
  SearchFailureAction = '[Wiki] Search Failure',

  UpdateAction = '[Wiki] Update',
  UpdateActionSuccess = '[Wiki] Update Success',
  UpdateActionFailure = '[Wiki] Update Failure',

  ViewAction = '[Wiki] View',
  ViewActionSuccess = '[Wiki] View Success',
  ViewActionFailure = '[Wiki] View Failure',

  CreateAction = '[Wiki] Create',
  CreateActionSuccess = '[Wiki] Create Success',
  CreateActionFailure = '[Wiki] Create Failure',

  FavoriteAction = '[Wiki] Favorite',
  FavoriteActionSuccess = '[Wiki] Favorite Success',
  FavoriteActionFailure = '[Wiki] Favorite Failure',

  ArchiveAction = '[Wiki] Archive',
  ArchiveActionSuccess = '[Wiki] Archive Success',
  ArchiveActionFailure = '[Wiki] Archive Failure',

  ChangeCategoryAction = '[Wiki] ChangeCategory',
  ChangeCategoryActionSuccess = '[Wiki] ChangeCategory Success',
  ChangeCategoryActionFailure = '[Wiki] ChangeCategory Failure',

  SortAction = '[Wiki] Sort',
  SortActionSuccess = '[Wiki] Sort Success',
  SortActionFailure = '[Wiki] Sort Failure',

  ToggleFilterBarAction = '[Wiki] Toggle Filter Bar',

  LikeAction = '[Wiki] Like',
  LikeActionSuccess = '[Wiki] Like Success',
  LikeActionFailure = '[Wiki] Like Failure',
  DislikeAction = '[Wiki] Dislike',
  DislikeActionSuccess = '[Wiki] Dislike Success',
  DislikeActionFailure = '[Wiki] Dislike Failure',
}

export class CombineTagAction implements Action {
  readonly type = WikiActionTypes.CombineTagAction;

  constructor(public id: string,
              public combineId: string[]) {
  }
}


export class SelectMultipleAction implements Action {
  readonly type = WikiActionTypes.SelectMultipleAction;

  constructor(public isChecked: boolean, public id?: string) {
  }
}

export class ReadAction implements Action {
  readonly type = WikiActionTypes.ReadAction;

  constructor(public payload: WikiModel) {
  }
}

export class ResetAction implements Action {
  readonly type = WikiActionTypes.ResetAction;
}

export class SearchAction implements Action {
  readonly type = WikiActionTypes.SearchAction;

  /**
   *
   * @param payload
   * @param deletePrevious
   * Заменить предыдущие статьи новыми? Если false, то подгруженные статьи добавятся к существующим
   */
  constructor(public payload: WikiQueryModel,
              public deletePrevious: boolean) {
  }
}

export class FilterAction implements Action {
  readonly type = WikiActionTypes.FilterAction;

  constructor(public payload: string) {
  }
}

export class SearchSuccessAction implements Action {
  readonly type = WikiActionTypes.SearchSuccessAction;

  constructor(public payload: PageResult<WikiModel[]>,
              public deletePrevious: boolean) {
  }
}

export class SearchFailureAction implements Action {
  readonly type = WikiActionTypes.SearchFailureAction;

  constructor(public payload: any) {
  }
}

export class SelectUpdateAction implements Action {
  readonly type = WikiActionTypes.SelectUpdateAction;

  constructor(public payload: string | null) {
  }
}

export class UpdateAction implements Action {
  readonly type = WikiActionTypes.UpdateAction;

  constructor(public payload: WikiModel) {
  }
}


export class UpdateActionSuccess implements Action {
  readonly type = WikiActionTypes.UpdateActionSuccess;

  constructor(public payload: WikiModel) {
  }
}

export class UpdateActionFailure implements Action {
  readonly type = WikiActionTypes.UpdateActionFailure;

  constructor(public payload: any) {
  }
}

export class ViewAction implements Action {
  readonly type = WikiActionTypes.ViewAction;

  constructor(public payload: string) {
  }
}

export class ViewActionSuccess implements Action {
  readonly type = WikiActionTypes.ViewActionSuccess;

  constructor(public payload: WikiModel) {
  }
}

export class ViewActionFailure implements Action {
  readonly type = WikiActionTypes.ViewActionFailure;

  constructor(public payload: any) {
  }
}

export class ViewCloseAction implements Action {
  readonly type = WikiActionTypes.ViewCloseAction;

  constructor() {
  }
}

export class CreateAction implements Action {
  readonly type = WikiActionTypes.CreateAction;

  constructor(public payload: WikiModel) {
  }
}


export class CreateActionSuccess implements Action {
  readonly type = WikiActionTypes.CreateActionSuccess;

  constructor(public payload: WikiModel) {
  }
}

export class CreateActionFailure implements Action {
  readonly type = WikiActionTypes.CreateActionFailure;

  constructor(public payload: any) {
  }
}

export class FavoriteAction implements Action {
  readonly type = WikiActionTypes.FavoriteAction;

  constructor(public payload: WikiFavoriteInterface) {
  }
}


export class FavoriteActionSuccess implements Action {
  readonly type = WikiActionTypes.FavoriteActionSuccess;

  constructor(public payload: WikiModel[]) {
  }
}

export class FavoriteActionFailure implements Action {
  readonly type = WikiActionTypes.FavoriteActionFailure;

  constructor(public payload: any) {
  }
}

export class ArchiveAction implements Action {
  readonly type = WikiActionTypes.ArchiveAction;

  constructor(public payload: string[] | string) {
  }
}


export class ArchiveActionSuccess implements Action {
  readonly type = WikiActionTypes.ArchiveActionSuccess;

  constructor(public payload: WikiModel[]) {
  }
}

export class ArchiveActionFailure implements Action {
  readonly type = WikiActionTypes.ArchiveActionFailure;

  constructor(public payload: any) {
  }
}

export class ChangeCategoryAction implements Action {
  readonly type = WikiActionTypes.ChangeCategoryAction;

  constructor(public payload: { ids: string[], categoryId: string }) {
  }
}


export class ChangeCategoryActionSuccess implements Action {
  readonly type = WikiActionTypes.ChangeCategoryActionSuccess;

  constructor(public payload: WikiModel[]) {
  }
}

export class ChangeCategoryActionFailure implements Action {
  readonly type = WikiActionTypes.ChangeCategoryActionFailure;

  constructor(public payload: any) {
  }
}

export class SortAction implements Action {
  readonly type = WikiActionTypes.SortAction;

  constructor(public payload: WikiSortInterface) {
  }
}

export class SortActionSuccess implements Action {
  readonly type = WikiActionTypes.SortActionSuccess;
}

export class SortActionFailure implements Action {
  readonly type = WikiActionTypes.SortActionFailure;

  constructor(public payload: any) {
  }
}

export class ToggleFilterBarAction implements Action {
  readonly type = WikiActionTypes.ToggleFilterBarAction;

  constructor(public payload: boolean) {
  }
}

export class LikeAction implements Action {
  readonly type = WikiActionTypes.LikeAction;

  constructor(
    public readonly wikiId: string,
  ) {
  }
}

export class LikeActionSuccess implements Action {
  readonly type = WikiActionTypes.LikeActionSuccess;

  constructor(
    public readonly wiki: WikiModel,
  ) {
  }
}

export class LikeActionFailure implements Action {
  readonly type = WikiActionTypes.LikeActionFailure;

  constructor(
    public readonly errors: any,
  ) {
  }
}

export class DislikeAction implements Action {
  readonly type = WikiActionTypes.DislikeAction;

  constructor(
    public readonly wikiId: string,
  ) {
  }
}

export class DislikeActionSuccess implements Action {
  readonly type = WikiActionTypes.DislikeActionSuccess;

  constructor(
    public readonly wiki: WikiModel,
  ) {
  }
}

export class DislikeActionFailure implements Action {
  readonly type = WikiActionTypes.DislikeActionFailure;

  constructor(
    public readonly errors: any,
  ) {
  }
}

export type WikiActions = SearchAction
  | SearchSuccessAction
  | SearchFailureAction
  | SelectUpdateAction
  | UpdateAction
  | UpdateActionSuccess
  | UpdateActionFailure
  | ViewCloseAction
  | ViewAction
  | ViewActionSuccess
  | ViewActionFailure
  | CreateAction
  | CreateActionSuccess
  | CreateActionFailure
  | FavoriteAction
  | FavoriteActionSuccess
  | FavoriteActionFailure
  | ArchiveAction
  | ArchiveActionSuccess
  | ArchiveActionFailure
  | ChangeCategoryAction
  | ChangeCategoryActionSuccess
  | ChangeCategoryActionFailure
  | SortAction
  | SortActionSuccess
  | SortActionFailure
  | FilterAction
  | ReadAction
  | ResetAction
  | SelectMultipleAction
  | CombineTagAction
  | ToggleFilterBarAction
  | LikeAction
  | LikeActionFailure
  | LikeActionSuccess
  | DislikeAction
  | DislikeActionFailure
  | DislikeActionSuccess
  ;
