import { InviteActions, InviteActionTypes, SearchSuccessAction } from './invite.actions';
import { InviteModel } from '@core/models/invite.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { PaginationResponse } from '@core/models/filters/pagination-response';
import { getUserId } from '@core/utils/get-user-id.utils';
import { UserCompanyActionTypes, SetUserCompanyActionSuccess, UserCompanyActions, SetUserCompanyContactsActionSuccess } from '../user-company/user-company.actions';

export interface State extends EntityState<InviteModel> {
  ids: string[];
  loading: boolean;
  updateId: string | null;
  selectedId: string | null;
  errors: any;
  pagination: PaginationResponse;
  allActiveInvites: InviteModel[]
}

export const initialState: State = {
  ids: [],
  loading: false,
  updateId: null,
  selectedId: null,
  entities: {},
  errors: {},
  pagination: null,
  allActiveInvites: []
};

export const adapter: EntityAdapter<InviteModel> = createEntityAdapter<InviteModel>({
  selectId: (invite: InviteModel) => invite.id,
  sortComparer: false,
});

export function reducer(state = initialState, action: InviteActions | UserCompanyActions): State {
  switch (action.type) {

    case InviteActionTypes.ResetAction: {
      return Object.assign({}, state, initialState);
    }

    case InviteActionTypes.SelectAction: {
      return { ...state, selectedId: action.payload };
    }

    case InviteActionTypes.SearchAction: {
      return { ...state, loading: true, errors: {} };
    }

    case InviteActionTypes.SearchSuccessAction: {
      const { payload, forInvitesPage } = action as SearchSuccessAction;
      if (!forInvitesPage) {
        state = { ...state, allActiveInvites: payload.data };
      }
      return adapter.setAll(action.payload.data, { ...state, loading: false, errors: {}, pagination: payload.pagination });
    }

    case InviteActionTypes.SearchFailureAction: {
      return { ...state, loading: false, errors: action.payload['error'] };
    }

    case InviteActionTypes.ConfirmInviteAdminAction: {
      return { ...state, loading: true, errors: {} };
    }

    case InviteActionTypes.ConfirmInviteAdminActionSuccess: {
      return adapter.updateOne({ id: action.payload.id, changes: action.payload }, { ...state, loading: false, errors: {} });
    }

    case InviteActionTypes.ConfirmInviteAdminActionFailure: {
      return { ...state, loading: false, errors: action.payload['error'] };
    }

    case InviteActionTypes.UpdateAction: {
      return { ...state, loading: true, errors: {} };
    }

    case InviteActionTypes.UpdateActionSuccess: {
      return adapter.updateOne({ id: action.payload.id, changes: action.payload }, { ...state, loading: false, errors: {} });
    }

    case InviteActionTypes.UpdateActionFailure: {
      return { ...state, loading: false, errors: action.payload['error'] };
    }

    case InviteActionTypes.CreateAction: {
      return { ...state, loading: true, errors: {} };
    }

    case InviteActionTypes.CreateActionSuccess: {
      return adapter.addOne(action.payload, { ...state, loading: false, errors: {} });
    }

    case InviteActionTypes.CreateActionFailure: {
      return { ...state, loading: false, errors: action.payload['error'] };
    }

    case InviteActionTypes.DeleteAction: {
      return { ...state, loading: true, errors: {} };
    }

    case InviteActionTypes.DeleteActionSuccess: {
      return adapter.removeOne(action.payload, { ...state, loading: false, errors: {} });
    }

    case InviteActionTypes.DeleteActionFailure: {
      return { ...state, loading: false, errors: action.payload['error'] };
    }

    case InviteActionTypes.ViewAction: {
      return { ...state, loading: true, errors: action.payload['error'] };
    }

    case InviteActionTypes.ViewActionSuccess: {
      return adapter.upsertOne(action.payload, { ...state, loading: false, errors: {} });
    }

    case InviteActionTypes.ViewActionFailure: {
      return { ...state, loading: false, errors: action.payload['error'] };
    }

    case UserCompanyActionTypes.SetuserCompanyActionSuccess: {
      const { user } = action as SetUserCompanyActionSuccess;
      const userInvite = Object.values(state.entities).find(i => i.userId === user.id);
      const updatedInvite = { ...userInvite, user };
      return adapter.updateOne({
        changes: updatedInvite,
        id: updatedInvite.id
      }, state);
    }

    case UserCompanyActionTypes.SetUserCompanyContactsActionSuccess: {
      const { user } = action as SetUserCompanyContactsActionSuccess;
      const userInvite = Object.values(state.entities).find(i => i.userId === user.id);
      const updatedInvite = { ...userInvite, user };
      return adapter.updateOne({
        changes: updatedInvite,
        id: updatedInvite.id
      }, state);
    }

    default:
      return state;
  }
}

export const getErrors = (state: State) => state.errors;
export const getPagination = (state: State) => state.pagination;
export const getEntitiesObject = (state: State) => state.entities;
export const getIds = (state: State) => state.ids;
export const getLoading = (state: State) => state.loading;
export const getUpdateId = (state: State) => state.updateId;
export const getSelectedId = (state: State) => state.selectedId;
export const getSelectedEntity = createSelector(getEntitiesObject, getSelectedId, (entities, selectedId) => entities[selectedId]);
export const getEntities = createSelector(getEntitiesObject, getIds, (entities, ids) => ids.map(id => entities[id]));
export const getActive = (state: State) => state.allActiveInvites;
export const getUpdateEntity = createSelector(getEntitiesObject, getUpdateId, (entities, id) => entities[id]);
export const getMy = createSelector(getEntities, (entities) => entities.filter((item) => item.userId === getUserId())[0]);
