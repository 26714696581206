import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {tap, map, exhaustMap, catchError, switchMap, debounceTime} from 'rxjs/operators';
import {of, combineLatest} from 'rxjs';
import {
  AuthActionTypes,
  SignUpAction,
  SignUpSuccessAction,
  SignUpFailureAction,
  SignInAction,
  SignInSuccessAction,
  SignInFailureAction,
  SignUpStepPersonalDataAction,
  SignUpStepPersonalDataSuccessAction,
  SignUpStepPersonalDataFailureAction,
  SignUpStepPersonalContactFailureAction,
  SignUpStepPersonalContactSuccessAction,
  SignUpStepPersonalContactAction,
  SignUpStepCompanySuccessAction,
  SignUpStepCompanyFailureAction,
  SignUpStepCompanyAction,
  SignOutSuccessAction,
  SignOutFailureAction,
  ProfileUpdateAction,
  ProfileUpdateSuccessAction,
  ProfileUpdateFailureAction,
  GetUserSuccessAction,
  GetUserFailureAction,
  ConfirmEmailAction,
  ConfirmEmailFailureAction,
  PasswordRecoveryConfirmAction,
  PasswordRecoveryConfirmFailureAction,
  PasswordRecoveryConfirmSuccessAction,
} from './auth.actions';
import {GetStartAction} from '@core/redux/company/company.actions';
import {getUser} from '@core/utils/get-user.utils';
import {ApiAuthService} from '@core/services/entities/auth/api-auth.service';
import {IdentityModel} from '@core/models';
import {getOnboarding} from '../onboarding/onboarding.actions';
import { CompanyService } from '@core/services/entities/company/company.service';

@Injectable()
export class AuthEffects {
  constructor(private actions$: Actions,
              private apiAuthService: ApiAuthService,
              private companyService: CompanyService,
              private router: Router) {
  }

  @Effect()
  profileUpdate$ = this.actions$.pipe(
    ofType(AuthActionTypes.ProfileUpdateAction),
    map((action: ProfileUpdateAction) => action.payload),
    exhaustMap((profile) => {
        return this.apiAuthService
          .profileUpdate(profile)
          .pipe(
            map((response) => new ProfileUpdateSuccessAction(response)),
            catchError((errors) => of(new ProfileUpdateFailureAction(errors)))
          );
      }
    )
  );

  @Effect()
  getUser$ = combineLatest([
    this.actions$.pipe(ofType(AuthActionTypes.GetUserAction)),
    this.companyService.getCompanyId(),
  ]).pipe(
    debounceTime(50),
    exhaustMap(() => {
        return this.apiAuthService
          .getUser()
          .pipe(
            map((user) => new GetUserSuccessAction(user)),
            catchError((errors) => of(new GetUserFailureAction(errors)))
          );
      }
    )
  );

  @Effect()
  signIn$ = this.actions$.pipe(
    ofType(AuthActionTypes.SignInAction),
    map((action: SignInAction) => action.payload),
    exhaustMap((model) => {
        return this.apiAuthService
          .signIn(model)
          .pipe(
            map((user) => new SignInSuccessAction(user)),
            catchError((errors) => of(new SignInFailureAction(errors)))
          );
      }
    )
  );

  @Effect({dispatch: false})
  signInSuccess$ = this.actions$.pipe(
    ofType(AuthActionTypes.SignInSuccessAction),
    tap(() => this.router.navigate(['/dashboard']))
  );

  @Effect()
  signUp$ = this.actions$.pipe(
    ofType(AuthActionTypes.SignUpAction),
    map((action: SignUpAction) => action.payload),
    exhaustMap((model) => {
        return this.apiAuthService
          .signUp(model)
          .pipe(
            switchMap((user) => [
                new SignUpSuccessAction(user),
                new GetStartAction(),
                getOnboarding()
              ],
            ),
            catchError((errors) => of(new SignUpFailureAction(errors)))
          );
      }
    )
  );

  @Effect({dispatch: false})
  signUpSuccess$ = this.actions$.pipe(
    ofType(AuthActionTypes.SignUpSuccessAction),
    tap(() => this.router.navigate(['/auth/sign-up-step-1']))
  );

  @Effect()
  signUpStepPersonalData$ = this.actions$.pipe(
    ofType(AuthActionTypes.SignUpStepPersonalDataAction),
    map((action: SignUpStepPersonalDataAction) => action.payload),
    exhaustMap((model) => {
        return this.apiAuthService
          .signUpStepPersonalData(model)
          .pipe(
            map((user) => new SignUpStepPersonalDataSuccessAction(user)),
            catchError((errors) => of(new SignUpStepPersonalDataFailureAction(errors)))
          );
      }
    )
  );

  @Effect({dispatch: false})
  signUpStepPersonalDataSuccess$ = this.actions$.pipe(
    ofType(AuthActionTypes.SignUpStepPersonalDataSuccessAction),
    tap(() => this.router.navigate(['/auth/sign-up-step-2']))
  );


  @Effect()
  signUpStepPersonalContact$ = this.actions$.pipe(
    ofType(AuthActionTypes.SignUpStepPersonalContactAction),
    map((action: SignUpStepPersonalContactAction) => action.payload),
    exhaustMap((model) => {
        return this.apiAuthService
          .signUpStepPersonalContact(model)
          .pipe(
            map((user) => new SignUpStepPersonalContactSuccessAction(user)),
            catchError((errors) => of(new SignUpStepPersonalContactFailureAction(errors)))
          );
      }
    )
  );

  @Effect({dispatch: false})
  signUpStepPersonalContactSuccess$ = this.actions$.pipe(
    ofType(AuthActionTypes.SignUpStepPersonalContactSuccessAction),
    tap(() => {
      if (getUser().step !== 'end') {
        this.router.navigate(['/auth/sign-up-step-3']);
      } else {
        this.router.navigate(['/dashboard']);
      }
    })
  );

  @Effect()
  signUpStepCompany$ = this.actions$.pipe(
    ofType(AuthActionTypes.SignUpStepCompanyAction),
    map((action: SignUpStepCompanyAction) => action.payload),
    exhaustMap((model) => {
        return this.apiAuthService
          .signUpStepCompany(model)
          .pipe(
            map((user) => new SignUpStepCompanySuccessAction(user)),
            catchError((errors) => of(new SignUpStepCompanyFailureAction(errors)))
          );
      }
    )
  );

  @Effect({dispatch: false})
  signUpStepCompanySuccess$ = this.actions$.pipe(
    ofType(AuthActionTypes.SignUpStepCompanySuccessAction),
    tap(() => this.router.navigate(['/dashboard']))
  );

  @Effect()
  signOut$ = this.actions$.pipe(
    ofType(AuthActionTypes.SignOutAction),
    tap(() => this.router.navigate(['/auth', 'sign-in'])),
    exhaustMap(() => this.apiAuthService.signOut().pipe(
      map(() => new SignOutSuccessAction()),
      catchError((err) => of(new SignOutFailureAction(err)))))
  );

  // @Effect({dispatch: false})
  // signOutSuccess$ = this.actions$.pipe(
  //   ofType(AuthActionTypes.SignOutSuccessAction),
  //   tap(() => this.router.navigate(['/auth', 'sign-in'])),
  // );

  @Effect()
  confirmEmail = this.actions$.pipe(
    ofType(AuthActionTypes.ConfirmEmailAction),
    exhaustMap((action: ConfirmEmailAction) => {
      return this.apiAuthService.confirmationEmail(action.payload.email)
        .pipe(
          map((model: IdentityModel) => new SignInSuccessAction(model)),
          catchError((err) => of(new ConfirmEmailFailureAction(err)))
        );
    })
  );

  @Effect()
  passwordRecoveryConfirm$ = this.actions$.pipe(
    ofType(AuthActionTypes.PasswordRecoveryConfirmAction),
    map((action: PasswordRecoveryConfirmAction) => action.payload.token),
    exhaustMap((token) => {
      return this.apiAuthService.passwordRecoveryConfirm(token)
        .pipe(
          map((model: IdentityModel) => new PasswordRecoveryConfirmSuccessAction(model)),
          catchError((err) => {
            this.router.navigate(['/auth/password-recovery'], {queryParams: {isValid: false}});
            return of(new PasswordRecoveryConfirmFailureAction(err));
          })
        );
    })
  );

  @Effect({dispatch: false})
  passwordRecoveryConfirmSuccess$ = this.actions$.pipe(
    ofType(AuthActionTypes.PasswordRecoveryConfirmSuccessAction),
    tap(() => this.router.navigate(['/auth/change-password']))
  );
}
