import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CompanyService} from '@core/services/entities/company/company.service';
import {InviteService} from '@core/services/entities/invite/invite.service';
import {CategoryService} from '@core/services/entities/category/category.service';
import {AuthService} from '@core/services/entities/auth/auth.service';
import {TagService} from '@core/services/entities/tag/tag.service';
import {InviteQueryModel} from '@core/models/invite.model';
import {UserPositionsService} from '@core/services/entities/user-positions/user-positions.service';
import {UserStatusService} from '@core/services/entities/user-status/user-status.service';
import {Subscription} from 'rxjs';
import {WikiService} from '@core/services/entities/wiki/wiki.service';
import {WikiQueryModel} from '@core/models';
import {EventService} from '@core/services/entities/events/events.service';

@Component({
  selector: 'app-wrapper2',
  templateUrl: './wrapper2.component.html',
  styleUrls: ['./wrapper2.component.less']
})
export class Wrapper2Component implements OnInit {

  private _subscriptions$: Subscription = new Subscription();

  constructor(private activatedRoute: ActivatedRoute,
              private authService: AuthService,
              private router: Router,
              private wikiService: WikiService,
              private categoryService: CategoryService,
              private tagService: TagService,
              private inviteService: InviteService,
              private userPositionsService: UserPositionsService,
              private userStatusService: UserStatusService,
              private eventService: EventService,
              private companyService: CompanyService) {
  }

  ngOnInit() {
    this._subscriptions$.add(this
      .activatedRoute
      .params
      .subscribe((params) => {
        if (params['company'] !== 'not-found') {
          const companyId = params['company'];
          this.companyService.fetchStart(companyId);
          const inviteQueryModel = new InviteQueryModel();
          inviteQueryModel.folder = 'active';
          const wikiQueryModel = new WikiQueryModel();
          wikiQueryModel.page = 1;

          this.authService.fetchUser();
          this.categoryService.searchAll();
          this.tagService.searchAll();
          this.inviteService.fetchAll(inviteQueryModel, false);
          this.userPositionsService.searchAll();
          this.userStatusService.searchAll();
          this.eventService.fetchAll();
        }
      })
    );
  }
}
