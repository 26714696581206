import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiCategoryBlockComponent} from '@ui/modules/ui-category-block/ui-category-block.component';
import {UiBtnEntityCreateModule} from '@ui/modules/buttons/ui-btn-entity-create/ui-btn-entity-create.module';
import {UiCategoryBlockHeaderControlComponent} from '@ui/modules/ui-category-block/companents/ui-category-block-header-control/ui-category-block-header-control.component';
import {UiTooltipModule} from '@ui/modules/ui-tooltip/ui-tooltip.module';

@NgModule({
  declarations: [
    UiCategoryBlockComponent,
    UiCategoryBlockHeaderControlComponent
  ],
  exports: [
    UiCategoryBlockComponent,
    UiCategoryBlockHeaderControlComponent,
  ],
  imports: [
    CommonModule,
    UiBtnEntityCreateModule,
    UiTooltipModule
  ]
})
export class UiCategoryBlockModule {
}
