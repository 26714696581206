<rt-tooltip [arrow]="arrow">
  <div class="onboarding-tooltip__lines">
    <span
      *ngFor="let line of lines"
      [style.opacity]="line"
      class="onboarding-tooltip__line">
    </span>
  </div>
  <div class="onboarding-tooltip__content">
    <ng-content></ng-content>
  </div>
</rt-tooltip>
