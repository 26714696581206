import { Component, OnInit, Input } from '@angular/core';
import { IdentityModel } from '@core/models';
import { Subject } from 'rxjs';
import { AuthService } from '@core/services/entities/auth/auth.service';
import { ChatraService } from '@core/services/chatra.service';
import { ModuleService } from '@core/services/module.service';
import { CompanyModel } from '@core/models/company.model';

@Component({
  selector: 'ui-user-menu-popup',
  templateUrl: './ui-user-menu-popup.component.html',
  styleUrls: ['./ui-user-menu-popup.component.less']
})
export class UiUserMenuPopupComponent implements OnInit {
  @Input()
  user: IdentityModel;

  @Input()
  closeTrigger: Subject<any>;

  @Input()
  company: CompanyModel;

  isOpenProducts = true;

  constructor(
    public moduleService: ModuleService,
    private chatraService: ChatraService,
    private authService: AuthService,
  ) { }

  ngOnInit() {
  }

  onCloseUserMenu() {
    this.closeTrigger.next();
  }

  onOpenProducts() {
    this.isOpenProducts = !this.isOpenProducts;
  }

  onSignOut() {
    this.authService.signOut();
  }

  onShowChat() {
    this.chatraService.openChatra();
  }

}
