import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class CookieStorage implements Storage {
  [index: number]: string;
  [key: string]: any;
  length: number;

  constructor(private cookieService: CookieService) {
  }

  public clear(): void {
    this.cookieService.deleteAll();
  }

  public getItem(key: string): string {
    return this.cookieService.get(key);
  }

  public key(index: number): string {
    return this.cookieService.getAll().propertyIsEnumerable[index];
  }

  public removeItem(key: string): void {
    // Fix problem ngx-cookie-service https://github.com/7leads/ngx-cookie-service/issues/5
    document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    this.cookieService.delete(key);
  }

  public setItem(key: string, data: string): void {
    const date = new Date().getTime() + 5 * 24 * 60 * 60 * 1000;
    this.cookieService.set(key, data, date, '/');
  }
}
