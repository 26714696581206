<span class="tooltip__content">
  <ng-content select="content"></ng-content>
</span>
<span
  *ngIf="!hideTip"
  [ngStyle]="{
  'right.px': right,
  'left.px': left
  }"
  class="tooltip__tip"
  [attr.data-arrow-position]="arrowPos"
  [ngClass]="classes">
  <span
    class="arrow"
    [ngStyle]="{
      'right.px': right ? arrowPos : '',
      'left.px': left ? arrowPos : ''
    }">
  </span>
  <ng-content select="tip"></ng-content>
</span>
