import {UserPositionsActions, UserPositionsActionTypes} from './user-positions.actions';
import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';
import {UserPositionsModel} from '@core/models/user-positions.model';
import {createSelector} from '@ngrx/store';

export interface State {
  ids: string[];
  loading: boolean;
  updateId: string | null;
  selectedId: string | null;
  entities: any;
  errors: any;
}

export const initialState: State = {
  ids: [],
  loading: false,
  updateId: null,
  selectedId: null,
  entities: {},
  errors: {}
};

export const adapter: EntityAdapter<UserPositionsModel> = createEntityAdapter<UserPositionsModel>({
  selectId: (userPositions: UserPositionsModel) => userPositions.id,
  sortComparer: false,
});

export function reducer(state = initialState, action: UserPositionsActions): State {
  switch (action.type) {

    case UserPositionsActionTypes.ResetAction: {
      return Object.assign({}, state, initialState);
    }

    case UserPositionsActionTypes.SearchAction: {
      return Object.assign({}, state, {loading: true});
    }

    case UserPositionsActionTypes.SearchSuccessAction: {
      return adapter.setAll(action.payload, Object.assign({}, state, {loading: false}));
    }

    case UserPositionsActionTypes.SelectUpdateAction: {
      return Object.assign({}, state, {updateId: action.payload, loading: false});
    }

    case UserPositionsActionTypes.UpdateAction: {
      return Object.assign({}, state, {loading: true});
    }

    case UserPositionsActionTypes.UpdateActionSuccess: {
      return adapter.updateOne({
        id: action.payload.id,
        changes: action.payload
      }, Object.assign({}, state, {loading: false}));
    }

    case UserPositionsActionTypes.CreateAction: {
      return Object.assign({}, state, {loading: true});
    }


    case UserPositionsActionTypes.CreateActionSuccess: {
      return adapter.addOne(action.payload, Object.assign({}, state, {loading: false}));
    }

    case UserPositionsActionTypes.DeleteAction: {
      return Object.assign({}, state, {loading: true});
    }

    case UserPositionsActionTypes.DeleteActionSuccess: {
      return adapter.removeOne(action.payload, Object.assign({}, state, {
        loading: false
      }));
    }

    case UserPositionsActionTypes.DeleteActionFailure: {
      return Object.assign({}, state, {loading: false});
    }

    default:
      return state;
  }
}

export const getErrors = (state: State) => state.errors;
export const getEntitiesObject = (state: State) => state.entities;
export const getIds = (state: State) => state.ids;
export const getLoading = (state: State) => state.loading;
export const getUpdateId = (state: State) => state.updateId;
export const getSelectedId = (state: State) => state.selectedId;
export const getSelectedEntity = createSelector(getEntitiesObject, getSelectedId, (entities, selectedId) => entities[selectedId]);
export const getEntities = createSelector(getEntitiesObject, getIds, (entities, ids) => ids.map(id => entities[id]));
