import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

interface ItemInterface {
  id: string;
  isChecked: boolean;
  name: string;
}

@Component({
  selector: 'ui-select-multi-tag',
  templateUrl: './ui-select-multi-tag.component.html',
  styleUrls: ['./ui-select-multi-tag.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiSelectMultiTagComponent),
      multi: true
    }
  ]
})
export class UiSelectMultiTagComponent implements OnInit, ControlValueAccessor {
  @Input() items: ItemInterface[] = [];

  @Input()
  get value(): any {
    return this._value;
  }

  set value(value: any) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
  }

  private _value: any;

  isOpenPopup = false;

  constructor() {
  }

  ngOnInit() {
  }

  onChangeCheckBox(value, e) {
    value.isChecked = e.target.checked;
    this.value = this.items.filter(item => item.isChecked).map(item => item.id);
  }

  writeValue(values: string[]): void {
    this.items.forEach((item) => {
      item.isChecked = false;
    });
    this._value = values;
    values.forEach(value => {
      this.items.forEach((item) => {
        if (item.id === value) {
          item.isChecked = true;
        }
      });
    });
  }

  onTogglePopup() {
    this.isOpenPopup = !this.isOpenPopup;
  }

  onClosePopup() {
    this.isOpenPopup = false;
  }

  onChange(value) {
  }

  onTouched() {
  }

  registerOnChange(fn: (rating: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
