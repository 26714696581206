<ng-template #selectTip>
  <rt-onboarding-tooltip
    [lines]="[1, 1, 1, .5]"
    arrow="left"
  >
    <p>
      Каждому отделу можно назначит доступ: только вам, всем или определённому отделу
    </p>
    <p>
      Выберите Отделу
    </p>
  </rt-onboarding-tooltip>
</ng-template>
<ng-template #checkboxTip>
  <rt-onboarding-tooltip
    [lines]="[1, 1, 1, .5]"
    arrow="left"
  >
    Назначьте права доступа к этому разделу
  </rt-onboarding-tooltip>
</ng-template>
<ng-template #saveTip>
  <rt-onboarding-tooltip
    [lines]="[1, 1, 1, .5]"
    arrow="left"
  >
    Теперь сохраните изменения раздела
  </rt-onboarding-tooltip>
</ng-template>
<span class="popup__icon-close icon-close" (click)="close.emit()"></span>
<form class="form" [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
  <span class="popup-category__label">{{form.get('id').value ? 'Редактирование ' + label : 'Создание ' + label}}</span>
  <div class="form__row" [ngClass]="getDisplayFieldCssClass('name')">
    <label for="name" class="label">Название {{label}}</label>
    <input #inputName id="name" type="text" class="input" value="Телеком" autocomplete="off" formControlName="name">
  </div>
  <!--<div class="form__row">-->
    <!--<label for="value" class="label">Родительский раздел</label>-->
    <!--<span class="form__icon form__icon_right icon-arrow"></span>-->
    <!--<input id="value" type="text" class="input input_padding-right" value="Разработка">-->
  <!--</div>-->
  <div
    #selectTipContainer
    *ngIf="isAccess && form.get('rootId').value === 0"
    [rtTooltip]="selectTip"
    [position]="tipPosition"
    class="form__row form__row_space-between"
  >
    <label class="label label_without-mb">доступ к разделу</label>
    <div class="radio radio_inline">
      <label class="radio__label radio__label_padding-left">
        <input name="accessLevel" type="radio" class="radio__input" value="all"  formControlName="accessLevel">
        <i class="radio__indicator radio__indicator_blue radio__indicator_absolute"></i>
        <span class="radio__text">Всем</span>
      </label>
    </div>
    <div class="radio radio_inline">
      <label class="radio__label radio__label_padding-left">
        <input name="accessLevel" type="radio" class="radio__input" value="me" formControlName="accessLevel">
        <i class="radio__indicator radio__indicator_blue radio__indicator_absolute"></i>
        <span class="radio__text">Мне</span>
      </label>
    </div>
    <div class="radio radio_inline">
      <label
        (click)="onSelect()"
        class="radio__label radio__label_padding-left"
      >
        <input name="accessLevel" type="radio" class="radio__input" value="group" formControlName="accessLevel">
        <i class="radio__indicator radio__indicator_blue radio__indicator_absolute"></i>
        <span class="radio__text">Отделу</span>
      </label>
    </div>

    <ui-multi-select
      #checkboxTipContainer
      *ngIf="form.get('accessLevel').value === 'group'"
      (valueChange)="onCheckboxValueChange()"
      [rtTooltip]="checkboxTip"
      [position]="tipPosition"
      [items]="departmentList"
      formControlName="accessDepartmentIds"
    >
    </ui-multi-select>
  </div>


  <div
    #saveTipContainer
    [rtTooltip]="saveTip"
    [position]="saveTipPosition"
    [class.form__row_space-between]="form.get('id').value"
    [class.form__row_center]="!form.get('id').value"
    class="popup__footer"
  >
    <button class="button button_mobile button_theme-6"
            type="button"
            *ngIf="form.get('id').value"
            (click)="onOpenDeleteCategoryForm()">{{isDeleteConfirm ? 'Уверены?' : 'Удалить'}}
    </button>
    <button class="button button_mobile button_theme-1" [disabled]="isSending || form.invalid">Сохранить</button>
  </div>
  <ui-btn-entity-create *ngIf="form.get('id').value && type !== 'user'" (click)="onNewChild()" class="popup-category__btn-entity-create"></ui-btn-entity-create>
</form>
