import { Action } from '@ngrx/store';
import { PutUserCompany, UserModel, ContactModel, IdentityModel, PutUserCompanyContacts } from '@core/models';

export enum UserCompanyActionTypes {
  SetUserCompanyAction = '[Company] Set User Company',
  SetuserCompanyActionSuccess = '[Company] Set User Company Success',
  SetuserCompanyActionFailure = '[Company] Set User Company Failure',

  SetUserCompanyContactsAction = '[Company] Set User Company Contacts',
  SetUserCompanyContactsActionSuccess = '[Company] Set User Company Contacts Success',
  SetUserCompanyContactsActionFailure = '[Company] Set User Company Contacts Failure',
}

export class SetUserCompanyAction implements Action {
  readonly type = UserCompanyActionTypes.SetUserCompanyAction;

  constructor(
    public readonly newUserCompany: PutUserCompany,
  ) { }
}

export class SetUserCompanyActionSuccess implements Action {
  readonly type = UserCompanyActionTypes.SetuserCompanyActionSuccess;

  constructor(
    public readonly user: IdentityModel,
  ) { }
}

export class SetUserCompanyActionFailure implements Action {
  readonly type = UserCompanyActionTypes.SetuserCompanyActionFailure;

  constructor(public readonly errors: any) { }
}

export class SetUserCompanyContactsAction implements Action {
  readonly type = UserCompanyActionTypes.SetUserCompanyContactsAction;

  constructor(public readonly contacts: PutUserCompanyContacts) { }
}

export class SetUserCompanyContactsActionSuccess implements Action {
  readonly type = UserCompanyActionTypes.SetUserCompanyContactsActionSuccess;

  constructor(public readonly user: IdentityModel) { }
}

export class SetUserCompanyContactsActionFailure implements Action {
  readonly type = UserCompanyActionTypes.SetUserCompanyContactsActionFailure;

  constructor(public readonly errors: any) { }
}

export type UserCompanyActions
  = SetUserCompanyAction
  | SetUserCompanyActionSuccess
  | SetUserCompanyActionFailure
  | SetUserCompanyContactsAction
  | SetUserCompanyContactsActionFailure
  | SetUserCompanyContactsActionSuccess
  ;
