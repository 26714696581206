import {ActionReducer, ActionReducerMap, createFeatureSelector, createSelector, MetaReducer} from '@ngrx/store';
import {routerReducer, RouterReducerState, getSelectors} from '@ngrx/router-store';
import {localStorageSync} from 'ngrx-store-localstorage';
import {CategoryTypes} from '@core/models/category.model';
import {AuthActionTypes} from '@core/redux/auth/auth.actions';
import * as fromAuth from './auth/auth.reducer';
import * as fromComment from './comment/comment.reducer';
import * as fromCompany from './company/company.reducer';
import * as fromCategory from './category/category.reducer';
import * as fromCourse from './course/course.reducer';
import * as fromWiki from './wiki/wiki.reducer';
import * as fromTag from './tag/tag.reducer';
import * as fromTest from './test/test.reducer';
import * as fromUserStatus from './user-status/user-status.reducer';
import * as fromUserPositions from './user-positions/user-positions.reducer';
import * as fromInvite from './invite/invite.reducer';
import * as fromUi from './ui/ui.reducer';
import * as fromFieldsOfActivity from './field-of-activity/field-of-activity.reducer';
import * as fromEvent from './event/event.reducer';
import * as fromKarma from './karma/karma.reducer';
import * as fromOnboarding from './onboarding/onboarding.reducer';
import {CourseModel, EventModel, TagTypes, TestModel, LessonModel, WikiModel} from '@core/models';
import {
  LessonStatus,
  StudyCreatingFromLinkStep,
  OnboardingResponse,
  StudyWritingArticleStep,
  StudyAddingUserStep,
  StudySettingUpUserStep
} from './onboarding/onboarding.model';
import {CompanyType} from '@core/models/company.model';

export interface State {
  auth: fromAuth.State;
  comment: fromComment.State;
  company: fromCompany.State;
  category: fromCategory.State;
  course: fromCourse.State;
  wiki: fromWiki.State;
  tag: fromTag.State;
  test: fromTest.State;
  userStatus: fromUserStatus.State;
  userPositions: fromUserPositions.State;
  invite: fromInvite.State;
  event: fromEvent.State;
  ui: fromUi.State;
  fieldsOfActivity: fromFieldsOfActivity.State;
  karma: fromKarma.State;
  router: RouterReducerState<any>;
  onboarding: fromOnboarding.State;
}

export const reducers: ActionReducerMap<State> = {
  auth: fromAuth.reducer,
  comment: fromComment.reducer,
  company: fromCompany.reducer,
  category: fromCategory.reducer,
  course: fromCourse.reducer,
  wiki: fromWiki.reducer,
  tag: fromTag.reducer,
  test: fromTest.reducer,
  userStatus: fromUserStatus.reducer,
  userPositions: fromUserPositions.reducer,
  invite: fromInvite.reducer,
  event: fromEvent.reducer,
  ui: fromUi.reducer,
  fieldsOfActivity: fromFieldsOfActivity.reducer,
  karma: fromKarma.reducer,
  router: routerReducer,
  onboarding: fromOnboarding.reducer,
};

const customStorage: Storage = {
  length: 0,
  clear: function (): void {
    if (window && window.localStorage) {
      window.localStorage.clear();
      this.length = window.localStorage.length;
    }
  },
  getItem: function (key: string): string | null {
    try {
      return window.localStorage.getItem(key);
    } catch {
      return null;
    }
  },
  key: function (index: number): string | null {
    try {
      return window.localStorage.key(index);
    } catch {
      return null;
    }
  },
  removeItem: function (key: string): void {
    try {
      window.localStorage.removeItem(key);
      this.length = window.localStorage.length;
    } catch {
      return;
    }
  },
  setItem: function (key: string, data: string): void {
    try {
      window.localStorage.setItem(key, data);
      this.length = window.localStorage.length;
    } catch {
      return;
    }
  }
};

/**
 * Дебаг рудукса
 * @param reducer
 */
export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({keys: ['auth', 'ui'], rehydrate: true, storage: customStorage})(reducer);
}

/**
 * Очистка редукса после logout
 * @param reducer
 */
export function signOutClearStorage(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    return reducer(action.type === AuthActionTypes.SignOutAction ? undefined : state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = [localStorageSyncReducer, signOutClearStorage];


/**
 * Auth Reducers
 */
export const getAuthState = createFeatureSelector<fromAuth.State>('auth');
export const getAuthIsGuest = createSelector(getAuthState, fromAuth.getIsAuth);
export const getAuthStep = createSelector(getAuthState, fromAuth.getStep);
export const getAuthErrors = createSelector(getAuthState, fromAuth.getErrors);
export const getAuthLoading = createSelector(getAuthState, fromAuth.getLoading);
export const getAuthUser = createSelector(getAuthState, fromAuth.getUser);
export const getToken = createSelector(getAuthState, fromAuth.getToken);
export const getUserIsActive = createSelector(getAuthState, fromAuth.getIsActive);
export const getUserIsTest = createSelector(getAuthState, fromAuth.getIsTest);

/**
 * Comment Reducers
 */
export const getCommentsState = createFeatureSelector<fromComment.State>('comment');

/**
 * Company Reducers
 */
export const getCompanyState = createFeatureSelector<fromCompany.State>('company');
export const getCompanyCurrentId = createSelector(getCompanyState, fromCompany.getCurrentId);
export const getCompanyStart = createSelector(getCompanyState, fromCompany.getSelectedEntity);
export const getCompanyEntities = createSelector(getCompanyState, fromCompany.getEntities);
export const getCompanyErrors = createSelector(getCompanyState, fromCompany.getErrors);
export const getCompanyLoading = createSelector(getCompanyState, fromCompany.getLoading);
export const getCompanyEntitiesActive = createSelector(getCompanyState, fromCompany.getEntitiesActive);

/**
 * Category Reducers
 */
export const getCategoryState = createFeatureSelector<fromCategory.State>('category');
export const getCategoryEntities = createSelector(getCategoryState, fromCategory.getEntities);
export const getCategoryEntitiesByType = (type: CategoryTypes) => createSelector(getCategoryState, fromCategory.getEntitiesByType(type));

export const getCategoryUpdateEntitity = createSelector(getCategoryState, fromCategory.getUpdateEntity);
export const getCategoryEntitiesByRootId = (rootId) => createSelector(getCategoryState, fromCategory.getEntitiesByRootId(rootId));

/**
 * Course Reducers
 */
export const getCourseState = createFeatureSelector<fromCourse.State>('course');
export const getCourseEntities = createSelector(getCourseState, fromCourse.getEntities);
export const getCourseUpdateEntity = createSelector(getCourseState, fromCourse.getUpdateEntity);
export const getCoursePagination = createSelector(getCourseState, fromCourse.getPagination);
export const getCourseSelected = createSelector<Object, fromCourse.State, CourseModel>(getCourseState, fromCourse.getSelectedEntity);
export const getCourseLoading = createSelector(getCourseState, fromCourse.getLoading);

/**
 * Wiki Reducers
 */
export const getWikiState = fromWiki.getWikiState;
export const getWikiByIds = (ids: string[]) =>
  createSelector<object, fromWiki.State, WikiModel[]>(getWikiState, fromWiki.getEntitiesByIds(ids));
export const getWikiEntities = createSelector(getWikiState, fromWiki.getEntitiesFiltered);
export const getWikiCountMultipleSelected = createSelector(getWikiState, fromWiki.getCountMultipleSelected);
export const getWikiMultipleSelectedIds = createSelector(getWikiState, fromWiki.getMultipleSelectedIds);
export const getWikiCount = createSelector(getWikiState, fromWiki.getCount);
export const getWikiTotalCount = createSelector(getWikiState, fromWiki.getTotalCount);
export const getWikiSelected = createSelector(getWikiState, fromWiki.getSelectedEntity);
export const getWikiUpdateEntity = createSelector(getWikiState, fromWiki.getUpdateEntity);
export const getWikiPagination = createSelector(getWikiState, fromWiki.getPagination);
export const getWikiLoading = createSelector(getWikiState, fromWiki.getLoading);

/**
 * Tag Reducers
 */
export const getTagState = createFeatureSelector<fromCategory.State>('tag');
export const getTagEntities = createSelector(getTagState, fromTag.getEntities);
export const getTagUpdate = createSelector(getTagState, fromTag.getUpdateEntity);
export const getTagLoading = createSelector(getTagState, fromTag.getLoading);
export const getTagEntitiesByType = (type: TagTypes) => createSelector(getTagState, fromTag.getEntitiesByType(type));

/**
 * Test Reducers
 */
export const getTestState = createFeatureSelector<fromTest.State>('test');
export const getTestsByIds = (ids: string[]) => createSelector(getTestState, fromTest.getEntitiesByIds(ids));
export const getTestEntities = createSelector(getTestState, fromTest.getEntities);
export const getTestErrors = createSelector(getTestState, fromTest.getErrors);
export const getTestUpdateEntity = createSelector(getTestState, fromTest.getUpdateEntity);
export const getTestPagination = createSelector(getTestState, fromTest.getPagination);
export const getTestSelected = createSelector<Object, fromTest.State, TestModel>(getTestState, fromTest.getSelectedEntity);
export const getTestLoading = createSelector(getTestState, fromTest.getLoading);

/**
 * User Status Reducers
 */
export const getUserStatusState = createFeatureSelector<fromUserStatus.State>('userStatus');
export const getUserStatusEntities = createSelector(getUserStatusState, fromUserStatus.getEntities);

/**
 * User Positions Reducers
 */
export const getUserPositionsState = createFeatureSelector<fromUserPositions.State>('userPositions');
export const getUserPositionsEntities = createSelector(getUserPositionsState, fromUserPositions.getEntities);

/**
 * Invite Reducers
 */
export const getInviteState = createFeatureSelector<fromInvite.State>('invite');
export const getInviteEntities = createSelector(getInviteState, fromInvite.getEntities);
export const getInviteActiveEntities = createSelector(getInviteState, fromInvite.getActive);
export const getInviteMy = createSelector(getInviteState, fromInvite.getMy);
export const getInviteLoading = createSelector(getInviteState, fromInvite.getLoading);
export const getInviteErrors = createSelector(getInviteState, fromInvite.getErrors);
export const getInvitePagination = createSelector(getInviteState, fromInvite.getPagination);
export const getInviteSelect = createSelector(getInviteState, fromInvite.getSelectedEntity);

export const getUiState = createFeatureSelector<fromUi.State>('ui');
export const getUiViewWiki = createSelector(getUiState, fromUi.getViewWiki);
export const getUiViewUser = createSelector(getUiState, fromUi.getViewUser);

/**
 * Event Reducers
 */
export const getEventState = createFeatureSelector<fromEvent.State>('event');
export const getEventEntities = createSelector(getEventState, fromEvent.getEntities);
export const getEventErrors = createSelector(getEventState, fromEvent.getErrors);
export const getEventSelected = createSelector(getEventState, fromEvent.getSelectedEntity);
export const getEventLoading = createSelector(getEventState, fromEvent.getLoading);

/**
 * Field of activity
 */
export const getFieldsOfActivityState = createFeatureSelector<fromFieldsOfActivity.State>('fieldsOfActivity');
export const getFieldsOfActivity = createSelector(getFieldsOfActivityState, (state) => state.activities);

/**
 * Karma
 */
export const getKarmaState = createFeatureSelector<fromKarma.State>('karma');
export const getKarmas = createSelector(getKarmaState, state => state.ids.map(id => state.entities[id]));

/**
 * Router
 */
export const selectRouter = createFeatureSelector<State, RouterReducerState<any>>('router');
export const {
  selectCurrentRoute,   // select the current route
  selectQueryParams,    // select the current route query params
  selectQueryParam,     // factory function to select a query param
  selectRouteParams,    // select the current route params
  selectRouteParam,     // factory function to select a route param
  selectRouteData,      // select the current route data
  selectUrl,            // select the current url
} = getSelectors(selectRouter);

/**
 * Onboarding
 */
export const getOnboarding = createFeatureSelector<fromOnboarding.State>('onboarding');
export const getOnboardingState = createSelector(getOnboarding, state => state.state);
export const getOnboardingOnboarding = createSelector(getOnboarding, state => state.onboarding);
// normalize for undefined/null
export const getOnboardingUserId = createSelector<object, OnboardingResponse, string>(getOnboardingOnboarding, onboarding => onboarding && onboarding.userId ? onboarding.userId : undefined);
export const getOnboardingCompanyId = createSelector<object, OnboardingResponse, string>(getOnboardingOnboarding, onboarding => onboarding && onboarding.companyId ? onboarding.companyId : undefined);
export const shouldCreateKnowledgeBase = createSelector<object, OnboardingResponse, boolean>(getOnboardingOnboarding, onboarding => onboarding && onboarding.createKnowledgeBase.status === LessonStatus.InProgress);
export const shouldTellAboutGoals = createSelector<object, OnboardingResponse, boolean>(getOnboardingOnboarding, onboarding => onboarding && onboarding.tellAboutGoals.status === LessonStatus.InProgress);
export const shouldTellAboutYourself = createSelector<object, OnboardingResponse, boolean>(getOnboardingOnboarding, onboarding => onboarding && onboarding.tellAboutYourself.status === LessonStatus.InProgress);
export const shouldStudyService = createSelector<object, OnboardingResponse, boolean>(getOnboardingOnboarding, onboarding => onboarding && onboarding.studyService === LessonStatus.InProgress);
export const shouldOpenStudyService = createSelector<object, OnboardingResponse, boolean>(getOnboardingOnboarding, onboarding => onboarding && onboarding.openStudyService === LessonStatus.InProgress);
export const shouldStudyCreatingFromLink = createSelector<object, OnboardingResponse, boolean>(getOnboardingOnboarding, onboarding => onboarding && onboarding.studyCreatingFromLink === LessonStatus.InProgress);
export const shouldStudyWritingArticle = createSelector<object, OnboardingResponse, boolean>(getOnboardingOnboarding, onboarding => onboarding && onboarding.studyWritingArticle === LessonStatus.InProgress);
export const shouldStudyAddingUser = createSelector<object, OnboardingResponse, boolean>(getOnboardingOnboarding, onboarding => onboarding && onboarding.studyAddingUser === LessonStatus.InProgress);
export const shouldStudySettingUpUser = createSelector<object, OnboardingResponse, boolean>(getOnboardingOnboarding, onboarding => onboarding && onboarding.studySettingUpUser === LessonStatus.InProgress);
export const getCompanyType = createSelector<object, OnboardingResponse, CompanyType>(getOnboardingOnboarding, onboarding => onboarding && onboarding.createKnowledgeBase.selected);
export const getStudyCreatingFromLinkStep = createSelector<object, fromOnboarding.State, StudyCreatingFromLinkStep>(getOnboarding, state => state.studyCreatingFromLinkStep);
export const getStudyWritingArticleStep = createSelector<object, fromOnboarding.State, StudyWritingArticleStep>(getOnboarding, state => state.studyWritingArticleStep);
export const getStudyAddingUserStep = createSelector<object, fromOnboarding.State, StudyAddingUserStep>(getOnboarding, onboarding => onboarding.studyAddingUserStep);
export const getStudySettingUpUserStep = createSelector<object, fromOnboarding.State, StudySettingUpUserStep>(getOnboarding, onboarding => onboarding.studySettingUpUserStep);
export const getFirstLinkArticle = createSelector<object, fromOnboarding.State, string>(getOnboarding, state => state.firstLinkArticle);
export const getFirstWrittenArticle = createSelector<object, fromOnboarding.State, string>(getOnboarding, state => state.firstWrittenArticle);
