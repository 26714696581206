import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  EventActionTypes,
  CreateAction,
  CreateActionFailure,
  CreateActionSuccess,
  UpdateAction,
  UpdateActionFailure,
  UpdateActionSuccess,
  DeleteAction,
  DeleteActionFailure,
  DeleteActionSuccess, GetEventsSuccessAction, GetEventsFailureAction
} from './event.actions';
import {map, exhaustMap, catchError, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/index';
import {PostEventModel, PutEventModel} from '@core/models';
import {ApiEventService} from '@core/services/entities/events/api-events.service';

@Injectable()
export class EventEffects {
  constructor(private actions$: Actions,
              private apiEventService: ApiEventService) {
  }

  @Effect()
  archive$ = this.actions$.pipe(
    ofType(EventActionTypes.DeleteAction),
    map((action: DeleteAction) => action.payload),
    exhaustMap((id) => {
        return this.apiEventService
          .delete(id)
          .pipe(
            map(() => new DeleteActionSuccess(id)),
            catchError((errors) => of(new DeleteActionFailure(errors)))
          );
      }
    )
  );

  @Effect()
  events$ = this.actions$.pipe(
    ofType(EventActionTypes.GetEventsAction),
    exhaustMap(() => {
        return this.apiEventService
          .getAll()
          .pipe(
            map((events) => new GetEventsSuccessAction(events)),
            catchError((errors) => of(new GetEventsFailureAction(errors)))
          );
      }
    )
  );
    
  @Effect()
  update$ = this.actions$.pipe(
    ofType(EventActionTypes.UpdateAction),
    map((action: UpdateAction) => action.payload),
    exhaustMap((model: PutEventModel) => {
        return this.apiEventService
          .update(model)
          .pipe(
            switchMap((response) => [
              new UpdateActionSuccess(response),
            ]),
            catchError((errors) => of(new UpdateActionFailure(errors)))
          );
      }
    )
  );

  @Effect()
  create$ = this.actions$.pipe(
    ofType(EventActionTypes.CreateAction),
    map((action: CreateAction) => action.payload),
    exhaustMap((model: PostEventModel) => {
        return this.apiEventService
          .create(model)
          .pipe(
            switchMap((response) => [
              new CreateActionSuccess(response),
            ]),
            catchError((errors) => of(new CreateActionFailure(errors)))
          );
      }
    )
  );
}
