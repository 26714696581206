import {BaseModel} from './base.model';

export class FileModel extends BaseModel {
  id: string;
  originalName: string;
  file: string;
  companyId: string;
  link: string;
  isImage: boolean;
  mimeType: string;
  absoluteUrl: string;
}
