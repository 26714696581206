import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

import {CommentModel, ObjectType, UserModel} from '@core/models';
import {
  CommentActions,
  CommentActionTypes,
  CreateActionSuccess,
  DeleteActionSuccess,
  GetActionSuccess,
  ReplyAction,
  EditAction,
  UpdateActionSuccess,
  LikeActionSuccess,
  DislikeActionSuccess
} from './comment.actions';


export interface State extends EntityState<CommentModel> {
  ids: string[];
  objectType: ObjectType;
  objectId: string;
  replyingTo: CommentModel | undefined;
  loading: boolean;
  errors: any;
  commentOnEditing: CommentModel | undefined;
}

export const initialState: State = {
  objectType: ObjectType.Wiki,
  objectId: '',
  replyingTo: undefined,
  ids: [],
  loading: false,
  entities: {},
  errors: {},
  commentOnEditing: undefined
};

export const adapter: EntityAdapter<CommentModel> = createEntityAdapter<CommentModel>({
  selectId: (comment: CommentModel) => comment.id,
  sortComparer: false,
});


export function reducer(state = initialState, action: CommentActions): State {
  switch (action.type) {
    case CommentActionTypes.CreateActionSuccess: {
      const {comment} = action as CreateActionSuccess;
      return adapter.addOne(comment, {...state, replyingTo: undefined});
    }

    case CommentActionTypes.DeleteActionSuccess: {
      const {commentId} = action as DeleteActionSuccess;
      return adapter.removeOne(commentId, {...state});
    }

    case CommentActionTypes.EditAction: {
      const {comment} = action as EditAction;
      return {...state, commentOnEditing: comment};
    }

    case CommentActionTypes.GetActionSuccess: {
      const {objectId, objectType, comments} = action as GetActionSuccess;
      return adapter.setAll(comments, {...state, objectType, objectId});
    }

    case CommentActionTypes.LikeActionSuccess: {
      const {comment} = action as LikeActionSuccess;
      return adapter.updateOne({id: comment.id, changes: comment}, state);
    }

    case CommentActionTypes.DislikeActionSuccess: {
      const {comment} = action as DislikeActionSuccess;
      return adapter.updateOne({id: comment.id, changes: comment}, state);
    }

    case CommentActionTypes.ReplyAction: {
      const {comment} = action as ReplyAction;
      return {...state, replyingTo: comment};
    }

    case CommentActionTypes.ResetAction: {
      return {...initialState};
    }

    case CommentActionTypes.UpdateActionSuccess: {
      const {comment} = action as UpdateActionSuccess;
      return adapter.updateOne({id: comment.id, changes: comment}, state);
    }

    default:
      return state;
  }
}
