import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  CompanyActionTypes,
  ConfirmInviteUserAction,
  ConfirmInviteUserActionFailure,
  ConfirmInviteUserActionSuccess,
  CreateAction,
  GetCompaniesFailureAction,
  GetCompaniesSuccessAction,
  GetStartAction,
  GetStartFailureAction,
  GetStartSuccessAction,
  LeftAction,
  UpdateFailureAction,
  UpdateSuccessAction,
} from './company.actions';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs/index';
import { CompanyModel } from '@core/models/company.model';
import { CreateFailureAction, CreateSuccessAction } from '@core/redux/company/company.actions';
import { ApiCompanyService } from '@core/services/entities/company/api-company.service';

@Injectable()
export class CompanyEffects {
  constructor(private actions$: Actions,
    private apiCompanyService: ApiCompanyService
  ) {
  }

  @Effect()
  company$ = this.actions$.pipe(
    ofType(CompanyActionTypes.GetStartAction),
    map((action: GetStartAction) => action.companyId),
    exhaustMap((companyId) => {
      return this.apiCompanyService
        .getStart(companyId)
        .pipe(
          map((company) => new GetStartSuccessAction(company)),
          catchError((errors) => of(new GetStartFailureAction(errors)))
        );
    }
    )
  );

  @Effect()
  companies$ = this.actions$.pipe(
    ofType(CompanyActionTypes.GetCompaniesAction),
    exhaustMap(() => {
      return this.apiCompanyService
        .getAll()
        .pipe(
          map((companies) => new GetCompaniesSuccessAction(companies)),
          catchError((errors) => of(new GetCompaniesFailureAction(errors)))
        );
    }
    )
  );

  @Effect()
  create$ = this.actions$.pipe(
    ofType(CompanyActionTypes.CreateAction),
    map((action: CreateAction) => action.payload),
    exhaustMap((model: CompanyModel) => {
      return this.apiCompanyService
        .create(model)
        .pipe(
          map((response) => new CreateSuccessAction(response)),
          catchError((errors) => of(new CreateFailureAction(errors)))
        );
    }
    )
  );

  @Effect()
  update$ = this.actions$.pipe(
    ofType(CompanyActionTypes.UpdateAction),
    map((action: CreateAction) => action.payload),
    exhaustMap((model: CompanyModel) => {
      return this.apiCompanyService
        .update(model)
        .pipe(
          map((response) => new UpdateSuccessAction(response)),
          catchError((errors) => of(new UpdateFailureAction(errors)))
        );
    }
    )
  );

  @Effect()
  left$ = this.actions$.pipe(
    ofType(CompanyActionTypes.LeftAction),
    map((action: LeftAction) => action.payload),
    exhaustMap((id: string) => {
      return this.apiCompanyService
        .left(id)
        .pipe(
          map((response) => new UpdateSuccessAction(response)),
          catchError((errors) => of(new UpdateFailureAction(errors)))
        );
    }
    )
  );

  @Effect()
  confirmInviteUser$ = this.actions$.pipe(
    ofType(CompanyActionTypes.ConfirmInviteUserAction),
    map((action: ConfirmInviteUserAction) => action.payload),
    exhaustMap((id) => {
      return this.apiCompanyService
        .confirmInviteUser(id)
        .pipe(
          map((response) => new ConfirmInviteUserActionSuccess(response)),
          catchError((errors) => of(new ConfirmInviteUserActionFailure(errors)))
        );
    }
    )
  );
}
