import { BehaviorSubject, interval, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { first } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import * as fromRoot from '@core/redux';
import { ApiService } from '@core/services/entities/api.service';
import { checkAnswer, mapTest } from '../helpers/functions';
import {
  CheckAnswerResponse,
  TestState,
  TmpQuestionModel,
  TmpTestModel,
  UncheckedAnswerModel
} from '../helpers/types';
import { ExitConfirmationComponent } from '../components/exit-confirmation/exit-confirmation.component';
import { stringTimeDiff } from '@helpers/time/diff';
import { TestModel, TestResultsModel, getEmptyTest } from '@core/models';
import { FinishTestAction } from '@core/redux/test/test.actions';


const defaultQuestion: TmpQuestionModel = {
  question: 'Загрузка...',
  answers: [],
  selectedAnswers: [],
  text: '',
  id: ''
};

const defaultTest: TmpTestModel = {
  ...getEmptyTest(),
  name: 'Загрузка...',
  questions: []
};


@Injectable()
export class TakeTestService extends ApiService {
  /**
   * Id урока, если тест из урока
   */
  private lessonId: string | undefined;
  /**
   * ID сессии прохождения теста
   */
  private sessionId: string;
  /**
   * Время начала теста
   * Устанавливается тут, чтобы не быть `undefined`
   * @see start
   */
  private startedAt = new Date();
  /**
   * `TakeTestComponent`, но из-за circular dependency стоит `any`
   */
  private takeTestComponentDialogRef: MatDialogRef<any>;
  private readonly isLoading$ = new BehaviorSubject<boolean>(false);
  /**
   * Индекс вопроса
   */
  private readonly questionIndex$ = new BehaviorSubject<number>(-1);
  /**
   * Текущий вопрос
   */
  private readonly question$ = new BehaviorSubject<TmpQuestionModel>(defaultQuestion);
  /**
   * Результаты теста
   */
  private readonly results$ = new BehaviorSubject<TestResultsModel>(defaultTest.results);
  /**
   * Состояние теста
   * @see TestState
   */
  private readonly state$ = new BehaviorSubject<TestState>('question');
  private readonly test$ = new BehaviorSubject<TmpTestModel>(defaultTest);
  /**
   * Сколько прошло времени с начала теста
   */
  private readonly time$ = interval(100);

  get isLoading(): Observable<boolean> {
    return this.isLoading$.asObservable();
  }

  get question(): Observable<TmpQuestionModel> {
    return this.question$.asObservable();
  }

  /**
   * Порядковый номер вопроса
   * Отчет с единицы, т.е. вопрос с индексом 0 имеет номер 1
   */
  get questionNumber(): Observable<number> {
    return this.questionIndex$.asObservable()
      .map(index => index + 1);
  }

  get results(): Observable<TestResultsModel> {
    return this.results$.asObservable();
  }

  get state(): Observable<TestState> {
    return this.state$.asObservable();
  }

  get test(): Observable<TmpTestModel> {
    return this.test$.asObservable();
  }

  get time(): Observable<string> {
    return this.time$.map(() => stringTimeDiff(new Date(), this.startedAt));
  }

  constructor(
    private dialog: MatDialog,
    private store: Store<fromRoot.State>,
    http: HttpClient,
  ) {
    super(http);
  }

  /**
   * Послать ответ на вопрос
   */
  answer() {
    const url = this.getApiUrl('tests/check-answer/' + this.test$.value.id);
    const answerIds: string[] = this.question$.value.answers
      .filter(a => a.isChecked)
      .map(a => a.id);
    if (!answerIds.length) {
      return false;
    }
    const body = {
      answerIds,
      questionId: this.question$.value.id,
      sessionId: this.sessionId,
    };
    this.isLoading$.next(true);
    this.http
      .post<CheckAnswerResponse>(url, body)
      .subscribe(response => this.showAnswerResults(response));
  }

  /**
   * Попытаться закрыть тестовый попап
   * Запросить подтверждение у пользователя.
   * Закрыть, если пользователь согласен.
   */
  askConfirmationAndClose() {
    if (!this.takeTestComponentDialogRef) {
      return console.warn('takeTestComponentDialogRef is undefined');
    }
    this.dialog
      .open(ExitConfirmationComponent)
      .afterClosed()
      .pipe(first())
      .subscribe((shouldClose: boolean) => {
        if (shouldClose) {
          this.store.dispatch(new FinishTestAction(undefined, this.lessonId));
          this.takeTestComponentDialogRef.close();
        }
      });
  }

  /**
   * Закончить тестирование
   */
  finish() {
    this.isLoading$.next(true);
    const url = this.getApiUrl('tests/end/' + this.sessionId);
    const body = {};
    const sub = this.http
      .post<TestResultsModel>(url, body)
      .subscribe(response => {
        this.results$.next(response);
        this.store.dispatch(new FinishTestAction({ ...this.test$.value, results: response }, this.lessonId));
        this.isLoading$.next(false);
        sub.unsubscribe();
        this.takeTestComponentDialogRef.close();
      });
  }

  /**
   * Перейти к следующему вопросу или результатам, если вопрос последний
   */
  goToNextQuestionOrResults() {
    const hasEnded = this.questionIndex$.value + 1 >= this.test$.value.questions.length;
    if (hasEnded) {
      this.finish();
    } else {
      this.goToNextQuestion();
    }
  }

  /**
   * Начать тестирование
   * Используется только внутри компонента `TakeTestComponent`. Чтобы начать тест, используй `StartTestService.start`
   * 
   * @param test тест с сервера
   * 
   * @param dialogRef {MatDialogRef<TakeTestComponent>}
   * 
   * @param lessonId id урока, если запущен из урока
   */
  start(test: TestModel, dialogRef: MatDialogRef<any>, lessonId: string | undefined) {
    this.questionIndex$.next(-1);
    this.takeTestComponentDialogRef = dialogRef;
    this.lessonId = lessonId;
    this.isLoading$.next(true);
    const url = this.getApiUrl('tests/start/' + test.id);
    const sub = this.http
      .post<{ id: string }>(url, {})
      .subscribe(response => {
        this.openTest(response, test);
        sub.unsubscribe();
      });
  }

  /**
   * Перейти к следующему вопросу
   */
  private goToNextQuestion() {
    const nextQuestionIndex = this.questionIndex$.value + 1;
    this.questionIndex$.next(nextQuestionIndex);
    const nextQuestion = this.test$.value.questions[nextQuestionIndex];
    this.question$.next(nextQuestion);
    this.state$.next('question');
  }

  private openTest({ id }: { id: string }, test: TestModel) {
    this.sessionId = id;
    this.startedAt = new Date();
    this.isLoading$.next(false);
    const tmpTest = mapTest(test);
    this.test$.next(tmpTest);
    this.goToNextQuestionOrResults();
  }

  /**
   * @todo было бы удобнее получать объект {id: boolean}, а не массив
   * @param results
   */
  private showAnswerResults(results: CheckAnswerResponse) {
    // превратить массив в объект,
    // где ключ -- id,
    // а значение -- isCorrent
    const resultsObject: { [key: string]: boolean } = results
      .reduce(
        (prev, current) => ({ ...prev, [current.id]: current.isCorrect }),
        {});
    const nextAnswers = this.question$.value
      .answers
      .map(a => checkAnswer(a as UncheckedAnswerModel, resultsObject[a.id]));
    const nextQuestion = {
      ...this.question$.value,
      answers: nextAnswers
    };
    this.isLoading$.next(false);
    this.question$.next(nextQuestion);
    this.state$.next('question-results');
  }

}
