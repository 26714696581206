<div class="check-box">
  <label class="check-box__label test-answer__label">
    <input
      (change)="onChange()"
      [value]="answer.isChecked"
      [checked]="answer.isChecked"
      [disabled]="disabled$ | async"
      type="checkbox"
      class="check-box__input">
    <i class="check-box__indicator check-box__indicator_blue check-box__indicator_absolute"></i>
    <span class="check-box__text test-answer__content">
          <content-view
            class="content-view_small test-answer__text"
            [class.test-question__answer-text_green]="answer.isCorrect"
            [content]="answer.answer">
      </content-view>
      <i
        *ngIf="right"
        class="icon-done icon-green">
      </i>
      <i
        *ngIf="wrong"
        class="icon-close icon-red">
      </i>
    </span>
  </label>
</div>
