import {UiActions, UiActionTypes} from './ui.actions';

export interface State {
  viewWiki: string;
  viewUser: string;
}

export const initialState: State = {
  viewWiki: 'list',
  viewUser: 'list',
};

export function reducer(state = initialState, action: UiActions): State {
  switch (action.type) {
    case UiActionTypes.ChangeViewWikiAction:
      return Object.assign({}, state, {
        viewWiki: action.payload
      });

    case UiActionTypes.ChangeViewUserAction:
      return Object.assign({}, state, {
        viewUser: action.payload
      });

    default:
      return state;
  }
}

export const getViewUser = (state: State) => state.viewUser;
export const getViewWiki = (state: State) => state.viewWiki;
