import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, AfterViewInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Subscription} from 'rxjs/Subscription';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CategoryModel, CategoryTypes} from '@core/models/category.model';
import {Validation} from '@shared/validation/validation.abstract.class';
import * as fromRoot from '@core/redux/index';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {ApiCategoryService} from '@core/services/entities/category/api-category.service';
import {CategoryService} from '@core/services/entities/category/category.service';
import {PopupDeleteCategoryComponent} from '@ui/modules/ui-category/components/popups/popup-delete-category/popup-delete-category.component';
import { Actions, ofType } from '@ngrx/effects';
import { take } from 'rxjs/operators';
import { CategoryActionTypes, CreateActionSuccess } from '@core/redux/category/category.actions';
import { TooltipDirective } from '@ui/modules/tooltip/tooltip.directive';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { OnboardingStateService } from '@core/redux/onboarding/onboarding-state.service';
import { StudySettingUpUserStep } from '@core/redux/onboarding/onboarding.model';
import { filter } from 'rxjs/operators';
import { OnboardingActionsService } from '@core/redux/onboarding/onboarding-actions.service';

@Component({
  selector: 'ui-popup-category',
  templateUrl: './ui-popup-category.component.html',
  styleUrls: ['./ui-popup-category.component.less']
})
export class UiPopupCategoryComponent extends Validation implements AfterViewInit, OnInit, OnDestroy {
  @Input()
  type: CategoryTypes;

  @Input()
  isAccess = true;

  @Output()
  close: EventEmitter<void> = new EventEmitter();

  @ViewChild('inputName', { static: true })
  inputName: ElementRef;

  @ViewChild('selectTipContainer', {static: false, read: TooltipDirective})
  selectTipContainer: TooltipDirective;

  @ViewChild('checkboxTipContainer', {static: false, read: TooltipDirective})
  checkboxTipContainer: TooltipDirective;

  @ViewChild('saveTipContainer', {static: false, read: TooltipDirective})
  saveTipContainer: TooltipDirective;

  isSending = false;
  form: UntypedFormGroup;
  label = 'раздела';
  category: CategoryModel;
  isDeleteConfirm = false;
  departmentList: CategoryModel[] = [];

  studyingSettingUpUser = false;

  readonly tipPosition: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'center',
      overlayX: 'start',
      overlayY: 'center',
      offsetY: 17,
      offsetX: 48,
    }
  ];

  readonly saveTipPosition: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'center',
      overlayX: 'start',
      overlayY: 'center',
      offsetX: 48,
    }
  ];

  private _subscriptions$: Subscription = new Subscription();

  constructor(private store: Store<fromRoot.State>,
              private categoryService: CategoryService,
              private actions: Actions,
              private apiCategoryService: ApiCategoryService,
              private formBuilder: UntypedFormBuilder,
              public dialog: MatDialog,
              private onboardingActionsService: OnboardingActionsService,
              private onboardingStateService: OnboardingStateService,
              ) {
    super();
  }

  ngAfterViewInit() {
    this.showTipIfNeeded();
  }

  ngOnInit() {
    this.label = this.type === 'user' ? 'отдела' : 'раздела';
    this.onInitForm();
    this._subscriptions$.add(this.store
      .pipe(select(fromRoot.getCategoryUpdateEntitity))
      .subscribe(
        (category: CategoryModel) => {
          if (category) {
            this.isDeleteConfirm = false;
            this.category = category;
            this.form.get('id').setValue(category.id);
            this.form.get('name').setValue(category.name);
            this.form.get('accessLevel').setValue(category.accessLevel);
            this.form.get('rootId').setValue(category.rootId);
            this.form.get('accessDepartmentIds').setValue(category.accessDepartmentIds);
            this.form.get('type').setValue(category.type);
          } else {
            this.onInitForm();
          }

          setTimeout(() => this.inputName.nativeElement.focus());
        }
      )
    );

    this._subscribeDepartmentList();
  }

  ngOnDestroy() {
    this._subscriptions$.unsubscribe();
  }

  onSubmit() {
    if (this.form.valid) {
      if (this.form.get('id').value) {
        this.categoryService.update(this.form.getRawValue());
      } else {
        this.categoryService.create(this.form.getRawValue());
      }
      this.close.emit();
      if (!this.studyingSettingUpUser) {
        return;
      }
      this.onboardingActionsService.changeStudySettingUpUserStep(StudySettingUpUserStep.Done);
      this.studyingSettingUpUser = false;
      this.saveTipContainer.hide();
    }
  }

  onNewChild() {
    const model = new CategoryModel();
    model.name = 'Новый раздел';
    model.accessLevel = 'all';
    model.accessDepartmentIds = [];
    model.rootId = this.form.get('id').value;
    model.type = this.form.get('type').value;
    this.categoryService.create(model);
    // переключить форму редактирования на новую категорию
    this.actions.pipe(
      ofType(CategoryActionTypes.CreateActionSuccess),
      take(1)
    ).subscribe((action: CreateActionSuccess) =>
      this.categoryService.selectUpdate(action.payload.id)
    );
  }

  onSelect() {
    if (!this.studyingSettingUpUser) {
      return;
    }
    this.selectTipContainer.hide();
    // нужно дождаться рендеринга чекбоксов
    setTimeout(() => this.checkboxTipContainer.show(), 1);
  }

  onCheckboxValueChange() {
    if (!this.studyingSettingUpUser) {
      return;
    }
    this.checkboxTipContainer.hide();
    this.saveTipContainer.show();
  }

  onOpenDeleteCategoryForm() {
    if (this.isDeleteConfirm) {
      this.categoryService.deleteOne({
        id: this.category.id,
        moveTo: ''
      });
      this.isDeleteConfirm = false;
      this.close.emit();
    } else {
      this.apiCategoryService.checkEmpty(this.category.id).subscribe(result => {
        if (result === true) {
          this.dialog.open(PopupDeleteCategoryComponent, {
            height: '100%',
            width: '100%',
            data: this.category
          });
          this.close.emit();
        } else {
          this.isDeleteConfirm = true;
        }
      });
    }
  }

  onInitForm() {
    this.form = this.formBuilder.group({
      id: [null],
      type: [this.type],
      name: [null, [Validators.required, Validators.minLength(1)]],
      rootId: [0, [Validators.required]],
      accessLevel: ['all', [Validators.required]],
      accessDepartmentIds: [[]],
    });
  }

  private showTipIfNeeded() {
    this._subscriptions$.add(
      this.onboardingStateService.getStudySettingUpUserStep().pipe(
        filter(step => step === StudySettingUpUserStep.SetUpAccess),
      ).subscribe(() => {
        this.studyingSettingUpUser = true;
        this.selectTipContainer.show();
      })
    );
  }

  private _subscribeDepartmentList() {
    this._subscriptions$.add(this
      .categoryService
      .getAllDepartments()
      .subscribe((items) => this.departmentList = items)
    );
  }
}
