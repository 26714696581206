import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

export type TooltipArrowPosition = 'bottom' | 'bottom-left' | 'bottom-right' | 'left' | 'left-bottom' | 'right' | 'right-top' | 'top' | 'top-left' | 'top-right';

@Component({
  selector: 'rt-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent implements OnInit {
  @Input()
  arrow: TooltipArrowPosition = 'bottom-right';

  constructor() { }

  ngOnInit() {
  }

}
