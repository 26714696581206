import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  CheckResultAction,
  CourseActionTypes,
  CreateAction,
  CreateActionFailure,
  CreateActionSuccess,
  SearchFailureAction,
  SearchSuccessAction,
  UpdateAction,
  UpdateActionFailure,
  UpdateActionSuccess,
  SearchAction,
  ViewAction,
  ViewActionFailure,
  ViewActionSuccess,
  ChangeCategoryAction,
  ChangeCategoryActionSuccess,
  ChangeCategoryActionFailure,
  DeleteAction,
  DeleteActionFailure,
  DeleteActionSuccess, RecoveryAction, RecoveryActionSuccess, RecoveryActionFailure
} from './course.actions';
import {SearchAction as TagSearchAction} from '@core/redux/tag/tag.actions';
import {map, exhaustMap, catchError, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/index';
import {CourseQueryModel, PostCourseModel, PutCourseModel} from '@core/models';
import {ApiCourseService} from '@core/services/entities/course/api-course.service';

@Injectable()
export class CourseEffects {
  constructor(private actions$: Actions,
              private apiCourseService: ApiCourseService) {
  }

  @Effect()
  archive$ = this.actions$.pipe(
    ofType(CourseActionTypes.DeleteAction),
    map((action: DeleteAction) => action.payload),
    exhaustMap((id) => {
        return this.apiCourseService
          .delete(id)
          .pipe(
            map(() => new DeleteActionSuccess(id)),
            catchError((errors) => of(new DeleteActionFailure(errors)))
          );
      }
    )
  );

  @Effect()
  checkResult$ = this.actions$.pipe(
    ofType(CourseActionTypes.CheckResultAction),
    exhaustMap((action: CheckResultAction) => {
      return this.apiCourseService
        .checkResult(action.courseId, action.lessonId)
        .pipe(
          map(course => new UpdateActionSuccess(course)),
          catchError((errors) => of(new UpdateActionFailure(errors)))
        );
    })
  );

  @Effect()
  search$ = this.actions$.pipe(
    ofType(CourseActionTypes.SearchAction),
    map((action: SearchAction) => action.payload),
    exhaustMap((query: CourseQueryModel) => {
        return this.apiCourseService
          .search(query)
          .pipe(
            map((response) => new SearchSuccessAction(response)),
            catchError((errors) => of(new SearchFailureAction(errors)))
          );
      }
    )
  );

  @Effect()
  view$ = this.actions$.pipe(
    ofType(CourseActionTypes.ViewAction),
    map((action: ViewAction) => action.payload),
    exhaustMap((id: string) => {
        return this.apiCourseService
          .view(id)
          .pipe(
            map((response) => new ViewActionSuccess(response)),
            catchError((errors) => of(new ViewActionFailure(errors)))
          );
      }
    )
  );

  @Effect()
  update$ = this.actions$.pipe(
    ofType(CourseActionTypes.UpdateAction),
    map((action: UpdateAction) => action.payload),
    exhaustMap((model: PutCourseModel) => {
        return this.apiCourseService
          .update(model)
          .pipe(
            switchMap((response) => [
              new UpdateActionSuccess(response),
              new TagSearchAction(),
            ]),
            catchError((errors) => of(new UpdateActionFailure(errors)))
          );
      }
    )
  );

  @Effect()
  changeCategory$ = this.actions$.pipe(
    ofType(CourseActionTypes.ChangeCategoryAction),
    map((action: ChangeCategoryAction) => action.payload),
    exhaustMap((payload) => {
        return this.apiCourseService
          .changeCategory(payload.ids, payload.categoryId)
          .pipe(
            map((response) => new ChangeCategoryActionSuccess(response)),
            catchError((errors) => of(new ChangeCategoryActionFailure(errors)))
          );
      }
    )
  );

  @Effect()
  create$ = this.actions$.pipe(
    ofType(CourseActionTypes.CreateAction),
    map((action: CreateAction) => action.payload),
    exhaustMap((model: PostCourseModel) => {
        return this.apiCourseService
          .create(model)
          .pipe(
            switchMap((response) => [
              new CreateActionSuccess(response),
              new TagSearchAction(),
            ]),
            catchError((errors) => of(new CreateActionFailure(errors)))
          );
      }
    )
  );

  @Effect()
  recovery$ = this.actions$.pipe(
    ofType(CourseActionTypes.RecoveryAction),
    map((action: RecoveryAction) => action.payload),
    exhaustMap((id) => {
        return this.apiCourseService
          .recovery(id)
          .pipe(
            map(() => new RecoveryActionSuccess(id)),
            catchError((errors) => of(new RecoveryActionFailure(errors)))
          );
      }
    )
  );
}
