import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output, QueryList,
  ViewChild
} from '@angular/core';
import {CategoryModel, CategorySortModel, CategoryTypes} from '@core/models/category.model';
import {TreeItemInterface} from '@core/interfaces/tree-item-interface.model';
import {Store} from '@ngrx/store';
import {TreeStateInterface} from '@core/interfaces/tree-state-interface.model';
import {Subject} from 'rxjs/Subject';
import {Subscription} from 'rxjs/Subscription';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import * as fromRoot from '@core/redux/index';
import {CategoryService} from '@core/services/entities/category/category.service';
import {UiPopupCategoryComponent} from '@ui/modules/ui-category/components/ui-popup-category/ui-popup-category.component';
import {KeyCode} from '@core/enums/keycode.enum';

declare var $: any;

interface PopupPositionsInterface {
  'top.px': number | null;
  'bottom.px': number | null;
}

/**
 * Высота одной папки
 */
const treeFolderHeight = 36;

/**
 * Дополнительное расстояние при включенном поиске
 */
const searchOffset = 36;

@Component({
  selector: 'ui-tree',
  templateUrl: './ui-tree.component.html',
  styleUrls: ['./ui-tree.component.less']
})
export class UiTreeComponent implements OnInit, OnDestroy, AfterViewInit {
  private _selectedId: string;
  @ViewChild('inputSearch', { static: false }) inputSearch: ElementRef;
  @Input() isAllowEdit = true;
  @Input() skipId: string;
  @Input() type: CategoryTypes = 'wiki';
  @Input() isEditAccess = false;
  @Input() turnSearch = false;
  @Input() searchPlaceholder = 'Поиск';
  @Input() allowHierarchy = true;
  @Input() keyboardSupport = false;

  @Input() set selectedId(value: string) {
    this._selectedId = value;
    this._selectItem();
  }

  @Output() changeValue: EventEmitter<string> = new EventEmitter();
  private _jqueryTreeElement;
  private _categories: CategoryModel[] = [];
  private _subscriptions$: Subscription = new Subscription();

  @ViewChild(UiPopupCategoryComponent, { static: false })
  form: UiPopupCategoryComponent;

  popupPosition: PopupPositionsInterface = {
    'bottom.px': null,
    'top.px': 300
  };
  treeFilterHtmlId: string;
  treeHtmlId: string;
  search = '';
  searchSubject: Subject<string> = new Subject<string>();
  isShowArrowPopup = true;
  popupArrowPosition = 120;
  isOpenPopup = false;
  activeOptionIndex = -1;

  @Input()
  set categories(value: CategoryModel[]) {
    this._categories = value;
    this._updateTree(value);
  }

  get categories(): CategoryModel[] {
    return this._categories;
  }

  private timer: NodeJS.Timer;

  constructor(private store: Store<fromRoot.State>,
              private categoryService: CategoryService,
              private zone: NgZone) {
    this.treeHtmlId = 'jqtree__tree_' + Math.random().toString(36).substr(2, 9);
    this.treeFilterHtmlId = 'jqtree__tree_filter_' + Math.random().toString(36).substr(2, 9);
  }

  @HostListener('document:keydown', ['$event'])
  onKeydown(e: KeyboardEvent) {
    if (this.keyboardSupport) {
      const keyCode = e.key as KeyCode;
      const activeOptionElement = this._activateOption(e, keyCode);
      if (keyCode === KeyCode.Enter) {
        e.preventDefault();
        e.stopPropagation();
        this.changeValue.emit(activeOptionElement.id);
      }
    }
  }

  ngOnInit() {
    if (this.turnSearch) {
      this._subscriptions$.add(this
        .searchSubject
        .debounceTime(50)
        .distinctUntilChanged()
        .subscribe((search) => {
          if (this._jqueryTreeElement) {
            this._jqueryTreeElement.tree('setOption', 'autoOpen', true);
            this.search = search;
            this._jqueryTreeElement.tree('loadData', this._normalize(this.categories));
          }
        }));
    }
  }

  ngAfterViewInit() {
    this._jqueryTreeElement = $(`#${this.treeHtmlId}`);
    this._initTree();
    setTimeout(() => {
      if (this.inputSearch) {
        this.inputSearch.nativeElement.focus();
      }
    });
  }

  ngOnDestroy() {
    this._unbindEditClick();
    this._jqueryTreeElement.tree('destroy');
    this._subscriptions$.unsubscribe();
    clearTimeout(this.timer);
  }

  onSearchChange(text: string) {
    this.searchSubject.next(text);
  }


  private _initTree() {
    const tree = this._jqueryTreeElement;
    let filtering = false;

    this
      ._jqueryTreeElement
      .tree({
        data: this._normalize(this.categories),
        autoOpen: false,
        dragAndDrop: this.isAllowEdit,
        onCreateLi: (node, $li) => {

          let buttons = '';
          if (this.isAllowEdit) {
            buttons += '<span class="jqtree__edit" category-id="' + node.id + '"></span>';
          }
          $li.find('.jqtree-element').append(
            buttons
            // +
            // '<span class="jqtree__count">' + node.count + '</span>'
          );

          if (this.turnSearch) {
            const title = $li.find('.jqtree-title');
            const search = this.search.toLowerCase();
            const value = title.text().toLowerCase();

            if (search !== '') {
              $li.hide();
              if (value.indexOf(search) > -1) {
                $li.show();
                let parent = node.parent;
                while (typeof (parent.element) !== 'undefined') {
                  $(parent.element)
                    .show()
                    .addClass('jqtree-filtered');
                  parent = parent.parent;
                }
              }
              if (!filtering) {
                filtering = true;
              }
              if (!tree.hasClass('jqtree-filtering')) {
                tree.addClass('jqtree-filtering');
              }
            } else {
              if (filtering) {
                filtering = false;
              }
              if (tree.hasClass('jqtree-filtering')) {
                tree.removeClass('jqtree-filtering');
              }
            }
          }
        },
        onCanMoveTo: (moved_node, target_node, position) => {
          return this.allowHierarchy || position !== 'inside';
        }
      });


    this._jqueryTreeElement
      .on('tree.move', (event) => {
        setTimeout(_ => {
          const node = event.move_info.moved_node;

          const model: CategorySortModel = {
            id: node.id,
            nextId: null,
            prevId: null,
            rootId: null,
          };

          if (!node.is_open) {
            if (node.getNextNode()) {
              model.nextId = node.getNextNode().id;
            }
          } else {
            let nextNode = node.getNextNode();
            while (nextNode) {
              if (nextNode && nextNode.parent && nextNode.parent.id === node.id) {
                nextNode = nextNode.getNextNode();
                continue;
              }
              model.nextId = nextNode.id;
              break;
            }
          }

          if (node.parent.id) {
            model.rootId = node.parent.id;
          }

          if (node.getPreviousNode()) {
            model.prevId = node.getPreviousNode().id;
          }

          this.categoryService.sort(model);
        }, 100);
      })
      .on('tree.click', (event) => {
        if (!event.click_event.target.classList.contains('jqtree__edit')) {
          this.changeValue.emit(event.node.id);
        }
      });

    this._selectItem();
    this._bindEditClick();
  }

  onSetPosition(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.classList.contains('jqtree__edit')) {
      return;
    }
    const tree = document.getElementById(this.treeHtmlId);
    const editButtons = tree.getElementsByClassName('jqtree__edit');
    let indexOfTarget = 0;
    let hiddenButtonsCount = 0;
    for (let i = 0; i < editButtons.length; i++) {
      // рассматриваем именно родителя потому,
      // что сама кнопка не видима без ховера
      const isVisible = (<HTMLElement>editButtons[i]).parentElement.offsetParent;
      if (!isVisible) {
        hiddenButtonsCount++;
        continue;
      }
      if (editButtons[i] === target) {
        indexOfTarget = i - hiddenButtonsCount;
        break;
      }
    }
    let offset = 0;
    if (this.turnSearch) {
      offset += searchOffset;
      const lists = tree.getElementsByTagName('ul');
      offset += lists[0].scrollTop;
    }
    const y = treeFolderHeight * indexOfTarget - offset;
    this.popupPosition = {
      'top.px': y,
      'bottom.px': null
    };
    this.popupArrowPosition = 120;
  }

  onOpenCreateForm() {
    this.popupArrowPosition = 238;
    this.onOpenPopupForm();
    this.popupPosition = {
      'top.px': null,
      'bottom.px': 0
    };
    this.categoryService.selectUpdate(null);
  }

  onOpenPopupForm() {
    this.isOpenPopup = true;
  }

  onClosePopupForm() {
    this.isOpenPopup = false;
  }

  onCreateCategory() {
    const model = new CategoryModel();
    model.name = this.search;
    model.type = this.type;
    model.accessLevel = 'all';
    model.rootId = 0;
    model.accessDepartmentIds = [];
    this.categoryService.create(model);
  }

  private _normalize(categories: CategoryModel[]) {
    const result: TreeItemInterface[] = [];

    categories.forEach((category) => {
      if (category.id !== this.skipId) {
        result.push({
          id: category.id,
          label: category.name,
          count: 0,
          children: [],
          rootId: category.rootId
        });
      }
    });

    const roots = [], children = {};

    for (let i = 0, len = result.length; i < len; ++i) {
      const item = result[i];
      if (item.id !== this.skipId) {
        const rootId = item.rootId;
        const target = !rootId ? roots : (children[rootId] || (children[rootId] = []));

        target.push(item);
      }
    }

    const findChildren = function (parent) {
      if (children[parent.id]) {
        parent.children = children[parent.id];
        for (let i = 0, len = parent.children.length; i < len; ++i) {
          findChildren(parent.children[i]);
        }
      }
    };

    for (let i = 0, len = roots.length; i < len; ++i) {
      findChildren(roots[i]);
    }

    return roots;
  }

  private _selectItem() {
    if (!this._jqueryTreeElement) {
      return;
    }
    this.zone.runOutsideAngular(() => {
      this.timer = setTimeout(() => {
        if (this._selectedId) {
          const node = this._jqueryTreeElement.tree('getNodeById', this._selectedId);
          if (!node) {
            return;
          }
          if (node.element.classList.contains('jqtree-selected')) {
            return;
          }
          this._jqueryTreeElement.tree('selectNode', node);
        } else {
          this._jqueryTreeElement.tree('selectNode', null);
        }
      }, 500);
    });
  }

  private _unbindEditClick() {
    this
      ._jqueryTreeElement
      .find(`.jqtree__edit`)
      .unbind('click');
  }

  private _bindEditClick() {
    this.zone.runOutsideAngular(() => {
      this
        ._jqueryTreeElement
        .find(`.jqtree__edit`)
        .bind('click', (event) => {
          const categoryId: string = $(event.target).attr('category-id');
          this.categoryService.selectUpdate(categoryId);
          this.onOpenPopupForm();
        });
    });
  }

  private _updateTree(categories: CategoryModel[]) {
    if (this._jqueryTreeElement) {
      this._unbindEditClick();
      this.zone.runOutsideAngular(() => {
        const treeState: TreeStateInterface = this._jqueryTreeElement.tree('getState');
        this._jqueryTreeElement.tree('loadData', this._normalize(categories));
        this._jqueryTreeElement.tree('setState', treeState);
      });

      this._bindEditClick();
    }
  }

  private _activateOption(e: KeyboardEvent, keyCode: KeyCode) {
    const options = this._jqueryTreeElement.tree('getTree').children as QueryList<ElementRef>;
    const maxOptionIndex = options.length - 1;
    if (keyCode === KeyCode.ArrowUp) {
      e.preventDefault();
      this.activeOptionIndex--;

      if (this.activeOptionIndex < 0) {
        this.activeOptionIndex = 0;
      }
    }
    if (keyCode === KeyCode.ArrowDown) {
      e.preventDefault();
      this.activeOptionIndex++;

      if (this.activeOptionIndex > maxOptionIndex) {
        this.activeOptionIndex = maxOptionIndex;
      }
    }
    let activeOption: any;
    options.forEach((option: any, index: number) => {
      const selectOptionElement = option.element as HTMLElement;
      if (index === this.activeOptionIndex) {
        selectOptionElement.classList.add('jqtree-element_active');
        activeOption = option;

        selectOptionElement.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
      } else {
        selectOptionElement.classList.remove('jqtree-element_active');
      }
    });

    return activeOption;
  }
}
