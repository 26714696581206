<ng-container [ngSwitch]="isPreviewMode">
  <div class="ck-content content-text content-text_readonly" *ngSwitchCase="true">
    <div class="content-text content-text_readonly" [innerHTML]="previewContent | safeHtml"></div>
    <div class="content-text content-text_readonly" *ngIf="isDeployed" [innerHTML]="spoilerContent | safeHtml"></div>
    <ui-files *ngIf="files.length" [files]="files"></ui-files>
    <ui-collapser *ngIf="spoilerContent.length" (changeState)="onChangeDeployed($event)"></ui-collapser>
  </div>
  <div class="ck-content content-text content-text_readonly" *ngSwitchCase="false">
    <div class="content-text content-text_readonly" [innerHTML]="content | safeHtml"></div>
  </div>
</ng-container>
