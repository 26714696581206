import { BaseModel } from './base.model';
import { FileModel } from '@core/models/file.model';
import { ContactModel } from './contact.model';

export type UserId = string;

export class UserModel extends BaseModel {
  id: UserId;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  middleName: string;
  avatarId: string;
  avatar: FileModel;
  birthday: string;
  colorCssClass: string;
  floor: 'man' | 'woman';
  karma: number;
}

export interface ReferralModel {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  isTest: boolean;
  isActive: boolean;
  countUser: number;
  countCompanies: number;
  countWiki: number;
  countTest: number;
  countCourse: number;
  lastActive: string;
  sumReferralFees: number;
  createdAt: string;
  phone: string;
}

export interface PutUserCompany {
  mcComapnyName: string;
  mcFieldOfActivityId: string;
  mcAbout: string;
  mcAnnualTurnover: string;
  mcPosition: string;
}

export interface PutUserCompanyContacts {
  mcSite: string;
  mcAdditionalContacts: ContactModel[];
  mcEmail: string;
}

export function getEmptyUser(): UserModel {
  return {
    avatar: {
      companyId: '',
      mimeType: '',
      file: '',
      id: '',
      isImage: true,
      originalName: '',
      link: '',
      absoluteUrl: '',
      setAttributes: () => undefined,
      getQuery: () => '',
    },
    avatarId: '',
    colorCssClass: '',
    id: '',
    email: '',
    firstName: '',
    floor: 'man',
    lastName: '',
    middleName: '',
    phone: '',
    birthday: '2018-12-12',
    setAttributes: () => undefined,
    getQuery: () => undefined,
    karma: 0
  };
}
