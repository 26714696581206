import { Component, EventEmitter, Input, Output, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { CompanyModel } from '@core/models/company.model';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'ui-header-burger-menu',
  templateUrl: 'ui-header-burger-menu.component.html',
  styleUrls: ['ui-header-burger-menu.component.less']
})
export class UiHeaderBurgerMenuComponent implements OnInit, OnDestroy {
  @Input() company;
  @Input() companies;
  @Output() onOpenCompany = new EventEmitter<string>();
  @ViewChild('menu', { static: false }) menuTemplate: TemplateRef<any>;

  readonly openTrigger = new Subject();
  readonly closeTrigger = new Subject();

  private readonly subscription = new Subscription();
  private dialogRef: MatDialogRef<any>;

  constructor(
    private router: Router,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.subscribeToRouter();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onOpenPopup() {
    this.dialogRef = this.dialog.open(this.menuTemplate, {
      width: '280px',
      height: '100%',
      closeOnNavigation: true,
      position: {
        left: '0'
      },
    });
  }

  onOpenCompanyList() {
    this.openTrigger.next();
  }

  onCloseCompanyList() {
    this.closeTrigger.next();
  }

  onClosePopup() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this.closeTrigger.next();
  }

  selectCompany(item: CompanyModel) {
    this.onOpenCompany.emit(item.id);
    this.onClosePopup();
  }

  private subscribeToRouter() {
    this.subscription.add(this.router
      .events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => this.onClosePopup())
    );
  }
}
