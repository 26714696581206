import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs/Subscription';
import { IdentityModel } from '@core/models/identity.model';
import { CompanyModel } from '@core/models/company.model';
import * as fromRoot from '@core/redux';
import { ChatraService } from '@core/services/chatra.service';
import { filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { OpenUserMenu } from '@core/redux/ui/ui.actions';

@Component({
  selector: 'ui-user-menu',
  templateUrl: './ui-user-menu.component.html',
  styleUrls: ['./ui-user-menu.component.less']
})
export class UiUserMenuComponent implements OnInit, OnDestroy {
  readonly openTrigger = new Subject();
  readonly closeTrigger = new Subject();
  readonly matDialogConfig: MatDialogConfig<any> = {
    width: '288px',
    minHeight: '100vh',
    closeOnNavigation: true,
    position: {
      right: '0',
      top: '0'
    }
  };

  user: IdentityModel;
  company: CompanyModel;
  private _subscriptions$: Subscription = new Subscription();

  constructor(
    private store: Store<fromRoot.State>,
    private chatraService: ChatraService,) {

    this._subscriptions$.add(store
      .pipe(select(fromRoot.getCompanyStart))
      .subscribe((company: CompanyModel) => {
        this.company = company;
      }));
  }


  ngOnInit() {
    this._subscriptions$.add(this
      .store
      .pipe(
        select(fromRoot.getAuthUser),
      )
      .subscribe((user) => {
        this.user = user;
        if (user) {
          this.chatraService.setData(user);
        }
      }));
  }

  ngOnDestroy() {
    this._subscriptions$.unsubscribe();
  }

  onOpenUserMenu() {
    this.openTrigger.next();
    this.store.dispatch(new OpenUserMenu());
  }
}
