<div
  [class.ui-files__images_in-comments]="inComments"
  class="ui-files__images">
  <div
    *ngFor="let item of imagesForView; trackBy: trackByFn; let index = index"
    [class.ui-files__image_focused]="index === 0 && focusFirstImage"
    class="ui-files__image">
    <a target="_blank"
       [href]="item.absoluteUrl">
       <img *ngIf="!(index === 0 && focusFirstImage)" [src]="item | imagePreview:100:100:'view-public-image/'">
       <img *ngIf="index === 0 && focusFirstImage" [src]="item | imagePreview:undefined:undefined:'view-public-image/'">
    </a>
    <span *ngIf="!isReadOnly" (click)="onDelete(item)"><i class="icon-trash"></i></span>
  </div>
</div>

<div class="ui-files__files">
  <div class="ui-files__file" *ngFor="let item of filesForView">
    <a [href]="item.absoluteUrl" target="_blank"><i class="icon-document"></i>{{item.originalName}}</a>
    <span *ngIf="!isReadOnly" (click)="onDelete(item)" class="ui-files__file-delete"><i class="icon-trash"></i></span>
  </div>
</div>
