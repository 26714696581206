export function getSearchQuerys(model): string[] {
  const keys = Object.keys(model).sort();
  const params = [];
  keys.forEach(el => {
    if (Array.isArray(model[el])) {
      params.push(`${el}=${model[el].join(',')}`);
    } else if (el && model[el] !== undefined) {
      params.push(`${el}=${encodeURI(model[el])}`);
    }
  });
  return params;
}
