import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '@core/services/entities/auth/auth.service';
import {Subscription} from 'rxjs/Subscription';
import {filter, pluck} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '@core/redux';
import {CompanyModel} from '@core/models/company.model';
import {ActivatedRoute, Router} from '@angular/router';
import {CompanyService} from '@core/services/entities/company/company.service';
import {AmplitudeService} from '@core/services/amplitude.service';

@Component({
  selector: 'ui-header',
  templateUrl: './ui-header.component.html',
  styleUrls: ['./ui-header.component.less']
})
export class UiHeaderComponent implements OnInit, OnDestroy {
  private readonly _subscriptions$: Subscription = new Subscription();

  @Input() isCabinet = false;
  @Input() isLogo = true;
  logo: string;
  companies: CompanyModel[] = [];
  company: CompanyModel;
  isOpenPopup = false;

  constructor(private _authService: AuthService,
              private store: Store<fromRoot.State>,
              private amplitudeService: AmplitudeService,
              private _route: ActivatedRoute,
              private _router: Router,
              private _companyService: CompanyService) {
  }

  ngOnInit() {
    this._subscriptions$.add(this
      ._authService
      .getIsAuth()
      .pipe(
        filter(_ => !!_)
      )
      .subscribe(_ => this._companyService.fetchAll())
    );
    this._subscriptionUser();
    this._subscribeRoute();
  }

  ngOnDestroy() {
    this._subscriptions$.unsubscribe();
  }

  onTogglePopup() {
    this.isOpenPopup = !this.isOpenPopup;
  }

  onClosePopup() {
    this.isOpenPopup = false;
  }

  onOpenCompany(companyId: string) {
    this._router.navigate(['/', companyId, 'wiki']);
  }

  private _subscriptionUser() {
    this._subscriptions$.add(this
      ._authService
      .getUser()
      .pipe(
        filter(_ => !!_)
      )
      .subscribe(_ => this.logo = (_.logo || 'MyBase'))
    );
  }

  private _subscribeRoute() {
    this._subscriptions$.add(this
      ._route
      .params
      .pipe(
        pluck('company')
      )
      .subscribe(response => {
        if (response) {
          this._subscribeCompany(response);
        }
      })
    );
  }

  private _subscribeCompany(companyId: string) {
    this._subscriptions$.add(this
      .store
      .pipe(select(fromRoot.getCompanyEntities))
      .subscribe((companies) => {
        this.companies = companies.filter((item: CompanyModel) => !item.isArchive && !item.inviteAccessInfo.isLeft && item.inviteAccessInfo.isConfirmedUser && item.inviteAccessInfo.isConfirmedAdmin);
        this.company = this.companies.find((item: CompanyModel) => item.id === companyId);
        if(this.company){
          this.amplitudeService.logEvent(`[Открыл БЗ] ${this.company.name} `);
        }
      }));
  }
}
