import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { UiCategoryBlockModule } from '@ui/modules/ui-category-block/ui-category-block.module';
import { UiCategoryModule } from '@ui/modules/ui-category/ui-category.module';
import { UiContainerModule } from '@ui/modules/ui-container/ui-container.module';
import { WikiCategoriesComponent } from './wiki-categories.component';
import {UiTooltipModule} from '@ui/modules/ui-tooltip/ui-tooltip.module';
import { TooltipModule } from '@ui/modules/tooltip/tooltip.module';


@NgModule({
  declarations: [
    WikiCategoriesComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    UiCategoryBlockModule,
    UiCategoryModule,
    UiContainerModule,
    UiTooltipModule,
    TooltipModule,
  ],
  exports: [
    WikiCategoriesComponent
  ],
})
export class WikiCategoriesModule { }
