import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/entities/api.service';
import { UserId, KarmaModel, PostKarmaModel } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class ApiKarmaService extends ApiService {

  delete(id: string) {
    return this.http.delete(this.getApiUrl('karma/' + id));
  }

  get(userId: UserId) {
    return this.http.get<KarmaModel[]>(this.getApiUrl('karma?userId=' + userId));
  }

  post(newKarma: PostKarmaModel) {
    return this.http.post<KarmaModel>(this.getApiUrl('karma'), newKarma);
  }

}
