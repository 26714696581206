import { Injectable } from '@angular/core';
import { WikiModel, WikiQueryModel, WikiSortInterface } from '@core/models/wiki.model';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { CategoryModel } from '@core/models/category.model';
import * as fromRoot from '@core/redux/index';
import * as _ from 'lodash';
import 'rxjs/add/observable/combineLatest';
import 'rxjs/add/operator/map';
import { addUUID, QuestionModel, TagModel } from '@core/models';
import * as wikiActions from '@core/redux/wiki/wiki.actions';
import { WikiFavoriteInterface } from '@core/interfaces/wiki-favorite.interface';
import { filter } from 'rxjs/operators';
import { toQueryString } from '@helpers/url/query-params';

@Injectable({
  providedIn: 'root',
})
export class WikiService {
  private companyId: string;
  constructor(private store: Store<fromRoot.State>, ) {
    this.subscribeToCompany();
  }

  getLoading() {
    return this.store.pipe(select(fromRoot.getWikiLoading));
  }

  getItems(): Observable<WikiModel[]> {
    const wiki$ = this.store.pipe(select(fromRoot.getWikiEntities));
    const category$ = this.store.pipe(select(fromRoot.getCategoryEntities));
    const tag$ = this.store.pipe(select(fromRoot.getTagEntities));
    return Observable.combineLatest(
      wiki$,
      category$,
      tag$,
      (wiki: WikiModel[],
        category: CategoryModel[],
        tag: TagModel[]) => {
        wiki.forEach(item => {
          const tags = [];
          item.category = _.find(category, { id: item.categoryId });

          (item.tagIds || []).forEach((id) => {
            const tagModel = _.find(tag, { id: id });
            if (tagModel) {
              tags.push(tagModel);
            }
          });

          item.tags = tags;
        });

        return wiki;
      });
  }

  getSelected() {
    const wiki$ = this.store.pipe(select(fromRoot.getWikiSelected));
    const category$ = this.store.pipe(select(fromRoot.getCategoryEntities));
    const tag$ = this.store.pipe(select(fromRoot.getTagEntities));
    return Observable.combineLatest(
      wiki$,
      category$,
      tag$,
      (wiki: WikiModel,
        category: CategoryModel[],
        tag: TagModel[]) => {

        if (wiki && category && tag) {
          const tags = [];
          wiki.category = _.find(category, { id: wiki.categoryId });

          (wiki.tagIds || []).forEach((id) => {
            const tagModel = _.find(tag, { id: id });
            if (tagModel) {
              tags.push(tagModel);
            }
          });

          wiki.tags = tags;
        }

        return wiki;
      });
  }

  onToggleFix(model: WikiModel) {
    model.isFix = !model.isFix;
    this.update(model);
  }


  onToggleArchive(model: WikiModel) {
    model.isArchive = !model.isArchive;
    this.update(model);
  }

  markAsRead(model: WikiModel) {
    this.store.dispatch(new wikiActions.ReadAction(model));
  }

  resetStore() {
    this.store.dispatch(new wikiActions.ResetAction());
  }

  /**
   * @param query
   *
   * @param deletePrevious см. документацию к SearchAction
   */
  searchAll(query: WikiQueryModel, deletePrevious = true) {
    this.store.dispatch(new wikiActions.SearchAction(query, deletePrevious));
  }

  filter(folder: string) {
    this.store.dispatch(new wikiActions.FilterAction(folder));
  }

  fetchOne(id: string) {
    this.store.dispatch(new wikiActions.ViewAction(id));
  }

  closeView() {
    this.store.dispatch(new wikiActions.ViewCloseAction());
  }

  sort(model: WikiSortInterface) {
    this.store.dispatch(new wikiActions.SortAction(model));
  }

  selectUpdate(id: string | null) {
    this.store.dispatch(new wikiActions.SelectUpdateAction(id));
  }

  selectMultiple(isChecked: boolean, id?: string) {
    this.store.dispatch(new wikiActions.SelectMultipleAction(isChecked, id));
  }

  favorite(model: WikiFavoriteInterface) {
    this.store.dispatch(new wikiActions.FavoriteAction(model));
  }

  create(model: WikiModel) {
    const questions = model.questions
      .map<QuestionModel>(addUUID as any);
    const postModel = {
      ...model,
      questions
    };
    this.store.dispatch(new wikiActions.CreateAction(postModel as any));
  }

  update(model: WikiModel) {
    const questions = model.questions
      .map<QuestionModel>(addUUID as any);
    const putModel = {
      ...model,
      questions
    };
    this.store.dispatch(new wikiActions.UpdateAction(putModel as any));
  }

  changeCategory(model) {
    this.store.dispatch(new wikiActions.ChangeCategoryAction(model));
  }

  archive(ids: string[] | string) {
    this.store.dispatch(new wikiActions.ArchiveAction(ids));
  }

  combineTag(tagId, combineIds) {
    this.store.dispatch(new wikiActions.CombineTagAction(tagId, combineIds));
  }

  like(wikiId: string) {
    this.store.dispatch(new wikiActions.LikeAction(wikiId));
  }

  dislike(wikiId: string) {
    this.store.dispatch(new wikiActions.DislikeAction(wikiId));
  }

  /**
   * Вернет ссылку вики раздела
   * 
   * Если передана строка, то вернет ссылку на статью с данным айди.
   * Если передан объект, то вернет ссылку на список статей с данным фильтром.
   * 
   * @param filter
   */
  getLink(filter: undefined | string | { [key: string]: string }): string {
    if (!filter) {
      return `/${this.companyId}/wiki`;
    }
    if (typeof filter === 'string') {
      filter = { id: filter };
    }
    return `/${this.companyId}/wiki/active?${toQueryString(filter)}`;
  }

  private subscribeToCompany() {
    this
      .store
      .pipe(
        select(fromRoot.getCompanyCurrentId),
      )
      .subscribe((companyId) => {
        this.companyId = companyId;
      });
  }
}
