import { Component, ElementRef, Input, OnInit, Renderer2, HostListener } from '@angular/core';
import { IdentityModel } from '@core/models/identity.model';
import { UserModel } from '@core/models/user.model';
import { ImagePreviewPipe } from '@pipes/image-preview/image-preview.pipe';
import { InviteModel } from '@core/models/invite.model';

@Component({
  selector: 'ui-avatar',
  templateUrl: './ui-avatar.component.html',
  styleUrls: ['./ui-avatar.component.less']
})
export class UiAvatarComponent implements OnInit {

  private _user: IdentityModel | UserModel;
  shortName: string;
  image: string;

  hovered = false;
  shouldOpenDetails = false;
  mouseenterTimeout = undefined;
  mouseleaveTimeout = undefined;
  msToWait = 200;

  @Input()
  userDetails = false;

  /**
   * Необходимо установить, если userDetails == true
   */
  @Input()
  invite: InviteModel;

  @Input()
  set user(value: IdentityModel | UserModel) {
    this._user = value;
    this._setShortName();
    this._setAvatarUrl();
    this._setCssClass();
  }

  get user(): IdentityModel | UserModel {
    return this._user;
  }

  constructor(private element: ElementRef,
    private renderer: Renderer2,
    private imagePreviewPipe: ImagePreviewPipe) {
  }

  ngOnInit() {
  }

  @HostListener('mouseenter')
  openPopup() {
    this.hovered = true;
    this.mouseenterTimeout = setTimeout(() => this.shouldOpenDetails = true, this.msToWait);
    clearTimeout(this.mouseleaveTimeout);
  }

  @HostListener('mouseleave')
  closePopup() {
    this.hovered = false;
    clearTimeout(this.mouseenterTimeout);
    this.mouseleaveTimeout = setTimeout(() => this.shouldOpenDetails = false, this.msToWait);
  }

  private _setAvatarUrl() {
    if (this._user && this._user.avatar) {
      this.image = this
        .imagePreviewPipe
        .transform(this._user.avatar,
          150,
          150,
          'view-avatar/');
    } else {
      this.image = null;
    }
  }

  private _setShortName() {
    if (!this._user) {
      this.shortName = '@';
    } else if (this._user.firstName && this._user.lastName) {
      this.shortName = this._user.firstName[0] + this._user.lastName[0];
    } else if (this._user.firstName) {
      this.shortName = this._user.firstName[0];
    } else if (this._user.email) {
      this.shortName = this._user.email[0];
    }
  }

  private _setCssClass() {
    const css = this.image ? 'avatar_image' : this.user.colorCssClass;
    this.renderer.addClass(this.element.nativeElement, css);
  }
}
