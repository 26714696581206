import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiFastSearchComponent } from '@ui/modules/ui-fast-search/ui-fast-search.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    UiFastSearchComponent
  ],
  exports: [
    UiFastSearchComponent
  ],
  imports: [
    CommonModule,
    ClickOutsideModule,
    FormsModule,
  ]
})
export class UiFastSearchModule {
}
