import { Action } from '@ngrx/store';
import { PostCommentModel, CommentModel, ObjectType, UserModel, PutCommentModel, CommentQueryModel } from '@core/models';


export enum CommentActionTypes {
  CreateAction = '[Comment] Create',
  CreateActionSuccess = '[Comment] Create Success',
  CreateActionFailure = '[Comment] Create Failure',

  DeleteAction = '[Comment] Delete',
  DeleteActionSuccess = '[Comment] Delete Success',
  DeleteActionFailure = '[Comment] Delete Failure',

  LikeAction = '[Comment] Like',
  LikeActionSuccess = '[Comment] Like Success',
  LikeActionFailure = '[Comment] Like Failure',

  DislikeAction = '[Comment] Dislike',
  DislikeActionSuccess = '[Comment] Dislike Success',
  DislikeActionFailure = '[Comment] Dislike Failure',

  EditAction = '[Comment] Edit',

  GetAction = '[Comment] Get',
  GetActionSuccess = '[Comment] Get Success',
  GetActionFailure = '[Comment] Get Failure',

  ReplyAction = '[Comment] Reply',

  ResetAction = '[Comment] Reset',

  UpdateAction = '[Comment] Update',
  UpdateActionSuccess = '[Comment] Update Success',
  UpdateActionFailure = '[Comment] Update Failure',
}

export class ResetAction implements Action {
  readonly type = CommentActionTypes.ResetAction;
}

export class CreateAction implements Action {
  readonly type = CommentActionTypes.CreateAction;

  constructor(
    public readonly comment: PostCommentModel,
  ) {
  }
}

export class CreateActionSuccess implements Action {
  readonly type = CommentActionTypes.CreateActionSuccess;

  constructor(
    public readonly objectType: ObjectType,
    public readonly objectId: string,
    public readonly comment: CommentModel,
  ) {
  }
}

export class CreateActionFailure implements Action {
  readonly type = CommentActionTypes.CreateActionFailure;

  constructor(public readonly error: any) {
  }
}

export class DeleteAction implements Action {
  readonly type = CommentActionTypes.DeleteAction;

  constructor(
    public readonly objectType: ObjectType,
    public readonly objectId: string,
    public readonly commentId: string
  ) {
  }
}

export class DeleteActionSuccess implements Action {
  readonly type = CommentActionTypes.DeleteActionSuccess;

  constructor(
    public readonly objectType: ObjectType,
    public readonly objectId: string,
    public readonly commentId: string
  ) {
  }
}

export class DeleteActionFailure implements Action {
  readonly type = CommentActionTypes.DeleteActionFailure;

  constructor(public readonly error: any) {
  }
}

export class EditAction implements Action {
  readonly type = CommentActionTypes.EditAction;

  constructor(
    public readonly comment: CommentModel,
  ) {
  }
}

export class LikeAction implements Action {
  readonly type = CommentActionTypes.LikeAction;

  constructor(
    public readonly commentId: string,
  ) {
  }
}

export class LikeActionSuccess implements Action {
  readonly type = CommentActionTypes.LikeActionSuccess;

  constructor(
    public readonly comment: CommentModel,
  ) {
  }
}

export class LikeActionFailure implements Action {
  readonly type = CommentActionTypes.LikeActionFailure;

  constructor(
    public readonly errors: any,
  ) {
  }
}

export class DislikeAction implements Action {
  readonly type = CommentActionTypes.DislikeAction;

  constructor(
    public readonly commentId: string,
  ) {
  }
}

export class DislikeActionSuccess implements Action {
  readonly type = CommentActionTypes.DislikeActionSuccess;

  constructor(
    public readonly comment: CommentModel,
  ) {
  }
}

export class DislikeActionFailure implements Action {
  readonly type = CommentActionTypes.DislikeActionFailure;

  constructor(
    public readonly errors: any,
  ) {
  }
}

export class GetAction implements Action {
  readonly type = CommentActionTypes.GetAction;

  constructor(
    public readonly query: CommentQueryModel,
  ) {
  }
}

export class GetActionSuccess implements Action {
  readonly type = CommentActionTypes.GetActionSuccess;

  constructor(
    public readonly objectType: ObjectType,
    public readonly objectId: string,
    public readonly comments: CommentModel[],
  ) {
  }
}

export class GetActionFailure implements Action {
  readonly type = CommentActionTypes.GetActionFailure;

  constructor(public readonly error: any) {
  }
}

export class ReplyAction implements Action {
  readonly type = CommentActionTypes.ReplyAction;

  constructor(public readonly comment: CommentModel | undefined) {
  }
}

export class UpdateAction implements Action {
  readonly type = CommentActionTypes.UpdateAction;

  constructor(public readonly comment: PutCommentModel) {
  }
}

export class UpdateActionSuccess implements Action {
  readonly type = CommentActionTypes.UpdateActionSuccess;

  constructor(public readonly comment: CommentModel) {
  }
}

export class UpdateActionFailure implements Action {
  readonly type = CommentActionTypes.UpdateActionFailure;

  constructor(public readonly errors: any) {
  }
}


export type CommentActions
  = CreateAction
  | CreateActionSuccess
  | CreateActionFailure
  | DeleteAction
  | DeleteActionSuccess
  | DeleteActionFailure
  | LikeAction
  | LikeActionFailure
  | LikeActionSuccess
  | DislikeAction
  | DislikeActionFailure
  | DislikeActionSuccess
  | EditAction
  | GetAction
  | GetActionSuccess
  | GetActionFailure
  | ReplyAction
  | ResetAction
  | UpdateAction
  | UpdateActionSuccess
  | UpdateActionFailure
  ;
