import { Component, Input, OnChanges } from "@angular/core";

@Component({
  selector: 'ui-rating',
  templateUrl: './ui-rating.component.html',
  styleUrls: ['./ui-rating.component.less']
})
export class UiRatingComponent implements OnChanges {
  @Input()
  value: number;

  @Input()
  hasBackground = true;

  ngClass: string;

  ngOnChanges() {
    this.ngClass = this.hasBackground
      ? (this.value
        ? (this.value > 0
          ? 'ui-rating__value_positive'
          : 'ui-rating__value_negative'
        )
        // при нуле
        : ''
      )
      // не нужен фон
      : 'ui-rating__value_transparent';
  }
}
