import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiFilesComponent } from './ui-files.component';
import { ImagePreviewModule } from '@pipes/image-preview/image-preview.module';

@NgModule({
  declarations: [UiFilesComponent],
  exports: [UiFilesComponent],
  imports: [
    CommonModule,
    ImagePreviewModule
  ]
})
export class UiFilesModule {
}
