import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { go } from 'fuzzysort';
import { TagModel } from '@core/models/tag.model';
import { FilterService } from '@core/services/filter/filter.service';
import { TagService } from '@core/services/entities/tag/tag.service';

@Component({
  selector: 'ui-tag-sidebar',
  templateUrl: './ui-tag-sidebar.component.html',
  styleUrls: ['./ui-tag-sidebar.component.less']
})
export class UiTagSidebarComponent implements OnChanges, OnDestroy, OnInit {
  /**
   * Все теги пользователя
   */
  @Input()
  tags: TagModel[] = [];

  @Input() allowCrud = false;

  /**
   * Максильное кол-во тегов в свернутом виде
   */
  @Input()
  maxTags = 10;

  @ViewChild('popup', { static: false }) popupEl: ElementRef;

  /**
   * Отфильтрованные теги
   */
  filteredTags: TagModel[] = [];

  /**
   * Показываемые отфильтрованные теги с учетом ограничения по кол-ву
   */
  visibleTags: TagModel[] = [];

  /**
   * Показывать все теги?
   */
  isExpanded = false;

  /**
   * Способ сортировки
   */
  sorting: (a: TagModel, b: TagModel) => number = this.DES;

  /**
   * Поисковой запрос
   */
  query = new UntypedFormControl('');

  /**
   * Настройки поиска fuzzysort
   */
  private readonly searchSettings = { key: 'name', threshold: -500 };
  /**
   * Хранилище подписок
   */
  private readonly subscriptions = new Subscription();

  popupPosition = {
    'bottom.px': null,
    'top.px': 300
  };
  isShowArrowPopup = true;
  popupArrowPosition = 120;
  isOpenPopup = false;

  get limit(): number {
    return this.isExpanded ? Infinity : this.maxTags;
  }

  constructor(public filterService: FilterService,
    private _tagService: TagService,
    private _componentElement: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('tags' in changes) {
      this.sort(true);
      this.filter();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.subscriptions.add(this.query
      .valueChanges
      .pipe(debounceTime(50))
      .subscribe(() => {
        this.sort();
        this.filter();
      })
    );
  }

  onSetPosition(event: MouseEvent) {
    const offsetTop = this._componentElement.nativeElement.getBoundingClientRect().top
      + window.pageYOffset
      - document.documentElement.clientTop;

    const bottom = this._componentElement.nativeElement.clientHeight - (event.pageY - offsetTop) - 140;
    this.popupPosition = {
      'bottom.px': bottom < 0 ? 0 : bottom,
      'top.px': null
    };

    this.popupArrowPosition = bottom < 0 ? (bottom * -1) + 152 : 154;
  }

  onOpenPopupForm(tag: TagModel) {
    this._tagService.setUpdateId(tag.id);
    this.isOpenPopup = true;
  }

  onClosePopupForm() {
    this._tagService.setUpdateId(undefined);
    this.isOpenPopup = false;
  }

  /**
   * Функция сортировки по возрастанию
   */
  ASC(a: TagModel, b: TagModel) {
    const diff = a.countData - b.countData;
    if (diff) {
      return diff;
    }
    if (a.name > b.name) {
      return 1;
    } else if (a.name === b.name) {
      return 0;
    }
    return -1;
  }

  /**
   * Функция сортировки по убыванию
   */
  DES(a: TagModel, b: TagModel) {
    const diff = b.countData - a.countData;
    if (diff) {
      return diff;
    }
    if (a.name > b.name) {
      return 1;
    } else if (a.name === b.name) {
      return 0;
    }
    return -1;
  }

  /**
   * Раскрытие / скрытие
   */
  onToggle() {
    this.isExpanded = !this.isExpanded;
    this.visibleTags = this.filteredTags.slice(0, this.limit);
  }

  /**
   * На событии сортировки
   */
  onSort() {
    this.sorting = this.sorting !== this.DES
      ? this.DES
      : this.ASC;
    this.sort();
  }

  /**
   * Фильтрация
   */
  private filter() {
    const query = this.query.value;
    if (!query) {
      this.filteredTags = this.tags;
    } else {
      // теги, точно начинающиеся с запроса
      const startingWithQuery: TagModel[] = [];
      // остальные теги
      const notStartingWithQuery: TagModel[] = [];
      for (const tag of this.tags) {
        if (tag.name.toLowerCase().startsWith(query.toLowerCase())) {
          startingWithQuery.push(tag);
        } else {
          notStartingWithQuery.push(tag);
        }
      }
      // теги, не начинающиеся с запроса, но содержащие что-то похожее
      const contain = go(query, notStartingWithQuery, this.searchSettings)
        .map(result => result.obj);
      this.filteredTags = [...startingWithQuery, ...contain];
    }
    // обрезание до 10 если не раскрыты
    this.visibleTags = this.filteredTags.slice(0, this.limit);
  }

  /**
   * Собственно сортировка
   */
  private sort(sortAllTags = false) {
    if (sortAllTags) {
      this.tags = this.tags.sort(this.sorting.bind(this));
      return;
    }
    this.visibleTags = this.filteredTags
      .sort(this.sorting.bind(this))
      .slice(0, this.limit);
  }
}
