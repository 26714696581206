import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {TransferHttpCacheModule} from '@nguniversal/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CookieStorage} from '@core/storage/browser.storage';
import {AppStorage} from '@core/storage/universal.inject';
import {AppComponent} from './app.component';
import {AppModule} from './app.module';
import {CookieService} from 'ngx-cookie-service';

@NgModule({
  bootstrap: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({appId: 'my-app'}),
    TransferHttpCacheModule,
    AppModule,
  ],
  providers: [
    CookieService,
    {provide: AppStorage, useClass: CookieStorage},
    {provide: 'ORIGIN_URL', useValue: location.origin},
  ]
})
export class AppBrowserModule {
}
