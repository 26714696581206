import { Action } from '@ngrx/store';
import { CompanyModel } from '@core/models/company.model';
import { InviteModel } from '@core/models/invite.model';

export enum CompanyActionTypes {
  GetStartAction = '[Company] Get Start',
  GetStartSuccessAction = '[Company] Get Start Success',
  GetStartFailureAction = '[Company] Get Start Failure',

  GetCompaniesAction = '[Company] Get Companies',
  GetCompaniesSuccessAction = '[Company] Get Companies Success',
  GetCompaniesFailureAction = '[Company] Get Companies Failure',

  CreateAction = '[Company] Create Start',
  CreateSuccessAction = '[Company] Create Success',
  CreateFailureAction = '[Company] Create Failure',

  UpdateAction = '[Company] Update Start',
  UpdateSuccessAction = '[Company] Update Success',
  UpdateFailureAction = '[Company] Update Failure',

  LeftAction = '[Company] Left Start',
  LeftSuccessAction = '[Company] Left Success',
  LeftFailureAction = '[Company] Left Failure',

  ConfirmInviteUserAction = '[Company] Confirm User Invite',
  ConfirmInviteUserActionSuccess = '[Company] Confirm User Invite Success',
  ConfirmInviteUserActionFailure = '[Company] Confirm User Invite Failure',
}

export class GetStartAction implements Action {
  readonly type = CompanyActionTypes.GetStartAction;

  constructor(public companyId?: string) {
  }
}

export class GetStartSuccessAction implements Action {
  readonly type = CompanyActionTypes.GetStartSuccessAction;

  constructor(public payload: CompanyModel) {
  }
}

export class GetStartFailureAction implements Action {
  readonly type = CompanyActionTypes.GetStartFailureAction;

  constructor(public payload: any) {
  }
}


export class GetCompaniesAction implements Action {
  readonly type = CompanyActionTypes.GetCompaniesAction;

  constructor() {
  }
}

export class GetCompaniesSuccessAction implements Action {
  readonly type = CompanyActionTypes.GetCompaniesSuccessAction;

  constructor(public payload: CompanyModel[]) {
  }
}

export class GetCompaniesFailureAction implements Action {
  readonly type = CompanyActionTypes.GetCompaniesFailureAction;

  constructor(public payload: any) {
  }
}

export class CreateAction implements Action {
  readonly type = CompanyActionTypes.CreateAction;

  constructor(public payload: CompanyModel) {
  }
}

export class CreateSuccessAction implements Action {
  readonly type = CompanyActionTypes.CreateSuccessAction;

  constructor(public payload: CompanyModel) {
  }
}

export class CreateFailureAction implements Action {
  readonly type = CompanyActionTypes.CreateFailureAction;

  constructor(public payload: any) {
  }
}

export class UpdateAction implements Action {
  readonly type = CompanyActionTypes.UpdateAction;

  constructor(public payload: CompanyModel) {
  }
}

export class UpdateSuccessAction implements Action {
  readonly type = CompanyActionTypes.UpdateSuccessAction;

  constructor(public payload: CompanyModel) {
  }
}

export class UpdateFailureAction implements Action {
  readonly type = CompanyActionTypes.UpdateFailureAction;

  constructor(public payload: any) {
  }
}

export class LeftAction implements Action {
  readonly type = CompanyActionTypes.LeftAction;

  constructor(public payload: string) {
  }
}

export class LeftSuccessAction implements Action {
  readonly type = CompanyActionTypes.LeftSuccessAction;

  constructor(public payload: InviteModel) {
  }
}

export class LeftFailureAction implements Action {
  readonly type = CompanyActionTypes.LeftFailureAction;

  constructor(public payload: any) {
  }
}

export class ConfirmInviteUserAction implements Action {
  readonly type = CompanyActionTypes.ConfirmInviteUserAction;

  constructor(public payload: string) {
  }
}


export class ConfirmInviteUserActionSuccess implements Action {
  readonly type = CompanyActionTypes.ConfirmInviteUserActionSuccess;

  constructor(public payload: CompanyModel) {
  }
}

export class ConfirmInviteUserActionFailure implements Action {
  readonly type = CompanyActionTypes.ConfirmInviteUserActionFailure;

  constructor(public payload: any) {
  }
}

export type CompanyActions = GetStartAction
  | GetStartSuccessAction
  | GetStartFailureAction
  | GetCompaniesAction
  | GetCompaniesSuccessAction
  | GetCompaniesFailureAction
  | CreateAction
  | CreateSuccessAction
  | CreateFailureAction
  | UpdateAction
  | UpdateSuccessAction
  | UpdateFailureAction
  | LeftAction
  | LeftSuccessAction
  | LeftFailureAction
  | ConfirmInviteUserAction
  | ConfirmInviteUserActionSuccess
  | ConfirmInviteUserActionFailure
  ;
