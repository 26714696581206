import {AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs/Subject';
import {Subscription} from 'rxjs/Subscription';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'ui-fast-search',
  templateUrl: './ui-fast-search.component.html',
  styleUrls: ['./ui-fast-search.component.less']
})
export class UiFastSearchComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() onChanged = new EventEmitter<boolean>();
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  private _subscriptions$: Subscription = new Subscription();
  keyUp$ = new Subject<any>();
  match = '';

  constructor(private _router: Router,
              private _activeRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this._fillMatch();
    this._subscribeKeyUp();
  }

  ngAfterViewInit() {
    setTimeout(() => this.searchInput.nativeElement.focus());
  }

  ngOnDestroy() {
    this._subscriptions$.unsubscribe();
  }

  onCloseSearch(increased: any) {
    this.match = '';
    this._goFilter();
    this.onChanged.emit(increased);
  }

  private _subscribeKeyUp() {
    this._subscriptions$.add(this.keyUp$
      .pipe(
        debounceTime(200),
        distinctUntilChanged()
      ).subscribe(() => this._goFilter()));
  }

  private _goFilter() {
    this._router
      .navigate([], {
        queryParams: {
          match: this.match
        },
        relativeTo: this._activeRoute,
        queryParamsHandling: 'merge'
      });
  }

  private _fillMatch() {
    this.match = this._activeRoute.snapshot.queryParamMap.get('match') || '';
  }
}
