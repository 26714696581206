import {Action} from '@ngrx/store';
import {TagModel} from '@core/models/tag.model';

export enum TagActionTypes {
  SelectUpdateAction = '[Tag] Select Update',

  SearchAction = '[Tag] Search',
  SearchSuccessAction = '[Tag] Search Success',
  SearchFailureAction = '[Tag] Search Failure',

  UpdateAction = '[Tag] Update',
  UpdateActionSuccess = '[Tag] Update Success',
  UpdateActionFailure = '[Tag] Update Failure',

  CreateAction = '[Tag] Create',
  CreateActionSuccess = '[Tag] Create Success',
  CreateActionFailure = '[Tag] Create Failure',

  DeleteAction = '[Tag] Delete',
  DeleteActionSuccess = '[Tag] Delete Success',
  DeleteActionFailure = '[Tag] Delete Failure',

  ResetAction = '[Tag] Reset',
}

export class ResetAction implements Action {
  readonly type = TagActionTypes.ResetAction;
}

export class SearchAction implements Action {
  readonly type = TagActionTypes.SearchAction;

  constructor() {
  }
}

export class SearchSuccessAction implements Action {
  readonly type = TagActionTypes.SearchSuccessAction;

  constructor(public payload: TagModel[]) {
  }
}

export class SearchFailureAction implements Action {
  readonly type = TagActionTypes.SearchFailureAction;

  constructor(public payload: any) {
  }
}

export class SelectUpdateAction implements Action {
  readonly type = TagActionTypes.SelectUpdateAction;

  constructor(public payload: string) {
  }
}

export class UpdateAction implements Action {
  readonly type = TagActionTypes.UpdateAction;

  constructor(public payload: TagModel) {
  }
}


export class UpdateActionSuccess implements Action {
  readonly type = TagActionTypes.UpdateActionSuccess;

  constructor(public payload: TagModel) {
  }
}

export class UpdateActionFailure implements Action {
  readonly type = TagActionTypes.UpdateActionFailure;

  constructor(public payload: any) {
  }
}

export class CreateAction implements Action {
  readonly type = TagActionTypes.CreateAction;

  constructor(public payload: TagModel) {
  }
}


export class CreateActionSuccess implements Action {
  readonly type = TagActionTypes.CreateActionSuccess;

  constructor(public payload: TagModel) {
  }
}

export class CreateActionFailure implements Action {
  readonly type = TagActionTypes.CreateActionFailure;

  constructor(public payload: any) {
  }
}

export class DeleteAction implements Action {
  readonly type = TagActionTypes.DeleteAction;

  constructor(public payload: string) {
  }
}


export class DeleteActionSuccess implements Action {
  readonly type = TagActionTypes.DeleteActionSuccess;

  constructor(public payload: string) {
  }
}

export class DeleteActionFailure implements Action {
  readonly type = TagActionTypes.DeleteActionFailure;

  constructor(public payload: any) {
  }
}

export type TagActions = SearchAction
  | SearchSuccessAction
  | SearchFailureAction
  | SelectUpdateAction
  | UpdateAction
  | UpdateActionSuccess
  | UpdateActionFailure
  | CreateAction
  | CreateActionSuccess
  | CreateActionFailure
  | DeleteAction
  | DeleteActionSuccess
  | DeleteActionFailure
  | ResetAction
  ;
