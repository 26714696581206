<div class="header__burger">
  <button (click)="onOpenPopup()">
    <span class="icon-burger-menu"></span>
  </button>
</div>
<ng-template #menu>
  <div class="header__burger-wrapper">
      <div class="header__burger-menu"
          *ngIf="company">
  
      <div class="header__burger-menu-header">
        <p class="header__logo_company" *ngIf="companies.length" (click)="onOpenCompanyList()">
          {{company?.name}}
          <span *ngIf="companies.length > 1" class="icon-arrows-three header__logo_company_icon"></span>
        </p>
        <ui-adaptive-popup
          [openTrigger]="openTrigger"
          [closeTrigger]="closeTrigger"
          class="header__burger-popup">
          <div class="header__company-wrap">
            <ui-popup-menu-item
              (click)="onCloseCompanyList()"
              iconName="icon-arrow-left">
              Назад
            </ui-popup-menu-item>
            <ui-select-option *ngFor="let item of companies"
                              [class.selected]="item.id === company.id"
                              [model]="item"
                              [value]="item.id"
                              [allowEdit]="false"
                              (select)="selectCompany(item)">
              <div option-type="name" class="header__company-name">{{item.name}}</div>
            </ui-select-option>
          </div>
        </ui-adaptive-popup>
      </div>
      <div class="header__burger-menu-wrap">
        <div class="header__burger-menu-link"
              (click)="onClosePopup()"
              [routerLink]="['/', company?.id, 'wiki']"
              routerLinkActive="header__burger-menu-link_active">
          <div class="header__burger-menu-icon svg-icon-name">
            <span class="icon-base"></span>
          </div>
          База знаний
        </div>
        <div class="header__burger-menu-link"
              (click)="onClosePopup()"
              [routerLink]="['/', company?.id, 'users']"
              routerLinkActive="header__burger-menu-link_active">
          <div class="header__burger-menu-icon svg-icon-name">
            <span class="icon-team"></span>
          </div>
          Команда
        </div>
        <div class="header__burger-menu-link"
              (click)="onClosePopup()"
              [routerLink]="['/', company?.id, 'learning']"
              routerLinkActive="header__burger-menu-link_active">
          <div class="header__burger-menu-icon svg-icon-name">
            <span class="icon-hat"></span>
          </div>
          Обучение
        </div>
        <div class="header__burger-menu-link"
              (click)="onClosePopup()"
              [routerLink]="['/', company?.id, 'events']"
              routerLinkActive="header__burger-menu-link_active">
          <div class="header__burger-menu-icon svg-icon-name">
            <span class="icon-period"></span>
          </div>
          События
        </div>
      </div>
  
      <ui-header-burger-aside></ui-header-burger-aside>
    </div>
  </div>      
</ng-template>
