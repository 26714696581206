import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { UserModel } from '@core/models/user.model';
import { InviteModel } from '@core/models/invite.model';
import * as fromRoot from '@core/redux';


@Component({
  selector: 'ui-user-details',
  templateUrl: './ui-user-detaits.component.html',
  styleUrls: ['./ui-user-detaits.component.less']
})
export class UiUserDetailsComponent implements OnInit, OnDestroy {
  @Input()
  user: UserModel;
  @Input()
  invite: InviteModel;

  companyId: string;

  private readonly _subscriptions$ = new Subscription();

  constructor(private store: Store<fromRoot.State>) {
  }

  ngOnInit() {
    this.subscribeToCompany();
  }

  ngOnDestroy() {
    this._subscriptions$.unsubscribe();
  }

  private subscribeToCompany() {
    this._subscriptions$.add(this
      .store
      .pipe(
        select(fromRoot.getCompanyStart),
        filter((company) => !!company)
      )
      .subscribe((company) => {
        this.companyId = company.id;
      }));
  }
}
