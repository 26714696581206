import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiCollapserComponent} from '@ui/modules/ui-collapser/ui-collapser.component';

@NgModule({
  declarations: [
    UiCollapserComponent
  ],
  exports: [
    UiCollapserComponent
  ],
  imports: [
    CommonModule
  ]
})
export class UiCollapserModule {
}
