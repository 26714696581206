import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  UserStatusActionTypes,
  CreateAction,
  CreateActionFailure,
  CreateActionSuccess,
  SearchFailureAction,
  SearchSuccessAction,
  UpdateAction,
  UpdateActionFailure,
  UpdateActionSuccess,
  SearchAction, DeleteAction, DeleteActionSuccess, DeleteActionFailure,
} from './user-status.actions';
import {map, exhaustMap, catchError} from 'rxjs/operators';
import {of} from 'rxjs/index';
import {UserStatusModel} from '@core/models/user-status.model';
import {Store} from '@ngrx/store';
import * as fromRoot from '@core/redux/index';
import {ApiUserStatusService} from '@core/services/entities/user-status/api-user-status.service';

@Injectable()
export class UserStatusEffects {
  constructor(private actions$: Actions,
              private store: Store<fromRoot.State>,
              private apiUserStatusService: ApiUserStatusService) {
  }

  @Effect()
  search$ = this.actions$.pipe(
    ofType(UserStatusActionTypes.SearchAction),
    exhaustMap(() => {
        return this.apiUserStatusService
          .search()
          .pipe(
            map((response) => new SearchSuccessAction(response)),
            catchError((errors) => of(new SearchFailureAction(errors)))
          );
      }
    )
  );

  @Effect()
  update$ = this.actions$.pipe(
    ofType(UserStatusActionTypes.UpdateAction),
    map((action: UpdateAction) => action.payload),
    exhaustMap((model: UserStatusModel) => {
        return this.apiUserStatusService
          .update(model)
          .pipe(
            map((response) => new UpdateActionSuccess(response)),
            catchError((errors) => of(new UpdateActionFailure(errors)))
          );
      }
    )
  );

  @Effect()
  create$ = this.actions$.pipe(
    ofType(UserStatusActionTypes.CreateAction),
    map((action: CreateAction) => action.payload),
    exhaustMap((model: UserStatusModel) => {
        return this.apiUserStatusService
          .create(model)
          .pipe(
            map((response) => new CreateActionSuccess(response)),
            catchError((errors) => of(new CreateActionFailure(errors)))
          );
      }
    )
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(UserStatusActionTypes.DeleteAction),
    map((action: DeleteAction) => action.payload),
    exhaustMap((id: string) => {
        return this.apiUserStatusService
          .delete(id)
          .pipe(
            map(() => new DeleteActionSuccess(id)),
            catchError((errors) => of(new DeleteActionFailure(errors)))
          );
      }
    )
  );
}
