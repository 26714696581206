<div class="user-menu-popup__wrap user-menu-popup__wrap_type-desktop">
  <div class="user-menu-popup__popup-body">
    <ui-avatar
      *ngIf="user"
      [user]="user"
      class="avatar_middle avatar_circle">
    </ui-avatar>
    <div class="user-menu-popup__popup-info">
      <div class="user-menu-popup__popup-name">{{user?.firstName}}</div>
      <div class="user-menu-popup__popup-site" *ngIf="company">{{company.name}}</div>
      <ul class="user-menu-popup__list">
        <li class="user-menu-popup__list-item" *ngIf="company && user">
          <a class="link link_without-decoration"
             (click)="onCloseUserMenu()"
             [routerLink]="['/', company.id, 'user', user.inviteId]">Мой профиль
          </a>
        </li>
        <!--<li class="user-menu-popup__list-item"><a class="link link_without-decoration">Помощь и обучение</a></li>-->
        <!--<li class="user-menu-popup__list-item"><a class="link link_without-decoration">Профиль компании</a></li>-->
        <li class="user-menu-popup__list-item">
          <a class="link link_without-decoration"
             (click)="onCloseUserMenu()"
             routerLink="/payment">Тарифы и оплата
          </a>
        </li>
        <li class="user-menu-popup__list-item" *ngIf="moduleService.isVisiblePartnerCabinet">
          <a class="link link_without-decoration"
             (click)="onCloseUserMenu()"
             routerLink="/affiliate/balance">Партнерам
          </a>
        </li>
        <li class="user-menu-popup__list-item">
          <a class="link link_without-decoration"
             (click)="onShowChat()">Чат поддержки
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="user-menu-popup__popup-footer">
    <a class="link link_without-decoration" routerLink="/dashboard">Мои базы знаний</a>
    <a class="button button_theme-3" (click)="onSignOut()">Выйти</a>
  </div>
  <div class="user-menu-popup__popup-products">
    <div class="user-menu-popup__popup-products-header" (click)="onOpenProducts()">
      <span class="user-menu-popup__popup-products-title">Наши продукты</span>
      <span class="icon-arrow icon-arrow_top"
            [ngClass]="{'icon-arrow_bottom': isOpenProducts, 'icon-arrow_top': !isOpenProducts}"></span>
    </div>
    <div class="user-menu-popup__popup-products-list" *ngIf="isOpenProducts">
      <div class="user-menu-popup__popup-products-item">
        <div>
        <span class="user-menu-popup__popup-products-logo">
            <span>Log</span>
            <span>Time</span>
        </span>
          <span class="user-menu-popup__popup-products-description">Сервис наглядного учета событий за день для повышения осознанности и продуктивности</span>
        </div>
        <a class="button button_theme-3" href="https://logtime.ru" target="_blank">Перейти</a>
      </div>
      <div class="user-menu-popup__popup-products-item">
        <div>
        <span class="user-menu-popup__popup-products-logo">
            iTasks.pro
        </span>
          <span class="user-menu-popup__popup-products-description">Управление проектами и задачами. Помимо карточек задач, доступен табличный вид и чат</span>
        </div>
        <a class="button button_theme-3" href="https://itasks.pro" target="_blank">Перейти</a>
      </div>
    </div>
  </div>
</div>

<div class="user-menu-popup__wrap user-menu-popup__wrap_type-mobile">
  <div class="user-menu-popup__popup-body user-menu-popup__popup-body_mobile">
    <ui-avatar
      *ngIf="user"
      [user]="user"
      class="avatar_small avatar_circle">
    </ui-avatar>
    <div class="user-menu-popup__popup-info user-menu-popup__popup-info_mobile">
      <div class="user-menu-popup__popup-name">{{user?.firstName}}</div>
      <div class="user-menu-popup__popup-site user-menu-popup__popup-site_mobile"
           *ngIf="company && user"
           (click)="onCloseUserMenu()"
           [routerLink]="['/', company.id, 'user', user.inviteId, 'options']">Открыть профиль
      </div>
    </div>
    <span class="icon-close" (click)="onCloseUserMenu()"></span>
  </div>

  <div class="user-menu-popup__popup-content-link">
    <div class="user-menu-popup__popup-links-wrap">
      <div class="user-menu-popup__popup-link"
           (click)="onCloseUserMenu()"
           routerLink="/payment">
        <div class="user-menu-popup__svg-icon svg-icon-name">
          <span class="icon-red icon-wallet"></span>
        </div>
        Тарифы и оплата
      </div>
      <div class="user-menu-popup__popup-link"
           (click)="onCloseUserMenu()"
           routerLink="/affiliate/balance">
        <div class="user-menu-popup__svg-icon svg-icon-name">
          <span class="icon-company"></span>
        </div>
        Партнерам
      </div>
      <div class="user-menu-popup__popup-link" (click)="onShowChat()">
        <div class="user-menu-popup__svg-icon svg-icon-name">
          <span class="icon-gold icon-scripts"></span>
        </div>
        Чат поддержки
      </div>
      <div class="user-menu-popup__popup-link" routerLink="/dashboard">
        <div class="user-menu-popup__svg-icon svg-icon-name">
          <span class="icon-base icon-dark-gray"></span>
        </div>
        Мои базы знаний
      </div>
    </div>
  </div>

  <div class="user-menu-popup__popup-footer-mobile user-menu-popup__popup-footer-mobile_pd-24">
    <a class="button button_theme-3" (click)="onSignOut()">Выйти</a>
  </div>

</div>