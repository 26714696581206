import {getSearchQuerys} from '@core/utils/query-builder.utils';

export class BaseModel {
  setAttributes(attributes?) {
    if (attributes) {
      const keys = Object.keys(attributes);
      if (keys.length) {
        keys.forEach(el => {
          this[el] = attributes[el];
        });
      }
    }

    return this;
  }

  getQuery(): string {
    const params = this;
    return getSearchQuerys(params).join('&');
  }

}

