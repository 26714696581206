<div class="ui-select-multi-tag__wrapper">

    <div class="ui-select-multi-tag__header" (click)="onTogglePopup()">
        Выбрано {{value.length}} эл.
        <span class="ui-select-multi-tag__icon-arrow icon-arrow"></span>
    </div>
    <ui-popup *ngIf="isOpenPopup"
              [arrow]="false"
              (clickOutside)="onClosePopup()"
              [excludeBeforeClick]="true"
              [delayClickOutsideInit]="true"
              [ngStyle]="{top: '66px',left: 0,right: 0}"
              class="ui-select-multi-tag__popup">
        <div class="ui-select-multi-tag__items">
            <ui-popup-menu-item
                    (click)="onClosePopup()"
                    iconName="icon-arrow-left">
                Назад
            </ui-popup-menu-item>
            <div class="ui-select-multi-tag__item" *ngFor="let item of items">
                <label class="check-box__label">
                    <input type="checkbox"
                           [checked]="item.isChecked"
                           (change)="onChangeCheckBox(item, $event)" class="check-box__input">
                    <i class="check-box__indicator check-box__indicator_blue check-box__indicator_absolute"></i>
                    <span class="check-box__text">{{item.name}}</span>
                </label>
            </div>
        </div>
    </ui-popup>
</div>
