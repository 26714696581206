<ui-container class="delete-category__container container_pd-32-60">
  <span class="delete-category__icon-close icon-close" (click)="onClose()"></span>
  <span class="icon-big-trashcan"></span>
  <h2 class="form__title-big form__title-big_padding-top-24">Удаление раздела:</h2>
  <h3 class="form__title form__title_without-padding">{{category.name}}</h3>
  <form (ngSubmit)="onSubmit()" novalidate>
    <div class="form__row form__row_inline">
      <div class="radio radio_inline">
        <label class="radio__label radio__label_padding-left">
          <input class="radio__input" type="radio" name="move" [value]="false" [(ngModel)]="isMove">
          <i class="radio__indicator radio__indicator_blue radio__indicator_absolute"></i>
          <span class="radio__text">Отправить содержимое в корзину</span>
        </label>
      </div>
      <div class="radio radio_inline">
        <label class="radio__label radio__label_padding-left">
          <input class="radio__input" type="radio" name="move" [value]="true" [(ngModel)]="isMove">
          <i class="radio__indicator radio__indicator_blue radio__indicator_absolute"></i>
          <span class="radio__text">Перенести содержимое в раздел</span>
        </label>
      </div>
    </div>

    <div class="form__row" *ngIf="isMove">
      <ui-select-category class="wiki-form__header-select"
                          [categories]="categories"
                          [type]="category.type"
                          [skipId]="category.id"
                          [isBordered]="true"
                          (changeValue)="onChangeCategory($event)">
      </ui-select-category>
    </div>

    <div class="form__row  form__row_full-width form__row_center">
      <button class="button button_theme-6">Удалить</button>
    </div>
  </form>
</ui-container>
