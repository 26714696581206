import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import {TakeTestComponent} from './components/take-test/take-test.component';
import {TakeTestService} from './services/take-test.service';
import {TestAnswerComponent} from './components/test-answer/test-answer.component';
import {ExitConfirmationComponent} from './components/exit-confirmation/exit-confirmation.component';
import {TestFooterComponent} from './components/test-footer/test-footer.component';
import {TestQuestionComponent} from './components/test-question/test-question.component';
import {ContentViewModule} from '@ui/modules/content-view/content-view.module';

@NgModule({
  declarations: [
    TakeTestComponent,
    TestAnswerComponent,
    ExitConfirmationComponent,
    TestFooterComponent,
    TestQuestionComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    ContentViewModule
  ],
  exports: [
    TakeTestComponent,
  ],
  providers: [
    TakeTestService,
  ]
})
export class TakeTestModule {
}
