import { FileModel } from './file.model';
import { UserModel } from './user.model';
import { InviteModel } from './invite.model';
import { BaseModel } from './base.model';

/**
 * Новый коммент, отправляемый при создании
 */
export interface PostCommentModel {
  ownerId: string;
  comment: string;
  files?: FileModel[];
  rootId?: string;
  objectType: ObjectType;
  objectId: string;
}

/**
 * Коммент для обновления
 */
export interface PutCommentModel extends PostCommentModel {
  id: string;
}

export interface CommentModel extends PostCommentModel {
  id: string;
  createdAt: string;
  updatedAt: string;
  rating: number;
  rootId: string;
  files: FileModel[];
  isAllowCrud: boolean;
  isAllowDislike: boolean;
  isAllowLike: boolean;
  user?: UserModel;
  invite?: InviteModel;
  shortWikiTitle?: string;
  /**
   * id Статьи
   */
  objectId: string;
}

export class CommentQueryModel extends BaseModel {
  ownerId: string;
  objectId: string;
  objectType: ObjectType;
  expand: string[];
}

export enum ObjectType {
  Wiki = 'wiki',
}



export type ChildComment = CommentModel & { parent: CommentModel, replyTo: CommentModel };
export type ParentComment = CommentModel & { children: ChildComment[] };


export class CommentsTree implements Iterable<CommentModel> {
  private _parents: ParentComment[] = [];
  private _length = 0;

  get length(): number {
    return this._length;
  }

  get parents(): ParentComment[] {
    return this._parents;
  }

  constructor(commentsList: CommentModel[]) {
    const commentsObject = {};
    for (const comment of commentsList) {
      this._length++;
      if (!comment.rootId) {
        const parent = { ...comment, children: [] };
        this._parents.push(parent);
        commentsObject[parent.id] = parent;
      } else {
        const replyTo = commentsObject[comment.rootId];
        let parent = replyTo;
        try {
          while (parent.rootId) {
            parent = commentsObject[parent.rootId];
          }
          const child = { ...comment, parent, replyTo };
          parent.children.push(child);
          commentsObject[child.id] = child;
        } catch (e) {
          console.warn('Seems like root comment has been deleted for ', comment);
          this._length--;
        }
      }
    }
  }

  [Symbol.iterator]() {
    const length = this._parents.length;
    if (length === 0) {
      return {
        next: () => ({
          value: undefined,
          done: true
        })
      };
    }
    let index = 0;
    let childIndex = 0;
    let prevParent: ParentComment = undefined;
    return {
      next: () => {
        const parentComment = this.parents[index];
        if (prevParent != parentComment) {
          const result = {
            value: parentComment,
            done: index === length && parentComment.children.length === childIndex
          };
          prevParent = parentComment;
          return result;
        } else if (childIndex === parentComment.children.length) {
          index++;
          const result = {
            value: this.parents[index],
            done: index === length
          };
          prevParent = this.parents[index];
          childIndex = 0;
          return result;
        } else {
          const result = {
            value: parentComment.children[childIndex],
            done: index === length && childIndex + 1 === parentComment.children.length
          };
          childIndex++;
          return result;
        }
      }
    };
  }

  /**
   * Идентична одноименной функции у массивов
   * @param f функция, которую нужно применить к каждому комментарию
   */
  map(f: (comment: CommentModel) => CommentModel): CommentsTree {
    const result = [];
    for (const comment of this) {
      const mappedComment = f(comment);
      result.push(mappedComment);
    }
    return new CommentsTree(result);
  }

  find(f: (comment: CommentModel) => boolean): CommentModel | undefined {
    for (const comment of this) {
      if (f(comment)) {
        return comment;
      }
    }
    return undefined;
  }

}
