import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiHeaderAppComponent } from '@ui/modules/header/ui-header-app/ui-header-app.component';
import { UiHeaderModule } from '@ui/modules/header/ui-header/ui-header.module';
import { UiHeaderMenuModule } from '@ui/modules/header/ui-header-menu/ui-header-menu.module';
import { UiNotificationModule } from '@ui/modules/ui-notification/ui-notification.module';
import { UiFastSearchModule } from '@ui/modules/ui-fast-search/ui-fast-search.module';
import { UiUserMenuModule } from '@ui/modules/ui-user-menu/ui-user-menu.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { UiHeaderAppContainerComponent } from '@ui/modules/header/ui-header-app/ui-header-app.container';
import { UiHeaderBurgerMenuModule } from '@ui/modules/header/ui-header-burger-menu/ui-header-burger-menu.module';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { UiHeaderFilterMenuModule } from '../ui-header-filter-menu/ui-header-filter-menu.module';

@NgModule({
  declarations: [
    UiHeaderAppComponent,
    UiHeaderAppContainerComponent
  ],
  exports: [
    UiHeaderAppComponent,
    UiHeaderAppContainerComponent,
    UiHeaderFilterMenuModule,
  ],
  imports: [
    CommonModule,
    UiHeaderModule,
    UiHeaderMenuModule,
    UiHeaderBurgerMenuModule,
    UiNotificationModule,
    UiFastSearchModule,
    UiUserMenuModule,
    ClickOutsideModule,
    MatDialogModule,
  ]
})
export class UiHeaderAppModule {
}
