import { Action } from '@ngrx/store';
import { PostTestModel, PutTestModel, TestModel, TestQueryModel, TestResultsModel } from '@core/models';
import { PageResult } from '@core/models/filters/page-result';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TestResultsComponent } from '@ui/modules/test-results/test-results.component';
import { TakeTestComponent } from '@ui/modules/take-test/components/take-test/take-test.component';

export enum TestActionTypes {
  CombineTagAction = '[Test] Combine Tag',

  DeleteAction = '[Test] Delete',
  DeleteActionSuccess = '[Test] Delete Success',
  DeleteActionFailure = '[Test] Delete Failure',

  ResetAction = '[Test] Reset',

  RecoveryAction = '[Test] Recovery',
  RecoveryActionSuccess = '[Test] Recovery Success',
  RecoveryActionFailure = '[Test] Recovery Failure',

  ViewCloseAction = '[Test] View Close',

  SearchAction = '[Test] Search',
  SearchSuccessAction = '[Test] Search Success',
  SearchFailureAction = '[Test] Search Failure',

  UpdateAction = '[Test] Update',
  UpdateActionSuccess = '[Test] Update Success',
  UpdateActionFailure = '[Test] Update Failure',

  ViewAction = '[Test] View',
  ViewActionSuccess = '[Test] View Success',
  ViewActionFailure = '[Test] View Failure',

  CreateAction = '[Test] Create',
  CreateActionSuccess = '[Test] Create Success',
  CreateActionFailure = '[Test] Create Failure',

  ChangeCategoryAction = '[Test] ChangeCategory',
  ChangeCategoryActionSuccess = '[Test] ChangeCategory Success',
  ChangeCategoryActionFailure = '[Test] ChangeCategory Failure',

  StartTestAction = '[Test] Start Test',
  StartTestActionSuccess = '[Test] Start Test Success',
  FinishTestAction = '[Test] Finish',

  ShowResultsAction = '[Test] Show Results',
  ShowResultsActionSuccess = '[Test] Show Results Success',
  CloseResultsAction = '[Test] Close Results Dialog',
}

export class ResetAction implements Action {
  readonly type = TestActionTypes.ResetAction;
}

export class CombineTagAction implements Action {
  readonly type = TestActionTypes.CombineTagAction;

  constructor(public id: string,
    public combineId: string[]) {
  }
}

export class DeleteAction implements Action {
  readonly type = TestActionTypes.DeleteAction;

  constructor(public payload: string) {
  }
}


export class DeleteActionSuccess implements Action {
  readonly type = TestActionTypes.DeleteActionSuccess;

  constructor(public payload: string) {
  }
}

export class DeleteActionFailure implements Action {
  readonly type = TestActionTypes.DeleteActionFailure;

  constructor(public payload: any) {
  }
}

export class RecoveryAction implements Action {
  readonly type = TestActionTypes.RecoveryAction;

  constructor(public payload: string) {
  }
}


export class RecoveryActionSuccess implements Action {
  readonly type = TestActionTypes.RecoveryActionSuccess;

  constructor(public payload: string) {
  }
}

export class RecoveryActionFailure implements Action {
  readonly type = TestActionTypes.RecoveryActionFailure;

  constructor(public payload: any) {
  }
}

export class ViewCloseAction implements Action {
  readonly type = TestActionTypes.ViewCloseAction;

  constructor() {
  }
}


export class SearchAction implements Action {
  readonly type = TestActionTypes.SearchAction;

  /**
   * 
   * @param payload 
   * @param deletePrevious
   * Заменить предыдущие тесты новыми? Если false, то подгруженные тесты добавятся к существующим
   */
  constructor(
    public payload: TestQueryModel,
    public deletePrevious: boolean) {
  }
}

export class SearchSuccessAction implements Action {
  readonly type = TestActionTypes.SearchSuccessAction;

  constructor(
    public payload: PageResult<TestModel[]>,
    public deletePrevious: boolean) {
  }
}

export class SearchFailureAction implements Action {
  readonly type = TestActionTypes.SearchFailureAction;

  constructor(public payload: any) {
  }
}

export class UpdateAction implements Action {
  readonly type = TestActionTypes.UpdateAction;

  constructor(public payload: PutTestModel) {
  }
}


export class UpdateActionSuccess implements Action {
  readonly type = TestActionTypes.UpdateActionSuccess;

  constructor(public payload: TestModel) {
  }
}

export class UpdateActionFailure implements Action {
  readonly type = TestActionTypes.UpdateActionFailure;

  constructor(public payload: any) {
  }
}

export class ViewAction implements Action {
  readonly type = TestActionTypes.ViewAction;

  constructor(public payload: string) {
  }
}

export class ViewActionSuccess implements Action {
  readonly type = TestActionTypes.ViewActionSuccess;

  constructor(public payload: TestModel) {
  }
}

export class ViewActionFailure implements Action {
  readonly type = TestActionTypes.ViewActionFailure;

  constructor(public payload: any) {
  }
}

export class CreateAction implements Action {
  readonly type = TestActionTypes.CreateAction;

  constructor(public payload: PostTestModel) {
  }
}


export class CreateActionSuccess implements Action {
  readonly type = TestActionTypes.CreateActionSuccess;

  constructor(public payload: TestModel) {
  }
}

export class CreateActionFailure implements Action {
  readonly type = TestActionTypes.CreateActionFailure;

  constructor(public payload: any) {
  }
}

export class ChangeCategoryAction implements Action {
  readonly type = TestActionTypes.ChangeCategoryAction;

  constructor(public payload: { ids: string[], categoryId: string }) {
  }
}


export class ChangeCategoryActionSuccess implements Action {
  readonly type = TestActionTypes.ChangeCategoryActionSuccess;

  constructor(public payload: TestModel[]) {
  }
}

export class ChangeCategoryActionFailure implements Action {
  readonly type = TestActionTypes.ChangeCategoryActionFailure;

  constructor(public payload: any) {
  }
}

export class StartTestAction implements Action {
  readonly type = TestActionTypes.StartTestAction;

  constructor(public test: TestModel, public lessonId: string | undefined) {
  }
}


export class StartTestActionSuccess implements Action {
  readonly type = TestActionTypes.StartTestActionSuccess;

  constructor(public dialog: MatDialogRef<TakeTestComponent>, public test: TestModel, public lessonId: string | undefined) {
  }
}


export class FinishTestAction implements Action {
  readonly type = TestActionTypes.FinishTestAction;

  constructor(public test: TestModel, public lessonId: string | undefined) {
  }
}


export class ShowResultsAction implements Action {
  readonly type = TestActionTypes.ShowResultsAction;

  constructor(public test: TestModel | string, public lessonId: string | undefined) {
  }
}

export class ShowResultsActionSuccess implements Action {
  readonly type = TestActionTypes.ShowResultsActionSuccess;

  constructor(public dialog: MatDialogRef<TestResultsComponent>, public test: TestModel, public lessonId: string | undefined) {
  }
}


export class CloseResultsAction implements Action {
  readonly type = TestActionTypes.CloseResultsAction;

  constructor() {
  }
}


export type TestActions = SearchAction
  | ResetAction
  | SearchSuccessAction
  | SearchFailureAction
  | UpdateAction
  | UpdateActionSuccess
  | UpdateActionFailure
  | ViewCloseAction
  | ViewAction
  | ViewActionSuccess
  | ViewActionFailure
  | CreateAction
  | CreateActionSuccess
  | CreateActionFailure
  | ChangeCategoryAction
  | ChangeCategoryActionSuccess
  | ChangeCategoryActionFailure
  | DeleteAction
  | DeleteActionSuccess
  | DeleteActionFailure
  | RecoveryAction
  | RecoveryActionSuccess
  | RecoveryActionFailure
  | CombineTagAction
  | StartTestAction
  | StartTestActionSuccess
  | FinishTestAction
  | ShowResultsAction
  | ShowResultsActionSuccess
  | CloseResultsAction
  ;
