import { BaseModel } from './base.model';
import { CategoryModel } from '@core/models/category.model';
import { FileModel } from '@core/models/file.model';
import { TagModel } from '@core/models/tag.model';
import { QuestionModel } from './question.model';

export class WikiModel extends BaseModel {
  id: string;
  slug: string;
  companyId: string;
  name: string;
  preview: string;
  content: string;
  link: string;
  categoryId: string;
  authorId: string;
  updateId: string;
  type: 100 | 101;
  isArchive: boolean;
  isFix: boolean;
  isShared: boolean;
  order: number;
  createdAt: string;
  authorFirstName: string;
  updatedAt: string;
  image: FileModel;
  isFavorite: boolean;
  colorCssClass: string;
  shortDescription: string;
  category: CategoryModel;
  tagNames: string[] = [];
  tagIds: string[] = [];
  tags: TagModel[] = [];
  checked = false;
  isRead: boolean;
  isAllowEdit = false;
  questions: QuestionModel[];
  commentCount: number;
  rating: number;
  isAllowDislike: boolean;
  isAllowLike: boolean;
  /**
   * Список id уроков, у которых есть эта статья
   * Используется для просмотра и редактирования курсов
   */
  lessonIds?: Set<string>;
  /**
   * Список файлов прикрепленных к статье
   */
  files: FileModel[];
}

export class WikiQueryModel extends BaseModel {
  id: string[];
  page: number;
  categoryId: string;
  tagIds: string[] = [];
  isFavorite: number;
  isArchive: number;
  isRead: number | undefined;
  match: string;
  authorId: string;
}

/**
 * Интерфейс сортировки записей
 */
export interface WikiSortInterface {
  prevId: string;
  nextId: string;
  id: string;
}
