import { Action } from '@ngrx/store';
import { InviteModel, InviteQueryModel } from '@core/models/invite.model';
import { PageResult } from '@core/models/filters/page-result';
import { CategoryActionTypes } from '@core/redux/category/category.actions';

export enum InviteActionTypes {
  SearchAction = '[Invite] Search',
  SearchSuccessAction = '[Invite] Search Success',
  SearchFailureAction = '[Invite] Search Failure',

  ConfirmInviteAdminAction = '[Invite] Confirm Invite',
  ConfirmInviteAdminActionSuccess = '[Invite] Confirm Invite Success',
  ConfirmInviteAdminActionFailure = '[Invite] Confirm Invite Failure',

  UpdateAction = '[Invite] Update',
  UpdateActionSuccess = '[Invite] Update Success',
  UpdateActionFailure = '[Invite] Update Failure',

  CreateAction = '[Invite] Create',
  CreateActionSuccess = '[Invite] Create Success',
  CreateActionFailure = '[Invite] Create Failure',

  ResendEmailAction = '[Invite] ResendEmail',
  ResendEmailActionSuccess = '[Invite] ResendEmail Success',
  ResendEmailActionFailure = '[Invite] ResendEmail Failure',

  DeleteAction = '[Invite] Delete',
  DeleteActionSuccess = '[Invite] Delete Success',
  DeleteActionFailure = '[Invite] Delete Failure',

  ViewAction = '[Invite] View',
  ViewActionSuccess = '[Invite] View Success',
  ViewActionFailure = '[Invite] View Failure',

  SelectAction = '[Invite] Select',
  ResetAction = '[Invite] Reset',
}

export class ResetAction implements Action {
  readonly type = InviteActionTypes.ResetAction;
}

export class SelectAction implements Action {
  readonly type = InviteActionTypes.SelectAction;

  constructor(public payload: string) {
  }
}

export class SearchAction implements Action {
  readonly type = InviteActionTypes.SearchAction;

  constructor(
    public payload: InviteQueryModel,
    public forInvitesPage: boolean,
  ) {
  }
}

export class SearchSuccessAction implements Action {
  readonly type = InviteActionTypes.SearchSuccessAction;

  constructor(
    public payload: PageResult<InviteModel[]>,
    public forInvitesPage: boolean,
  ) {
  }
}

export class SearchFailureAction implements Action {
  readonly type = InviteActionTypes.SearchFailureAction;

  constructor(public payload: any) {
  }
}

export class ViewAction implements Action {
  readonly type = InviteActionTypes.ViewAction;

  constructor(public payload: string) {
  }
}

export class ViewActionSuccess implements Action {
  readonly type = InviteActionTypes.ViewActionSuccess;

  constructor(public payload: InviteModel) {
  }
}

export class ViewActionFailure implements Action {
  readonly type = InviteActionTypes.ViewActionFailure;

  constructor(public payload: any) {
  }
}

export class UpdateAction implements Action {
  readonly type = InviteActionTypes.UpdateAction;

  constructor(public payload: InviteModel) {
  }
}


export class UpdateActionSuccess implements Action {
  readonly type = InviteActionTypes.UpdateActionSuccess;

  constructor(public payload: InviteModel) {
  }
}

export class UpdateActionFailure implements Action {
  readonly type = InviteActionTypes.UpdateActionFailure;

  constructor(public payload: any) {
  }
}

export class ConfirmInviteAdminAction implements Action {
  readonly type = InviteActionTypes.ConfirmInviteAdminAction;

  constructor(public payload: InviteModel) {
  }
}


export class ConfirmInviteAdminActionSuccess implements Action {
  readonly type = InviteActionTypes.ConfirmInviteAdminActionSuccess;

  constructor(public payload: InviteModel) {
  }
}

export class ConfirmInviteAdminActionFailure implements Action {
  readonly type = InviteActionTypes.ConfirmInviteAdminActionFailure;

  constructor(public payload: any) {
  }
}

export class CreateAction implements Action {
  readonly type = InviteActionTypes.CreateAction;

  constructor(public payload: InviteModel) {
  }
}


export class CreateActionSuccess implements Action {
  readonly type = InviteActionTypes.CreateActionSuccess;

  constructor(public payload: InviteModel) {
  }
}

export class CreateActionFailure implements Action {
  readonly type = InviteActionTypes.CreateActionFailure;

  constructor(public payload: any) {
  }
}

export class ResendEmailAction implements Action {
  readonly type = InviteActionTypes.ResendEmailAction;

  constructor(public payload: string) {
  }
}


export class ResendEmailActionSuccess implements Action {
  readonly type = InviteActionTypes.ResendEmailActionSuccess;

  constructor() {
  }
}

export class ResendEmailActionFailure implements Action {
  readonly type = InviteActionTypes.ResendEmailActionFailure;

  constructor(public payload: any) {
  }
}

export class DeleteAction implements Action {
  readonly type = InviteActionTypes.DeleteAction;

  constructor(public payload: string) {
  }
}


export class DeleteActionSuccess implements Action {
  readonly type = InviteActionTypes.DeleteActionSuccess;

  constructor(public payload: string) {
  }
}

export class DeleteActionFailure implements Action {
  readonly type = InviteActionTypes.DeleteActionFailure;

  constructor(public payload: any) {
  }
}

export type InviteActions = SelectAction
  | SearchAction
  | SearchSuccessAction
  | SearchFailureAction
  | UpdateAction
  | UpdateActionSuccess
  | UpdateActionFailure
  | ConfirmInviteAdminAction
  | ConfirmInviteAdminActionSuccess
  | ConfirmInviteAdminActionFailure
  | CreateAction
  | CreateActionSuccess
  | CreateActionFailure
  | ResendEmailAction
  | ResendEmailActionSuccess
  | ResendEmailActionFailure
  | DeleteAction
  | DeleteActionSuccess
  | DeleteActionFailure
  | ViewAction
  | ViewActionSuccess
  | ViewActionFailure
  | ResetAction
  ;
