import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '@core/redux/index';
import 'rxjs/add/operator/do';
import {first, flatMap} from 'rxjs/operators';

@Injectable()
export class CompanyInterceptor implements HttpInterceptor {
  constructor(private store: Store<fromRoot.State>) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.pipe(
      select(fromRoot.getCompanyCurrentId),
      first(),
      flatMap((companyId) => {
        const companyReq = !!companyId ? request.clone({
          setParams: {companyId: companyId}
        }) : request;
        return next.handle(companyReq);
      }),
    );
  }
}
