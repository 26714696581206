import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TagModel, IdentityModel } from '@core/models';
import { Subscription } from 'rxjs';
import * as fromRoot from '@core/redux';
import { Store, select } from '@ngrx/store';
import { AuthService } from '@core/services/entities/auth/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-ui-header-filter-menu',
  templateUrl: './ui-header-filter-menu.component.html',
  styleUrls: ['./ui-header-filter-menu.component.less']
})
export class UiHeaderFilterMenuComponent implements OnInit, OnDestroy {

  /**
   * Список всех тегов
   * @type {TagModel[]}
   */
  tags: TagModel[] = [];

  /**
   * Авторизованный пользователь
   */
  user: IdentityModel;

  isRead: string | undefined = undefined;

  filterOptions = [
    {
      name: 'Все материалы',
      value: null
    },
    {
      name: 'Непрочитанные статьи',
      value: '0'
    },
    {
      name: 'Прочитанные статьи',
      value: '1'
    },
  ];

  private readonly subscriptions = new Subscription();

  constructor(
    private dialogRef: MatDialogRef<UiHeaderFilterMenuComponent>,
    private store: Store<fromRoot.State>,
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.receiveCurrentUrl();
    this.subscribeToTags();
    this.subscribeToIsRead();
    this.subscribeToUser();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onClose() {
    this.dialogRef.close();
  }

  private receiveCurrentUrl() {
    const params = new URLSearchParams(window.location.search);
    this.isRead = params.get('isRead');
  }

  /**
   * Подписка на получение списка тегов
   * @private
   */
  private subscribeToTags() {
    this.subscriptions.add(this
      .store
      .pipe(select(fromRoot.getTagEntities))
      .subscribe((tags: TagModel[]) => this.tags = tags)
    );
  }

  /**
   * Подписка на пользователя
   *
   * @private
   */
  private subscribeToUser() {
    this.subscriptions.add(this.authService.getUser().subscribe((user) => this.user = user));
  }

  private subscribeToIsRead() {
    this.subscriptions.add(
      this.router
        .events
        .pipe(
          filter(event => event instanceof NavigationEnd)
        )
        .subscribe(() => this.receiveCurrentUrl())
    );
  }

}
