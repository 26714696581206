import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiPopupMenuItemComponent } from '@ui/modules/ui-popup-menu-item/ui-popup-menu-item.component';

@NgModule({
  declarations: [
    UiPopupMenuItemComponent
  ],
  exports: [
    UiPopupMenuItemComponent
  ],
  imports: [
    CommonModule
  ]
})
export class UiPopupMenuItemModule { }
