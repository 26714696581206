import {Action} from '@ngrx/store';
import {UserPositionsModel} from '@core/models/user-positions.model';
import {InviteActionTypes} from '@core/redux/invite/invite.actions';

export enum UserPositionsActionTypes {
  SelectUpdateAction = '[UserPositions] Select Update',

  SearchAction = '[UserPositions] Search',
  SearchSuccessAction = '[UserPositions] Search Success',
  SearchFailureAction = '[UserPositions] Search Failure',

  UpdateAction = '[UserPositions] Update',
  UpdateActionSuccess = '[UserPositions] Update Success',
  UpdateActionFailure = '[UserPositions] Update Failure',

  CreateAction = '[UserPositions] Create',
  CreateActionSuccess = '[UserPositions] Create Success',
  CreateActionFailure = '[UserPositions] Create Failure',

  DeleteAction = '[UserPositions] Delete',
  DeleteActionSuccess = '[UserPositions] Delete Success',
  DeleteActionFailure = '[UserPositions] Delete Failure',

  ResetAction = '[UserPositions] Reset',
}

export class ResetAction implements Action {
  readonly type = UserPositionsActionTypes.ResetAction;
}

export class SearchAction implements Action {
  readonly type = UserPositionsActionTypes.SearchAction;

  constructor() {
  }
}

export class SearchSuccessAction implements Action {
  readonly type = UserPositionsActionTypes.SearchSuccessAction;

  constructor(public payload: UserPositionsModel[]) {
  }
}

export class SearchFailureAction implements Action {
  readonly type = UserPositionsActionTypes.SearchFailureAction;

  constructor(public payload: any) {
  }
}

export class SelectUpdateAction implements Action {
  readonly type = UserPositionsActionTypes.SelectUpdateAction;

  constructor(public payload: string) {
  }
}

export class UpdateAction implements Action {
  readonly type = UserPositionsActionTypes.UpdateAction;

  constructor(public payload: UserPositionsModel) {
  }
}


export class UpdateActionSuccess implements Action {
  readonly type = UserPositionsActionTypes.UpdateActionSuccess;

  constructor(public payload: UserPositionsModel) {
  }
}

export class UpdateActionFailure implements Action {
  readonly type = UserPositionsActionTypes.UpdateActionFailure;

  constructor(public payload: any) {
  }
}

export class CreateAction implements Action {
  readonly type = UserPositionsActionTypes.CreateAction;

  constructor(public payload: UserPositionsModel) {
  }
}


export class CreateActionSuccess implements Action {
  readonly type = UserPositionsActionTypes.CreateActionSuccess;

  constructor(public payload: UserPositionsModel) {
  }
}

export class CreateActionFailure implements Action {
  readonly type = UserPositionsActionTypes.CreateActionFailure;

  constructor(public payload: any) {
  }
}

export class DeleteAction implements Action {
  readonly type = UserPositionsActionTypes.DeleteAction;

  constructor(public payload: string) {
  }
}


export class DeleteActionSuccess implements Action {
  readonly type = UserPositionsActionTypes.DeleteActionSuccess;

  constructor(public payload: string) {
  }
}

export class DeleteActionFailure implements Action {
  readonly type = UserPositionsActionTypes.DeleteActionFailure;

  constructor(public payload: any) {
  }
}

export type UserPositionsActions = SearchAction
  | SearchSuccessAction
  | SearchFailureAction
  | SelectUpdateAction
  | UpdateAction
  | UpdateActionSuccess
  | UpdateActionFailure
  | CreateAction
  | CreateActionSuccess
  | CreateActionFailure
  | DeleteAction
  | DeleteActionSuccess
  | DeleteActionFailure
  | ResetAction
  ;
