import {Injectable} from '@angular/core';
import {IdentityModel} from '@core/models/identity.model';

declare const Chatra;

@Injectable({
  providedIn: 'root',
})
export class ChatraService {
  setData(user: IdentityModel) {
    Chatra('updateIntegrationData', {
      'Проект': 'MyBase.pro',
      name: user.firstName + ' ' + user.lastName,
      'Номер телефона': user.phone,
      'Дата завершения': user.expiredAt,
      email: user.email,
      'Тестовый': user.isTest ? 'Да' : 'Нет',
      clientId: user.id
    });
  }

  openChatra() {
    Chatra('openChat', true);
  }
}
