import {Component, EventEmitter, Input, Output, AfterViewInit, ViewChild} from '@angular/core';
import {CategoryModel, CategoryTypes} from '@core/models/category.model';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { OnboardingActionsService } from '@core/redux/onboarding/onboarding-actions.service';
import { OnboardingStateService } from '@core/redux/onboarding/onboarding-state.service';
import { StudyCreatingFromLinkStep } from '@core/redux/onboarding/onboarding.model';
import { TooltipDirective } from '@ui/modules/tooltip/tooltip.directive';
import { delay } from 'rxjs/operators';
import { ConnectedPosition } from '@angular/cdk/overlay';

@Component({
  selector: 'ui-select-category',
  templateUrl: './ui-select-category.component.html',
  styleUrls: ['./ui-select-category.component.less']
})
export class UiSelectCategoryComponent implements AfterViewInit {

  private _categories: CategoryModel[] = [];
  private _categoriesSaved: CategoryModel[] = [];
  private _value: string;
  private readonly subscription = new Subscription();

  @Input() type: CategoryTypes;
  @Input() skipId: string;
  @Input() isBordered = false;
  @Input() isHeader = false;
  @Input() isAllowEdit = true;

  @Input()
  set categories(value: CategoryModel[]) {
    this._categories = this._categoriesSaved = value;
  }

  get categories(): CategoryModel[] {
    return this._categories;
  }

  @Input()
  set value(value) {
    if (value && value !== this._value) {
      this._value = value;
      this.updateHeader(value);
    }
  }

  @Output()
  changeValue: EventEmitter<string> = new EventEmitter();

  @Output()
  openStateChange = new EventEmitter<boolean>();

  @ViewChild('tree', {static: false, read: TooltipDirective})
  tipContainer: TooltipDirective;

  isOpen = false;
  header: string;
  placeholder = 'Выберите раздел';

  tipPosition: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top',
      offsetX: 24,
      offsetY: 32,
    }
  ];

  constructor(
    private onboardingActionsService: OnboardingActionsService,
    private onboardingStateService: OnboardingStateService,
  ) {
  }


  ngAfterViewInit() {
    this.subscribeToOnboarding();
  }

  onOpen() {
    this.isOpen = !this.isOpen;
    this.openStateChange.emit(this.isOpen);
  }

  onClose() {
    this.isOpen = false;
    this.openStateChange.emit(this.isOpen);
  }

  onSelect(value) {
    this.changeValue.emit(value);
    this.updateHeader(value);
    this.onClose();
  }

  private subscribeToOnboarding() {
    this.subscription.add(
      this.onboardingStateService.getStudyCreatingFromLinkStep().pipe(
        delay(500),  // дождаться рендера дерева
      ).subscribe(step => {
        if (step === StudyCreatingFromLinkStep.SelectCategory) {
          this.tipContainer.show();
        }
      })
    );
  }

  private updateHeader(id) {
    if (!id) {
      this.header = null;
      return;
    }

    const category = _.find<CategoryModel>(this.categories, {id: id});

    if (category) {
      this.header = category.name;
    } else {
      this.header = null;
    }
  }
}
