import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';

import { TakeTestService } from '../../services/take-test.service';
import { TestState, TmpTestModel, TmpQuestionModel } from '../../helpers/types';
import { TestModel } from '@core/models';
import { mapTest } from '../../helpers/functions';


export interface MatDialogData {
  test: TestModel;
  lessonId: string | undefined;
}


/**
 * Компонент прохождения теста
 * Отвечает за показ вопроса и правильного ответа на вопрос
 */
@Component({
  selector: 'take-test',
  templateUrl: './take-test.component.html',
  styleUrls: ['./take-test.component.less']
})
export class TakeTestComponent implements OnInit {
  question$: Observable<TmpQuestionModel>;
  state$: Observable<TestState>;
  testName$: Observable<string>;

  private test: TmpTestModel;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: MatDialogData,
    private dialogRef: MatDialogRef<TakeTestComponent>,
    private service: TakeTestService,
  ) {
    this.test = mapTest(data.test);
  }

  ngOnInit() {
    this.question$ = this.service.question;
    this.state$ = this.service.state;
    this.testName$ = this.service.test.map(t => t.name);
    this.service.start(this.test, this.dialogRef, this.data.lessonId);
  }

  onCloseAttempt() {
    this.service.askConfirmationAndClose();
  }
}
