<button
  (click)="onCloseAttempt()"
  class="button button_not-button take-test__close">
  <i class="icon-close"></i>
</button>

<div class="take-test__scroll">
  <div class="take-test__container">
    <h2 class="take-test__title">
      {{ testName$ | async }}
    </h2>
    <test-question></test-question>
  </div>
</div>

<div class="take-test__filler"></div>

<test-footer></test-footer>
