import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'ui-category-block',
  templateUrl: './ui-category-block.component.html',
  styleUrls: ['./ui-category-block.component.less']
})
export class UiCategoryBlockComponent implements OnInit {
  @Output() create: EventEmitter<void> = new EventEmitter();
  @Input() isShowCreateButton = true;

  constructor() {
  }

  ngOnInit() {
  }

  onCreate() {
    this.create.emit();
  }
}
