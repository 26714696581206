import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {WrapperComponent} from './wrapper/wrapper.component';
import {RouterModule} from '@angular/router';
import {LayoutComponent} from './layout/layout.component';
import {Wrapper2Component} from './wrapper2/wrapper2.component';
import {UiHeaderModule} from '@ui/modules/header/ui-header/ui-header.module';
import {UiHeaderAppModule} from '@ui/modules/header/ui-header-app/ui-header-app.module';
import {UiUserMenuModule} from '@ui/modules/ui-user-menu/ui-user-menu.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UiHeaderModule,
    UiHeaderAppModule,
    UiUserMenuModule,
  ],
  declarations: [
    LayoutComponent,
    WrapperComponent,
    Wrapper2Component,
  ],
  exports: [
    LayoutComponent,
  ]
})
export class LayoutsModule {
}
