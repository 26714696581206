import {Component, EventEmitter, Input, HostBinding, OnInit, Output} from '@angular/core';

@Component({
  selector: 'ui-tag',
  templateUrl: './ui-tag.component.html',
  styleUrls: ['./ui-tag.component.less']
})
export class UiTagComponent implements OnInit {
  @Input() value: string;
  @Input() icon: string;

  @Input()
  @HostBinding('class.ui-tag_gray')
  isGray: boolean;

  @Input() showDeleteButton: boolean;
  @Output() onDelete = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }
}
