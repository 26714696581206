import {Action} from '@ngrx/store';
import {CourseModel, CourseQueryModel, PostCourseModel, PutCourseModel} from '@core/models';
import {PageResult} from '@core/models/filters/page-result';

export enum CourseActionTypes {
  CheckResultAction = '[Course] Check Result',

  ResetAction = '[Course] Reset',

  DeleteAction = '[Course] Delete',
  DeleteActionSuccess = '[Course] Delete Success',
  DeleteActionFailure = '[Course] Delete Failure',

  RecoveryAction = '[Course] Recovery',
  RecoveryActionSuccess = '[Course] Recovery Success',
  RecoveryActionFailure = '[Course] Recovery Failure',

  ViewCloseAction = '[course] View Close',

  SearchAction = '[Course] Search',
  SearchSuccessAction = '[Course] Search Success',
  SearchFailureAction = '[Course] Search Failure',

  UpdateAction = '[Course] Update',
  UpdateActionSuccess = '[Course] Update Success',
  UpdateActionFailure = '[Course] Update Failure',

  ViewAction = '[Course] View',
  ViewActionSuccess = '[Course] View Success',
  ViewActionFailure = '[Course] View Failure',

  CreateAction = '[Course] Create',
  CreateActionSuccess = '[Course] Create Success',
  CreateActionFailure = '[Course] Create Failure',

  ChangeCategoryAction = '[Course] ChangeCategory',
  ChangeCategoryActionSuccess = '[Course] ChangeCategory Success',
  ChangeCategoryActionFailure = '[Course] ChangeCategory Failure',
}

export class ResetAction implements Action {
  readonly type = CourseActionTypes.ResetAction;
}

export class CheckResultAction implements Action {
  readonly type = CourseActionTypes.CheckResultAction;

  constructor(public courseId: string, public lessonId: string) {
  }
}

export class DeleteAction implements Action {
  readonly type = CourseActionTypes.DeleteAction;

  constructor(public payload: string) {
  }
}


export class DeleteActionSuccess implements Action {
  readonly type = CourseActionTypes.DeleteActionSuccess;

  constructor(public payload: string) {
  }
}

export class DeleteActionFailure implements Action {
  readonly type = CourseActionTypes.DeleteActionFailure;

  constructor(public payload: any) {
  }
}

export class ViewCloseAction implements Action {
  readonly type = CourseActionTypes.ViewCloseAction;

  constructor() {
  }
}

export class SearchAction implements Action {
  readonly type = CourseActionTypes.SearchAction;

  constructor(public payload: CourseQueryModel) {
  }
}

export class SearchSuccessAction implements Action {
  readonly type = CourseActionTypes.SearchSuccessAction;

  constructor(public payload: PageResult<CourseModel[]>) {
  }
}

export class SearchFailureAction implements Action {
  readonly type = CourseActionTypes.SearchFailureAction;

  constructor(public payload: any) {
  }
}

export class UpdateAction implements Action {
  readonly type = CourseActionTypes.UpdateAction;

  constructor(public payload: PutCourseModel) {
  }
}


export class UpdateActionSuccess implements Action {
  readonly type = CourseActionTypes.UpdateActionSuccess;

  constructor(public payload: CourseModel) {
  }
}

export class UpdateActionFailure implements Action {
  readonly type = CourseActionTypes.UpdateActionFailure;

  constructor(public payload: any) {
  }
}

export class ViewAction implements Action {
  readonly type = CourseActionTypes.ViewAction;

  constructor(public payload: string) {
  }
}

export class ViewActionSuccess implements Action {
  readonly type = CourseActionTypes.ViewActionSuccess;

  constructor(public payload: CourseModel) {
  }
}

export class ViewActionFailure implements Action {
  readonly type = CourseActionTypes.ViewActionFailure;

  constructor(public payload: any) {
  }
}

export class CreateAction implements Action {
  readonly type = CourseActionTypes.CreateAction;

  constructor(public payload: PostCourseModel) {
  }
}


export class CreateActionSuccess implements Action {
  readonly type = CourseActionTypes.CreateActionSuccess;

  constructor(public payload: CourseModel) {
  }
}

export class CreateActionFailure implements Action {
  readonly type = CourseActionTypes.CreateActionFailure;

  constructor(public payload: any) {
  }
}

export class ChangeCategoryAction implements Action {
  readonly type = CourseActionTypes.ChangeCategoryAction;

  constructor(public payload: { ids: string[], categoryId: string }) {
  }
}


export class ChangeCategoryActionSuccess implements Action {
  readonly type = CourseActionTypes.ChangeCategoryActionSuccess;

  constructor(public payload: CourseModel[]) {
  }
}

export class ChangeCategoryActionFailure implements Action {
  readonly type = CourseActionTypes.ChangeCategoryActionFailure;

  constructor(public payload: any) {
  }
}

export class RecoveryAction implements Action {
  readonly type = CourseActionTypes.RecoveryAction;

  constructor(public payload: string) {
  }
}


export class RecoveryActionSuccess implements Action {
  readonly type = CourseActionTypes.RecoveryActionSuccess;

  constructor(public payload: string) {
  }
}

export class RecoveryActionFailure implements Action {
  readonly type = CourseActionTypes.RecoveryActionFailure;

  constructor(public payload: any) {
  }
}

export type CourseActions = SearchAction
  | ResetAction
  | SearchSuccessAction
  | SearchFailureAction
  | ViewCloseAction
  | UpdateAction
  | UpdateActionSuccess
  | UpdateActionFailure
  | ViewAction
  | ViewActionSuccess
  | ViewActionFailure
  | CreateAction
  | CreateActionSuccess
  | CreateActionFailure
  | ChangeCategoryAction
  | ChangeCategoryActionSuccess
  | ChangeCategoryActionFailure
  | CheckResultAction
  | DeleteAction
  | DeleteActionSuccess
  | DeleteActionFailure
  | RecoveryAction
  | RecoveryActionSuccess
  | RecoveryActionFailure
  ;
