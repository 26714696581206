import { CompanyActions, CompanyActionTypes } from './company.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CompanyModel } from '@core/models/company.model';
import { createSelector } from '@ngrx/store';

export interface State extends EntityState<CompanyModel> {
  currentId: string | null;
  ids: string[];
  loading: boolean;
  selectedId: string | null;
  errors: any;
}

export const initialState: State = {
  currentId: null,
  ids: [],
  loading: false,
  selectedId: null,
  entities: {},
  errors: {}
};

export const adapter: EntityAdapter<CompanyModel> = createEntityAdapter<CompanyModel>({
  selectId: (company: CompanyModel) => company.id,
  sortComparer: false,
});

export function reducer(state = initialState, action: CompanyActions): State {
  switch (action.type) {
    case CompanyActionTypes.GetStartAction: {
      return { ...state, loading: true, currentId: action.companyId };
    }

    case CompanyActionTypes.GetStartSuccessAction: {
      return adapter.addOne(action.payload, { ...state, selectedId: action.payload.id, loading: false });
    }

    case CompanyActionTypes.GetStartFailureAction: {
      return { ...state, loading: false };
    }

    case CompanyActionTypes.GetCompaniesAction: {
      return { ...state, loading: true };
    }

    case CompanyActionTypes.GetCompaniesSuccessAction: {
      return adapter.setAll(action.payload, { ...state, loading: false });
    }

    case CompanyActionTypes.GetCompaniesFailureAction: {
      return { ...state, loading: false, errors: action.payload };
    }

    case CompanyActionTypes.CreateAction: {
      return { ...state, loading: true, errors: {} };
    }

    case CompanyActionTypes.CreateSuccessAction: {
      return adapter.addOne(action.payload, { ...state, loading: false });
    }

    case CompanyActionTypes.CreateFailureAction: {
      return { ...state, loading: false, errors: action.payload };
    }

    case CompanyActionTypes.UpdateAction: {
      return { ...state, loading: true, errors: {} };
    }

    case CompanyActionTypes.UpdateSuccessAction: {
      return adapter.updateOne({ id: action.payload.id, changes: action.payload }, { ...state, loading: false });
    }

    case CompanyActionTypes.UpdateFailureAction: {
      return { ...state, loading: false, errors: action.payload };
    }

    case CompanyActionTypes.ConfirmInviteUserAction: {
      return { ...state, loading: true, errors: {} };
    }

    case CompanyActionTypes.ConfirmInviteUserActionFailure: {
      return { ...state, loading: false, errors: action.payload };
    }

    case CompanyActionTypes.ConfirmInviteUserActionSuccess: {
      return adapter.updateOne({ id: action.payload.id, changes: action.payload }, { ...state, loading: false });
    }

    default:
      return state;
  }
}

export const getCurrentId = (state: State) => state.currentId;
export const getErrors = (state: State) => state.errors;
export const getEntitiesObject = (state: State) => state.entities;
export const getIds = (state: State) => state.ids;
export const getEntities = createSelector(getEntitiesObject, getIds, (entities, ids) => ids.map(id => entities[id]));
export const getEntitiesActive = createSelector(getEntities, (entities) => entities.filter(entity => entity.isArchive));
export const getLoading = (state: State) => state.loading;
export const getSelectedId = (state: State) => state.selectedId;
export const getSelectedEntity = createSelector(getEntitiesObject, getSelectedId, (entities, selectedId) => entities[selectedId]);
