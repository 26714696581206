/**
 * Основные поля ответа, общие для всех ответов
 */
interface BaseAnswerModel {
  /**
   * Текст ответа
   */
  answer: string;
}

/**
 * Новый ответ, созданный в форме создания теста
 */
export interface NewAnswerModel extends BaseAnswerModel {
  /**
   * Верный ответ?
   */
  isCorrect: boolean;
  /**
   * Следует ли его сфокусировать в форме создания теста
   */
  shouldFocus?: boolean;
}

/**
 * Ответ, приходящий с сервера и отправляемый на сервер в POST/PUT запросах
 */
export interface AnswerModel extends BaseAnswerModel {
  /**
   * UUID
   * Генерируется клиентом перед отправкой
   */
  id: string;
  isCorrect: boolean;
}

/**
 * Вернуть пустой ответ
 * @param shouldFocus следует ли сфокусировать инпут нового вопроса в форме создания теста
 */
export function getEmptyAnswer(shouldFocus=false): NewAnswerModel {
  return {
    answer: '',
    isCorrect: false,
    shouldFocus,
  };
}
