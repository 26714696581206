import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {ApiService} from '@core/services/entities/api.service';
import {EventModel} from '@core/models/event.model';
import {PostEventModel, PutEventModel} from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class ApiEventService extends ApiService {
  delete(id: string) {
    return this
      .http
      .delete(this.getApiUrl('events/' + id));
  }

  getAll() {
    return this
      .http
      .get<EventModel[]>(this.getApiUrl('events'));
  }

  update(model: PutEventModel) {
    return this
      .http
      .put<EventModel>(this.getApiUrl(`events/${model.id}`), model);
  }

  view(id: string) {
    return this
      .http
      .get<EventModel>(this.getApiUrl(`events/${id}`));
  }

  create(model: PostEventModel) {
    return this
      .http
      .post<EventModel>(this.getApiUrl(`events`), model);
  }
}
