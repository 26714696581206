import {Action} from '@ngrx/store';
import {UserStatusModel} from '@core/models/user-status.model';

export enum UserStatusActionTypes {
  SelectUpdateAction = '[UserStatus] Select Update',

  SearchAction = '[UserStatus] Search',
  SearchSuccessAction = '[UserStatus] Search Success',
  SearchFailureAction = '[UserStatus] Search Failure',

  UpdateAction = '[UserStatus] Update',
  UpdateActionSuccess = '[UserStatus] Update Success',
  UpdateActionFailure = '[UserStatus] Update Failure',

  CreateAction = '[UserStatus] Create',
  CreateActionSuccess = '[UserStatus] Create Success',
  CreateActionFailure = '[UserStatus] Create Failure',

  DeleteAction = '[UserStatus] Delete',
  DeleteActionSuccess = '[UserStatus] Delete Success',
  DeleteActionFailure = '[UserStatus] Delete Failure',
  ResetAction = '[UserStatus] Reset',
}

export class ResetAction implements Action {
  readonly type = UserStatusActionTypes.ResetAction;
}

export class SearchAction implements Action {
  readonly type = UserStatusActionTypes.SearchAction;

  constructor() {
  }
}

export class SearchSuccessAction implements Action {
  readonly type = UserStatusActionTypes.SearchSuccessAction;

  constructor(public payload: UserStatusModel[]) {
  }
}

export class SearchFailureAction implements Action {
  readonly type = UserStatusActionTypes.SearchFailureAction;

  constructor(public payload: any) {
  }
}

export class SelectUpdateAction implements Action {
  readonly type = UserStatusActionTypes.SelectUpdateAction;

  constructor(public payload: string) {
  }
}

export class UpdateAction implements Action {
  readonly type = UserStatusActionTypes.UpdateAction;

  constructor(public payload: UserStatusModel) {
  }
}


export class UpdateActionSuccess implements Action {
  readonly type = UserStatusActionTypes.UpdateActionSuccess;

  constructor(public payload: UserStatusModel) {
  }
}

export class UpdateActionFailure implements Action {
  readonly type = UserStatusActionTypes.UpdateActionFailure;

  constructor(public payload: any) {
  }
}

export class CreateAction implements Action {
  readonly type = UserStatusActionTypes.CreateAction;

  constructor(public payload: UserStatusModel) {
  }
}


export class CreateActionSuccess implements Action {
  readonly type = UserStatusActionTypes.CreateActionSuccess;

  constructor(public payload: UserStatusModel) {
  }
}

export class CreateActionFailure implements Action {
  readonly type = UserStatusActionTypes.CreateActionFailure;

  constructor(public payload: any) {
  }
}

export class DeleteAction implements Action {
  readonly type = UserStatusActionTypes.DeleteAction;

  constructor(public payload: string) {
  }
}


export class DeleteActionSuccess implements Action {
  readonly type = UserStatusActionTypes.DeleteActionSuccess;

  constructor(public payload: string) {
  }
}

export class DeleteActionFailure implements Action {
  readonly type = UserStatusActionTypes.DeleteActionFailure;

  constructor(public payload: any) {
  }
}


export type UserStatusActions = SearchAction
  | SearchSuccessAction
  | SearchFailureAction
  | SelectUpdateAction
  | UpdateAction
  | UpdateActionSuccess
  | UpdateActionFailure
  | CreateAction
  | CreateActionSuccess
  | CreateActionFailure
  | DeleteAction
  | DeleteActionSuccess
  | DeleteActionFailure
  | ResetAction
  ;
