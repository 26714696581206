export enum RemoteDataState {
  NotAsked,
  Loading,
  Failure,
  Success,
}

export interface RemoteData {
  state: RemoteDataState;
}
