import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiContainerModule } from '@ui/modules/ui-container/ui-container.module';
import { UiCategoryModule } from '@ui/modules/ui-category/ui-category.module';
import { UiCategoryBlockModule } from '@ui/modules/ui-category-block/ui-category-block.module';
import { UserListFilterSidebarComponent } from './user-list-filter-sidebar.component';
import { RouterModule } from '@angular/router';
import { TooltipModule } from '@ui/modules/tooltip/tooltip.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UiContainerModule,
    UiCategoryModule,
    UiCategoryBlockModule,
    TooltipModule,
  ],
  declarations: [
    UserListFilterSidebarComponent,
  ],
  exports: [
    UserListFilterSidebarComponent,
  ],
})
export class UserListFilterSidebarModule {
}
