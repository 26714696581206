import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';


@Component({
  selector: 'exit-confirmation',
  templateUrl: './exit-confirmation.component.html',
  styleUrls: ['./exit-confirmation.component.less']
})
export class ExitConfirmationComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ExitConfirmationComponent>,
  ) { }

  ngOnInit() {
  }

  /**
   * Закрыть попап и передать решение пользователя завершить тест или нет
   * @param shouldExit 
   */
  onClose(shouldExit: boolean) {
    this.dialogRef.close(shouldExit);
  }

}
