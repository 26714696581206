import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  getOnboarding,
  getOnboardingSuccess,
  getOnboardingFailure,
  clearOnboarding,
  updateOnboarding,
  setFirstLinkArticle,
  setFirstWrittenArticle,
  changeStudyCreatingFromLinkStep,
  changeStudyWritingArticleStep,
  resetOnboardingSteps
} from './onboarding.actions';
import {map, catchError, mergeMap, tap, exhaustMap, withLatestFrom, flatMap, distinctUntilChanged} from 'rxjs/operators';
import {ApiOnboardingService} from '@core/redux/onboarding/api-onboarding.service';
import {of, combineLatest} from 'rxjs';
import {AuthService} from '@core/services/entities/auth/auth.service';
import {OnboardingStateService} from '@core/redux/onboarding/onboarding-state.service';
import {WikiActionTypes, CreateActionSuccess} from '../wiki/wiki.actions';
import {StudyCreatingFromLinkStep, StudyWritingArticleStep} from './onboarding.model';
import { CompanyService } from '@core/services/entities/company/company.service';

@Injectable()
export class OnboardingEffects {
  linkArticleCreation$ = createEffect(() => this.actions.pipe(
    ofType(WikiActionTypes.CreateActionSuccess),
    map((action: CreateActionSuccess) => action),
    withLatestFrom(this.onboardingService.getStudyCreatingFromLinkStep()),
    flatMap(([action, step]) => [
      setFirstLinkArticle({id: step === StudyCreatingFromLinkStep.Done ? action.payload.id : undefined}),
      changeStudyCreatingFromLinkStep({nextStep: StudyCreatingFromLinkStep.NotStarted}),
    ]),
  ));

  writtenArticleCreation$ = createEffect(() => this.actions.pipe(
    ofType(WikiActionTypes.CreateActionSuccess),
    map((action: CreateActionSuccess) => action),
    withLatestFrom(this.onboardingService.getStudyWritingArticleStep()),
    flatMap(([action, step]) => [
      setFirstWrittenArticle({id: step === StudyWritingArticleStep.Done ? action.payload.id : undefined}),
      changeStudyWritingArticleStep({nextStep: StudyWritingArticleStep.NotStarted}),
    ]),
  ));

  userChange$ = createEffect(() => combineLatest([
    this.authService.getUser(),
    this.onboardingService.getUserId(),
  ]).pipe(
    flatMap(([user, userId]) => {
      if (!user) {
        return [clearOnboarding()];
      }
      if (user.id !== userId) {
        return [clearOnboarding(), getOnboarding()];
      }
      return [];
    })
  ));

  companyChange$ = createEffect(() => this.companyService.getCompanyId().pipe(
    distinctUntilChanged(),
    map(() => resetOnboardingSteps()),
  ));

  get$ = createEffect(() => this.actions.pipe(
    ofType(getOnboarding),
    withLatestFrom(this.authService.getUser()),
    mergeMap(([action, user]) =>
      this.apiOnboardingService.get().pipe(
        map(response => getOnboardingSuccess({response})),
        catchError(error => of(getOnboardingFailure({error})))
      )
    )));

  update$ = createEffect(() => this.actions.pipe(
    ofType(updateOnboarding),
    withLatestFrom(this.authService.getUser()),
    exhaustMap(([action, user]) => this.apiOnboardingService.update(action.request).pipe(
      map(response => getOnboardingSuccess({response})),
      catchError(error => of(getOnboardingFailure({error}))),
    ))
  ));

  constructor(
    private actions: Actions,
    private apiOnboardingService: ApiOnboardingService,
    private authService: AuthService,
    private companyService: CompanyService,
    private onboardingService: OnboardingStateService,
  ) {
  }
}
