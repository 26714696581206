import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  InviteActionTypes,
  CreateAction,
  CreateActionFailure,
  CreateActionSuccess,
  SearchAction,
  SearchFailureAction,
  SearchSuccessAction,
  UpdateAction,
  UpdateActionFailure,
  UpdateActionSuccess,
  DeleteAction,
  DeleteActionSuccess,
  DeleteActionFailure,
  ResendEmailAction,
  ResendEmailActionSuccess,
  ResendEmailActionFailure,
  ViewActionSuccess,
  ViewActionFailure,
  ConfirmInviteAdminAction,
  ConfirmInviteAdminActionSuccess,
  ConfirmInviteAdminActionFailure,
  ViewAction
} from './invite.actions';
import {map, exhaustMap, catchError, switchMap} from 'rxjs/operators';
import { of } from 'rxjs/index';
import { InviteModel } from '@core/models/invite.model';
import { Store } from '@ngrx/store';
import * as fromRoot from '@core/redux/index';
import { ApiInviteService } from '@core/services/entities/invite/api-invite.service';

@Injectable()
export class InviteEffects {
  constructor(private actions$: Actions,
    private store: Store<fromRoot.State>,
    private apiInviteService: ApiInviteService) {
  }

  @Effect()
  view$ = this.actions$.pipe(
    ofType(InviteActionTypes.ViewAction),
    map((action: ViewAction) => action.payload),
    exhaustMap((id) => {
      return this.apiInviteService
        .view(id)
        .pipe(
          map((response) => new ViewActionSuccess(response)),
          catchError((errors) => of(new ViewActionFailure(errors)))
        );
    }
    )
  );

  @Effect()
  search$ = this.actions$.pipe(
    ofType(InviteActionTypes.SearchAction),
    switchMap((action: SearchAction) => {
      return this.apiInviteService
        .search(action.payload)
        .pipe(
          map((response) => new SearchSuccessAction(response, action.forInvitesPage)),
          catchError((errors) => of(new SearchFailureAction(errors)))
        );
    }
    )
  );

  @Effect()
  update$ = this.actions$.pipe(
    ofType(InviteActionTypes.UpdateAction),
    map((action: UpdateAction) => action.payload),
    exhaustMap((model: InviteModel) => {
      return this.apiInviteService
        .update(model)
        .pipe(
          map((response) => new UpdateActionSuccess(response)),
          catchError((errors) => of(new UpdateActionFailure(errors)))
        );
    }
    )
  );

  @Effect()
  confirmInviteAdmin$ = this.actions$.pipe(
    ofType(InviteActionTypes.ConfirmInviteAdminAction),
    map((action: ConfirmInviteAdminAction) => action.payload),
    exhaustMap((model: InviteModel) => {
      return this.apiInviteService
        .confirmInviteAdmin(model)
        .pipe(
          map((response) => new ConfirmInviteAdminActionSuccess(response)),
          catchError((errors) => of(new ConfirmInviteAdminActionFailure(errors)))
        );
    }
    )
  );


  @Effect()
  create$ = this.actions$.pipe(
    ofType(InviteActionTypes.CreateAction),
    map((action: CreateAction) => action.payload),
    exhaustMap((model: InviteModel) => {
      return this.apiInviteService
        .create(model)
        .pipe(
          map((response) => new CreateActionSuccess(response)),
          catchError((errors) => of(new CreateActionFailure(errors)))
        );
    }
    )
  );

  @Effect()
  resendEmail$ = this.actions$.pipe(
    ofType(InviteActionTypes.ResendEmailAction),
    map((action: ResendEmailAction) => action.payload),
    exhaustMap((id: string) => {
      return this.apiInviteService
        .resendConfirmEmail(id)
        .pipe(
          map(() => new ResendEmailActionSuccess()),
          catchError((errors) => of(new ResendEmailActionFailure(errors)))
        );
    }
    )
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(InviteActionTypes.DeleteAction),
    map((action: DeleteAction) => action.payload),
    exhaustMap((id: string) => {
      return this.apiInviteService
        .delete(id)
        .pipe(
          map(() => new DeleteActionSuccess(id)),
          catchError((errors) => of(new DeleteActionFailure(errors)))
        );
    }
    )
  );
}
