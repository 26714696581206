import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {TransferHttpCacheModule} from '@nguniversal/common';
import {ActivatedRoute, Router, RouterModule, Scroll, Routes} from '@angular/router';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {AppComponent} from './app.component';
import {SharedModule} from '@shared/shared.module';
import {Wrapper2Component} from '@shared/layouts/wrapper2/wrapper2.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {AuthEffects} from '@core/redux/auth/auth.effects';
import {environment} from '../environments/environment';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {registerLocaleData, ViewportScroller} from '@angular/common';
import {metaReducers, reducers} from '@core/redux/index';
import localeRu from '@angular/common/locales/ru';
import {TokenInterceptor} from '@core/interceptors/token.interceptor';
import {CompanyEffects} from '@core/redux/company/company.effects';
import {CategoryEffects} from '@core/redux/category/category.effects';
import {CourseEffects} from '@core/redux/course/course.effects';
import {WikiEffects} from '@core/redux/wiki/wiki.effects';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {FilterService} from '@core/services/filter/filter.service';
import {TagEffects} from '@core/redux/tag/tag.effects';
import {TestEffects} from '@core/redux/test/test.effects';
import {FieldsOfActivityEffects} from '@core/redux/field-of-activity/field-of-activity.effects';
import {UserStatusEffects} from '@core/redux/user-status/user-status.effects';
import {UserPositionsEffects} from '@core/redux/user-positions/user-positions.effects';
import {InviteEffects} from '@core/redux/invite/invite.effects';
import {KarmaEffects} from '@core/redux/karma/karma.effects';
import {FilterUserService} from '@core/services/filter/filter-user.service';
// import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {GuardAuthService} from '@core/services/entities/auth/guard-auth.service';
import {CompanyInterceptor} from '@core/interceptors/company.interceptor';
import {WrapperComponent} from '@shared/layouts/wrapper/wrapper.component';
import {TestResultsModule} from '@ui/modules/test-results/test-results.module';
import {TakeTestModule} from '@ui/modules/take-test/take-test.module';
import {EventEffects} from '@core/redux/event/event.effects';
import {DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE} from '@ui/modules/datetimepicker/date-time';
import {MomentDateTimeAdapter} from '@ui/modules/datetimepicker/date-time/adapter/moment-adapter/moment-date-time-adapter.class';
import {filter} from 'rxjs/operators';
import {TimeagoModule, TimeagoFormatter} from 'ngx-timeago';
import {CustomTimeagoFormatter} from '@helpers/time/custom-timeago-formatter';
import {UserCompanyEffects} from '@core/redux/user-company/user-company.effects';
import {CommentEffects} from '@core/redux/comment/comment.effects';
import {HAMMER_GESTURE_CONFIG, HammerGestureConfig} from '@angular/platform-browser';
import {OverlayModule} from '@angular/cdk/overlay';
import {OnboardingEffects} from '@core/redux/onboarding/onboarding.effects';
import {OnboardingGuard} from '@core/redux/onboarding/onboarding-guard.service';

// import { DeviceDetectorModule } from 'ngx-device-detector';

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    'pinch': {enable: false},
    'rotate': {enable: false}
  };
}

const routes: Routes = [
  {
    path: 'share',
    loadChildren: () => import('./pages/wiki-shared-page/wiki-shared-page.module').then(m => m.WikiSharedPageModule),
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'dashboard',
    canActivate: [GuardAuthService, OnboardingGuard],
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'affiliate',
    canActivate: [GuardAuthService],
    loadChildren: () => import('./pages/affiliate/affiliate.module').then(m => m.AffiliateModule),
  },
  {
    path: 'onboarding',
    canActivate: [GuardAuthService],
    loadChildren: () => import('./pages/onboarding/onboarding.module').then(m => m.OnboardingModule)
  },
  {
    path: 'payment',
    component: WrapperComponent,
    canActivate: [GuardAuthService],
    loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentModule)
  },
  {
    path: ':company',
    component: Wrapper2Component,
    canActivate: [GuardAuthService],
    children: [
      {
        path: 'learning',
        loadChildren: () => import('./pages/learning/learning.module').then(m => m.LearningModule)
      },
      {
        path: 'events',
        loadChildren: () => import('./pages/events/events.module').then(m => m.EventsModule)
      },
      {
        path: 'wiki',
        loadChildren: () => import('./pages/wiki/wiki.module').then(m => m.WikiModule),
      },
      {
        path: 'users',
        loadChildren: () => import('./pages/user-list/user-list.module').then(m => m.UserListModule),
      },
      {
        path: 'user/:id',
        loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule),
      },
    ]
  },
  {path: '**', redirectTo: ''}
];

registerLocaleData(localeRu);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    // LoadingBarHttpClientModule,
    BrowserModule.withServerTransition({appId: 'my-app'}),
    TransferHttpCacheModule,
    RouterModule.forRoot(routes, {initialNavigation: 'enabledBlocking'}),
    SharedModule.forRoot(),
    TimeagoModule.forRoot({formatter: {provide: TimeagoFormatter, useClass: CustomTimeagoFormatter}}),
    HttpClientModule,
    MatDialogModule,
    //TODO: После обновления на новую весрию ангуляра появились typeError object is not extensible
    StoreModule.forRoot(reducers, {
      metaReducers, runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    TestResultsModule,
    TakeTestModule,
    OverlayModule,
    StoreRouterConnectingModule.forRoot(),
    // DeviceDetectorModule.forRoot(),
    EffectsModule.forRoot([
      AuthEffects,
      CompanyEffects,
      CategoryEffects,
      CommentEffects,
      CourseEffects,
      WikiEffects,
      TagEffects,
      TestEffects,
      UserStatusEffects,
      UserPositionsEffects,
      InviteEffects,
      EventEffects,
      FieldsOfActivityEffects,
      UserCompanyEffects,
      KarmaEffects,
      OnboardingEffects,
    ]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CompanyInterceptor,
      multi: true
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'ru-RU'
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    },
    {provide: LOCALE_ID, useValue: 'ru-RU'},
    {provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE]},
    {
      provide: OWL_DATE_TIME_FORMATS, useValue: {
        parseInput: 'LL LT',
        fullPickerInput: 'LL LT',
        datePickerInput: 'LL',
        timePickerInput: 'LT',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
      }
    },
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'ru'},
    GuardAuthService,
    FilterService,
    FilterUserService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private _router: Router,
              private _route: ActivatedRoute,
              private _viewportScroller: ViewportScroller) {
    _router
      .events
      .pipe(filter(e => e instanceof Scroll))
      .subscribe((e: Scroll) => {
        if (e.position) {
          _viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          _viewportScroller.scrollToAnchor(e.anchor);
        } else {
          if (!_route.snapshot.queryParamMap.get('id')) {
            _viewportScroller.scrollToPosition([0, 0]);
          }
        }
      });
  }
}
