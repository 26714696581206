import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {CreateAction, ResetAction, SearchAction, UpdateAction} from '@core/redux/user-positions/user-positions.actions';
import {UserPositionsModel} from '@core/models/user-positions.model';
import * as fromRoot from '@core/redux';
import * as UserPositionsActions from '@core/redux/user-positions/user-positions.actions';

@Injectable({
  providedIn: 'root',
})
export class UserPositionsService {
  constructor(private store: Store<fromRoot.State>) {

  }

  resetStore() {
    this.store.dispatch(new ResetAction());
  }

  searchAll() {
    this.store.dispatch(new SearchAction());
  }

  create(model: UserPositionsModel) {
    this.store.dispatch(new CreateAction(model));
  }

  update(model: UserPositionsModel) {
    this.store.dispatch(new UpdateAction(model));
  }

  deleteOne(id: string) {
    this.store.dispatch(new UserPositionsActions.DeleteAction(id));
  }

  getAllPositions() {
    return this.store.pipe(select(fromRoot.getUserPositionsEntities));
  }
}
