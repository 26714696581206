import {CategoryActions, CategoryActionTypes} from './category.actions';
import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';
import {CategoryModel, CategoryTypes} from '@core/models/category.model';
import {createSelector} from '@ngrx/store';

export interface State {
  ids: string[];
  loading: boolean;
  updateId: string | null;
  selectedId: string | null;
  entities: any;
  errors: any;
}

export const initialState: State = {
  ids: [],
  loading: false,
  updateId: null,
  selectedId: null,
  entities: {},
  errors: {}
};

export const adapter: EntityAdapter<CategoryModel> = createEntityAdapter<CategoryModel>({
  selectId: (category: CategoryModel) => category.id,
  sortComparer: false,
});

export function reducer(state = initialState, action: CategoryActions): State {
  switch (action.type) {

    case CategoryActionTypes.ResetAction: {
      return Object.assign({}, state, initialState);
    }

    case CategoryActionTypes.SearchSuccessAction: {
      return adapter.setAll(action.payload, state);
    }

    case CategoryActionTypes.SelectUpdateAction: {
      return Object.assign({}, state, {updateId: action.payload});
    }

    case CategoryActionTypes.UpdateAction: {
      return Object.assign({}, state, {
        loading: true,
        errors: {}
      });
    }

    case CategoryActionTypes.UpdateActionSuccess: {
      return adapter.updateOne({
        id: action.payload.id,
        changes: action.payload
      }, Object.assign({}, state, {
        loading: false,
        errors: {}
      }));
    }

    case CategoryActionTypes.UpdateActionFailure: {
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload
      });
    }

    case CategoryActionTypes.CreateAction: {
      return Object.assign({}, state, {
        loading: true,
        errors: {}
      });
    }

    case CategoryActionTypes.CreateActionSuccess: {
      return adapter.addOne(action.payload, Object.assign({}, state, {
        loading: false,
        errors: {}
      }));
    }


    case CategoryActionTypes.CreateActionFailure: {
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload
      });
    }

    case CategoryActionTypes.DeleteAction: {
      return Object.assign({}, state, {
        loading: true
      });
    }

    case CategoryActionTypes.DeleteActionSuccess: {
      return adapter.removeOne(action.payload, Object.assign({}, state, {
        loading: false
      }));
    }

    case CategoryActionTypes.DeleteActionFailure: {
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload
      });
    }

    default:
      return state;
  }
}

export const getErrors = (state: State) => state.errors;
export const getEntitiesObject = (state: State) => state.entities;
export const getIds = (state: State) => state.ids;
export const getLoading = (state: State) => state.loading;
export const getUpdateId = (state: State) => state.updateId;
export const getSelectedId = (state: State) => state.selectedId;
export const getSelectedEntity = createSelector(getEntitiesObject, getSelectedId, (entities, selectedId) => entities[selectedId]);
export const getEntities = createSelector(getEntitiesObject, getIds, (entities, ids) => ids.map(id => entities[id]));
export const getUpdateEntity = createSelector(getEntitiesObject, getUpdateId, (entities, id) => entities[id]);
export const getEntitiesByRootId = (rootId) => createSelector(getEntities, (entities) => {
  return entities.filter(item => item.rootId === rootId);
});
export const getEntitiesByType = (type: CategoryTypes) => createSelector(getEntities, (entities) => {
  return entities.filter(item => item.type === type);
});
