import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, CanActivateChild} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs/Observable';
import {map, take} from 'rxjs/operators';
import 'rxjs/add/observable/zip';
import * as fromRoot from '@core/redux/index';
import { AuthService } from './auth.service';

@Injectable()
export class GuardAuthService implements CanActivate, CanActivateChild {
  constructor(
    private authService: AuthService,
    private router: Router,
    private store: Store<fromRoot.State>,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const isAuth$ = this.store.pipe(select(fromRoot.getAuthIsGuest));
    const authStep$ = this.store.pipe(select(fromRoot.getAuthStep));
    const url: string = state.url;
    const step1 = '/auth/sign-up-step-1';
    const step2 = '/auth/sign-up-step-2';
    const step3 = '/auth/sign-up-step-3';
    const step4 = '/auth/sign-up-step-4';
    if (!url.includes('/auth')) {
      this.authService.fetchUser();
    }
    return Observable
      .zip(
        isAuth$,
        authStep$
      )
      .pipe(
        map(([isAuth, authStep]) => {
          if (isAuth) {
            // Если пользователь прошел все этапы регистрации и он авторизован
            if (isAuth && authStep === 'end' && url.indexOf('/auth/') > -1 && state.url.indexOf('change-password') === -1) {
              this.router.navigate(['/dashboard']);
              return false;
            }

            if (isAuth && authStep === 'end') {
              return true;
            }

            if (authStep === 'personalData' && url !== step1) {
              this.router.navigate([step1]);
              return false;
            }

            if (authStep === 'personalContact' && url !== step2) {
              this.router.navigate([step2]);
              return false;
            }

            if (authStep === 'aboutCompany' && url !== step3) {
              this.router.navigate([step3]);
              return false;
            }

            return true;
          } else if (url.indexOf('/auth/') === -1) {
            this.router.navigate(['/auth/sign-in']);
            return false;
          }

          return true;
        }),
        take(1)
      );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
}
