import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { KarmaActionTypes, GetAction, GetActionSuccess, GetActionFailure, PostAction, PostActionSuccess, PostActionFailure, DeleteAction, DeleteActionSuccess, DeleteActionFailure } from './karma.actions';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs/index';
import { Store } from '@ngrx/store';
import * as fromRoot from '@core/redux/index';
import { ApiKarmaService } from '@core/services/entities/karma/api-karma.service';

@Injectable()
export class KarmaEffects {
  constructor(private actions$: Actions,
    private store: Store<fromRoot.State>,
    private apiService: ApiKarmaService,
  ) {
  }

  @Effect()
  get$ = this.actions$.pipe(
    ofType(KarmaActionTypes.GetAction),
    exhaustMap((action: GetAction) => {
      return this.apiService
        .get(action.userId)
        .pipe(
          map((response) => new GetActionSuccess(response)),
          catchError((errors) => of(new GetActionFailure(errors)))
        );
    }
    )
  );

  @Effect()
  post$ = this.actions$.pipe(
    ofType(KarmaActionTypes.PostAction),
    exhaustMap((action: PostAction) => this
      .apiService
      .post(action.newKarma)
      .pipe(
        map(karma => new PostActionSuccess(karma)),
        catchError(errors => of(new PostActionFailure(errors)))
      ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(KarmaActionTypes.DeleteAction),
    exhaustMap((action: DeleteAction) => this
      .apiService
      .delete(action.karmaId)
      .pipe(
        map(() => new DeleteActionSuccess(action.karmaId)),
        catchError(errors => of(new DeleteActionFailure(errors)))
      ))
  );

}
