import {Inject, Injectable, Optional, PLATFORM_ID} from '@angular/core';
import {SignInModel} from '@core/models/sign-in.model';
import {SignUpModel} from '@core/models/sign-up.model';
import {SignUpPersonalDataModel} from '@core/models/sign-up-personal-data.model';
import {SignUpPersonalContactModel} from '@core/models/sign-up-personal-contact.model';
import {SignUpCompanyModel} from '@core/models/sign-up-company.model';
import {IdentityModel} from '@core/models/identity.model';
import {map} from 'rxjs/operators';
import {UserModel} from '@core/models/user.model';
import {CompanyModel} from '@core/models/company.model';
import {ApiService} from '@core/services/entities/api.service';
import {HttpClient} from '@angular/common/http';
import {RESPONSE} from '@nguniversal/express-engine/tokens';

@Injectable({
  providedIn: 'root',
})
export class ApiAuthService extends ApiService {

  constructor(protected http: HttpClient,
              @Inject(PLATFORM_ID) private platform: any,
              @Optional() @Inject(RESPONSE) private _response: any,) {
    super(http);
  }

  signOut() {
    return this
      .http
      .delete(this.getApiUrl('authentications/sign-out'));
  }

  signUp(model: SignUpModel) {
    return this
      .http
      .post<IdentityModel>(this.getApiUrl('authentications/sign-up'), model)
      .pipe(map((response) => {
        return (new IdentityModel()).setAttributes(response);
      }));
  }

  getUser() {
    return this
      .http
      .get<IdentityModel>(this.getApiUrl('authentications/get-user'))
      .pipe(map((response) => (new IdentityModel()).setAttributes(response)));
  }

  signIn(model: SignInModel) {
    return this
      .http
      .post<IdentityModel>(this.getApiUrl('authentications/sign-in'), model)
      .pipe(map((response) => {
        return (new IdentityModel()).setAttributes(response);
      }));
  }

  signUpStepPersonalData(model: SignUpPersonalDataModel) {
    return this
      .http
      .put<IdentityModel>(this.getApiUrl('authentications/sign-up-step-personal-data'), model)
      .pipe(map((response) => (new IdentityModel()).setAttributes(response)));
  }

  signUpStepPersonalContact(model: SignUpPersonalContactModel) {
    return this
      .http
      .put<IdentityModel>(this.getApiUrl('authentications/sign-up-step-personal-contact'), model)
      .pipe(map((response) => (new IdentityModel()).setAttributes(response)));
  }

  signUpStepCompany(model: SignUpCompanyModel) {
    return this
      .http
      .put<IdentityModel>(this.getApiUrl('authentications/sign-up-step-company'), model)
      .pipe(map((response) => (new IdentityModel()).setAttributes(response)));
  }

  profileUpdate(model: IdentityModel | UserModel) {
    return this
      .http
      .put<IdentityModel>(this.getApiUrl('profiles'), model)
      .pipe(map((response) => (new IdentityModel()).setAttributes(response)));
  }

  confirmation(token: string) {
    return this
      .http
      .get(this.getApiUrl(`authentications/confirm-invite?token=${token}`))
      .pipe(
        map((response) => (new IdentityModel()).setAttributes(response))
      );
  }

  getCompanyBySignUpToken(token: string) {
    return this
      .http
      .get(this.getApiUrl(`authentications/get-company-by-sign-up-token?token=${token}`))
      .pipe(
        map((response) => (new CompanyModel()).setAttributes(response))
      );
  }

  confirmationInviteLink(token: string) {
    return this
      .http
      .post(this.getApiUrl(`authentications/confirm-invite-link?token=${token}`), {})
      .pipe(
        map((response) => (new IdentityModel()).setAttributes(response))
      );
  }

  confirmationEmail(token: any) {
    return this
      .http
      .get(this.getApiUrl(`authentications/confirmation?token=${token}`))
      .pipe(
        map((response) => (new IdentityModel()).setAttributes(response))
      );
  }

  passwordRecovery(email: string) {
    return this
      .http
      .post(this.getApiUrl('authentications/password-recovery'), {email});
  }

  passwordRecoveryConfirm(token: string) {
    return this
      .http
      .post<IdentityModel>(this.getApiUrl('authentications/password-recovery-confirm'), {token})
      .pipe(
        map((response) => {
          return (new IdentityModel()).setAttributes(response);
        })
      );
  }

  changePassword(password: any) {
    return this
      .http
      .put(this.getApiUrl(`profiles/change-password`), password);
  }
}
