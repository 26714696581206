import {Injectable} from '@angular/core';
import {TagModel} from '@core/models/tag.model';
import 'rxjs/add/operator/map';
import {map} from 'rxjs/operators';
import {ApiService} from '@core/services/entities/api.service';

@Injectable({
  providedIn: 'root',
})
export class ApiTagService extends ApiService {
  search() {
    return this
      .http
      .get<TagModel[]>(this.getApiUrl('tags'))
      .pipe(
        map((response) => response.map(model => (new TagModel()).setAttributes(model)))
      );
  }

  update(model: TagModel) {
    return this
      .http
      .put<TagModel>(this.getApiUrl(`tags/${model.id}`), model)
      .pipe(
        map((response) => (new TagModel()).setAttributes(response))
      );
  }

  create(model: TagModel) {
    return this
      .http
      .post<TagModel>(this.getApiUrl(`tags`), model)
      .pipe(
        map((response) => (new TagModel()).setAttributes(response))
      );
  }

  delete(id: string) {
    return this
      .http
      .delete(this.getApiUrl(`tags/${id}`));
  }
}
