import {IdentityModel} from '@core/models/identity.model';

export function getUser(): IdentityModel | null {
  const authData = localStorage.getItem('auth');
  if (authData) {
    return JSON.parse(authData).user;
  } else {
    return null;
  }
}
