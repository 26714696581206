import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiTreeComponent} from '@ui/modules/ui-category/components/ui-tree/ui-tree.component';
import {UiSelectCategoryComponent} from '@ui/modules/ui-category/components/ui-select-category/ui-select-category.component';
import {UiPopupCategoryComponent} from '@ui/modules/ui-category/components/ui-popup-category/ui-popup-category.component';
import {PopupDeleteCategoryComponent} from '@ui/modules/ui-category/components/popups/popup-delete-category/popup-delete-category.component';
import {PopupChangeCategoryComponent} from '@ui/modules/ui-category/components/popups/popup-change-category/popup-change-category.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UiPopupModule} from '@ui/modules/ui-popup/ui-popup.module';
import {ClickOutsideModule} from 'ng-click-outside';
import {UiContainerModule} from '@ui/modules/ui-container/ui-container.module';
import {UiMultiSelectModule} from '@ui/modules/ui-multi-select/ui-multi-select.module';
import {UiBtnEntityCreateModule} from '@ui/modules/buttons/ui-btn-entity-create/ui-btn-entity-create.module';
import { TooltipModule } from '../tooltip/tooltip.module';

@NgModule({
  declarations: [
    UiTreeComponent,
    UiSelectCategoryComponent,
    UiPopupCategoryComponent,
    PopupDeleteCategoryComponent,
    PopupChangeCategoryComponent
  ],
  exports: [
    UiTreeComponent,
    UiSelectCategoryComponent,
    PopupChangeCategoryComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UiPopupModule,
    ClickOutsideModule,
    UiContainerModule,
    UiMultiSelectModule,
    UiBtnEntityCreateModule,
    TooltipModule,
  ],
})
export class UiCategoryModule {
}
