import {Injectable} from '@angular/core';
import * as fromRoot from '@core/redux';
import {select, Store} from '@ngrx/store';
import {DeleteAction, ResetAction, SearchAction, SelectUpdateAction, UpdateAction} from '@core/redux/tag/tag.actions';
import {TagModel} from '@core/models';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TagService {
  constructor(private store: Store<fromRoot.State>) {

  }

  getUpdate() {
    return this.store.pipe(select(fromRoot.getTagUpdate)).pipe(filter(item => !!item));
  }

  getLoading() {
    return this.store.pipe(select(fromRoot.getTagLoading));
  }

  getTagForTest() {
    return this.store.pipe(select(fromRoot.getTagEntitiesByType('test')));

  }

  getTagForWiki() {
    return this.store.pipe(select(fromRoot.getTagEntitiesByType('wiki')));

  }

  getTagForCourse() {
    return this.store.pipe(select(fromRoot.getTagEntitiesByType('course')));
  }

  searchAll() {
    this.store.dispatch(new SearchAction());
  }

  resetStore() {
    this.store.dispatch(new ResetAction());
  }

  setUpdateId(id: string) {
    this.store.dispatch(new SelectUpdateAction(id));
  }

  update(model: TagModel) {
    this.store.dispatch(new UpdateAction(model));
  }

  delete(id: string) {
    this.store.dispatch(new DeleteAction(id));
  }
}
