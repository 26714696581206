import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs/Subscription';
import { CategoryModel } from '@core/models/category.model';
import { ActivatedRoute, Router } from '@angular/router';
import { pluck } from 'rxjs/operators';
import * as fromRoot from '@core/redux';

@Component({
  selector: 'learning-categories',
  templateUrl: './learning-categories.component.html',
  styleUrls: ['./learning-categories.component.less']
})
export class LearningCategoriesComponent implements OnInit, OnDestroy {
  private _subscriptions$: Subscription = new Subscription();
  @Input() allowCrud = false;
  @Input() categories: CategoryModel[] = [];
  @Input() route: ActivatedRoute;
  @Input() mobileMode = false;

  /**
   * Открыто ли меню?
   */
  isMenuOpened: boolean;
  /**
   * Типы сущностей
   */
  options = [
    {icon: 'hat', color: 'blue', text: 'Курсы', type: 'courses', categoryType: 'course'},
    {icon: 'task', color: 'green', text: 'Тесты', type: 'tests', categoryType: 'test'},
  ];
  /**
   * Выбранная сущность
   */
  currentOption = this.options[0];
  selectedId: string | null;

  constructor(private _store: Store<fromRoot.State>,
              private _router: Router,
              private _route: ActivatedRoute,
              private _location: Location) {
  }

  ngOnInit() {
    this._subscriptions$
      .add(this
        ._route
        .queryParams
        .pipe(
          pluck('categoryId'),
        )
        .subscribe((categoryId: string) => {
          this.selectedId = categoryId || null;
        })
      );
    this._subscriptions$
      .add(this
        ._location.subscribe(() => this.findCurrentOption()));
    this.findCurrentOption();
    this.subscribeToCategories();
  }

  ngOnDestroy() {
    this._subscriptions$.unsubscribe();
  }

  onFilter(categoryId) {
    this._router.navigate(['..', 'active'], {
      queryParams: {
        categoryId: categoryId,
      },
      relativeTo: this._route
    });
  }

  /**
   * Открыть или закрыть меню
   */
  onToggleMenu() {
    this.isMenuOpened = !this.isMenuOpened;
  }

  /**
   */
  private findCurrentOption() {
    const path = this._location.path();
    const typeStartIndex = path.indexOf('learning') + 'learning/'.length;
    const partOfPathAfterLearning = path.slice(typeStartIndex);
    const type = partOfPathAfterLearning.split('/')[0];
    this.currentOption = this.options.find(o => o.type === type) || this.options[0];
  }

  /**
   * Подписаться на категории из стора
   */
  private subscribeToCategories() {
    this
      ._subscriptions$
      .add(this
        ._store
        .pipe(select(fromRoot.getCategoryEntitiesByType('test')))
        .subscribe((categories) => {
          this.categories = categories;
        })
      );
  }
}
