import {Injectable} from '@angular/core';
import {CategoryModel, CategorySortModel} from '@core/models/category.model';
import 'rxjs/add/operator/map';
import {map} from 'rxjs/operators';
import {CategoryDeleteInterface} from '@core/interfaces/category-delete.interface';
import {ApiService} from '@core/services/entities/api.service';

@Injectable({
  providedIn: 'root',
})
export class ApiCategoryService extends ApiService {
  search() {
    return this
      .http
      .get<CategoryModel[]>(this.getApiUrl(`categories`))
      .pipe(
        map((response) => response.map(model => (new CategoryModel()).setAttributes(model)))
      );
  }

  sort(model: CategorySortModel) {
    return this.http.put<CategorySortModel>(this.getApiUrl(`categories/${model.id}`), model);
  }

  update(model: CategoryModel) {
    return this
      .http
      .put<CategoryModel>(this.getApiUrl(`categories/${model.id}`), model)
      .pipe(
        map((response) => (new CategoryModel()).setAttributes(response))
      );
  }

  create(model: CategoryModel) {
    return this
      .http
      .post<CategoryModel>(this.getApiUrl(`categories`), model)
      .pipe(
        map((response) => (new CategoryModel()).setAttributes(response))
      );
  }

  checkEmpty(id: string) {
    return this.http.get(this.getApiUrl(`categories/check-empty/${id}`));
  }

  delete(data: CategoryDeleteInterface) {
    return this.http.delete<CategoryModel>(this.getApiUrl(`categories/${data.id}?moveTo=${data.moveTo}`));
  }
}
