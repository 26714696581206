import {Injectable} from '@angular/core';
import {TestModel, TestQueryModel, PostTestModel, PutTestModel} from '@core/models';
import 'rxjs/add/operator/map';
import {map} from 'rxjs/operators';
import {PageResult} from '@core/models/filters/page-result';
import {ApiService} from '@core/services/entities/api.service';


@Injectable({
  providedIn: 'root',
})
export class ApiTestService extends ApiService {
  delete(id: string) {
    return this
      .http
      .delete(this.getApiUrl(`tests/${id}`));
  }

  recovery(id: string) {
    return this
      .http
      .put(this.getApiUrl(`tests/recovery/${id}`), {});
  }

  search(query: TestQueryModel) {
    return this
      .http
      .get<TestModel[]>(this.getApiUrl(`tests?${query.getQuery()}`), {observe: 'response'})
      .pipe(
        map((response) => new PageResult<TestModel[]>(
          response,
          response.body
        ))
      );
  }

  update(model: PutTestModel) {
    return this
      .http
      .put<TestModel>(this.getApiUrl(`tests/${model.id}`), model);
  }

  view(id: string) {
    return this
      .http
      .get<TestModel>(this.getApiUrl(`tests/${id}`));
  }

  create(model: PostTestModel) {
    return this
      .http
      .post<TestModel>(this.getApiUrl(`tests`), model);
  }

  changeCategory(ids: string[], categoryId: string) {
    return this
      .http
      .put<TestModel[]>(this.getApiUrl(`tests/change-category/${categoryId}`), {
        ids: ids
      });
  }
}
