import { AnswerModel, NewAnswerModel, getEmptyAnswer } from './answer.model';

import uuid from 'uuid/v4';

/**
 * Основные поля вопроса
 */
interface BaseQuestionModel {
  /**
   * Текст вопроса
   */
  question: string;
  text: any;
}

/**
 * Новый вопрос, созданный в форме теста
 */
export interface NewQuestionModel extends BaseQuestionModel {
  /**
   * Новые ответы, созданные пользователем
   */
  answers: NewAnswerModel[];
}

/**
 * Вопрос, приходящий с сервера и отправляемый на сервер
 * Отличается от `NewQuestionModel` тем,
 * что он сам и его ответы имеют `id`,
 * сгенерированные клиентом
 */
export interface QuestionModel extends BaseQuestionModel {
  /**
   * UUID
   * Генерируется клиентом перед отправкой
   */
  id: string;
  /**
   * Ответы с `id`
   */
  answers: AnswerModel[];
}

export function addUUID(question: NewQuestionModel): QuestionModel {
  const id = (question as QuestionModel).id || uuid();
  const answers = question.answers
    .map<AnswerModel>(a => ({ ...a, id: (a as AnswerModel).id || uuid() }));
  return {
    ...question,
    id,
    answers
  };
}

/**
 * Возвращает пустой вопрос
 * @param withEmptyAnswer добавить пустой ответ?
 */
export function getEmptyQuestion(withEmptyAnswer = false): NewQuestionModel {
  return {
    question: '',
    text: '',
    answers: withEmptyAnswer ? [getEmptyAnswer()] : []
  };
}
