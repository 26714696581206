import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiBtnEntityCreateComponent} from '@ui/modules/buttons/ui-btn-entity-create/ui-btn-entity-create.component';

@NgModule({
  declarations: [
    UiBtnEntityCreateComponent
  ],
  exports: [
    UiBtnEntityCreateComponent
  ],
  imports: [
    CommonModule
  ]
})
export class UiBtnEntityCreateModule {
}
