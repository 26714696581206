import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiHeaderComponent} from '@ui/modules/header/ui-header/ui-header.component';
import {RouterModule} from '@angular/router';
import {UiPopupModule} from '@ui/modules/ui-popup/ui-popup.module';
import {UiSelectModule} from '@ui/modules/ui-select/ui-select.module';
import {UiPopupMenuItemModule} from '@ui/modules/ui-popup-menu-item/ui-popup-menu-item.module';
import {ClickOutsideModule} from 'ng-click-outside';
import { UiHeaderBurgerMenuModule } from '@ui/modules/header/ui-header-burger-menu/ui-header-burger-menu.module';

@NgModule({
  declarations: [
    UiHeaderComponent
  ],
  exports: [
    UiHeaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    UiHeaderBurgerMenuModule,
    UiPopupModule,
    UiSelectModule,
    UiPopupMenuItemModule,
    ClickOutsideModule
  ]
})
export class UiHeaderModule {
}
