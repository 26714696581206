import { createAction, props } from '@ngrx/store';
import { OnboardingRequest, StudyCreatingFromLinkStep, OnboardingResponse, StudyWritingArticleStep, StudyAddingUserStep, StudySettingUpUserStep } from '@core/redux/onboarding/onboarding.model';

export const clearOnboarding = createAction('[onboarding] clear');
export const resetOnboardingSteps = createAction('[onboarding] reset onboarding steps');
export const getOnboarding = createAction('[sign up page] get onboarding');
// export const postOnboarding = createAction('[sign up page] post onboarding');
export const updateOnboarding = createAction('[onboarding] update', props<{request: OnboardingRequest}>());
export const changeStudyCreatingFromLinkStep = createAction('[onboarding] change study creating from link step', props<{nextStep: StudyCreatingFromLinkStep}>());
export const changeStudyWritingArticleStep = createAction('[onboarding] change study writing article step', props<{nextStep: StudyWritingArticleStep}>());
export const changeStudyAddingUserStep = createAction('[onboarding] change study adding user step', props<{nextStep: StudyAddingUserStep}>());
export const changeStudySettingUpUserStep = createAction('[onboarding] change study setting up user step', props<{nextStep: StudySettingUpUserStep}>());
export const setFirstLinkArticle = createAction('[onboarding] set first link article', props<{id: string}>());
export const setFirstWrittenArticle = createAction('[onboarding] set first written article', props<{id: string}>());

export const getOnboardingSuccess = createAction('[onboarding server] get onboarding success', props<{response: OnboardingResponse}>());
export const getOnboardingFailure = createAction('[onboarding server] get onboarding failure', props<{error: any}>());
