import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ui-tooltip',
  templateUrl: './ui-tooltip.component.html',
  styleUrls: ['./ui-tooltip.component.less']
})
export class UiTooltipComponent implements OnInit {
  @Input()
  hideTip = false;

  @Input()
  arrow: 'left' | 'right' = 'left';

  @Input()
  position: 'top' | 'bottom' = 'top';

  @Input()
  right: string;

  @Input()
  left: string;

  @Input()
  arrowPos: string;

  @Input()
  textNowrap = false;

  classes = [];

  constructor() {
  }

  ngOnInit() {
    this.classes = ['tooltip__tip_' + this.position, 'tooltip__tip_arrow-' + this.arrow];
    if (this.textNowrap) {
      this.classes.push('tooltip__tip_nowrap');
    }
  }

}
