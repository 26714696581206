import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentViewComponent } from './content-view.component';
import { UiCollapserModule } from '@ui/modules/ui-collapser/ui-collapser.module';
import { UiFilesModule } from '@ui/modules/ui-files/ui-files.module';
import { SafeHtmlModule } from '@pipes/safe-html/safe-html.module';
import { ShortUrlModule } from '@pipes/short-url/short-url.module';

@NgModule({
  declarations: [
    ContentViewComponent,
  ],
  exports: [
    ContentViewComponent
  ],
  imports: [
    CommonModule,
    SafeHtmlModule,
    ShortUrlModule,
    UiCollapserModule,
    UiFilesModule
  ]
})
export class ContentViewModule {
}
