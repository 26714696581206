import { Component, OnInit, Input, OnDestroy, HostListener, ViewChild, TemplateRef, HostBinding, EventEmitter, Output } from '@angular/core';
import { Observable, Subscription, Subject, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';

/**
 * Возможные состояния экрана
 */
enum ScreenMode {
  Mobile = 1,
  Desktop = 2,
}

export type Trigger
  = Observable<any>
  | HTMLElement
  ;

/**
 * Открывающееся адаптивное меню
 * 
 * Есть одно важное ограничение по стилям.
 * Всё внутреннее содержимое нужно выделить в
 * отдельный компонент, иначе стили в мобильном режиме
 * применяться не будут. Связано это с тем, что внутренне
 * содержимое откроется на мобильном не внутри компонента,
 * а в специльно отведенном для ангуляровского диалога
 * месте.
 * 
 * На телефоне открывается с затемнением на весь экран и
 * располагается снизу.
 * На компе открывается небольшое абсолютно спозиционированное
 * окошко.
 * 
 * Принимает три возможных источника сигнала:
 *   1. Открытие
 *   2. Закрытие
 *   3. Переключение
 * 
 * Можно передать один, можно два, можно три.
 * 
 * Источник сигнала (`Triger`) -- это либо экзепляр `Observable`,
 * либо экзепляр `HTMLElement`. В случае, когда источник сигнала
 * является `Observable`, компонент подписывается на него и при
 * каждом событии совершает соответсвующее действие (закрытие,
 * открытие или переключение). В случае, когда источник сигнала
 * является `HTMLElement`, компонент подписывается на события
 * `click` и тоже совершается необходимое действие. Использовать
 * `HTMLElement` удобнее, но `Observable` позволяет объединить
 * несколько источников сигналов.
 * 
 * @example
 * // HTML
 * <button #btn>Open</button>
 * <button (click)="close()">Close 1</button>
 * <button (click)="close()">Close 2</button>
 * <ui-adaptive-popup [openTrigger]="btn" [closeTrigger]="closeTrigger">
 *   <my-popup-content></my-popup-content>
 * </ui-adaptive-popup>
 * // TS
 * export const ExampleComponent {
 *   closeTrigger = new Subject();
 *   close() {
 *     this.closeTrigger.next();
 *   }
 * }
 */
@Component({
  selector: 'ui-adaptive-popup',
  templateUrl: './ui-adaptive-popup.component.html',
  styleUrls: ['./ui-adaptive-popup.component.less']
})
export class UiAdaptivePopupComponent implements OnInit, OnDestroy {
  @Input()
  openTrigger: Trigger;

  @Input()
  closeTrigger: Trigger;

  @Input()
  toggleTrigger: Trigger;

  /**
   * На какой отметке заканчивается мобильный вид
   */
  @Input()
  modeThreshold = 550;

  @Input()
  matDialogConfig: MatDialogConfig<any> = {
    width: '100%',
    closeOnNavigation: true,
    position: {
      bottom: '0'
    }
  };

  @Output()
  open = new EventEmitter();

  @Output()
  close = new EventEmitter();


  @ViewChild('content', { static: false })
  contentTemplateRef: TemplateRef<any>;

  /**
   * Состояние экрана, для которого адаптирован попап сейчас
   * 
   * Если `undefined`, то попапа нет
   */
  popupScreenMode: ScreenMode | undefined;

  ScreenMode = ScreenMode;

  private readonly subscriptions = new Subscription();

  /**
   * Используется для оптимизации
   * 
   * Компонент будет реагировать на ресайз только
   * тогда, когда размер экрана перестанет изменяться
   * в течение небольшого промежутка времени.
   * 
   * @see onResize
   * @see subscribeToResizeEvents
   */
  private readonly resizeEvents$ = new Subject();

  private dialogRef: MatDialogRef<any>;

  get isOpen(): boolean {
    return this.popupScreenMode !== undefined;
  }

  @HostBinding('class.ui-adaptive-popup_shown')
  get isOpenAndDesktop(): boolean {
    return this.popupScreenMode === ScreenMode.Desktop;
  }

  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    if (this.openTrigger) {
      this.subscribeToTrigger(this.openTrigger, () => this.openPopup());
    }
    if (this.closeTrigger) {
      this.subscribeToTrigger(this.closeTrigger, () => this.closePopup());
    }
    if (this.toggleTrigger) {
      this.subscribeToTrigger(this.toggleTrigger, () => this.togglePopup());
    }
    this.subscribeToResizeEvents();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onClickOutside() {
    this.closePopup();
  }

  @HostListener('window:resize')
  onResize() {
    this.resizeEvents$.next();
  }

  private adaptToCurrentScreen() {
    const currentScreenMode = this.getCurrentScreenMode();
    if (currentScreenMode !== this.popupScreenMode) {
      this.closePopup();
    }
  }

  private closePopup() {
    this.popupScreenMode = undefined;
    if (this.dialogRef) {
      this.dialogRef.close();
      this.dialogRef = undefined;
    }
  }

  private openPopup() {
    this.popupScreenMode = this.getCurrentScreenMode();
    if (this.popupScreenMode === ScreenMode.Mobile) {
      this.dialogRef = this.dialog.open(this.contentTemplateRef, this.matDialogConfig);
      const sub = this.dialogRef
        .afterClosed()
        .subscribe(
          () => {
            this.popupScreenMode = undefined;
            this.dialogRef = undefined;
            sub.unsubscribe();
          }
        );
    }
  }

  private togglePopup() {
    if (this.isOpen) {
      this.closePopup();
    } else {
      this.openPopup();
    }
  }

  private getCurrentScreenMode(): ScreenMode {
    return window.innerWidth > this.modeThreshold
      ? ScreenMode.Desktop
      : ScreenMode.Mobile;
  }

  private subscribeToResizeEvents() {
    this.subscriptions.add(this
      .resizeEvents$
      .pipe(
        debounceTime(100)
      )
      .subscribe(() => this.adaptToCurrentScreen())
    );
  }

  private subscribeToTrigger(trigger: Trigger, action: Function) {
    const observableTrigger: Observable<any> = trigger instanceof HTMLElement
      ? fromEvent(trigger, 'click')
      : trigger
      ;
    this.subscriptions.add(
      observableTrigger
        .subscribe(() => action())
    );
  }

}
