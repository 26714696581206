<div [ngClass]="{'select-category__header_bordered': isBordered}" class="select-category__header select-category__header_top" (click)="onOpen()">
  <span class="select-category__header-text">
    {{ header ? header : placeholder }}
  </span>
  <span class="select-category__icon-arrow icon-arrow"></span>
</div>
<ui-container class="container_pd-16 select-category__container" *ngIf="isOpen"
              [ngClass]="{'select-category__container_full-width': isBordered}"
              (clickOutside)="onClose()"
              [exclude]="'.click-outside'"
              [excludeBeforeClick]="true"
              [delayClickOutsideInit]="true">
  <ui-tree #tree
           [rtTooltip]="tooltip"
           [position]="tipPosition"
           [searchPlaceholder]="isAllowEdit ? 'Найти или добавить раздел' : 'Найти раздел'"
           [skipId]="skipId"
           [type]="type"
           [turnSearch]="true"
           [keyboardSupport]="true"
           [categories]="categories"
           [isAllowEdit]="isAllowEdit"
           (changeValue)="onSelect($event)">
  </ui-tree>
</ui-container>
<ng-template #tooltip>
  <rt-onboarding-tooltip
    [lines]="[1,1,1,.5]"
    arrow="right-top"
  >
    <p>
      Это список всех текущих разделов вашей базы знаний
    </p>
    <p>
      Добавьте эту запись в раздел Дизайн или любой другой
    </p>  
  </rt-onboarding-tooltip>
</ng-template>
