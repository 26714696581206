import { Injectable } from '@angular/core';
import { CompanyModel } from '@core/models/company.model';
import { map } from 'rxjs/operators';
import { ApiService } from '@core/services/entities/api.service';

@Injectable({
  providedIn: 'root',
})
export class ApiCompanyService extends ApiService {
  getStart(companyId: string) {
    return this
      .http
      .get<CompanyModel>(this.getApiUrl(`companies/get-start` + (companyId ? `?companyId=${companyId}` : '')))
      .pipe(map((response) => (new CompanyModel()).setAttributes(response)));
  }

  getAll() {
    return this
      .http
      .get<CompanyModel[]>(this.getApiUrl(`companies?expand=${this.getExpand()}`))
      .pipe(
        map((response) => response.map(model => (new CompanyModel()).setAttributes(model)))
      );
  }

  create(model: CompanyModel) {
    return this
      .http
      .post<CompanyModel>(this.getApiUrl(`companies?expand=${this.getExpand()}`), model)
      .pipe(
        map((response) => (new CompanyModel()).setAttributes(response))
      );
  }

  update(model: CompanyModel) {
    return this
      .http
      .put<CompanyModel>(this.getApiUrl(`companies/${model.id}?expand=${this.getExpand()}`), model)
      .pipe(
        map((response) => (new CompanyModel()).setAttributes(response))
      );
  }

  left(id: string) {
    return this
      .http
      .put<CompanyModel>(this.getApiUrl(`companies/left/${id}?expand=${this.getExpand()}`), {})
      .pipe(
        map((response) => (new CompanyModel()).setAttributes(response))
      );
  }

  confirmInviteUser(id: string) {
    return this
      .http
      .put<CompanyModel>(this.getApiUrl(`companies/confirm-invite/${id}?expand=${this.getExpand()}`), {})
      .pipe(
        map((response) => (new CompanyModel()).setAttributes(response))
      );
  }

  private getExpand() {
    return 'countWiki,countTest,countCourse,countUsers,owner';
  }
}
