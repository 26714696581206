import {Action} from '@ngrx/store';
import {CategoryModel, CategorySortModel} from '@core/models/category.model';
import {CategoryDeleteInterface} from '@core/interfaces/category-delete.interface';

export enum CategoryActionTypes {
  SelectUpdateAction = '[Category] Select Update',
  SortAction = '[Category] Sort',
  ResetAction = '[Category] ResetAction',

  SearchAction = '[Category] Search',
  SearchSuccessAction = '[Category] Search Success',
  SearchFailureAction = '[Category] Search Failure',

  UpdateAction = '[Category] Update',
  UpdateActionSuccess = '[Category] Update Success',
  UpdateActionFailure = '[Category] Update Failure',

  DeleteAction = '[Category] Delete',
  DeleteActionSuccess = '[Category] Delete Success',
  DeleteActionFailure = '[Category] Delete Failure',

  CreateAction = '[Category] Create',
  CreateActionSuccess = '[Category] Create Success',
  CreateActionFailure = '[Category] Create Failure',
}

export class ResetAction implements Action {
  readonly type = CategoryActionTypes.ResetAction;
}

export class SearchAction implements Action {
  readonly type = CategoryActionTypes.SearchAction;

  constructor() {
  }
}

export class SearchSuccessAction implements Action {
  readonly type = CategoryActionTypes.SearchSuccessAction;

  constructor(public payload: CategoryModel[]) {
  }
}

export class SearchFailureAction implements Action {
  readonly type = CategoryActionTypes.SearchFailureAction;

  constructor(public payload: any) {
  }
}

export class SelectUpdateAction implements Action {
  readonly type = CategoryActionTypes.SelectUpdateAction;

  constructor(public payload: string | null) {
  }
}

export class UpdateAction implements Action {
  readonly type = CategoryActionTypes.UpdateAction;

  constructor(public payload: CategoryModel) {
  }
}


export class UpdateActionSuccess implements Action {
  readonly type = CategoryActionTypes.UpdateActionSuccess;

  constructor(public payload: CategoryModel) {
  }
}

export class UpdateActionFailure implements Action {
  readonly type = CategoryActionTypes.UpdateActionFailure;

  constructor(public payload: any) {
  }
}

export class DeleteAction implements Action {
  readonly type = CategoryActionTypes.DeleteAction;

  constructor(public payload: CategoryDeleteInterface) {
  }
}


export class DeleteActionSuccess implements Action {
  readonly type = CategoryActionTypes.DeleteActionSuccess;

  constructor(public payload: string) {
  }
}

export class DeleteActionFailure implements Action {
  readonly type = CategoryActionTypes.DeleteActionFailure;

  constructor(public payload: any) {
  }
}

export class SortAction implements Action {
  readonly type = CategoryActionTypes.SortAction;

  constructor(public payload: CategorySortModel) {
  }
}

export class CreateAction implements Action {
  readonly type = CategoryActionTypes.CreateAction;

  constructor(public payload: CategoryModel) {
  }
}


export class CreateActionSuccess implements Action {
  readonly type = CategoryActionTypes.CreateActionSuccess;

  constructor(public payload: CategoryModel) {
  }
}

export class CreateActionFailure implements Action {
  readonly type = CategoryActionTypes.CreateActionFailure;

  constructor(public payload: any) {
  }
}

export type CategoryActions = SearchAction
  | SearchSuccessAction
  | SearchFailureAction
  | SelectUpdateAction
  | UpdateAction
  | UpdateActionSuccess
  | UpdateActionFailure
  | DeleteAction
  | DeleteActionSuccess
  | DeleteActionFailure
  | SortAction
  | CreateAction
  | CreateActionSuccess
  | CreateActionFailure
  | ResetAction
  ;
