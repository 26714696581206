import {UserStatusActions, UserStatusActionTypes} from './user-status.actions';
import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';
import {UserStatusModel} from '@core/models/user-status.model';
import {createSelector} from '@ngrx/store';

export interface State {
  ids: string[];
  loading: boolean;
  updateId: string | null;
  selectedId: string | null;
  entities: any;
  errors: any;
}

export const initialState: State = {
  ids: [],
  loading: false,
  updateId: null,
  selectedId: null,
  entities: {},
  errors: {}
};

export const adapter: EntityAdapter<UserStatusModel> = createEntityAdapter<UserStatusModel>({
  selectId: (userStatus: UserStatusModel) => userStatus.id,
  sortComparer: false,
});

export function reducer(state = initialState, action: UserStatusActions): State {
  switch (action.type) {

    case UserStatusActionTypes.ResetAction: {
      return Object.assign({}, state, initialState);
    }

    case UserStatusActionTypes.SearchAction: {
      return Object.assign({}, state, {loading: true});
    }

    case UserStatusActionTypes.SearchSuccessAction: {
      return adapter.setAll(action.payload, Object.assign({}, state, {loading: false}));
    }

    case UserStatusActionTypes.SelectUpdateAction: {
      return Object.assign({}, state, {updateId: action.payload, loading: false});
    }

    case UserStatusActionTypes.UpdateAction: {
      return Object.assign({}, state, {loading: true});
    }

    case UserStatusActionTypes.UpdateActionSuccess: {
      return adapter.updateOne({
        id: action.payload.id,
        changes: action.payload
      }, Object.assign({}, state, {loading: false}));
    }

    case UserStatusActionTypes.CreateAction: {
      return Object.assign({}, state, {loading: true});
    }


    case UserStatusActionTypes.CreateActionSuccess: {
      return adapter.addOne(action.payload, Object.assign({}, state, {loading: false}));
    }

    case UserStatusActionTypes.DeleteAction: {
      return Object.assign({}, state, {loading: true});
    }

    case UserStatusActionTypes.DeleteActionSuccess: {
      return adapter.removeOne(action.payload, Object.assign({}, state, {
        loading: false
      }));
    }

    case UserStatusActionTypes.DeleteActionFailure: {
      return Object.assign({}, state, {loading: false});
    }

    default:
      return state;
  }
}

export const getErrors = (state: State) => state.errors;
export const getEntitiesObject = (state: State) => state.entities;
export const getIds = (state: State) => state.ids;
export const getLoading = (state: State) => state.loading;
export const getUpdateId = (state: State) => state.updateId;
export const getSelectedId = (state: State) => state.selectedId;
export const getSelectedEntity = createSelector(getEntitiesObject, getSelectedId, (entities, selectedId) => entities[selectedId]);
export const getEntities = createSelector(getEntitiesObject, getIds, (entities, ids) => ids.map(id => entities[id]));
