import { FieldOfActivityActions, FieldsOfActivityActionTypes, GetFieldsOfActivityActionSuccess } from './field-of-activity.actions';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { FieldOfActivity } from '@core/models/company.model';
import { createSelector } from '@ngrx/store';

export interface State {
  activities: FieldOfActivity[];
}

export const initialState: State = {
  activities: [],
};

export function reducer(state = initialState, action: FieldOfActivityActions): State {
  switch (action.type) {

    case FieldsOfActivityActionTypes.GetFieldsOfActivityActionSuccess: {
      const { activities } = action as GetFieldsOfActivityActionSuccess;
      return { activities };
    }

    default:
      return state;
  }
}
