import {EventActions, EventActionTypes} from './event.actions';
import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';
import {EventModel} from '@core/models';
import {createSelector} from '@ngrx/store';
import * as _ from 'lodash';

export interface State {
  ids: string[];
  loading: boolean;
  updateId: string | null;
  selectedId: string | null;
  entities: any;
  errors: any;
  filter: string;
}

export const initialState: State = {
  ids: [],
  loading: false,
  updateId: null,
  selectedId: null,
  entities: {},
  errors: {},
  filter: 'active'
};


export const adapter: EntityAdapter<EventModel> = createEntityAdapter<EventModel>({
  selectId: (event: EventModel) => event.id
});

export function reducer(state = initialState, action: EventActions): State {
  switch (action.type) {

    case EventActionTypes.DeleteAction: {
      return {...state, loading: true};
    }

    case EventActionTypes.DeleteActionSuccess: {
      return adapter.removeOne(action.payload, {...state, loading: false});
    }

    case EventActionTypes.DeleteActionFailure: {
      return {...state, loading: false, errors: action.payload};
    }

    case EventActionTypes.GetEventsAction: {
      return {...state, loading: true, selectedId: null};
    }

    case EventActionTypes.GetEventsSuccessAction: {
      return adapter.setAll(action.payload, {...state, loading: false});
    }

    case EventActionTypes.GetEventsFailureAction: {
      return {...state, loading: false, errors: action.payload};
    }

    case EventActionTypes.SelectAction: {
      return {...state, selectedId: action.payload};
    }

    case EventActionTypes.UpdateActionSuccess: {
      return adapter.updateOne({
        id: action.payload.id,
        changes: action.payload
      }, state);
    }

    case EventActionTypes.CreateActionSuccess: {
      return adapter.addOne(action.payload, state);
    }

    default:
      return state;
  }
}

// Ошибки
export const getErrors = (state: State) => state.errors;

// Записи в виде объектов {id: item}
export const getEntitiesObject = (state: State) => state.entities;

// Все ID объектов в сторе
export const getIds = (state: State) => state.ids;

// Происходит ли обращение в апи
export const getLoading = (state: State) => state.loading;

// Обновляемая запись
export const getUpdateId = (state: State) => state.updateId;

// Выделенный объект
export const getSelectedId = (state: State) => state.selectedId;

// Записи в виде массива
export const getEntitiesArray = createSelector(getEntitiesObject, getIds, (entities, ids) => ids.map(id => entities[id]));

// Список объектов для вывода. Отсортированные по фиксации и сортировке.
export const getEntities = createSelector(getEntitiesArray, (entities) => _.orderBy(entities, ['id'], ['desc']));

// Получить выделенный объект
export const getSelectedEntity = createSelector(getEntitiesObject, getSelectedId, (entities, selectedId) => entities[selectedId]);

// Получить обновляемый объект
export const getUpdateEntity = createSelector(getEntitiesObject, getUpdateId, (entities, id) => entities[id]);

