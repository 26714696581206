import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { UserCompanyActionTypes, SetUserCompanyAction, SetUserCompanyActionSuccess, SetUserCompanyActionFailure, SetUserCompanyContactsAction, SetUserCompanyContactsActionFailure, SetUserCompanyContactsActionSuccess } from './user-company.actions';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs/index';
import { ApiUserCompanyService } from '@core/services/entities/user-company/api-user-company.service';

@Injectable()
export class UserCompanyEffects {
  constructor(private actions$: Actions,
    private apiService: ApiUserCompanyService
  ) {
  }

  @Effect()
  set$ = this.actions$.pipe(
    ofType(UserCompanyActionTypes.SetUserCompanyAction),
    exhaustMap((action: SetUserCompanyAction) =>
      this.apiService
        .put(action.newUserCompany)
        .pipe(
          map((response) => new SetUserCompanyActionSuccess(response)),
          catchError((errors) => of(new SetUserCompanyActionFailure(errors)))
        )),
  );

  @Effect()
  setContacts$ = this.actions$.pipe(
    ofType(UserCompanyActionTypes.SetUserCompanyContactsAction),
    exhaustMap((action: SetUserCompanyContactsAction) =>
      this.apiService
        .putContacts(action.contacts)
        .pipe(
          map(response => new SetUserCompanyContactsActionSuccess(response)),
          catchError(errors => of(new SetUserCompanyContactsActionFailure(errors)))
        )),
  );
}
