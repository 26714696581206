import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { TakeTestService } from '../../services/take-test.service';
import { TestState } from '../../helpers/types';


@Component({
  selector: 'test-footer',
  templateUrl: './test-footer.component.html',
  styleUrls: ['./test-footer.component.less']
})
export class TestFooterComponent implements OnInit {
  isLoading$: Observable<boolean>;
  questionNumber$: Observable<number>;
  questionsCount$: Observable<number>;
  state$: Observable<TestState>;
  time$: Observable<string>;

  constructor(
    private service: TakeTestService,
    ) { }

  ngOnInit() {
    this.isLoading$ = this.service.isLoading;
    this.questionNumber$ = this.service.questionNumber;
    this.questionsCount$ = this.service.test
      .map(test => test.questions.length);
    this.state$ = this.service.state;
    this.time$ = this.service.time;
  }

  onAnswer() {
    this.service.answer();
  }

  onNext() {
    this.service.goToNextQuestionOrResults();
  }

}
