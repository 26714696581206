import { Injectable } from '@angular/core';
import { PostCommentModel, CommentModel, ObjectType, PutCommentModel, CommentQueryModel } from '@core/models';
import { ApiService } from '@core/services/entities/api.service';


@Injectable({
  providedIn: 'root',
})
export class ApiCommentService extends ApiService {
  private baseUrl = this.getApiUrl('comments');

  create(model: PostCommentModel) {
    return this
      .http
      .post<CommentModel>(this.baseUrl, model);
  }

  delete(id: string) {
    return this
      .http
      .delete(this.baseUrl + '/' + id);
  }

  dislike(commentId: string) {
    return this
      .http
      .post(`${this.baseUrl}/dislike/${commentId}`, {});
  }

  get(query: CommentQueryModel) {
    return this
      .http
      .get<CommentModel[]>(`${this.baseUrl}?${query.getQuery()}`);
  }

  like(commentId: string) {
    return this
      .http
      .post<CommentModel>(`${this.baseUrl}/like/${commentId}`, {});
  }

  update(model: PutCommentModel) {
    return this
      .http
      .put<CommentModel>(this.baseUrl + '/' + model.id, model);
  }
}
