import { BaseModel } from './base.model';
import { ContactModel } from './contact.model';
import { InviteAccessInfoInterface } from '@core/models/invite.model';
import { FileModel } from '@core/models/file.model';
import { UserModel } from '@core/models/user.model';

export enum CompanyType {
  Community = 'community',
  Corporate = 'corporate',
  Private = 'private',
}

export class CompanyModel extends BaseModel {
  type: CompanyType;
  id: string;
  name: string;
  email: string;
  phone: string;
  address: string;
  site: string;
  isArchive: boolean;
  signUpToken: string;
  isAllowSignUpByToken: boolean;
  isSignUpConfirm: boolean;
  image: FileModel;
  inviteAccessInfo: InviteAccessInfoInterface;
  countWiki: number;
  countCourse: number;
  countTest: number;
  countUsers: number;
  owner: UserModel;
  isAllowEdit: boolean;
  additionalContacts?: ContactModel[];
  isEmployeeAccessAllowed: boolean;
  rEnable: boolean;
  rCompanyName: string;
  rInn: string;
  rOkpo: string;
  rOgrn: string;
  rBank: string;
  rBik: string;
  rCheckingAccount: string;
  rLegalAddress: string;
  rMailingAddress: string;
}

export class FieldOfActivity {
  id: string;
  name: string;
}
