import {Injectable} from '@angular/core';
import {InviteModel, InviteQueryModel} from '@core/models/invite.model';
import 'rxjs/add/operator/map';
import {map} from 'rxjs/operators';
import {PageResult} from '@core/models/filters/page-result';
import {ApiService} from '@core/services/entities/api.service';

@Injectable({
  providedIn: 'root',
})
export class ApiInviteService extends ApiService {
  search(query: InviteQueryModel) {
    return this
      .http
      .get<InviteModel[]>(this.getApiUrl(`invites?${query.getQuery()}`), {observe: 'response'})
      .pipe(
        map((response) => new PageResult<InviteModel[]>(
          response,
          response.body.map(model => (new InviteModel()).setAttributes(model))
        ))
      );
  }

  view(id: string) {
    return this
      .http
      .get<InviteModel>(this.getApiUrl(`invites/${id}`))
      .pipe(
        map((response) => (new InviteModel()).setAttributes(response))
      );
  }

  update(model: InviteModel) {
    return this
      .http
      .put<InviteModel>(this.getApiUrl(`invites/${model.id}`), model)
      .pipe(
        map((response) => (new InviteModel()).setAttributes(response))
      );
  }

  confirmInviteAdmin(model: InviteModel) {
    return this
      .http
      .put<InviteModel>(this.getApiUrl(`invites/confirm-admin/${model.id}`), model)
      .pipe(
        map((response) => (new InviteModel()).setAttributes(response))
      );
  }

  confirmInviteUser(model: InviteModel) {
    return this
      .http
      .put<InviteModel>(this.getApiUrl(`invites/confirm-user/${model.id}`), model)
      .pipe(
        map((response) => (new InviteModel()).setAttributes(response))
      );
  }

  create(model: InviteModel) {
    return this
      .http
      .post<InviteModel>(this.getApiUrl(`invites`), model)
      .pipe(
        map((response) => (new InviteModel()).setAttributes(response))
      );
  }

  resendConfirmEmail(id: string) {
    return this.http.post(this.getApiUrl(`invites/resend-confirm-email/${id}`), {});
  }

  delete(id: string) {
    return this.http.delete(this.getApiUrl(`invites/${id}`));
  }
}
