<span class="popup__icon-close icon-close" (click)="close.emit()"></span>
<form class="form" [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
    <span class="ui-tag-sidebar-form__label">Редактирование тега</span>
    <div class="form__row" [ngClass]="getDisplayFieldCssClass('name')">
        <label for="name" class="label">Название тега</label>
        <input #inputName id="name" type="text" class="input" value="Новости" formControlName="name">
    </div>

    <div class="form__row" [ngClass]="getDisplayFieldCssClass('combineIds')">
        <label for="name" class="label">Объединить c тегом:</label>
        <ui-select-multi-tag formControlName="combineIds" [items]="filteredTags"></ui-select-multi-tag>
    </div>

    <div class="popup__footer ui-tag-sidebar-form__footer form__row_space-between form__row_no-wrap">
        <button class="button button_mobile button_theme-6"
                type="button"
                (click)="onDelete()">{{isDeleteConfirm ? 'Уверены?' : 'Удалить'}}
        </button>
        <button class="button button_mobile button_theme-1" [disabled]="isLoading || form.invalid">Сохранить</button>
    </div>
</form>
