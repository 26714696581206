<div class="owl-dt-calendar-control">
  <div class="owl-dt-calendar-control-content">
    <button class="owl-dt-control owl-dt-control-button owl-dt-control-period-button"
            type="button" tabindex="0"
            [attr.aria-label]="periodButtonLabel"
            (click)="toggleViews()">
            <span class="owl-dt-control-content owl-dt-control-button-content" tabindex="-1">
              <span class="owl-dt-control-content__date">{{periodButtonText}}</span>

              <span class="owl-dt-control-button-arrow"
                    [style.transform]="'rotate(' + (isMonthView? 0 : 180) +'deg)'">
                   <i class="icon-arrows-three"></i>
                </span>
            </span>
    </button>
  </div>

  <!-- focus when keyboard tab (http://kizu.ru/en/blog/keyboard-only-focus/#x) -->
  <button class="owl-dt-control owl-dt-control-button owl-dt-control-arrow-button"
          type="button" tabindex="0"
          [style.visibility]="showControlArrows? 'visible': 'hidden'"
          [disabled]="!prevButtonEnabled()"
          [attr.aria-label]="prevButtonLabel"
          (click)="previousClicked()">
        <span class="owl-dt-control-content owl-dt-control-button-content" tabindex="-1">
           <i class="icon-arrow icon-arrow_left"></i>
        </span>
  </button>

  <button class="owl-dt-control owl-dt-control-button owl-dt-control-arrow-button"
          type="button" tabindex="0"
          [style.visibility]="showControlArrows? 'visible': 'hidden'"
          [disabled]="!nextButtonEnabled()"
          [attr.aria-label]="nextButtonLabel"
          (click)="nextClicked()">
        <span class="owl-dt-control-content owl-dt-control-button-content" tabindex="-1">
          <i class="icon-arrow icon-arrow_right"></i>
        </span>
  </button>
</div>
<div class="owl-dt-calendar-main" cdkMonitorSubtreeFocus [ngSwitch]="currentView" tabindex="-1">
  <owl-date-time-month-view
    *ngSwitchCase="'month'"
    [events]="events"
    [pickerMoment]="pickerMoment"
    [firstDayOfWeek]="firstDayOfWeek"
    [selected]="selected"
    [selecteds]="selecteds"
    [selectMode]="selectMode"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [dateFilter]="dateFilter"
    [hideOtherMonths]="hideOtherMonths"
    (pickerMomentChange)="handlePickerMomentChange($event)"
    (selectedChange)="dateSelected($event)"
    (userSelection)="userSelected()"></owl-date-time-month-view>

  <owl-date-time-year-view
    *ngSwitchCase="'year'"
    [pickerMoment]="pickerMoment"
    [selected]="selected"
    [selecteds]="selecteds"
    [selectMode]="selectMode"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [dateFilter]="dateFilter"
    (keyboardEnter)="focusActiveCell()"
    (pickerMomentChange)="handlePickerMomentChange($event)"
    (monthSelected)="selectMonthInYearView($event)"
    (change)="goToDateInView($event, 'month')"></owl-date-time-year-view>

  <owl-date-time-multi-year-view
    *ngSwitchCase="'multi-years'"
    [pickerMoment]="pickerMoment"
    [selected]="selected"
    [selecteds]="selecteds"
    [selectMode]="selectMode"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [dateFilter]="dateFilter"
    (keyboardEnter)="focusActiveCell()"
    (pickerMomentChange)="handlePickerMomentChange($event)"
    (yearSelected)="selectYearInMultiYearView($event)"
    (change)="goToDateInView($event, 'year')"></owl-date-time-multi-year-view>
</div>
