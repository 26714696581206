import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiAdaptivePopupComponent } from './ui-adaptive-popup.component';
import { ClickOutsideModule } from 'ng-click-outside';

@NgModule({
  declarations: [UiAdaptivePopupComponent],
  imports: [
    CommonModule,
    ClickOutsideModule,
  ],
  exports: [UiAdaptivePopupComponent]
})
export class UiAdaptivePopupModule { }
