<footer
  *ngIf="state$ | async as state"
  class="test-footer__footer">
  <div class="test-footer__container">
    <div class="test-footer__time">
      {{ time$ | async }}
    </div>
    <div class="test-footer__progress">
      <span class="test-footer__number">
        {{ questionNumber$ | async }}
      </span>
      /{{ questionsCount$ | async }}
    </div>
    <button
      *ngIf="state === 'question'"
      (click)="onAnswer()"
      [disabled]="isLoading$ | async"
      class="button button_theme-1 test-footer__submit"
      type="button">
        Ответить
    </button>
    <button
            *ngIf="state === 'question'"
            (click)="onAnswer()"
            [disabled]="isLoading$ | async"
            class="test-footer__submit-mobile"
            type="button">
      <i class="icon-downarrow icon-arrow-right test-footer__submit-mobile-icon"></i>
    </button>
    <button
      *ngIf="state === 'question-results'"
      (click)="onNext()"
      [disabled]="isLoading$ | async"
      class="button button_theme-1 test-footer__submit"
      type="button">
        Далее
    </button>
    <button
            *ngIf="state === 'question-results'"
            (click)="onNext()"
            [disabled]="isLoading$ | async"
            class="test-footer__submit-mobile"
            type="button">
      <i class="icon-downarrow icon-arrow-right test-footer__submit-mobile-icon"></i>
    </button>
  </div>
</footer>
