<table class="owl-dt-calendar-table owl-dt-calendar-multi-year-table">
  <tbody owl-date-time-calendar-body role="grid"
         [rows]="years" [numCols]="3" [cellRatio]="3 / 7"
         [activeCell]="activeCell"
         [todayValue]="todayYear"
         [selectedValues]="selectedYears"
         [selectMode]="selectMode"
         (keydown)="handleCalendarKeydown($event)"
         (select)="selectCalendarCell($event)"></tbody>
</table>

<div class="owl-dt-controls-bottom">
  <button class="owl-dt-control-button owl-dt-control-arrow-button"
          [disabled]="!previousEnabled()" [attr.aria-label]="prevButtonLabel"
          type="button" tabindex="0" (click)="prevYearList($event)">
    <span class="owl-dt-control-button-content" tabindex="-1">
      <i class="icon-arrow icon-arrow_left"></i>
    </span>
  </button>
  <button class="owl-dt-control-button owl-dt-control-arrow-button"
          [disabled]="!nextEnabled()" [attr.aria-label]="nextButtonLabel"
          type="button" tabindex="0" (click)="nextYearList($event)">
    <span class="owl-dt-control-button-content" tabindex="-1">
             <i class="icon-arrow icon-arrow_right"></i>
    </span>
  </button>
</div>
