import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs';

import {TakeTestService} from '../../services/take-test.service';
import {TmpAnswerModel} from '../../helpers/types';
import {Subscription} from 'rxjs/Subscription';


@Component({
  selector: 'test-question',
  templateUrl: './test-question.component.html',
  styleUrls: ['./test-question.component.less']
})
export class TestQuestionComponent implements OnInit, OnDestroy {
  answers: TmpAnswerModel[];
  question: string;
  text: any;
  private _subscriptions$: Subscription = new Subscription();

  constructor(
    private service: TakeTestService,
  ) {
  }

  ngOnInit() {
    this._subscriptions$.add(this
      .service
      .question
      .subscribe((question) => {
        this.answers = question.answers;
        this.question = question.question;
        this.text = question.text;
      }));
  }

  ngOnDestroy() {
    this._subscriptions$.unsubscribe();
  }

  /**
   * Обновить вопрос после изменения состояния чек бокса
   */
  onAnswerValueChange(answer: TmpAnswerModel, value: boolean) {
    // const nextAnswer = {...answer, isChecked: value};
    // const index = this.question.answers.indexOf(answer);
    // if (index >= 0) {
    //   const answers: TmpAnswerModel[] = [
    //     ...this.question.answers.slice(0, index),
    //     nextAnswer,
    //     ...this.question.answers.slice(index + 1)
    //   ];
    //   const nextQuestion = {...this.question, answers};
    //   this.questionChange.emit(nextQuestion);
    // }
  }

}
