import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { KarmaModel } from '@core/models';
import {
  GetActionSuccess, KarmaActions, KarmaActionTypes, DeleteActionSuccess, PostActionSuccess,
} from './karma.actions';


export interface State extends EntityState<KarmaModel> {
  ids: string[];
}

export const initialState: State = {
  ids: [],
  entities: {}
};

export const adapter: EntityAdapter<KarmaModel> = createEntityAdapter<KarmaModel>({
  selectId: (comment: KarmaModel) => comment.id,
  sortComparer: false,
});


export function reducer(state = initialState, action: KarmaActions): State {
  switch (action.type) {
    case KarmaActionTypes.GetActionSuccess: {
      const { karmas } = action as GetActionSuccess;
      return adapter.setAll(karmas, state);
    }

    case KarmaActionTypes.DeleteActionSuccess: {
      const { karmaId } = action as DeleteActionSuccess;
      return adapter.removeOne(karmaId, state);
    }

    case KarmaActionTypes.PostActionSuccess: {
      const { karma } = action as PostActionSuccess;
      return adapter.addOne(karma, state);
    }

    default:
      return state;
  }
}
