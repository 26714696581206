import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiContainerModule } from '@ui/modules/ui-container/ui-container.module';
import { UiCategoryModule } from '@ui/modules/ui-category/ui-category.module';
import { UiCategoryBlockModule } from '@ui/modules/ui-category-block/ui-category-block.module';
import { RouterModule } from '@angular/router';
import { LearningCategoriesComponent } from '@learning/learning-shared/learning-categories/learning-categories.component';
import { UiPopupModule } from '@ui/modules/ui-popup/ui-popup.module';
import { ClickOutsideModule } from 'ng-click-outside';

@NgModule({
  imports: [
    ClickOutsideModule,
    CommonModule,
    RouterModule,
    UiCategoryModule,
    UiCategoryBlockModule,
    UiContainerModule,
    UiPopupModule,
  ],
  declarations: [
    LearningCategoriesComponent,
  ],
  exports: [
    LearningCategoriesComponent,
  ],
})
export class LearningCategoriesModule {
}
