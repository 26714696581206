<div class="header__inner">
  <div class="header__logo" [ngClass]="{'header__logo_no-company-selected': !company}">
    <a *ngIf="isLogo" routerLink="/dashboard"><b>{{logo}}</b></a>
    <p class="header__logo_company" *ngIf="companies.length" (click)="onTogglePopup()">
      <span
        [title]="company?.name || ''"
        class="header__logo-company-name"
      >
        {{company?.name}}
      </span>
      <span *ngIf="companies.length > 1" class="icon-arrows-three header__logo_company_icon"></span>
    </p>
  </div>
  <ui-header-burger-menu *ngIf="!!companies.length"
                         [companies]="companies"
                         [company]="company"
                         (onOpenCompany)="onOpenCompany($event)"></ui-header-burger-menu>

  <ui-popup
    class="header__company-list header__company-list_desktop"
    *ngIf="isOpenPopup && companies.length > 1"
    [ngStyle]="{'top': '64px', 'max-width': '177px'}">
    <div class="header__company-wrap">
      <!--<ui-popup-menu-item-->
      <!--(click)="onClosePopup()"-->
      <!--iconName="icon-arrow-left">-->
      <!--Назад-->
      <!--</ui-popup-menu-item>-->
      <ui-select-option *ngFor="let item of companies"
                        [class.selected]="item.id === company.id"
                        [model]="item"
                        [value]="item.id"
                        [allowEdit]="false"
                        [excludeBeforeClick]="true"
                        [delayClickOutsideInit]="true"
                        (clickOutside)="onClosePopup()"
                        (select)="onOpenCompany(item.id)">
        <div option-type="name" class="header__company-name">{{item.name}}</div>
      </ui-select-option>
    </div>
  </ui-popup>
  <ng-content></ng-content>
</div>
