<ui-container class="container_pd-24-16 container_mobile">
  <ui-category-block
    (create)="tree.onOpenCreateForm()"
    [isShowCreateButton]="user?.permissions.inviteCrud">
    <span ngProjectAs="header">
      Отделы
    </span>
    <ui-category-block-header-control
      [routerLink]="[mobileMode ? 'users' : '../', 'active']"
      #active="routerLinkActive"
      routerLinkActive
      [isActive]="active.isActive"
      tooltip="Все отделы"
      icon="team"
      color="blue">
    </ui-category-block-header-control>
    <ui-category-block-header-control
      [routerLink]="[mobileMode ? 'users' : '../', 'delete']"
      #delete="routerLinkActive"
      routerLinkActive
      [isActive]="delete.isActive"
      tooltip="Без доступа"
      icon="fired"
      color="blue"></ui-category-block-header-control>
    <ui-category-block-header-control
      #requestsTipContainer
      (click)="onNavigationToRequests()"
      [rtTooltip]="requestsTip"
      [position]="requestsTipPosition"
      [routerLink]="[mobileMode ? 'users' : '../', 'confirmation']"
      #confirmation="routerLinkActive"
      routerLinkActive
      [isActive]="confirmation.isActive"
      [tooltip]="studyingSettingUpUser ? undefined : 'Запросы на доступ'"
      icon="user-indicator"
      color="yellow">
    </ui-category-block-header-control>
    <ui-category-block-header-control
      #invited="routerLinkActive"
      [routerLink]="[mobileMode ? 'users' : '../', 'invited']"
      routerLinkActive
      [isActive]="invited.isActive"
      tooltip="Приглашенные"
      icon="invited-users"
      color="red">
    </ui-category-block-header-control>
    <ui-tree #tree
             type="user"
             [isAllowEdit]="user?.permissions.inviteCrud"
             [allowHierarchy]="false"
             [categories]="categories"
             (changeValue)="onFilter($event)">
    </ui-tree>
  </ui-category-block>
</ui-container>
<ng-template #requestsTip>
  <rt-onboarding-tooltip
    [lines]="[1, 1, .5, .2]"
    arrow="left"
  >
    <p>
      Кто-то уже перешёл по вашей ссылке!
    </p>
    <p>
      Перейдите в раздел<br>
      Запросы на доступ
    </p>
  </rt-onboarding-tooltip>
</ng-template>
