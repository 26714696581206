import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { orderBy } from 'lodash';

import * as fromRoot from '@core/redux/index';
import { IdentityModel } from '@core/models/identity.model';
import { TestService } from '@core/services/entities/test/test.service';
import { TestResultsModel, TestResultModel, TestModel } from '@core/models';
import { stringTimeDiff } from '@helpers/time/diff';


/**
 * Результат с текстовым представлением времени
 */
interface TestResultModelWithStringTime extends TestResultModel {
  stringTime: string;
}

interface TestResultsModelWithStringTime extends TestResultsModel {
  userResult: TestResultModelWithStringTime;
  allResults: TestResultModelWithStringTime[];
  /**
   * Кол-во результатов в статистике
   */
  allResultsCount: number;
}

/**
 * Добавить в один результат текстовое отображение времени
 * @param results 
 */
function addStringTime(results: TestResultModel): TestResultModelWithStringTime {
  if (!results) {
    return results as TestResultModelWithStringTime;
  }
  return {
    ...results,
    stringTime: stringTimeDiff(new Date(results.time * 1000), new Date(0))
  };
}

/**
 * Добавить во все результаты текстовые представления времени и сортировать статистику
 * @param results 
 */
function mapResults(results: TestResultsModel): TestResultsModelWithStringTime {
  if (!results) {
    return results as TestResultsModelWithStringTime;
  }
  const allResults = results.allResults.map(addStringTime);
  return {
    userResult: addStringTime(results.userResult),
    allResults: orderBy(allResults, ['score', 'countCorrectAnswer', 'time'], ['desc', 'desc', 'asc']),
    allResultsCount: Array.isArray(allResults) ? allResults.length : 0
  };
}


export interface MatDialogData {
  test: TestModel;
  lessonId: string | undefined;
}


/**
 * Страница результатов теста
 */
@Component({
  selector: 'test-results',
  templateUrl: './test-results.component.html',
  styleUrls: ['./test-results.component.less']
})
export class TestResultsComponent implements OnDestroy, OnInit {
  /**
   * Показать всю статистику?
   */
  readonly isExpanded$ = new BehaviorSubject<boolean>(false);
  /**
   * Максимальное кол-во строк в статистике
   */
  readonly maxRowsCount = 10;
  user$: Observable<IdentityModel>;

  test: TestModel & { results: TestResultsModelWithStringTime };
  lessonId: string | undefined;

  private readonly subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: MatDialogData,
    private testService: TestService,
    private store: Store<fromRoot.State>,
  ) { }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.user$ = this.store.pipe(select(fromRoot.getAuthUser));
    this.test = { ...this.data.test, results: mapResults(this.data.test.results) };
    this.lessonId = this.data.lessonId;
  }

  onClose() {
    this.testService.closeResults();
  }

  /**
   * Пройти заново
   */
  onStartAgain() {
    this.testService.startTest(this.test, this.lessonId);
  }

  /**
   * Раскрыть или свернуть статистику
   */
  onToggle() {
    this.isExpanded$.next(!this.isExpanded$.value);
  }
}
