<ui-container class="container_pd-24-16 container_mobile">
  <ui-category-block
    [isShowCreateButton]="allowCrud"
    (create)="tree.onOpenCreateForm()">
    <span ngProjectAs="header" class="learning-categories__header">
      <button (click)="onToggleMenu()" class="button button_not-button learning-categories__select-button">
        <span>
          <i
            class="icon-{{currentOption.icon}} icon-{{currentOption.color}} learning-categories__select-button-icon"></i>
          {{currentOption.text}}
        </span>
        <i [class.learning-categories__icon_rotated]="isMenuOpened" class="icon-arrow"></i>
      </button>
      <ui-popup *ngIf="isMenuOpened"
                (clickOutside)="onToggleMenu()"
                [delayClickOutsideInit]="true"
                class="learning-categories__popup">
        <div class="learning-categories__menu">
          <a *ngFor="let option of options"
             (click)="onToggleMenu()"
             [routerLink]="['../../', option.type]"
             routerLinkActive="learning-categories__menu-link_current"
             class="link link_not-link learning-categories__menu-link">
            <span>
              <i class="icon-{{option.icon}} icon-{{option.color}} learning-categories__menu-link-icon"></i>
              {{option.text}}
            </span>
            <i *ngIf="option == currentOption" class="icon-done icon-green"></i>
          </a>
        </div>
      </ui-popup>
    </span>
    <ui-category-block-header-control
      [routerLink]="[mobileMode ? ('learning/' + currentOption.type) : '../', 'active']"
      #active="routerLinkActive"
      routerLinkActive
      [isActive]="active.isActive"
      tooltip="Все разделы"
      icon="home"
      color="blue">
    </ui-category-block-header-control>
    <ui-category-block-header-control
      [routerLink]="[mobileMode ? ('learning/' + currentOption.type) : '../', 'unsorted']"
      #unsorted="routerLinkActive"
      routerLinkActive
      [isActive]="unsorted.isActive"
      tooltip="Без раздела"
      icon="documents"
      color="blue">
    </ui-category-block-header-control>
    <ui-category-block-header-control
      [routerLink]="[mobileMode ? ('learning/' + currentOption.type) : '../', 'archive']"
      #archive="routerLinkActive"
      routerLinkActive
      [isActive]="archive.isActive"
      tooltip="Корзина"
      icon="trash"
      color="red">
    </ui-category-block-header-control>
    <ui-tree #tree
             [type]="currentOption.categoryType"
             [selectedId]="selectedId"
             [categories]="categories"
             (changeValue)="onFilter($event)"
             [isAllowEdit]="allowCrud"
             [isEditAccess]="true">
    </ui-tree>
  </ui-category-block>
</ui-container>
