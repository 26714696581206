import * as fromRoot from '@core/redux';
import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {PostEventModel, PutEventModel} from '@core/models';
import {CreateAction, DeleteAction, EventActionTypes, GetEventsAction, SelectAction, UpdateAction} from '@core/redux/event/event.actions';
import {Actions, ofType} from '@ngrx/effects';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  constructor(private store: Store<fromRoot.State>,
              private updates: Actions
  ) {

  }

  select(id) {
    this.store.dispatch(new SelectAction(id));
  }

  fetchAll() {
    this.store.dispatch(new GetEventsAction());
  }

  update(model: PutEventModel) {
    this.store.dispatch(new UpdateAction(model));
  }

  create(model: PostEventModel) {
    this.store.dispatch(new CreateAction(model));
  }

  delete(id: string) {
    this.store.dispatch(new DeleteAction(id));
  }

  getSelected() {
    return this.store.pipe(select(fromRoot.getEventSelected)).pipe(filter((item) => !!item));
  }

  getLoading() {
    return this.store.pipe(select(fromRoot.getEventLoading));
  }

  onSaveSuccess() {
    const events = [EventActionTypes.CreateActionSuccess, EventActionTypes.UpdateActionSuccess, EventActionTypes.DeleteActionSuccess];
    return this.updates.pipe(ofType(...events));
  }
}
