import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { exhaustMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { ApiCommentService } from '@core/services/entities/comment/api-comment.service';
import { DeleteAction, DeleteActionFailure, DeleteActionSuccess, CreateAction, CommentActionTypes, GetAction, GetActionSuccess, GetActionFailure, CreateActionSuccess, CreateActionFailure, UpdateAction, UpdateActionSuccess, UpdateActionFailure, LikeAction, LikeActionSuccess, LikeActionFailure, DislikeAction, DislikeActionSuccess, DislikeActionFailure } from './comment.actions';
import { CommentModel } from '@core/models';


@Injectable()
export class CommentEffects {
  constructor(
    private actions$: Actions,
    private apiCommentService: ApiCommentService
  ) { }

  @Effect()
  get$ = this.actions$.pipe(
    ofType(CommentActionTypes.GetAction),
    exhaustMap((action: GetAction) =>
      this.apiCommentService
        .get(action.query)
        .pipe(
          map((comments: CommentModel[]) => new GetActionSuccess(action.query.objectType, action.query.objectId, comments)),
          catchError(errors => of(new GetActionFailure(errors)))
        ))
  );

  @Effect()
  create$ = this.actions$.pipe(
    ofType(CommentActionTypes.CreateAction),
    exhaustMap((action: CreateAction) =>
      this.apiCommentService
        .create(action.comment)
        .pipe(
          map((comment: CommentModel) => new CreateActionSuccess(action.comment.objectType, action.comment.objectId, comment)),
          catchError(errors => of(new CreateActionFailure(errors)))
        ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(CommentActionTypes.DeleteAction),
    exhaustMap((action: DeleteAction) =>
      this.apiCommentService
        .delete(action.commentId)
        .pipe(
          map(() => new DeleteActionSuccess(action.objectType, action.objectId, action.commentId)),
          catchError(errors => of(new DeleteActionFailure(errors)))
        ))
  );

  @Effect()
  like$ = this.actions$.pipe(
    ofType(CommentActionTypes.LikeAction),
    exhaustMap((action: LikeAction) =>
      this.apiCommentService
        .like(action.commentId)
        .pipe(
          map((comment: CommentModel) => new LikeActionSuccess(comment)),
          catchError(errors => of(new LikeActionFailure(errors)))
        ))
  );

  @Effect()
  dislike$ = this.actions$.pipe(
    ofType(CommentActionTypes.DislikeAction),
    exhaustMap((action: DislikeAction) =>
      this.apiCommentService
        .dislike(action.commentId)
        .pipe(
          map((comment: CommentModel) => new DislikeActionSuccess(comment)),
          catchError(errors => of(new DislikeActionFailure(errors)))
        ))
  );

  @Effect()
  update$ = this.actions$.pipe(
    ofType(CommentActionTypes.UpdateAction),
    exhaustMap((action: UpdateAction) =>
      this.apiCommentService
        .update(action.comment)
        .pipe(
          map((comment: CommentModel) => new UpdateActionSuccess(comment)),
          catchError(errors => of(new UpdateActionFailure(errors)))
        ))
  );

}

