import { AfterContentInit, Component, ComponentFactoryResolver, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { UserListFilterSidebarComponent } from '../../../../pages/user-list/components/sidebar/user-list-filter-sidebar/user-list-filter-sidebar.component';
import { UiHeaderBurgerAsideDirective } from '@ui/modules/header/ui-header-burger-menu/ui-header-burger-aside.directive';
import { WikiCategoriesComponent } from '@wiki/components/wiki-sidebar/wiki-categories/wiki-categories.component';
import { LearningCategoriesComponent } from '@learning/learning-shared/learning-categories/learning-categories.component';
import { ActivatedRoute } from '@angular/router';
import { WikiAsideCalendarComponent } from '@ui/modules/header/ui-header-burger-menu/calendar/wiki-aside-calendar.component';

const AsideComponent = {
  'wiki': [WikiCategoriesComponent, WikiAsideCalendarComponent],
  'users': [UserListFilterSidebarComponent],
  'learning': [LearningCategoriesComponent]
};

@Component({
  selector: 'ui-header-burger-aside',
  template: `
    <ng-container *ngIf='!!components?.length'>
      <ng-template uiBurgerAside *ngFor='let component of components;' }></ng-template>
    </ng-container>
  `
})
export class UiHeaderBurgerAsideComponent implements AfterContentInit, OnDestroy {
  @ViewChildren(UiHeaderBurgerAsideDirective) hosts: QueryList<UiHeaderBurgerAsideDirective>;
  components = [];

  constructor(private _componentFactoryResolver: ComponentFactoryResolver,
              private _activatedRoute: ActivatedRoute) {
  }

  ngAfterContentInit() {
    this.loadComponent();
  }

  ngOnDestroy() {
  }

  loadComponent() {
    const route = this._activatedRoute.snapshot.firstChild.routeConfig.path;
    this.components = AsideComponent[route];

    setTimeout(() => {
      if (this.components) {
        this.components.forEach((component, index) => {
          const componentFactory = this._componentFactoryResolver.resolveComponentFactory(component);
          const viewContainerRef = this.hosts.toArray()[index].viewContainerRef;
          viewContainerRef.clear();

          const componentRef = viewContainerRef.createComponent(componentFactory);
          componentRef.instance['mobileMode'] = true;
        });
      }
    }, 100);
  }
}

