import { Router } from '@angular/router';


/**
 * Добавит параметры к текущему url
 * @param router роутер сервис
 * @param queryParams дополнительные параметры
 * @returns url с добавленными параметрами
 */
// export function addQueryParams(router: Router, queryParams: { [key: string]: string }): string {
//   const urlTree = router.parseUrl(router.url);
//   const nextUrlTree = { ...urlTree, queryParams };
//   return router.serializeUrl(nextUrlTree);
// }

/**
 * Превращает объект в строку query params URL
 *
 * Например { id: '5', type: 'wiki' } вернет id=5&type=wiki
 *
 * @param filter
 */
export function toQueryString(filter: { [key: string]: string }): string {
  return Object.keys(filter)
    .reduce((acc, next) => `${acc}&${next}=${filter[next]}`, '')
    .slice(1);
}
