import * as wikiActions from './wiki.actions';
import {DislikeActionSuccess, LikeActionSuccess, WikiActionTypes} from './wiki.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { WikiModel } from '@core/models/wiki.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UpdateStr } from '@ngrx/entity/src/models';
import { PaginationResponse } from '@core/models/filters/pagination-response';
import * as _ from 'lodash';

export interface State extends EntityState<WikiModel> {
  loading: boolean;
  updateId: string | null;
  selectedId: string | null;
  errors: any;
  pagination: PaginationResponse;
  filter: string;
  filtersAreOpen: boolean;
}

export const adapter: EntityAdapter<WikiModel> = createEntityAdapter<WikiModel>({
  selectId: (wiki: WikiModel) => wiki.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  updateId: null,
  selectedId: null,
  errors: {},
  pagination: null,
  filter: 'active',
  filtersAreOpen: false
});

export function reducer(state = initialState, action: wikiActions.WikiActions): State {
  switch (action.type) {

    case WikiActionTypes.ReadAction: {
      return {...state, loading: true};
    }

    case WikiActionTypes.ResetAction: {
      return Object.assign({}, state, initialState);
    }

    case WikiActionTypes.CombineTagAction: {
      const id = action.id;
      const combineId = action.combineId;
      const entities = {...state.entities};

      const updates: UpdateStr<WikiModel>[] = [];

      for (const field in entities) {
        if (entities.hasOwnProperty(field)) {
          const item: WikiModel = entities[field];
          let isUpdate = false;
          combineId.forEach((tagId: string) => {
            if (item.tagIds.indexOf(tagId) > -1) {
              item.tagIds.splice(item.tagIds.indexOf(tagId), 1);
              isUpdate = true;
            }
          });

          if (isUpdate) {
            item.tagIds.push(id);
            item.tagIds = item.tagIds.filter((el, i, a) => i === a.indexOf(el));
            updates.push({
              id: item.id,
              changes: {
                tagIds: item.tagIds
              }
            });
          }
        }
      }

      return adapter.updateMany(updates, state);
    }

    case WikiActionTypes.SelectMultipleAction: {
      if (action.id) {
        return adapter.updateOne({
          id: action.id,
          changes: {
            checked: action.isChecked
          }
        }, state);
      } else {
        const updates: UpdateStr<WikiModel>[] = [];
        state.ids.forEach((id) => {
          updates.push({
            id: id,
            changes: {
              checked: action.isChecked
            }
          });
        });

        return adapter.updateMany(updates, state);
      }
    }

    case WikiActionTypes.SearchAction: {
      return Object.assign({}, state, {
        loading: true,
        errors: [],
      });
    }

    case WikiActionTypes.SearchSuccessAction: {
      const func = action.deletePrevious
        ? adapter.setAll
        : adapter.addMany;
      return func(action.payload.data, Object.assign({}, state, {
        loading: false,
        pagination: action.payload.pagination
      }));
    }

    case WikiActionTypes.SearchFailureAction: {
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload
      });
    }

    case WikiActionTypes.SelectUpdateAction: {
      return Object.assign({}, state, {updateId: action.payload});
    }

    case WikiActionTypes.UpdateActionSuccess: {
      return adapter.updateOne({
        id: action.payload.id,
        changes: action.payload
      }, state);
    }

    case WikiActionTypes.ViewAction: {
      return Object.assign({}, state, {
        loading: true,
        errors: {}
      });
    }

    case WikiActionTypes.ViewActionSuccess: {
      return adapter.upsertOne(action.payload, Object.assign({}, state, {
        selectedId: action.payload.id,
        loading: false
      }));
    }

    case WikiActionTypes.ViewActionFailure: {
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload
      });
    }

    case WikiActionTypes.ViewCloseAction: {
      return Object.assign({}, state, {
        loading: false,
        selectedId: null,
        errors: {}
      });
    }

    case WikiActionTypes.FavoriteActionSuccess: {
      const updates: UpdateStr<WikiModel>[] = [];

      action.payload.forEach((model: WikiModel) => {
        updates.push({
          id: model.id,
          changes: model
        });
      });

      return adapter.updateMany(updates, state);
    }

    case WikiActionTypes.ChangeCategoryActionSuccess: {
      const updates: UpdateStr<WikiModel>[] = [];

      action.payload.forEach((model: WikiModel) => {
        updates.push({
          id: model.id,
          changes: model
        });
      });

      return adapter.updateMany(updates, state);
    }

    case WikiActionTypes.ArchiveActionSuccess: {
      const updates: UpdateStr<WikiModel>[] = [];

      action.payload.forEach((model: WikiModel) => {
        updates.push({
          id: model.id,
          changes: model
        });
      });

      return adapter.updateMany(updates, state);
    }

    case WikiActionTypes.CreateActionSuccess: {
      return adapter.addOne(action.payload, state);
    }

    case WikiActionTypes.FilterAction: {
      return Object.assign({}, state, {
        filter: action.payload
      });
    }

    case WikiActionTypes.ToggleFilterBarAction: {
      return Object.assign({}, state, {
        filtersAreOpen: action.payload
      });
    }

    case WikiActionTypes.LikeActionSuccess: {
      const {wiki} = action as LikeActionSuccess;
      return adapter.updateOne({id: wiki.id, changes: wiki}, state);
    }

    case WikiActionTypes.DislikeActionSuccess: {
      const {wiki} = action as DislikeActionSuccess;
      return adapter.updateOne({id: wiki.id, changes: wiki}, state);
    }

    default:
      return state;
  }
}

export const getWikiState = createFeatureSelector<State>('wiki');

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(getWikiState);

// Фильтр
export const getFilter = (state: State) => state.filter;

// Ошибки
export const getErrors = (state: State) => state.errors;

// Пагинация
export const getPagination = (state: State) => state.pagination;

// Записи в виде объектов {id: item}
export const getEntitiesObject = (state: State) => state.entities;

// Все ID объектов в сторе
export const getIds = (state: State) => state.ids;

// Происходит ли обращение в апи
export const getLoading = (state: State) => state.loading;

// Обновляемая запись
export const getUpdateId = (state: State) => state.updateId;

// Выделенный объект
export const getSelectedId = (state: State) => state.selectedId;

// Записи в виде массива
// @ts-ignore
export const getEntitiesArray = createSelector(getEntitiesObject, getIds, (entities, ids) => ids.map(id => entities[id]));

// Получить выделенный объект
export const getSelectedEntity = createSelector(getEntitiesObject, getSelectedId, (entities, selectedId) => entities[selectedId]);

// Список объектов для вывода. Отсортированные по фиксации и сортировке.
export const getEntities = createSelector(getEntitiesArray, (entities) => _.orderBy(entities, ['isFix', 'order'], ['desc', 'desc']));

// Список статей из урока
export const getEntitiesByIds = (ids: string[]) => createSelector(getEntitiesObject, (entities) => {
  return ids.map(id => entities[id]).filter(wiki => wiki);
});

export const getEntitiesFiltered = createSelector(getEntities, getFilter, (entities, filter) => {
  switch (filter) {
    case 'active':
      return entities.filter((item: WikiModel) => item.isArchive === false);
    case 'unsorted':
      return entities.filter((item: WikiModel) => item.isArchive === false && item.categoryId === null);
    case 'favorite':
      return entities.filter((item: WikiModel) => item.isArchive === false && item.isFavorite === true);
    case 'archive':
      return entities.filter((item: WikiModel) => item.isArchive === true);
    default:
      return [];
  }
});

// Выделенные элементы. Используется в табличном виде для групповых действий.
export const getMultipleSelectedEntities = createSelector(getEntitiesFiltered, (entities: WikiModel[]) => entities.filter((item) => item.checked));

// Количество выделенных объектов
export const getCountMultipleSelected = createSelector(getMultipleSelectedEntities, (entities: WikiModel[]) => entities.length);

// Количество выделенных объектов
export const getMultipleSelectedIds = createSelector(getMultipleSelectedEntities, (entities: WikiModel[]) => entities.map((item) => item.id));

// Количество объектов в сторе
export const getCount = createSelector(getEntitiesFiltered, (filteredEntities: WikiModel[]) => filteredEntities.length);

// Количество объектов согласно фильтрам
export const getTotalCount = createSelector(getWikiState, state => state.pagination.totalCount);

// Получить обновляемый объект
export const getUpdateEntity = createSelector(getEntitiesObject, getUpdateId, (entities, id) => entities[id]);

export const isFilterOpen = createSelector(getWikiState, state => state.filtersAreOpen);

