import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'ui-container',
  templateUrl: './ui-container.component.html',
  styleUrls: ['./ui-container.component.less']
})
export class UiContainerComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
