import { Action } from '@ngrx/store';
import { UserId } from '@core/models';
import { KarmaModel, PostKarmaModel } from '@core/models/karma.model';

export enum KarmaActionTypes {
  GetAction = '[Karma] Get',
  GetActionSuccess = '[Karma] Get Success',
  GetActionFailure = '[Karma] Get Failure',

  PostAction = '[Karma] Post',
  PostActionSuccess = '[Karma] Post Success',
  PostActionFailure = '[Karma] Post Failure',

  DeleteAction = '[Karma] Delete',
  DeleteActionSuccess = '[Karma] Delete Success',
  DeleteActionFailure = '[Karma] Delete Failure',
}

export class GetAction implements Action {
  readonly type = KarmaActionTypes.GetAction;

  constructor(public readonly userId: UserId) { }
}

export class GetActionSuccess implements Action {
  readonly type = KarmaActionTypes.GetActionSuccess;

  constructor(public readonly karmas: KarmaModel[]) { }
}

export class GetActionFailure implements Action {
  readonly type = KarmaActionTypes.GetActionFailure;

  constructor(public readonly errors: any) { }
}

export class PostAction implements Action {
  readonly type = KarmaActionTypes.PostAction;

  constructor(public readonly newKarma: PostKarmaModel) { }
}

export class PostActionSuccess implements Action {
  readonly type = KarmaActionTypes.PostActionSuccess;

  constructor(public readonly karma: KarmaModel) { }
}

export class PostActionFailure implements Action {
  readonly type = KarmaActionTypes.PostActionFailure;

  constructor(public readonly errors: any) { }
}

export class DeleteAction implements Action {
  readonly type = KarmaActionTypes.DeleteAction;

  constructor(public readonly karmaId: string) { }
}

export class DeleteActionSuccess implements Action {
  readonly type = KarmaActionTypes.DeleteActionSuccess;

  constructor(public readonly karmaId: string) { }
}

export class DeleteActionFailure implements Action {
  readonly type = KarmaActionTypes.DeleteActionFailure;

  constructor(public readonly errors: any) { }
}

export type KarmaActions
  = GetAction
  | GetActionSuccess
  | GetActionFailure
  | PostAction
  | PostActionSuccess
  | PostActionFailure
  | DeleteAction
  | DeleteActionSuccess
  | DeleteActionFailure
  ;
