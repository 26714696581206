import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UiHeaderBurgerMenuComponent } from '@ui/modules/header/ui-header-burger-menu/ui-header-burger-menu.component';
import { UiSelectModule } from '@ui/modules/ui-select/ui-select.module';
import { UiPopupMenuItemModule } from '@ui/modules/ui-popup-menu-item/ui-popup-menu-item.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { UiPopupModule } from '@ui/modules/ui-popup/ui-popup.module';
import { UiHeaderBurgerAsideComponent } from '@ui/modules/header/ui-header-burger-menu/ui-header-burger-aside.component';
import { UiHeaderBurgerAsideDirective } from '@ui/modules/header/ui-header-burger-menu/ui-header-burger-aside.directive';
import { UserListFilterSidebarModule } from '../../../../pages/user-list/components/sidebar/user-list-filter-sidebar/user-list-filter-sidebar.module';
import { UiTagSidebarModule } from '@ui/modules/ui-tag-sidebar/ui-tag-sidebar.module';
import { WikiCategoriesModule } from '@wiki/components/wiki-sidebar/wiki-categories/wiki-categories.module';
import { LearningCategoriesModule } from '@learning/learning-shared/learning-categories/learning-categories.module';
import { OwlDateTimeModule } from '@ui/modules/datetimepicker/date-time';
import { WikiAsideCalendarComponent } from '@ui/modules/header/ui-header-burger-menu/calendar/wiki-aside-calendar.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { UiAdaptivePopupModule } from '@ui/modules/ui-adaptive-popup/ui-adaptive-popup.module';

@NgModule({
  declarations: [
    UiHeaderBurgerAsideComponent,
    UiHeaderBurgerAsideDirective,
    UiHeaderBurgerMenuComponent,
    WikiAsideCalendarComponent
  ],
  exports: [
    UiHeaderBurgerMenuComponent
  ],
  imports: [
    ClickOutsideModule,
    CommonModule,
    LearningCategoriesModule,
    RouterModule,
    UiSelectModule,
    UiPopupModule,
    UiPopupMenuItemModule,
    UiTagSidebarModule,
    UserListFilterSidebarModule,
    WikiCategoriesModule,
    OwlDateTimeModule,
    MatDialogModule,
    UiAdaptivePopupModule,
  ],
})
export class UiHeaderBurgerMenuModule {
}
