import { Pipe, PipeTransform } from '@angular/core';
import { cutUrl } from '@pipes/short-url/short-url.utils';

@Pipe({name: 'shortUrl'})
export class ShortUrlPipe implements PipeTransform {
  constructor() {
  }

  transform(value) {
    return cutUrl(value);
  }
}
