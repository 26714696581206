<form (ngSubmit)="undefined;false" class="form__row form__row_fast-search">
  <input #searchInput
         (input)="match = $event.target.value"
         [value]="match"
         (keyup)="keyUp$.next($event)"
         class="fast-search__input input input_padding-side"
         placeholder="Введите запрос"
         type="search">
  <button class="input__btn-search form__icon form__icon_left">
    <span class="icon-search"></span>
  </button>
  <span class="form__icon form__icon_right icon-close" (click)="onCloseSearch(false)"></span>
</form>
