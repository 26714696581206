import { Component } from '@angular/core';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'wiki-aside-calendar',
  templateUrl: './wiki-aside-calendar.component.html',
  styleUrls: ['./wiki-aside-calendar.component.less']
})
export class WikiAsideCalendarComponent {
  constructor(private _router: Router,
              private _activatedRoute: ActivatedRoute) {
  }

  goEvent(event) {
    this._router.navigate(['./events', moment(event).format('YYYY-MM-DD')], {
      relativeTo: this._activatedRoute
    });
  }
}
