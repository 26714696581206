<div class="user-menu__inner" (click)="onOpenUserMenu()">
  <ui-avatar
    *ngIf="user"
    [user]="user"
    class="user-menu__avatar avatar_xs">
  </ui-avatar>
  <span class="user-menu__name">{{user?.firstName}}</span>
  <span class="user-menu__icon-arrow icon-arrow"></span>
</div>
<ui-adaptive-popup
  [openTrigger]="openTrigger"
  [closeTrigger]="closeTrigger"
  [matDialogConfig]="matDialogConfig"
  class="user-menu__popup">
  <ui-user-menu-popup
    [user]="user"
    [company]="company"
    [closeTrigger]="closeTrigger">
  </ui-user-menu-popup>
</ui-adaptive-popup>
