import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { WikiModel } from '@core/models';

import * as wikiActions from '../../../../core/redux/wiki/wiki.actions';

@Component({
  selector: 'ui-header-app-container',
  template: `
    <ui-header-app (onOpenFilters)="onOpenFilters()"></ui-header-app>`
})
export class UiHeaderAppContainerComponent {
  constructor(private _store: Store<WikiModel>) {
  }

  onOpenFilters() {
    this._store.dispatch(new wikiActions.ToggleFilterBarAction(true));
  }
}
