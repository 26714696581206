import {Injectable} from '@angular/core';
import {UserStatusModel} from '@core/models/user-status.model';
import 'rxjs/add/operator/map';
import {map} from 'rxjs/operators';
import {ApiService} from '@core/services/entities/api.service';

@Injectable({
  providedIn: 'root',
})
export class ApiUserStatusService extends ApiService {
  search() {
    return this
      .http
      .get<UserStatusModel[]>(this.getApiUrl(`user-statuses`))
      .pipe(
        map((response) => response.map(model => (new UserStatusModel()).setAttributes(model)))
      );
  }

  update(model: UserStatusModel) {
    return this
      .http
      .put<UserStatusModel>(this.getApiUrl(`user-statuses/${model.id}`), model)
      .pipe(
        map((response) => (new UserStatusModel()).setAttributes(response))
      );
  }

  create(model: UserStatusModel) {
    return this
      .http
      .post<UserStatusModel>(this.getApiUrl(`user-statuses`), model)
      .pipe(
        map((response) => (new UserStatusModel()).setAttributes(response))
      );
  }

  delete(id: string) {
    return this.http.delete(this.getApiUrl(`user-statuses/${id}`));
  }
}
