import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiUserMenuComponent } from '@ui/modules/ui-user-menu/ui-user-menu.component';
import { UiPopupModule } from '@ui/modules/ui-popup/ui-popup.module';
import { UiAvatarModule } from '@ui/modules/ui-avatar/ui-avatar.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { RouterModule } from '@angular/router';
import { UiAdaptivePopupModule } from '../ui-adaptive-popup/ui-adaptive-popup.module';
import { UiUserMenuPopupComponent } from './components/ui-user-menu-popup/ui-user-menu-popup.component';

@NgModule({
  declarations: [
    UiUserMenuComponent,
    UiUserMenuPopupComponent
  ],
  exports: [
    UiUserMenuComponent
  ],
  imports: [
    CommonModule,
    UiPopupModule,
    UiAvatarModule,
    ClickOutsideModule,
    RouterModule,
    UiAdaptivePopupModule,
  ]
})
export class UiUserMenuModule {
}
