import {BaseModel} from './base.model';
import {UserModel} from '@core/models/user.model';
import {UserStatusModel} from '@core/models/user-status.model';
import {UserPositionsModel} from '@core/models/user-positions.model';
import {CategoryModel} from '@core/models/category.model';

export type InviteStatuses = 'noActive' | 'active' | 'delete';
export type InviteRoles = 'user' | 'author' | 'moderator' | 'administrator';
export type InviteQueryFolders = 'active' | 'delete' | 'invited' | 'confirmation';
export const roleNames = {
  user: 'Пользователь',
  author: 'Автор',
  moderator: 'Модератор',
  administrator: 'Администратор',
};

export interface InviteAccessInfoInterface {
  status: string;
  isConfirmedUser: boolean;
  isConfirmedAdmin: boolean;
  isLeft: boolean;
}

export class InviteModel extends BaseModel {
  id: string;
  firstName: string;
  status: InviteStatuses;
  userId: string;
  email: string;
  role: InviteRoles;
  userStatusId: string;
  userPositionId: string;
  departmentId: string;
  isConfirmedUser: boolean;
  isConfirmedAdmin: boolean;
  isLeft: boolean;
  isIm: boolean;
  user: UserModel;
  userStatus: UserStatusModel;
  userPosition: UserPositionsModel;
  department: CategoryModel;
  isBlocking: boolean;
}


export class InviteQueryModel extends BaseModel {
  page: number;
  match: string;
  departmentId: string;
  folder: InviteQueryFolders = 'active';
}
