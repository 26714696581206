import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiSelectUserDepartmentFormComponent } from '@ui/modules/ui-select/components/forms/ui-select-user-department-form/ui-select-user-department-form.component';
import { UiSelectUserPositionsFormComponent } from '@ui/modules/ui-select/components/forms/ui-select-user-positions-form/ui-select-user-positions-form.component';
import { UiSelectUserStatusFormComponent } from '@ui/modules/ui-select/components/forms/ui-select-user-status-form/ui-select-user-status-form.component';
import { UiSelectComponent } from '@ui/modules/ui-select/ui-select.component';
import { UiSelectOptionComponent } from '@ui/modules/ui-select/components/ui-select-option/ui-select-option.component';
import { UiPopupModule } from '@ui/modules/ui-popup/ui-popup.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { UiPopupMenuItemModule } from '@ui/modules/ui-popup-menu-item/ui-popup-menu-item.module';
import { ReactiveFormsModule } from '@angular/forms';
import { UiAdaptivePopupModule } from '../ui-adaptive-popup/ui-adaptive-popup.module';

@NgModule({
  declarations: [
    UiSelectUserDepartmentFormComponent,
    UiSelectUserPositionsFormComponent,
    UiSelectUserStatusFormComponent,
    UiSelectComponent,
    UiSelectOptionComponent
  ],
  exports: [
    UiSelectComponent,
    UiSelectOptionComponent
  ],
  imports: [
    CommonModule,
    UiPopupModule,
    ClickOutsideModule,
    UiPopupMenuItemModule,
    ReactiveFormsModule,
    UiAdaptivePopupModule,
  ],
})
export class UiSelectModule {
}
