import 'rxjs/add/observable/combineLatest';
import 'rxjs/add/observable/combineLatest';
import 'rxjs/add/operator/map';
import * as fromRoot from '@core/redux/index';
import * as _ from 'lodash';
import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {CategoryModel} from '@core/models/category.model';
import {FilterInterface, FilterUserInterface} from '@core/interfaces/filter-interface.model';
import {Subscription} from 'rxjs/Subscription';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {debounceTime} from 'rxjs/operators';
import {InviteQueryFolders} from '@core/models/invite.model';

@Injectable({
  providedIn: 'root',
})
export class FilterUserService implements OnDestroy {
  /**
   * Подписки
   *
   * @type {Subscription}
   * @private
   */
  private _subscriptions$: Subscription = new Subscription();

  /**
   * Подписка на обновление фильтров
   *
   * @type {BehaviorSubject<FilterInterface>}
   */
  filterBehavior = new BehaviorSubject<FilterUserInterface>({
    department: null,
    isFiltered: false,
    folder: 'active',
  });

  /**
   * Фильтра
   *
   * @type {{department: null; isFiltered: boolean; folder: "active"}}
   * @private
   */
  private _filter: FilterUserInterface = {
    department: null,
    isFiltered: false,
    folder: 'active',
  };

  constructor(public router: Router,
              private activeRoute: ActivatedRoute,
              private store: Store<fromRoot.State>) {
    this._subscribeRouter();
  }

  /**
   *
   */
  ngOnDestroy() {
    this._subscriptions$.unsubscribe();
  }

  /**
   * Удалить департамент из фильтра
   */
  deleteDepartment() {
    this._filter.department = null;
    this.navigate();
  }

  /**
   * Применить фильтр
   */
  navigate() {
    const queryParams: any = {};
    if (this._filter.department) {
      queryParams.departmentId = this._filter.department.id;
    }

    this.router.navigate([], {
      queryParams: queryParams
    });
  }

  /**
   * Указать активную директорию вики
   * @param {string} folder
   */
  setFolder(folder: string) {
    this._filter.folder = folder as InviteQueryFolders;
    this.filterBehavior.next(this._filter);
  }

  /**
   * Автоматическое заполнение фильтра из параметров роутинга
   *
   * @returns {Subscription}
   * @private
   */
  private _subscribeRouter() {
    const params$ = this.activeRoute.queryParamMap;
    const departments$ = this.store.pipe(select(fromRoot.getCategoryEntitiesByType('user')));

    const subscription = combineLatest(params$, departments$)
      .pipe(
        debounceTime(0)
      )
      .subscribe(([params, departments]) => {
        params = params as ParamMap;
        departments = departments as CategoryModel[];

        this._prepareDepartment(departments, params);
        this.filterBehavior.next(this._filter);
      });

    this._subscriptions$.add(subscription);
  }

  /**
   * Получить департамент по id из url
   *
   * @param {CategoryModel[]} departments
   * @param {ParamMap} params
   * @private
   */
  private _prepareDepartment(departments: CategoryModel[], params: ParamMap) {
    if (params.has('departmentId')) {
      this._filter.department = _.find<CategoryModel>(departments, {id: params.get('departmentId')});
    } else {
      this._filter.department = null;
    }
  }
}
