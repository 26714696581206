import {BaseModel} from './base.model';

export type CategoryTypes = 'wiki' | 'course' | 'test' | 'user';

export interface CategorySortModel {
  id: string;
  nextId: string;
  prevId: string;
  rootId: string;
  
}

export class CategoryModel extends BaseModel {
  id: string;
  name: string;
  type: CategoryTypes;
  rootId: string | 0;
  accessLevel: string;
  accessDepartmentIds: string[] = [];
}
