import {Action} from '@ngrx/store';
import {SignInModel} from '@core/models/sign-in.model';
import {IdentityModel} from '@core/models/identity.model';
import {SignUpModel} from '@core/models/sign-up.model';
import {SignUpPersonalDataModel} from '@core/models/sign-up-personal-data.model';
import {SignUpPersonalContactModel} from '@core/models/sign-up-personal-contact.model';
import {SignUpCompanyModel} from '@core/models/sign-up-company.model';
import {UserModel} from '@core/models/user.model';

export enum AuthActionTypes {
  GetUserAction = '[Auth] Get User',
  GetUserSuccessAction = '[Auth] Get User Success',
  GetUserFailureAction = '[Auth] Get User Failure',

  SignUpAction = '[Auth] Sign Up',
  SignUpSuccessAction = '[Auth] Sign Up Success',
  SignUpFailureAction = '[Auth] Sign Up Failure',

  SignInAction = '[Auth] Sign In',
  SignInSuccessAction = '[Auth] Sign In Success',
  SignInFailureAction = '[Auth] Sign In Failure',

  SignOutAction = '[Auth] Sign Out',
  SignOutSuccessAction = '[Auth] Sign Out Success',
  SignOutFailureAction = '[Auth] Sign Out Failure',

  SignUpStepPersonalDataAction = '[Auth] Sign Up Personal Data',
  SignUpStepPersonalDataSuccessAction = '[Auth] Sign Up Personal Data Success',
  SignUpStepPersonalDataFailureAction = '[Auth] Sign Up Personal Data Failure',

  SignUpStepPersonalContactAction = '[Auth] Sign Up Personal Contact',
  SignUpStepPersonalContactSuccessAction = '[Auth] Sign Up Personal Contact Success',
  SignUpStepPersonalContactFailureAction = '[Auth] Sign Up Personal Contact Failure',

  SignUpStepCompanyAction = '[Auth] Sign Up Personal Company',
  SignUpStepCompanySuccessAction = '[Auth] Sign Up Personal Company Success',
  SignUpStepCompanyFailureAction = '[Auth] Sign Up Personal Company Failure',

  ProfileUpdateAction = '[Auth] Profile Update',
  ProfileUpdateSuccessAction = '[Auth] Profile Update Success',
  ProfileUpdateFailureAction = '[Auth] Profile Update Failure',

  ChangeStateOnboardingAction = '[Auth] Change State Onboarding',

  ConfirmEmailAction = '[Auth] Confirmation Email',
  ConfirmEmailFailureAction = '[Auth] Confirmation Email Failure',

  PasswordRecoveryConfirmAction = '[Auth] Password Recovery ConfirmAction',
  PasswordRecoveryConfirmFailureAction = '[Auth] Password Recovery Confirm Failure Action',
  PasswordRecoveryConfirmSuccessAction = '[Auth] Password Recovery Confirm Success Action'
}

export class ProfileUpdateAction implements Action {
  readonly type = AuthActionTypes.ProfileUpdateAction;

  constructor(public payload: IdentityModel | UserModel) {
  }
}

export class ProfileUpdateSuccessAction implements Action {
  readonly type = AuthActionTypes.ProfileUpdateSuccessAction;

  constructor(public payload: IdentityModel) {
  }
}

export class ProfileUpdateFailureAction implements Action {
  readonly type = AuthActionTypes.ProfileUpdateFailureAction;

  constructor(public payload: any) {
  }
}

export class SignInAction implements Action {
  readonly type = AuthActionTypes.SignInAction;

  constructor(public payload: SignInModel) {
  }
}

export class SignInSuccessAction implements Action {
  readonly type = AuthActionTypes.SignInSuccessAction;

  constructor(public payload: IdentityModel) {
  }
}

export class PasswordRecoveryConfirmSuccessAction implements Action {
  readonly type = AuthActionTypes.PasswordRecoveryConfirmSuccessAction;

  constructor(public payload: IdentityModel) {
  }
}

export class SignInFailureAction implements Action {
  readonly type = AuthActionTypes.SignInFailureAction;

  constructor(public payload: any) {
  }
}

export class GetUserAction implements Action {
  readonly type = AuthActionTypes.GetUserAction;
}

export class GetUserSuccessAction implements Action {
  readonly type = AuthActionTypes.GetUserSuccessAction;

  constructor(public payload: IdentityModel) {
  }
}

export class GetUserFailureAction implements Action {
  readonly type = AuthActionTypes.GetUserFailureAction;

  constructor(public payload: any) {
  }
}

export class SignUpAction implements Action {
  readonly type = AuthActionTypes.SignUpAction;

  constructor(public payload: SignUpModel) {
  }
}

export class SignUpSuccessAction implements Action {
  readonly type = AuthActionTypes.SignUpSuccessAction;

  constructor(public payload: IdentityModel) {
  }
}

export class SignUpFailureAction implements Action {
  readonly type = AuthActionTypes.SignUpFailureAction;

  constructor(public payload: any) {
  }
}

export class SignUpStepPersonalDataAction implements Action {
  readonly type = AuthActionTypes.SignUpStepPersonalDataAction;

  constructor(public payload: SignUpPersonalDataModel) {
  }
}

export class SignUpStepPersonalDataSuccessAction implements Action {
  readonly type = AuthActionTypes.SignUpStepPersonalDataSuccessAction;

  constructor(public payload: IdentityModel) {
  }
}

export class SignUpStepPersonalDataFailureAction implements Action {
  readonly type = AuthActionTypes.SignUpStepPersonalDataFailureAction;

  constructor(public payload: any) {
  }
}

export class SignUpStepPersonalContactAction implements Action {
  readonly type = AuthActionTypes.SignUpStepPersonalContactAction;

  constructor(public payload: SignUpPersonalContactModel) {
  }
}

export class SignUpStepPersonalContactSuccessAction implements Action {
  readonly type = AuthActionTypes.SignUpStepPersonalContactSuccessAction;

  constructor(public payload: IdentityModel) {
  }
}

export class SignUpStepPersonalContactFailureAction implements Action {
  readonly type = AuthActionTypes.SignUpStepPersonalContactFailureAction;

  constructor(public payload: any) {
  }
}

export class SignUpStepCompanyAction implements Action {
  readonly type = AuthActionTypes.SignUpStepCompanyAction;

  constructor(public payload: SignUpCompanyModel) {
  }
}

export class SignUpStepCompanySuccessAction implements Action {
  readonly type = AuthActionTypes.SignUpStepCompanySuccessAction;

  constructor(public payload: IdentityModel) {
  }
}

export class SignUpStepCompanyFailureAction implements Action {
  readonly type = AuthActionTypes.SignUpStepCompanyFailureAction;

  constructor(public payload: any) {
  }
}

export class SignOutAction implements Action {
  readonly type = AuthActionTypes.SignOutAction;
}

export class SignOutSuccessAction implements Action {
  readonly type = AuthActionTypes.SignOutSuccessAction;

}

export class SignOutFailureAction implements Action {
  readonly type = AuthActionTypes.SignOutFailureAction;

  constructor(public payload: any) {
  }
}

export class ChangeStateOnboardingAction implements Action {
  readonly type = AuthActionTypes.ChangeStateOnboardingAction;

  constructor(public payload: any) {
  }
}

export class ConfirmEmailAction implements Action {
  readonly type = AuthActionTypes.ConfirmEmailAction;

  payload: {
    email: string
  };

  constructor(email: string) {
    this.payload = {email};
  }
}

export class ConfirmEmailFailureAction {
  readonly type = AuthActionTypes.ConfirmEmailFailureAction;

  constructor(public payload: any) {
  }
}

export class PasswordRecoveryConfirmAction implements Action {
  readonly type = AuthActionTypes.PasswordRecoveryConfirmAction;

  payload: {
    token: string
  };

  constructor(token: string) {
    this.payload = {token};
  }
}

export class PasswordRecoveryConfirmFailureAction implements Action {
  readonly type = AuthActionTypes.PasswordRecoveryConfirmFailureAction;

  constructor(public payload: any) {
  }
}

export type AuthActions = SignInAction
  | SignInSuccessAction
  | SignInFailureAction
  | GetUserAction
  | GetUserSuccessAction
  | GetUserFailureAction
  | SignUpAction
  | SignUpSuccessAction
  | SignUpFailureAction
  | SignUpStepPersonalDataAction
  | SignUpStepPersonalDataSuccessAction
  | SignUpStepPersonalDataFailureAction
  | SignUpStepPersonalContactAction
  | SignUpStepPersonalContactSuccessAction
  | SignUpStepPersonalContactFailureAction
  | SignUpStepCompanyAction
  | SignUpStepCompanySuccessAction
  | SignUpStepCompanyFailureAction
  | SignOutAction
  | SignOutSuccessAction
  | SignOutFailureAction
  | ProfileUpdateAction
  | ProfileUpdateSuccessAction
  | ProfileUpdateFailureAction
  | ChangeStateOnboardingAction
  | ConfirmEmailAction
  | ConfirmEmailFailureAction
  | PasswordRecoveryConfirmAction
  | PasswordRecoveryConfirmFailureAction
  | PasswordRecoveryConfirmSuccessAction
  ;
