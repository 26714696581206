import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FileModel} from '@core/models/file.model';

@Component({
  selector: 'content-view',
  templateUrl: './content-view.component.html',
  styleUrls: ['./content-view.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentViewComponent implements OnInit {
  private _content: string;
  private _files: FileModel[] = [];

  @Input()
  set content(value: string) {
    this._content = value;

    if (this.isPreviewMode) {
      this._dataPreparationForPreview();
    }
  }

  get content() {
    return this._content;
  }

  @Input()
  set files(value: FileModel[]) {
    this._files = value;
  }

  get files() {
    return this._files;
  }

  @Input() isPreviewMode = false;

  previewContent = '';
  spoilerContent = '';
  isDeployed = false;

  constructor(
    private _cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    if (this.isPreviewMode) {
      this._dataPreparationForPreview();
    }
  }

  onChangeDeployed(value) {
    this.isDeployed = value;
  }

  private _dataPreparationForPreview() {
    const div = document.createElement('div');
    div.innerHTML = this.content;
    const content = Array.from(div.childNodes);

    const previewContent = document.createElement('div');
    const spoilerContent = document.createElement('div');

    let isPreview = true;
    content.forEach((item, key) => {
      const isDelimiter = (i) => i['className'] && (i['className'].indexOf('ck-delimiter') > -1);

      if (isDelimiter(item)) {
        isPreview = false;
      }

      if (isPreview) {
        previewContent.appendChild(item);
      } else {
        spoilerContent.appendChild(item);
      }

      this.previewContent = previewContent.innerHTML;
      this.spoilerContent = spoilerContent.innerHTML;
    });

    // TODO: Сомнительно, возможно, и без этого будет работать
    // в крайнем случае markForCheck лучше, чем detectChanges
    this._cdr.detectChanges();
  }
}
