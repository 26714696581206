import 'rxjs/add/observable/combineLatest';
import 'rxjs/add/observable/combineLatest';
import 'rxjs/add/operator/map';
import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {CategoryModel} from '@core/models/category.model';
import {FilterInterface} from '@core/interfaces/filter-interface.model';
import {TagModel} from '@core/models/tag.model';
// import {ParamMap} from '@angular/router';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {debounceTime} from 'rxjs/operators';
import {Subscription} from 'rxjs/Subscription';
import * as fromRoot from '@core/redux/index';
import * as _ from 'lodash';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';

@Injectable({
  providedIn: 'root',
})
export class FilterService implements OnDestroy {
  /**
   * Подписки
   *
   * @type {Subscription}
   * @private
   */
  private _subscriptions$: Subscription = new Subscription();

  /**
   * Подписка на обновление фильтров
   *
   * @type {BehaviorSubject<FilterInterface>}
   */
  filterBehavior = new BehaviorSubject<FilterInterface>({
    category: null,
    tags: [],
    folder: 'active',
  });

  /**
   * Активные фильтра
   *
   * @type {{category: null; tags: any[]; folder: string}}
   * @private
   */
  private _filter: FilterInterface = {
    category: null,
    tags: [],
    folder: 'active',
  };

  private readonly defaultFilter: FilterInterface = {...this._filter};


  constructor(public router: Router,
              private activeRoute: ActivatedRoute,
              private store: Store<fromRoot.State>,
              ) {
    this._subscribeRouter();
  }

  /**
   *
   */
  ngOnDestroy() {
    this._subscriptions$.unsubscribe();
  }

  /**
   * Удалить категорию и обновить роутинг
   */
  deleteCategory() {
    this._filter.category = null;
    this.navigate();
  }

  /**
   * Сбросить фильтры
   */
  reset() {
    const nextFilter = {...this.defaultFilter};
    this.filterBehavior.next(nextFilter);
    this._filter = nextFilter;
  }

  /**
   * Включить или отключить тег из роутинга
   * @param {TagModel} tag
   */
  toggleTag(tag: TagModel) {
    const index = _.findIndex(this._filter.tags, {id: tag.id});
    if (index > -1) {
      this._filter.tags.splice(index, 1);
    } else {
      this._filter.tags.push(tag);
    }
    this.navigate();
  }

  /**
   * Применить фильтр
   */
  navigate() {
    const queryParams: any = {};
    if (this._filter.category) {
      queryParams.categoryId = this._filter.category.id;
    }

    if (this._filter.tags) {
      const tagIds = [];
      this._filter.tags.forEach((tag) => tagIds.push(tag.id));
      queryParams.tagIds = tagIds;
    }

    this.router.navigate([], {
      queryParams: queryParams
    });
  }

  /**
   * Указать активную директорию вики
   * @param {string} folder
   */
  setFolder(folder: string) {
    this._filter.folder = folder;
    this.filterBehavior.next(this._filter);
  }

  /**
   * Присваиваем категорию из ID роутинга
   *
   * @param {CategoryModel[]} categories
   * @param {ParamMap} params
   */
  private prepareCategory(categories: CategoryModel[], params: ParamMap) {
    if (categories.length && params.has('categoryId')) {
      this._filter.category = _.find<CategoryModel>(categories, {id: params.get('categoryId')});
    } else {
      this._filter.category = null;
    }
  }

  /**
   * Присваиваем теги по ID из роутинга
   *
   * @param {TagModel[]} tags
   * @param {ParamMap} params
   */
  private prepareTags(tags: TagModel[], params: ParamMap) {
    this._filter.tags = [];

    if (tags.length && params.has('tagIds')) {
      params
        .getAll('tagIds')
        .forEach((item) => {
          this._filter.tags.push(_.find(tags, {id: item}));
        });
    }
  }

  /**
   * Автоматическое заполнение фильтра из параметров роутинга
   *
   * @returns {Subscription}
   * @private
   */
  private _subscribeRouter() {
    const params$ = this.activeRoute.queryParamMap;
    const categories$ = this.store.pipe(select(fromRoot.getCategoryEntities));
    const tags$ = this.store.pipe(select(fromRoot.getTagEntities));

    const subscription = combineLatest(params$, categories$, tags$)
      .pipe(
        debounceTime(0)
      )
      .subscribe(([params, categories, tags]) => {
        params = params as ParamMap;
        tags = tags as TagModel[];
        categories = categories as CategoryModel[];

        this.prepareCategory(categories, params);
        this.prepareTags(tags, params);

        this.filterBehavior.next(this._filter);
      });

    this._subscriptions$.add(subscription);
  }
}
