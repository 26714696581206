<div (click)="onClick()" class="select-option__item" [ngClass]="{'select-option__item_padding4': type === 'thin'}">
  <div #contentWrapper>
    <i class="select-option__icon" *ngIf="icon" [ngClass]="icon"></i>
    <ng-content select="[option-type=name]"></ng-content>
  </div>
  <div class="select-option__description">
    <ng-content select="[option-type=description]"></ng-content>
  </div>
</div>
<span (click)="onEdit()" *ngIf="allowEdit" class="icon-pencil"></span>
<span *ngIf="selected" class="icon-done icon-green"></span>

