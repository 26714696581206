import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {go} from 'fuzzysort';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'ui-multi-select',
  templateUrl: './ui-multi-select.component.html',
  styleUrls: ['./ui-multi-select.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiMultiSelectComponent),
      multi: true
    }
  ]
})
export class UiMultiSelectComponent implements OnInit, ControlValueAccessor {
  private readonly _searchSettings = {key: 'name', threshold: -500};
  @Input() items: any[] = [];
  @Output() valueChange: EventEmitter<string[]> = new EventEmitter();
  filtered: any[] = [];

  @Input()
  get value(): any {
    return this._value;
  }

  set value(value: any) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
    this.valueChange.emit(this._value);
  }

  private _value: any;


  constructor() {
  }

  ngOnInit() {
    this.filtered = this.items;
  }

  onFilter(query) {
    if (!query) {
      this.filtered = this.items;
      return;
    }
    this.filtered = go<any>(query, this.items, this._searchSettings)
      .map(sortResult => sortResult.obj);
  }

  onChangeChecker(value, e) {
    value.isChecked = e.target.checked;
    this.value = this.items.filter(item => item.isChecked).map(item => item.id);
  }

  writeValue(values: string[]): void {
    this.items.forEach((item) => {
      item.isChecked = false;
    });
    this._value = values;
    values.forEach(value => {
      this.items.forEach((item) => {
        if (item.id === value) {
          item.isChecked = true;
        }
      });
    });
  }

  onChange(value) {
  }

  onTouched() {
  }

  registerOnChange(fn: (rating: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
