<div class="test-results__cover" *ngIf="test.image" [style.backgroundImage]="'url('+ test.image.link +')'"></div>
<button
  (click)="onClose()"
  class="button button_not-button test-results__close">
  <i class="icon-close"></i>
</button>

<div class="test-results__container">
  <h2 class="test-results__title">
    {{ test?.name }}
  </h2>
  <div
    *ngIf="test?.results; else loadingTemplate"
    class="test-results__header">
    <div class="test-results__img">
      <ui-avatar
        [user]="user$ | async"
        class="test-results__avatar">
      </ui-avatar>
    </div>
    <div class="test-results__main">
      <div
        [ngClass]="['test-results__mark', 'test-results__text_' + (test.results.userResult ? (test.results.userResult.score || 1) : 0)]">
        <ng-container *ngIf="!test.results.userResult">
          Тест еще не пройден
        </ng-container>
        <ng-container *ngIf="test.results.userResult?.score === 1">
          Вернитесь к изучению темы
        </ng-container>
        <ng-container *ngIf="!test.results.userResult?.score === 2">
          Можно и лучше
        </ng-container>
        <ng-container *ngIf="!test.results.userResult?.score === 3">
          Отлично!
        </ng-container>
      </div>
      <div class="test-results__common-text">
        <ng-container *ngIf="!test.results.userResult">
          Вопросов в тесте:
        </ng-container>
        <ng-container *ngIf="test.results.userResult">
          Вы ответили правильно на:
        </ng-container>
      </div>
      <div class="test-results__results">
        <ng-container *ngIf="!test?.results?.userResult">
          {{ test.questions.length }}
        </ng-container>
        <ng-container *ngIf="test?.results?.userResult">
            <span
              [ngClass]="['test-results__right-answers-count', 'test-results__text_' + (test.results.userResult ? (test.results.userResult.score || 1) : 0)]">
              {{ test.results.userResult.countCorrectAnswer }}
            </span>
            из {{ test.results.userResult.totalCountQuestions }} вопросов
        </ng-container>
      </div>
    </div>
    <div class="test-results__actions">
      <button
        (click)="onStartAgain()"
        class="button button_theme-10">
        <ng-container *ngIf="!test.results.userResult">
          Начать тест
        </ng-container>
        <ng-container *ngIf="test.results.userResult">
          Пройти заново
        </ng-container>
      </button>
      <span
        *ngIf="test?.results?.userResult"
        class="test-results__count">
        Завершено раз: {{ test.results.userResult.numberOfAttempts }}
      </span>
    </div>
  </div>

  <div
    *ngIf="test?.results"
    class="test-results__rating">
    <table
      *ngIf="test?.results?.allResults?.length"
      class="test-results__table">
      <tr
        *ngFor="let result of test.results.allResults; let index = index"
        class="test-results__table-row">
        <td class="test-results__table-col-number">
          {{ index + 1 }}
        </td>
        <td class="test-results__table-col-avatar">
          <ui-avatar
            *ngIf="result.user"
            [user]="result.user"
            class="test-results__table-avatar">
          </ui-avatar>
        </td>
        <td class="test-results__table-col-name">
          {{ result.user?.firstName }} {{ result.user?.lastName }}
        </td>
        <td class="test-results__table-col-time">
          {{ result.stringTime }}
        </td>
        <td
          class="test-results__table-col-results">
          <span
            [ngClass]="'test-results__text_' + result.score">
            {{ result.countCorrectAnswer }}
          </span> из {{ result.totalCountQuestions }}
        </td>
        <td
          [ngSwitch]="result.score"
          class="test-results__table-col-mark">
          <i
            *ngSwitchCase="1"
            class="icon-sad-face icon-orange">
          </i>
          <img
            *ngSwitchCase="2"
            src="/assets/img/modest.svg">
          <i
            *ngSwitchCase="3"
            class="icon-done icon-green">
          </i>
        </td>
      </tr>
    </table>
    <button
      *ngIf="test?.results?.allResultsCount > maxRowsCount"
      (click)="onToggle()"
      [ngSwitch]="isExpanded$ | async"
      class="button button_not-button test-results__load-stats">
      <ng-container *ngSwitchCase="true">
        Свернуть
      </ng-container>
      <ng-container *ngSwitchCase="false">
        Показать всю статистику
      </ng-container>
    </button>
    <div
      *ngIf="!test?.results?.allResults?.length"
      class="test-results__future-stats">
      <i class="icon-chart-small"></i>
      Тут будет рейтинг прохождения этого теста
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  Loading...
</ng-template>
