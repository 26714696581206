import { QuestionModel, TestModel } from '@core/models';
import { shuffle } from '@helpers/arrays/shuffle';
import {
  TestAnswerState,
  TmpAnswerModel,
  TmpTestModel,
  TmpQuestionModel,
  UncheckedAnswerModel
} from './types';


/**
 * Проверить вопрос
 * Проверить, правильно ли пользователь (не-)выбрал ответ,
 * и отметить это в возвращенной модели
 * @param answer вопрос на проверку
 */
export function checkAnswer(answer: UncheckedAnswerModel, isCorrect: boolean): TmpAnswerModel {
  const nextState =  isCorrect || answer.isChecked
    ? isCorrect ? TestAnswerState.Right : TestAnswerState.Wrong
    : TestAnswerState.Unchecked;
  return { ...answer, state: nextState};
}

/**
 * Добавить дополнительные поля в тест с сервера и перемешать вопросы и ответы
 * @param test тест с сервера
 */
export function mapTest(test: TestModel): TmpTestModel {
  const questions: TmpQuestionModel[] = test.questions.map(mapQuestion);
  return {...test, questions: shuffle(questions)};
}

/**
 * Добавить временные поля в вопрос и перемешать ответы
 * @param question вопрос
 */
export function mapQuestion(question: QuestionModel): TmpQuestionModel {
  const additionalAnswerState = {
    isChecked: false,
    state: TestAnswerState.Unchecked
  };
  const answers: TmpAnswerModel[] = question.answers
    .map(a => ({...a, ...additionalAnswerState}));
  return {...question, answers: shuffle(answers), selectedAnswers: []};
}
