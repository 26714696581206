import { Directive, Input, OnInit, ElementRef, TemplateRef, ViewContainerRef, OnDestroy, AfterViewInit } from '@angular/core';
import { Overlay, OverlayRef, OverlayPositionBuilder, ConnectedPosition } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';

export const classWhenShown = 'tooltip-shown';

@Directive({
  selector: '[rtTooltip]',
})
export class TooltipDirective implements AfterViewInit, OnInit, OnDestroy {
  @Input()
  set rtTooltip(v: TemplateRef<undefined>) {
    this.template = v;
  }
  get rtTooltip(): TemplateRef<undefined> {
    return this.template;
  }

  @Input()
  position: ConnectedPosition[] = [{
    originX: 'end',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'bottom',
    offsetY: -24,
    offsetX: 18,
  }];

  @Input()
  open = false;

  private template: TemplateRef<undefined>;
  private overlayRef: OverlayRef;

  get opened(): boolean {
    return this.overlayRef.hasAttached();
  }

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private viewContainerRef: ViewContainerRef,
  ) { }

  ngAfterViewInit() {
    if (this.open) {
      this.show();
    }
  }

  ngOnDestroy() {
    this.hide();
  }

  ngOnInit() {
    const positionStrategy = this.overlayPositionBuilder
      // Create position attached to the elementRef
      .flexibleConnectedTo(this.elementRef)
      // Describe how to connect overlay to the elementRef
      // Means, attach overlay's center bottom point to the         
      // top center point of the elementRef.
      .withPositions(this.position);
    this.overlayRef = this.overlay.create({positionStrategy});
  }

  toggle() {
    if (this.opened) {
      this.hide();
    } else {
      this.show();
    }
  }

  show() {
    if (this.opened) {
      return;
    }
    const tooltipPortal = new TemplatePortal(this.template, this.viewContainerRef);
    this.overlayRef.attach(tooltipPortal);
    this.elementRef.nativeElement.classList.add(classWhenShown);
  }

  hide() {
    this.overlayRef.detach();
    this.elementRef.nativeElement.classList.remove(classWhenShown);
  }
}
